export const Table = ({primaryColor="#ffd800", primaryFontColor="rgba(0,0,0,0.8)", secondaryColor="#3b4046"}) => {
    return (
        <svg  viewBox="0 0 973.245 817.199">
            <defs>
                <filter id="Rectangle_23" x="0" y="0" width="973.245" height="817.199" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
                </filter>
            </defs>
            <g id="Group_38572" data-name="Group 38572" transform="translate(-875 -690.801)">
                <g transform="matrix(1, 0, 0, 1, 875, 690.8)" filter="url(#Rectangle_23)">
                <rect id="Rectangle_23-2" data-name="Rectangle 23" width="955.245" height="799.199" rx="20" transform="translate(9 6)" fill="#fff"/>
                </g>
                <g id="Group_11707" data-name="Group 11707" transform="translate(2257.5 517.748)">
                <rect id="Rectangle_2876" data-name="Rectangle 2876" width="917.929" height="58" transform="translate(-1352 844.253)" fill="#f2f2f2"/>
                <rect id="Rectangle_2877" data-name="Rectangle 2877" width="917.929" height="58" transform="translate(-1352 738.157)" fill="#f2f2f2"/>
                <rect id="Rectangle_46" data-name="Rectangle 46" width="917.929" height="58" transform="translate(-1352 417.011)" fill="#f2f2f2"/>
                <rect id="Rectangle_53" data-name="Rectangle 53" width="917.929" height="58" transform="translate(-1352 299)" fill={secondaryColor}/>
                <rect id="Rectangle_49" data-name="Rectangle 49" width="917.929" height="58" transform="translate(-1352 631.753)" fill="#f2f2f2"/>
                <rect id="Rectangle_47" data-name="Rectangle 47" width="917.929" height="58" transform="translate(-1352 524.382)" fill="#f2f2f2"/>
                <rect id="Rectangle_48" data-name="Rectangle 48" width="917.929" height="58" transform="translate(-1352 739.124)" fill="#f2f2f2"/>
                <text id="J00949" transform="translate(-1311 395)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer" data-name="Java Developer" transform="translate(-1122.825 395)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada" transform="translate(-906.627 395)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active" transform="translate(-732.212 395)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ" transform="translate(-543.071 395)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-2" data-name="J00949" transform="translate(-1311 446.828)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-2" data-name="Java Developer" transform="translate(-1122.825 446.828)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-2" data-name="Canada" transform="translate(-906.627 446.828)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Inactive" transform="translate(-731.998 446.828)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Inactive</tspan></text>
                <text id="TDQ-2" data-name="TDQ" transform="translate(-543.071 446.828)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-3" data-name="J00949" transform="translate(-1311 501.098)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-3" data-name="Java Developer" transform="translate(-1122.825 501.098)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-3" data-name="Canada" transform="translate(-906.627 501.098)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-2" data-name="Active" transform="translate(-732.212 501.098)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-3" data-name="TDQ" transform="translate(-543.071 501.098)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-4" data-name="J00949" transform="translate(-1311 555.367)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-4" data-name="Java Developer" transform="translate(-1122.825 555.367)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-4" data-name="Canada" transform="translate(-906.627 555.367)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-3" data-name="Active" transform="translate(-732.212 555.367)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-4" data-name="TDQ" transform="translate(-543.071 555.367)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-5" data-name="J00949" transform="translate(-1311 611.575)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-5" data-name="Java Developer" transform="translate(-1122.825 609.636)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-5" data-name="Canada" transform="translate(-906.627 609.636)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-4" data-name="Active" transform="translate(-732.212 609.636)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-5" data-name="TDQ" transform="translate(-543.071 609.636)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-6" data-name="J00949" transform="translate(-1311 663.906)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-6" data-name="Java Developer" transform="translate(-1122.825 663.906)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-6" data-name="Canada" transform="translate(-906.627 663.906)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-5" data-name="Active" transform="translate(-732.212 663.906)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-6" data-name="TDQ" transform="translate(-543.071 663.906)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-7" data-name="J00949" transform="translate(-1311 718.175)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-7" data-name="Java Developer" transform="translate(-1122.825 718.175)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-7" data-name="Canada" transform="translate(-906.627 718.175)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-6" data-name="Active" transform="translate(-732.212 718.175)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-7" data-name="TDQ" transform="translate(-543.071 718.175)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-8" data-name="J00949" transform="translate(-1311 772.445)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-8" data-name="Java Developer" transform="translate(-1122.825 772.445)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-8" data-name="Canada" transform="translate(-906.627 772.445)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-7" data-name="Active" transform="translate(-732.212 772.445)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-8" data-name="TDQ" transform="translate(-543.071 772.445)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-9" data-name="J00949" transform="translate(-1311 826.714)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-9" data-name="Java Developer" transform="translate(-1122.825 826.714)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-9" data-name="Canada" transform="translate(-906.627 826.714)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-8" data-name="Active" transform="translate(-732.212 826.714)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-9" data-name="TDQ" transform="translate(-543.071 826.714)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-10" data-name="J00949" transform="translate(-1311 879.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-10" data-name="Java Developer" transform="translate(-1122.825 879.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-10" data-name="Canada" transform="translate(-906.627 879.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-9" data-name="Active" transform="translate(-732.212 879.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-10" data-name="TDQ" transform="translate(-543.071 879.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="J00949-11" data-name="J00949" transform="translate(-1311 935.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">J00949</tspan></text>
                <text id="Java_Developer-11" data-name="Java Developer" transform="translate(-1122.825 935.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Java Developer</tspan></text>
                <text id="Canada-11" data-name="Canada" transform="translate(-906.627 935.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Canada</tspan></text>
                <text id="Active-10" data-name="Active" transform="translate(-732.212 935.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Active</tspan></text>
                <text id="TDQ-11" data-name="TDQ" transform="translate(-543.071 935.253)" fill="#3e3e3e" font-size="16" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">TDQ</tspan></text>
                <text id="Job_Code" data-name="Job Code" transform="translate(-1308 333)" fill="#fff" font-size="18" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Job Code</tspan></text>
                <text id="Job_Title" data-name="Job Title" transform="translate(-1123.126 333)" fill="#fff" font-size="18" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Job Title</tspan></text>
                <text id="Location" transform="translate(-907.524 333)" fill="#fff" font-size="18" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Location</tspan></text>
                <text id="Job_Status" data-name="Job Status" transform="translate(-732.487 333)" fill="#fff" font-size="18" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Job Status</tspan></text>
                <text id="Client" transform="translate(-544.071 333)" fill="#fff" font-size="18" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Client</tspan></text>
                </g>
                <path id="Union_11" data-name="Union 11" d="M602.65,50H0V19A19,19,0,0,1,19,0H767L721.146,50Z" transform="translate(883 697)" fill={primaryColor}/>
                <text id="List_of_Requirements_Assigned_to_Recruiters" data-name="List of Requirements Assigned to Recruiters" transform="translate(905.5 729.001)" fill={primaryFontColor} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">List of Requirements Assigned</tspan></text>
            </g>
        </svg>
    )
}