
export const Video = ({primaryColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66.1" height="45.602" viewBox="0 0 66.1 45.602">
      <g id="video-lesson" transform="translate(-3 -6.699)">
        <g id="Group_38374" data-name="Group 38374">
          <g id="Group_38373" data-name="Group 38373">
            <g id="Group_38370" data-name="Group 38370">
              <path id="Path_60892" data-name="Path 60892" d="M64.9,6.7H7.1a4.034,4.034,0,0,0-4.1,4V48.2a4.118,4.118,0,0,0,4.1,4.1H65a4.118,4.118,0,0,0,4.1-4.1V10.7a4.188,4.188,0,0,0-4.2-4ZM36,46A16.6,16.6,0,1,1,52.6,29.4,16.623,16.623,0,0,1,36,46Z" fill={primaryColor} />
            </g>
            <g id="Group_38372" data-name="Group 38372">
              <g id="Group_38371" data-name="Group 38371">
                <path id="Path_60893" data-name="Path 60893" d="M43.5,29.5A2.973,2.973,0,0,1,42.1,32l-8.3,5a2.822,2.822,0,0,1-1.5.4,2.3,2.3,0,0,1-1.4-.4,2.946,2.946,0,0,1-1.5-2.5v-10A2.75,2.75,0,0,1,30.9,22a2.991,2.991,0,0,1,2.9,0l8.3,5A2.739,2.739,0,0,1,43.5,29.5Z" fill={primaryColor} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>



  )
}

