import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { DateRangePicker } from "materialui-daterange-picker";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { decrypt } from "../../../../_utilities/_encryptDecryptHelper";
import * as _appSer from "../../../../_redux/_services/ApplicantsSlice";

// import "../ApplicantsNewDashboard.css";

const DateTime = ({
  handleAddCard,
  handleSaveConfig,
  updateDate,
  reloadWidgets,
  editMode,
  setEditMode,
}) => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();

   const selectedCountry = JSON.parse(decrypt('selectCountry'));
   const allCountries = JSON.parse(decrypt('allCountryId'));
   const prevCountry = Cookies.get("selectCountryId");

  const onLoadWidgetHandler = () => {
    const formatStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formatEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const getAttributes = JSON.parse(localStorage.getItem("getAttributes"));
    if (getAttributes) {
      const payload = {
        startDate: formatStartDate,
        endDate: formatEndDate,
        userId: getAttributes[0].userId,
        countryId: allCountries === "all" ? allCountries : selectedCountry?.id ? selectedCountry?.id : prevCountry
      };
      dispatch(_appSer.getResumeCountAsync(payload));
      dispatch(_appSer.getPieChartDataAsync(payload));
      dispatch(_appSer.getBarChartDataAsync(payload));
      fetchRequirementAssigned(getAttributes[0].userId);
    }
  };

  useEffect(() => {
    onLoadWidgetHandler();
  }, []);

  useEffect(() => {
    const formatStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formatEndDate = dayjs(endDate).format("YYYY-MM-DD");
    updateDate({
      startDate: formatStartDate,
      endDate: formatEndDate,
    });
  }, [startDate, endDate]);

  const toggle = () => setOpen(!open);

  const handleDateChange = (range) => {
    setStartDate(range.startDate);
    setEndDate(range.endDate);
    //  setDateRange(range);
    setOpen(false);
  };

  const fetchRequirementAssigned = (userId) => {
    let data = {
      limit: 10,
      offSet: 1,
      userId: userId,
      countryId: allCountries === "all" ? allCountries : selectedCountry?.id ? selectedCountry?.id : prevCountry
    };
    dispatch(_appSer.getRequirementAssignedAsync(data));
  };

  return (
    <div className="date-time-toolbar-container">
      <div className="dashbaord__date-time-container">
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <div className="educationTermRangePicker">
              <TextField
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                label="Start Date"
                placeholder="Start Date"
                style={{ width: "50%" }}
                name="startdate"
                value={startDate.toLocaleDateString()}
                onClick={() => setOpen(true)}
                InputLabelProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                label="End Date"
                placeholder="End Date"
                style={{ width: "50%" }}
                name="enddate"
                value={endDate.toLocaleDateString()}
                onClick={() => setOpen(true)}
                InputLabelProps={{
                  style: {
                    fontWeight: "bold",
                  },
                }}
              />
            </div>
            <DateRangePicker
              open={open}
              toggle={toggle}
              maxDate={new Date()}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              className="onHover"
              style={{
                backgroundColor: "var(--primary-bg-color)",
                color: "var(--primary-background-text-color)",
                borderRadius: "10px",
                textTransform: "capitalize",
                width: "92px",
                font: "normal normal normal 0.875rem Roboto",
                height: "47px",
                fontWeight: "600",
                fontSize: "14px",
              }}
              onClick={() => {
                onLoadWidgetHandler();
                reloadWidgets();
              }}
            >
              Reload
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="dashboard__toolbar">
        {editMode && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <Button
              onClick={handleAddCard}
              style={{
                marginRight: "15px",
                padding: 0,
              }}
            >
              <AddIcon className="add-icon" />{" "}
              <span className="add-text">Add</span>
            </Button>
          </div>
        )}
        <Button
          onClick={() => editMode ? handleSaveConfig() : setEditMode(true)}
          className="dashboard__toolbar-button"
          variant="contained"
        >
          {editMode ? "Save" : "Edit"}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(DateTime);
