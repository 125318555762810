
export const Portal = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg id="_008-form" data-name="008-form" xmlns="http://www.w3.org/2000/svg" width="18.583" height="22" viewBox="0 0 18.583 22">
    <path id="Path_6307" data-name="Path 6307" d="M3.33,4.656V17.8A3.667,3.667,0,0,0,6.988,21.46h4.951a5.148,5.148,0,0,1,.433-1.468,5.605,5.605,0,0,1,3-2.878,3.593,3.593,0,0,1-1.55-2.966,3.639,3.639,0,0,1,3.636-3.634,3.468,3.468,0,0,1,1.036.154,3.6,3.6,0,0,1,1.469.852V4.656A3.657,3.657,0,0,0,16.3,1H6.988A3.662,3.662,0,0,0,3.33,4.656Zm4.437-.015h7.757a.734.734,0,1,1,0,1.468H7.767a.734.734,0,1,1,0-1.468Zm0,3.906h7.757a.734.734,0,1,1,0,1.468H7.767a.734.734,0,1,1,0-1.468Zm0,3.9h3.526a.734.734,0,1,1,0,1.468H7.767a.734.734,0,1,1,0-1.468Z" transform="translate(-3.33 -1)" fill={primaryFontColor}/>
    <circle id="Ellipse_45" data-name="Ellipse 45" cx="2.494" cy="2.494" r="2.494" transform="translate(11.673 10.65)" fill={primaryFontColor}/>
    <path id="Path_6308" data-name="Path 6308" d="M17.183,29.03h7.38a.725.725,0,0,0,.723-.723,4.413,4.413,0,1,0-8.825,0,.725.725,0,0,0,.723.723Z" transform="translate(-6.703 -7.03)" fill={primaryFontColor}/>
  </svg>
  

  )
}

