
export const Notesemail = ({ primaryColor = "#ffd800", primaryFontColor = "rgb(255, 255, 255)" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="69" height="69" viewBox="0 0 69 69">
      <defs>
        <filter id="Ellipse_10" x="0" y="0" width="69" height="69" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_5046" data-name="Group 5046" transform="translate(-309 -559.999)">
        <g id="Group_2207" data-name="Group 2207" transform="translate(-1411.511 536.488)">
          <g transform="matrix(1, 0, 0, 1, 1720.51, 23.51)" filter="url(#Ellipse_10)">
            <circle id="Ellipse_10-2" data-name="Ellipse 10" cx="25.5" cy="25.5" r="25.5" transform="translate(9 6)" fill={primaryColor} />
          </g>
        </g>
        <g id="email" transform="translate(333.189 583.777)">
          <path id="Path_15708" data-name="Path 15708" d="M12.034,177.133a3.1,3.1,0,0,1-3.447,0L.137,171.5c-.047-.031-.093-.064-.137-.1v9.231a1.9,1.9,0,0,0,1.9,1.9H18.723a1.9,1.9,0,0,0,1.9-1.9V171.4c-.045.034-.091.066-.138.1Z" transform="translate(0 -167.087)" fill={primaryFontColor} />
          <path id="Path_15709" data-name="Path 15709" d="M.808,67.673l8.45,5.634a1.893,1.893,0,0,0,2.106,0l8.45-5.634a1.809,1.809,0,0,0,.808-1.509,1.9,1.9,0,0,0-1.9-1.9H1.9a1.9,1.9,0,0,0-1.9,1.9,1.809,1.809,0,0,0,.808,1.508Z" transform="translate(0 -64.266)" fill={primaryFontColor} />
        </g>
      </g>
    </svg>


  )
}

