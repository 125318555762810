import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateNextPath } from '../../../_redux/SelectedNextPath';
import * as _ser from "../../../_redux/_services/ApplicantsSlice";
import * as _sercloud from "../../../_redux/_services/CloudSourcingSlice";
import * as _props from '../../../_redux/_services/JobPostingSlice';
import GetJobId from '../../../_utilities/GetJobId';
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import AssociateCards from '../../../components/AssociateCards/AssociateCards';
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import RightSideBar from "../../../components/Rightsidebar/RightSideBar";
import PostingsView from '../../JobPostings/PostingsView/PostingsView';
import './ApplicantAssociatedJobs.css';
import { GetTimeZone } from "../../../_utilities/GetTimeZone";
import { getCandidateInternalStatus, getCandidateStatusId, getCandidateStatusCustomValues } from "../../../_utilities/internalexternalhelper";
import { useNavigate } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import { Button, ButtonGroup, FormControl, MenuItem, Select } from "@mui/material";
import { customFieldsAsync } from "../../../_redux/_services/UserManagerSlice";
import { defaultApplicantsData } from "../../../_utilities/defaultfieldsModel";
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import { PlusIcon } from "../../../Icons/PlusIcon";
import { UpdateStausillus } from '../../../Icons/UpdateStausillus';



const initalState = { data: null, message: '', isloader: true }

const ApplicantAssociatedApplicants = () => {
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);

  const [showTable, setShowTable] = useState(false);
  const [AccociatedJobSeekerList, setAccociatedJobSeekerList] = useState([]);
  const [qnsCount, setQnsCount] = useState(0);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [getData, setGetData] = useState("");
  const [questionsData, setQuestionsData] = useState([]);
  const [config, setConfig] = useState(configuration);
  const getJobCode = GetJobId();
  const dispatch = useDispatch();
  const [componentType, setComponentType] = useState("AssociatedJobs");
  const [isValidUser,setIsValidUser ] = useState(false);
  const [pannelobj, setPannelobj] = useState({ title: "", description: "", bgImage: "", navigateTo:"" })
  const [candidateStatusValues, setCandidateStatusValues] = useState([]);


  const nextPathValue = useSelector((state) => state.SeletedNextPath.value);
  const navigate = useNavigate();
  const customFieldsData = useSelector(state => state.CustomFields.data);

  useEffect(() => {
    if (getJobCode !== null) {
      FetchData();
    }
    // eslint-disable-next-line
  }, [getJobCode]);

  useEffect(() => {
    if (nextPathValue === "null") {
      setShowJobDetails(false);
      localStorage.setItem("AssociatedMenu", "false");
      window.dispatchEvent(new Event('storage'))
    }
  }, [nextPathValue]);

  const GetApplicantJobsData = useSelector((state) => state.GetApplicantJobs.data);
  const GetApplicantJobsCode = useSelector((state) => state.GetApplicantJobs.code);

  useEffect(()=>{
    if(GetApplicantJobsCode === 404) {
      setIsValidUser(true);
    }
    },[GetApplicantJobsCode])

  const FetchData = async () => {
    setAccociatedJobSeekerList([]);
    dispatch(_ser.getApplicantJobsAsync(getJobCode));
  };

  useEffect(()=>{
    let userId = JSON.parse(decrypt('GetUserId'));
    dispatch(customFieldsAsync(userId));
  },[])

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
        const array = getCandidateStatusCustomValues(customFieldsData);
        setCandidateStatusValues(array);
    }
  }, [customFieldsData])

  useEffect(()=> {
    if(GetApplicantJobsData?.length && candidateStatusValues.length) {
      let myData = JSON.parse(JSON.stringify(GetApplicantJobsData));
      let newArray = [];
      myData.forEach((e, i) => {
        e["percentage"] = Math.round(e.matchPercentage * 100);
        e["lastUpdated"] = GetTimeZone(e.lastUpdated);
        e["isChecked"] = false;
        if(candidateStatusValues.length){
          e.status = getCandidateInternalStatus(e.status, candidateStatusValues);
        }
        e["id"] = i + 1;
        if (
          e.sourcedFrom === "Cloudsourcing" ||
          e.sourcedFrom === "Employee Portal"
        ) {
          e["screeningquestions"] = `${e.anscount}/${e.qnscount}`;
        } else {
          e["screeningquestions"] = "";
        }
        newArray.push(e);
      });
      setAccociatedJobSeekerList(newArray);
      let configure = JSON.parse(JSON.stringify(config))
      configure.totalRecords = newArray.length - 1;
      configure.currentPage = 1;
      setConfig(configure)
      setComponentType("AssociatedJobs");
      dispatch(_ser.getApplicantJobsStartReducer(initalState))
    }
  }, [candidateStatusValues, GetApplicantJobsData])

  const onReceiveActionHandler = async (type, object) => {
    setShowTable(false);
    if (type === "pagination") {
      FetchData(object);
    } else if (type === "searchResult") {
    } else if (type === "cancelSearchResult") {
      setFilterValue("");
      let obj1 = {
        current: config.currentPage,
        offset: config.currentPage !== 1 ? (config.currentPage - 1) * 20 + 1 : config.currentPage,
      };
      FetchData(obj1);
    } else if (type === "confirmDel") {
      DeleteRecords(object);
    } else if (type === "detailspage") {
      setIsRightSidePannel(true);
      setComponentType("jobProfile")
      let obj = {
        jobopeningID : object.jobopeningID,
        asscoId : object.asscoId,
        status : object.status
      }
      setGetData(obj);
      console.log(object.jobopeningID);
      setPannelobj({ ...pannelobj, title: `${object.jobTitle} (${object.jobCode})`, navigateTo: `/ats/layout/jobpostings/view/${object.jobopeningID}` });
      // sessionStorage.setItem("AssociatedId", object.jobopeningID);
      // dispatch(updateNextPath(object.jobTitle));

      // setShowJobDetails(true);
    //   navigate(`/ats/layout/applicants/associatedjobs/jobview/${getJobCode}`, {
    //     state: { object }
    // });
    //   sessionStorage.setItem("actualId", getJobCode);
    //   localStorage.setItem("AssociatedMenu", object.jobTitle);
    //   window.dispatchEvent(new Event('storage'))
    } else if (type === "viewAnswers") {
      let Obj = {
        cs_id: object.cs_id,
        job_id: object.jobopeningID,
        applicant_id: object.applicantID,  //TODO
      };
      dispatch(_sercloud.getQuestionsAnswersReducerAsync(Obj));
    } else if(type === "pageRange") {
      setConfig({...config,limitData:config.limitData=object, recordperPage:config.limitData=object})
    }
    setTimeout(() => { setShowTable(true) }, 100);
  }

  const delApplicantJobsCode = useSelector((state) => state.DeleteApplicantJobs.data);

  const responseOfQuestionAnswer = useSelector((state) => state.GetQuestionsAnswers.data);

  const DeleteRecords = async (object) => {
    if (object.sourcedFrom === "Cloudsourcing") {
      object["portalassociatedJobs"] = "csassociatedJobs";
    } else if (object.sourcedFrom === "Employee Portal") {
      object["portalassociatedJobs"] = "eassociatedJobs";
    } else if (object.sourcedFrom === "Vendorsourcing") {
      object["portalassociatedJobs"] = "vsassociatedJobs";
    } else {
      object["portalassociatedJobs"] = "associatedJobs";
    }
    object["id"] = object[0].jobopeningID;
    let array = object.map((i => i.asscoId));
    let deleteIds = { ids: array};
    dispatch(_ser.deleteApplicantJobsAsync(getJobCode, object, deleteIds));
  };

  useEffect(() => {
    if (delApplicantJobsCode === 200) {
      let obj1 = {
        current: config.currentPage,
        offset: config.currentPage !== 1 ? (config.currentPage - 1) * 20 + 1 : config.currentPage,
      };
      FetchData(obj1);
      dispatch(_ser.deleteApplicantJobsStartReducer(initalState))
    }
  }, [delApplicantJobsCode])

  useEffect(() => {
    if (responseOfQuestionAnswer && responseOfQuestionAnswer.length > 0) {
      let newObj = JSON.parse(JSON.stringify(responseOfQuestionAnswer));
      newObj.forEach(item => {
        item.answer = JSON.parse(item.answer);
      })
      setQuestionsData(newObj);
      setIsRightSidePannel(true);
      setComponentType("Questions&Answers");
      dispatch(_sercloud.getQuestionsAnswersStartReducer({}));
    }
  }, [responseOfQuestionAnswer]);

  useEffect(() => {
    console.log(AccociatedJobSeekerList.length > 0);
  }, [showTable, AccociatedJobSeekerList])

  const onReceivePropsHandler = (pannel, load) => {
    if (load === "getFetch") {
      setIsRightSidePannel(pannel);
      FetchData();
    } else if (load === "close") {
      setIsRightSidePannel(pannel);
      setComponentType("AssociatedJobs");
    }
  };

  const storedTheme = JSON.parse(localStorage.getItem("themeSettings")) || '';

  return (
    <>
      {!showJobDetails && !isValidUser ?
        <>
          <div
            className="adddocumentsection"
            style={{ display: "flex", height: "34px", gap: "10px" }}
            onClick={() => setIsRightSidePannel(true)}
          >
            {/* <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" /> */}
            <PlusIcon primaryColor={storedTheme?.primaryColor}
            primaryFontColor={storedTheme?.primaryFontColor} />
            <h4 className="addnewdocumenttext" > Associate Jobs</h4>
          </div>
        </>
        : ''}
      {showJobDetails ?
        <div id="InnerComponents">
          {/* <PostingsView getData={getData} /> */}
        </div>
        : <div className="contentSection">
          {AccociatedJobSeekerList.length > 0 && (
            <DynamicTable
              config={config}
              data={AccociatedJobSeekerList}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              actions={actionsList}
              searchKeyWord="Search by Job code, Title"
              noDataText="No Associated Job"
              filterValue={filterValue}
              addModel={addNewModel}
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
          {AccociatedJobSeekerList.length === 0 && (
            <DynamicTable
              config={config}
              data={AccociatedJobSeekerList}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              actions={actionsList}
              searchKeyWord="Search by Job code, Title"
              noDataText="No Associated Job"
              filterValue={filterValue}
              addModel={addNewModel}
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
        </div>
      }

      <div className={componentType === "jobProfile" ? "jobProfileWidth" :"rightsidebarbtn" }>
        {isRightSidePannel ? (
          <RightSideBar
            componentData={
              <ComponentRenderData
                JobId={getJobCode}
                isLoad={isRightSidePannel}
                clientData={AccociatedJobSeekerList}
                componentType={componentType}
                onReceivechildProps={onReceivePropsHandler}
                questionsData={questionsData}
                getData= {getData}
              />
            }
            componentLayout={componentType === "AssociatedJobs" ? ASAObj : componentType === "Questions&Answers" ? QAObj : componentType === "jobProfile" ? pannelobj : ''}
            footerImg={componentType === "AssociatedJobs" ? <UpdateStausillus /> : componentType === "Questions&Answers" ? <UpdateStausillus /> : componentType === "jobProfile" ? <UpdateStausillus /> : ''}
            onReceiveProps={onReceivePropsHandler}
          />
        ) : (
          ""
        )}
      </div>
    </>
  )
}


const ComponentRenderData = ({ JobId, clientData, onReceivechildProps, isLoad, componentType, questionsData, getData }) => {
  const [jobData, setjobData] = useState(null);
  const [AlljobData, setAlljobData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);  // clientData.map(x => x.jobopeningID)
  const [findconfig, setFindconfig] = useState(findconfiguration);
  const [aiconfig, setaiconfig] = useState(aiconfiguration);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('1');
  const [totalRecords, setTotalRecords] = useState(null);
  const [documents, setDocuments] = useState([]);
  const getJobCode = GetJobId();
  const [candidateStatusValues, setCandidateStatusValues] = useState([]);
  const [updateStatus, setUpdateStatus] = useState("");

  const [managerObj, setManagerObj] = useState([]);
  const [teamLeadObj, setteamLeadObj] = useState([]);
  const[jobPostingsDetails,setJobPostingsDetails]=useState({});

  useEffect(() => {
    if(getData){
      dispatch(_props.jobpostingsbyidAsync(getData.jobopeningID));
    }
  }, [getData]);

  useEffect(() => {
    let userId = JSON.parse(decrypt('GetUserId'))
    dispatch(_props.getManagerTeamleadAsync("team Lead", userId))
    dispatch(_props.getManagerTeamleadAsync("manager", userId))
  }, [])

  const teamleadList = useSelector(state => state.GetManagerTeamlead.teamLeads);
  const managerList = useSelector(state => state.GetManagerTeamlead.manager);

  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);

  useEffect(() => {
    if (getDetails !== null && getDetails !== undefined && managerList && teamleadList) {
      let postingData = JSON.parse(JSON.stringify(getDetails));
      let managerIndex = managerList?.findIndex((x)=> x.id === postingData.manager);
      if(managerIndex !== -1){
        postingData.managerName = managerList[managerIndex].firstName + " " +  managerList[managerIndex].lastName;
      } else {
        postingData.managerName = "";
      }
      let teamLeadIndex = teamleadList?.findIndex((x)=> x.id === postingData.teamLead);
      if(teamLeadIndex !== -1){
        postingData.teamLeadname = teamleadList[teamLeadIndex].firstName + " " +  teamleadList[teamLeadIndex].lastName;
      } else {
        postingData.teamLeadname = "";
      }
      let jobType = (postingData.jobType).split(",");
      postingData.jobType = jobType.join(", ");
      setJobPostingsDetails(postingData);

      dispatch(_props.jobpostingsbyidStartReducer(initalState));
      // console.log(getDetails);
      // let name =`${postingData.jobTitle} (${postingData.jobCode})`
      // setBreadCrumb(postingData.id, name, "Job Code")
    }
  }, [getDetails, teamleadList, managerList]);

  

  useEffect(() => {
    if (teamleadList && teamleadList.length > 0) {
      const data = JSON.parse(JSON.stringify(teamleadList));
      console.log("teamleadList data", data);
      setteamLeadObj(data);
      dispatch(_props.getManagerTeamleadStartReducer({}))
    }
  }, [teamleadList])

  useEffect(() => {
    if (managerList && managerList.length > 0) {
      const data = JSON.parse(JSON.stringify(managerList));
      console.log("managerList data", data);
      setManagerObj(data);
      dispatch(_props.getManagerTeamleadStartReducer({}))
    }
  }, [managerList])
  
  useEffect(()=>{
    let selectedIdsArray = [];
    clientData.map((x) => {
      let obj = {
        id : x.jobopeningID, 
        documentName : x.documentName,
        lastUpdated : x.docCreatedDate,
        uploadedBy: x.docUploadedBy,
        docId: x.docId
      };
      selectedIdsArray.push(obj)
    } )
    setSelectedIds(selectedIdsArray);
  },[clientData, AlljobData])

  const GetMatchAssociatedJobsData = useSelector((state) => state.GetMatchAssociatedJobs.data);

  const GetData = async () => {
    dispatch(_ser.getMatchAssociatedJobsAsync(JobId));
  };

  useEffect(() => {
    if (GetMatchAssociatedJobsData !== null && GetMatchAssociatedJobsData !== undefined) {
      setjobData(null);
      if (GetMatchAssociatedJobsData.length > 0) {
        let myData = JSON.parse(JSON.stringify(GetMatchAssociatedJobsData));
        setTimeout(() => { setjobData(myData) }, 200);
        dispatch(_ser.getMatchAssociatedJobsStartReducer(initalState))
      }
    }
  }, [GetMatchAssociatedJobsData])


  const onActionHandler = (id, type) => {
    if (type === "delete") {
      DeleteAssociatedjobs(id);
    } else if (type === "add") {
      AddAssociatedjobs(id);
      setSearchValue({keyword: id.keyword})
    } else if (type === "searchResult") {
      setSearchValue(id);
      getListJobs(id);
      FetchDoc(id);
    }
  }
  
  const DelAssociatedjobscode = useSelector((state) => state.DeleteAssociatedjobs.data);

  const DeleteAssociatedjobs = async (obj) => {
    obj["portalassociatedJobs"] = "associatedJobs";
    dispatch(_ser.deleteAssociatedjobsAsync(JobId, obj));
  }

  useEffect(() => {
    if (DelAssociatedjobscode === 200) {
      onReceivechildProps(true, "getFetch");
      dispatch(_ser.deleteAssociatedjobsStartReducer(initalState))
    }
  }, [DelAssociatedjobscode]);

  const AddAssociatedjobscode = useSelector((state) => state.AddAssociatedjobs.data);

  const GetDocumentsdata = useSelector(state => state.GetDocuments.data);

  const FetchDoc = async () => {
    setDocuments([]);
    dispatch(_ser.getDocumentsAsync(getJobCode));
  };

  useEffect(() => {
    if (GetDocumentsdata !== null) {
      let docData = JSON.parse(JSON.stringify(GetDocumentsdata));
      if (docData.length > 0) {
        docData.forEach((list) => {
          list.date = GetTimeZone(list.date);
          list["total_count"] = docData.length;
          list["uploadedBy"] = list.firstName + " " + list.lastName;
          list["docscreatedDate"] = list.createdDate;
        });
        let resumeData = docData.filter(x => x.documentType === "Resume");
        setDocuments(resumeData.reverse());
        dispatch(_ser.getDocumentsStartReducer(initalState))
      } else {
        setDocuments([]);
      }
    }
  }, [GetDocumentsdata])

  let userInfo = JSON.parse(decrypt('GetUserInfo'));
  let userId = JSON.parse(localStorage.getItem("getAttributes"));

  const AddAssociatedjobs = async (obj) => {
    let userInfo = JSON.parse(decrypt('GetUserInfo'));
    let userId = JSON.parse(localStorage.getItem("getAttributes"));
    obj["portalassociatedJobs"] = "associatedJobs";
    obj["status"] = "Associated";
    obj["source"] = "ATS";
    obj["submittedBy"] = userInfo[0].userId;
    obj["firstName"] = obj.firstName;
    obj["lastName"] = obj.lastName;
    dispatch(_ser.addAssociatedjobsAsync(JobId, obj, "posting"));
    // let obj1 = { id: JobId, portalassociatedApplicants: "associatedApplicants", applicantCode: obj.jobCode }
    // dispatch(_props.addAssociatedjobsAsync(obj.id, obj1, "posting"));
  }

  useEffect(() => {
    if (AddAssociatedjobscode === 200) {
      onReceivechildProps(true, "getFetch");
      dispatch(_ser.addAssociatedjobsStartReducer(initalState));
      // getListJobs(searchValue);
    }
  }, [AddAssociatedjobscode])

  useEffect(() => {
    if (isLoad === true) {
      GetData();
    }
    // eslint-disable-next-line
  }, [JobId, isLoad]);

  const responseData = useSelector((state) => state.Listjobpostings.data);

  const getListJobs = async (obj) => {
    setAlljobData([]);
    dispatch(_props.listjobpostingsAsync(obj, 'searchWithoutPaging'));
  }

  const handleChange = (event, Value) => {
    setValue(Value);
    setAlljobData([]);
  };

  useEffect(() => {
    if (responseData && responseData?.rows?.length > 0) {
      setAlljobData(responseData.rows);
      setFindconfig({ ...findconfig, totalRecords: 0 });
      setTotalRecords(null);
      setSearchValue('');
      dispatch(_props.listjobpostingsStartReducer(initalState));
    } else if (responseData?.rows?.length === 0) {
      setAlljobData([])
    }
  }, [responseData])

  const onChangeHandler = (value, type) => {
     if (type === "candidateStatus") {
      setUpdateStatus(value);
    }
  };

    const HandleSubmit = async (event) => {
      if(updateStatus !== ""){

        const url = window.location.pathname;
        const path = url.substring(url.lastIndexOf("/") + 1);
        let jobId = getJobCode;
        const statusId = getCandidateStatusId(updateStatus, candidateStatusValues);
    let updateData = {
          data: [{
              id: (getData.asscoId).toString(),
              status: statusId,
              // applicantCode: profileData.applicantCode,
              applicantId: path,
              jobCode: jobPostingsDetails.jobCode
            }]
        }
        dispatch(_props.updateCandidateStatusAsync(jobId, updateData));
      }else{
        dispatch(updateSnackbar({ type: 'error', message: "Change Status" }));
      }
  }

  const updateCandidateCode = useSelector((state) => state.UpdateCandidateStatus.data);

  useEffect(() => {
    if (updateCandidateCode === 200) {
      dispatch(_props.updateCandidateStatusStartReducer(initalState))
      onReceivechildProps(false, "getFetch");
    }
  }, [updateCandidateCode]);

  useEffect(() => {
    let defaultPostingModules = defaultApplicantsData.filter(x => x.moduleId === "2");
    if (defaultPostingModules.length > 0) {
      defaultPostingModules.forEach(list => {
        if (list.fieldName === "Candidate status") {
          setCandidateStatusValues(list.customValues);
        }
      })
    }
  }, [])

  const customFieldsData = useSelector(state => state.CustomFields.data);

  useEffect(() => {
    let userId = JSON.parse(decrypt('GetUserId'))
    dispatch(customFieldsAsync(userId));
  }, []);

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const data = JSON.parse(JSON.stringify(customFieldsData));
      let customStatusFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "default" && x.fieldName === "Candidate status");
      if (customStatusFieldsData.length > 0) {
        // customStatusFieldsData.forEach(list => {
        // if (list.fieldName === "candidateStatus") {
        //   setCandidateStatusValues(JSON.parse(list.customValues));
        // }
        let index = customStatusFieldsData.length - 1;
        let array = JSON.parse(customStatusFieldsData[index].customValues)
        setCandidateStatusValues(array);
        // })
      }
    }
  }, [customFieldsData])
  return (
    <>
      {componentType === "AssociatedJobs" && (
        <div className='tabs'>
          <Box sx={{ width: '100%', typography: 'body1', color: "#3B4046", marginTop: "-35px" }} >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" className='tabsvalue' >
                  {/* <Tab label="AI matched jobs" value="2" /> */}
                  <Tab label="Find jobs" value="1" />
                </TabList>
              </Box>
              {/* <TabPanel value="2">
                <div className="mt-5">
                  {jobData && jobData.length > 0 ?
                    <AssociateCards data={jobData} selectedItems={selectedIds} isAImatched={true} searchKeyWord="Search by Job code, Title" navigateTo="/ats/layout/jobpostings/view/" config={aiconfig} componentType="AssociatedJobs"
                      onReceiveHandler={(Item, obj) => onActionHandler(Item, obj)} /> :
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No AI match Jobs
                      </div>
                    </div>
                  }
                </div>
              </TabPanel> */}
              <TabPanel value="1">
                <div className="mt-5">
                  {AlljobData ?
                    <AssociateCards documentData={documents} data={AlljobData} isAImatched={false} selectedItems={selectedIds} searchKeyWord="Search by Job code, Title" navigateTo="/ats/layout/jobpostings/view/" config={findconfig} componentType="AssociatedJobs"
                      onReceiveHandler={(Item, obj) => onActionHandler(Item, obj)} /> :
                    <div>
                      <div className="nodatasection nodataSection">
                        <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noResult" />
                        No Jobs
                      </div>
                    </div>
                  }
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      )}
      {componentType === "Questions&Answers" && (
        <div style={{ height: "calc(100vh - 459px)", overflowY: "auto" }}>
          {questionsData.length > 0 && questionsData.map((item) => {
            return (
              <div className='questionsarea'>
                <h3>{item.question}</h3>
                <p>
                  {item.answer.join(", ")}
                </p>
              </div>
            )
          })
          }
        </div>
      )}
      {
        componentType === "jobProfile" && (
          <div>

            <div className='jobsdatacss'>
                  <tr>
                    <td>Job Type</td>
                    <td>:</td>
                    <td>{jobPostingsDetails?.jobType}</td>
                  </tr>
                  <tr>
                    <td>External Job ID</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.externalSystemID}</td>
                  </tr>
                  <tr>
                    <td>Job Status</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.jobStatus}</td>
                  </tr>
                  <tr>
                    <td>Client</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.clientName}</td>
                  </tr>
                  <tr>
                    <td>Client Bill Rate</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.clientBillRate}</td>
                  </tr>
                  <tr>
                    <td>Pay rate/Salary</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.payBillRate}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.location}</td>
                  </tr>
                  <tr>
                    <td>Manager</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.managerName}</td>
                  </tr>
                  <tr>
                    <td>Team Lead</td>
                    <td>:</td>
                    <td>{jobPostingsDetails.teamLeadname}</td>
                  </tr>
                  <Divider className="secline" />
                </div>

                <div className="statusdropdwnView" >
                  <p>Update Status</p>
                  <div style={{ width: "25%" }}>
                    <FormControl
                      fullWidth
                    // error={
                    //   formik.errors.candidateStatus && formik.touched.candidateStatus ? true : false
                    // }
                    >
                      {/* <InputLabel className="boldSelectlabel">Candidate's Status</InputLabel> */}
                      <Select
                        className='sourcedfrominput'
                        value={updateStatus !== "" ? updateStatus : getData.status}
                        name="candidateStatus"
                        autoComplete="off"
                        // label="Candidate's Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        // disabled={updateStatus ? false : true}
                        onChange={(e) => onChangeHandler(e.target.value, "candidateStatus")}
                        style={{ width: "100%", height: "2rem", borderRadius: "4px" }}
                      // onFocus={formik.handleBlur}
                      >
                        {candidateStatusValues.map((status) => (
                          <MenuItem className="selectOptions" key={status.id} value={status.internal}>
                            {status.internal}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <FormHelperText>
                  {formik.errors.candidateStatus && formik.touched.candidateStatus
                    ? formik.errors.candidateStatus
                    : ""}
                </FormHelperText> */}
                    </FormControl>
                  </div>
                <div className="updatebutton" style={{marginLeft:"31%"}}>
                  <Button
                    className="updatestatussavebtn"
                    variant="contained"
                    type="submit"
                    // disabled={updateStatus ? false : true}
                    onClick={HandleSubmit}
                    size="small"
                  >
                    Update
                  </Button>
                </div>
                </div>
          </div>
        )
      }
    </>
  );
};

export default ApplicantAssociatedApplicants;

const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: true,
  isCheckbox: true,
  isActions: false,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  ischeckAlignRight: false,
  isPaginationTop: false,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange: true,
};

const aiconfiguration = {
  recordperPage: 8,
  currentPage: 1,
  totalRecords: 0,
  limitData: 8,
  isSearchServer: false,
};

const findconfiguration = {
  recordperPage: 9,
  currentPage: 1,
  totalRecords: 0,
  limitData: 9,
  isSearchServer: true,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: false,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Job Code",
    mappingName: "jobCode",
    theadClass: "text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: true,
    nagigate: 'detailspage'
  },
  {
    id: 2,
    displayName: "Job Title",
    mappingName: "jobTitle",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: 'null'
  },
  {
    id: 3,
    displayName: "Document Name",
    mappingName: "documentName",
    theadClass: "text-center  dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: 'null'
  },
  {
    id: 4,
    displayName: "Client",
    mappingName: "clientName",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: 'null'
  },
  {
    id: 5,
    displayName: "Last Updated",
    mappingName: "lastUpdated",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: 'null'
  },
  // {
  //   id: 5,
  //   displayName: "Job Status",
  //   mappingName: "jobStatus",
  //   theadClass: "text-center  dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: false,
  //   nagigate: 'null'
  // },

  {
    id: 6,
    displayName: "Candidate Status",
    mappingName: "status",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext td-flex",
    width: "",
    isEdit: false,
    nagigate: 'null'
  },
  // {
  //   id: 8,
  //   displayName: "Action",
  //   mappingName: "actionsList",
  //   theadClass: "text-center text-center dynatableheadtext",
  //   tbodyClass: "text-center dynatablebodytext",
  //   width: "",
  //   isEdit: false,
  //   nagigate: null
  // },
  {
    id: 7,
    displayName: "Percentage",
    mappingName: "percentage",
    theadClass: "text-center text-center dynatableheadtext",
    tbodyClass: "text-center dynatablebodytext",
    width: "",
    isEdit: false,
    nagigate: "null",
  },
];

const addNewModel = {
  url: "",
  displayName: ""
}

const ASAObj = {
  title: " Associate Jobs ",
  description: "",
  bgImage: "",
};
const QAObj = {
  title: "Questions and Answers",
  description: "",
  bgImage: "",
};


