import { Editing } from "../Icons/Editing";
import { Documenticon } from "../Icons/Documenticon";
import { Associatedapplicants } from "../Icons/Associatedapplicants";
import { Portal } from "../Icons/portal";
import { Notesicon } from "../Icons/notesicon";
import { Jobportal } from "../Icons/jobportal";
import { AuditInfoInactive } from "../Icons/auditInfo-Inactive";
import { Userinactive } from "../Icons/userinactive";
import { GroupUsersInactive } from "../Icons/groupUsers-Inactive";
import { Jobboardportal_black } from "../Icons/jobboardportal_black";
import { MailInactive } from "../Icons/mail-Inactive";
import { Settings } from "../Icons/settings";





const storedTheme = JSON.parse(localStorage.getItem("themeSettings")) || '';
console.log(storedTheme?.secondaryColor);
console.log(storedTheme?.primaryColor);


export const jobPostingMenus = [
  {
    id: 1,
    img: <Editing primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Editing primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Job Details",
    url: "jobpostings/view",
    urls: "jobpostings/edit",
  },
  {
    id: 2,
    img: <Documenticon primaryFontColor={storedTheme?.secondaryColor}/>,
    activeImg: <Documenticon primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Documents",
    url: "jobpostings/documents",
  },
  {
    id: 3,
    img: <Associatedapplicants primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Associatedapplicants  primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Associated Applicants",
    url: "jobpostings/associatedapplicants",
  },
  {
    id: 4,
    img: <Portal primaryFontColor={storedTheme?.secondaryColor}/>,
    activeImg: <Portal primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Portals",
    url: "jobpostings/portals/view",
    urls: "jobpostings/portals/edit",
  },
  {
    id: 5,
    img:<Notesicon primaryFontColor={storedTheme?.secondaryColor}/> ,
    activeImg:<Notesicon primaryFontColor={storedTheme?.primaryColor} /> ,
    menuName: "Notes",
    url: "jobpostings/notes",
  },
  {
    id: 6,
    img: <Jobportal primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Jobportal primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Screening Questions",
    url: "jobpostings/screeningquestions",
  },
  {
    id: 7,
    img:<AuditInfoInactive primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg:<AuditInfoInactive primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Audit Info",
    url: "jobpostings/auditinfo",
  },
];


export const applicantsMenus = [
  {
    id: 1,
    img: <Editing primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Editing primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Applicant Details",
    url: "applicants/view",
    urls: "applicants/edit",
  },
  {
    id: 2,
    img: <Documenticon primaryFontColor={storedTheme?.secondaryColor}/>,
    activeImg: <Documenticon primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Documents",
    url: "applicants/documents",
  },
  {
    id: 3,
    img: <Associatedapplicants primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Associatedapplicants  primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Associated Jobs",
    url: "applicants/associatedjobs",
  },
  {
    id: 4,
    img:<Notesicon primaryFontColor={storedTheme?.secondaryColor}/> ,
    activeImg:<Notesicon primaryFontColor={storedTheme?.primaryColor} /> ,
    menuName: "Notes",
    url: "applicants/notes",
  },
  {
    id: 5,
    img:<AuditInfoInactive primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg:<AuditInfoInactive primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Audit Info",
    url: "applicants/auditinfo",
  },
];

export const clientsMenus = [
  {
    id: 1,
    img: <Editing primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Editing primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Clients Details",
    url: "clients/view",
    urls: "clients/edit",
  },
  {
    id: 2,
    img:<AuditInfoInactive primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg:<AuditInfoInactive primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Audit Info",
    url: "clients/auditinfo",
  },
];

export const helpSectionMenus = [
  {
    id: 1,
    img: <Editing primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Editing primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Job Postings",
    url: "helpsection/postings",
    urls: "helpsection/postings",
  },
  {
    id: 2,
    img:<Notesicon primaryFontColor={storedTheme?.secondaryColor}/> ,
    activeImg:<Notesicon primaryFontColor={storedTheme?.primaryColor} /> ,
    menuName: "Applicants",
    url: "helpsection/applicant",
  },
  {
    id: 3,
    img: <Documenticon primaryFontColor={storedTheme?.secondaryColor}/>,
    activeImg: <Documenticon primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Email Templates",
    url: "helpsection/emailtemplates",
  }
];

export const customerMenus = [
  {
    id: 1,
    img: <Userinactive primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Userinactive primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Customer Details",
    url: "customer/view",
    // urls: "customers/edit",
  },
  {
    id: 2,
    img: <GroupUsersInactive primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <GroupUsersInactive primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Users",
    url: "customer/users",
  },
  {
    id: 3,
    img: <GroupUsersInactive primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <GroupUsersInactive primaryFontColor={storedTheme?.primaryColor} />,
    menuName: "Vendors",
    url: "customer/vendors",
  },
  {
    id: 4,
    img: <Settings primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Settings primaryFontColor={storedTheme?.primaryColor} />,  
    menuName: "Custom",
    url: "customer/custom",
   
  },
  {
    id: 5,
    img: <Jobboardportal_black primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <Jobboardportal_black primaryFontColor={storedTheme?.primaryColor} />,  
    menuName: "Jobboard Portal",
    url: "customer/jobboardportal/view",
    urls: "customer/jobboardportal/edit",
  },
  {
    id: 6,
    img: <MailInactive primaryFontColor={storedTheme?.secondaryColor} />,
    activeImg: <MailInactive primaryFontColor={storedTheme?.primaryColor} />,  
    menuName: "Mail",
    url: "customer/organize/mail",
    // urls: "customers/jobboardportal/edit",
  },
  ];


export const BreadCrumbData = [
  {
    id: 1,
    name: "View",
    url: "jobpostings/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/editing.svg`, //"/assests/img/Group.svg",
    type: "jobpostings"
    
  },
  {
    id: 2,
    name: "Edit",
    url: "jobpostings/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    type: "jobpostings"
  },
  {
    id: 3,
    name: "Documents",
    url: "jobpostings/documents",
    icon: `${process.env.PUBLIC_URL}/assests/img/documenticon.svg`,
    type: "jobpostings"
  },
  {
    id: 4,
    name: "Associated Applicants",
    url: "jobpostings/associatedapplicants",
    icon:`${process.env.PUBLIC_URL}/assests/img/Associatedapplicants.svg`,
    type: "jobpostings"
  },
  {
    id: 5,
    name: "Notes",
    url: "jobpostings/notes",
    icon: `${process.env.PUBLIC_URL}/assests/img/notesicon.svg`,
    type: "jobpostings"
  },
  {
    id: 6,
    name: "Screening Questions",
    url: "jobpostings/screeningquestions",
    icon: `${process.env.PUBLIC_URL}/assests/img/jobportal.svg`,
    type: "jobpostings"
  },
  {
    id: 7,
    name: "Audit Info",
    url: "jobpostings/auditinfo",
    icon: `${process.env.PUBLIC_URL}/assests/img/auditinfo.svg`,
    type: "jobpostings"
  },
 
  // applicants
  {
    id: 8,
    name: "View",
    url: "applicants/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    type: "applicants"
  },
  {
    id: 9,
    name: "Edit",
    url: "applicants/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    type: "applicants"
  },
  {
    id: 10,
    name: "Documents",
    url: "applicants/documents",
    icon: `${process.env.PUBLIC_URL}/assests/img/documenticon.svg`,
    type: "applicants"
  },
  {
    id: 11,
    name: "Associated Jobs",
    url: "applicants/associatedjobs",
    icon: `${process.env.PUBLIC_URL}/assests/img/Associatedapplicants.svg`,
    type: "applicants"
  },
  {
    id: 12,
    name: "Notes",
    url: "applicants/notes",
    icon: `${process.env.PUBLIC_URL}/assests/img/notesicon.svg`,
    type: "applicants"
  },
  {
    id: 13,
    name: "Audit Info",
    url: "applicants/auditinfo",
    icon: `${process.env.PUBLIC_URL}/assests/img/auditinfo.svg`,
    type: "applicants"
  },
  // clients
  {
    id: 14,
    name: "View",
    url: "clients/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    type: "clients"
  },
  {
    id: 15,
    name: "Edit",
    url: "clients/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/editing.svg`,
    type: "clients"
  },
  {
    id: 16,
    name: "Audit Info",
    url: "clients/auditinfo",
    icon: `${process.env.PUBLIC_URL}/assests/img/auditinfo.svg`,
    type: "clients"
  },
// jobpostingsportals
  {
    id: 17,
    name: "Portals View",
    url: "jobpostings/portals/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/portal.svg`,
    type: "jobpostings"
  },
  {
    id: 18,
    name: "Portals Edit",
    url: "jobpostings/portals/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/portal.svg`,
    type: "jobpostings"
  },
// helpsection


  // {
  //   id: 19,
  //   name: "Portals Edit",
  //   url: "postings/portals/edit",
  //   icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
  //   type: "helpsection"
  // },
  // {
  //   id: 20,
  //   name: "Portals Edit",
  //   url: "postings/portals/edit",
  //   icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
  //   type: "helpsection"
  // },
  // {
  //   id: 21,
  //   name: "Portals Edit",
  //   url: "postings/portals/edit",
  //   icon: `${process.env.PUBLIC_URL}/assests/img/personicon.svg`,
  //   type: "helpsection"
  // },
  //customer
  {
    id: 19,
    name: "View",
    url: "customer/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/user-Inactive.svg`,
    type: "customer"
  },
  {
    id: 20,
    name: "Users",
    url: "customer/users",
    icon: `${process.env.PUBLIC_URL}/assests/img/groupUsers-Inactive.svg`,
    type: "customer"
  },
  {
    id: 21,
    name: "Vendors",
    url: "customer/vendors",
    icon: `${process.env.PUBLIC_URL}/assests/img/groupUsers-Inactive.svg`,
    type: "customer"
  },
  {
    id: 22,
    name: "Custom",
    url: "customer/custom",
    icon: `${process.env.PUBLIC_URL}/assests/img/settings.svg`,
    type: "customer"
  },
  {
    id: 23,
    name: "Jobboard Portal View",
    url: "customer/jobboardportal/view",
    icon: `${process.env.PUBLIC_URL}/assests/img/jobboardportal_black.svg`,
    type: "customer"
  },
  {
    id: 24,
    name: "Jobboard Portal Edit",
    url: "customer/jobboardportal/edit",
    icon: `${process.env.PUBLIC_URL}/assests/img/jobboardportal_black.svg`,
    type: "customer"
  },
  {
    id: 25,
    name: "Mail",
    url: "customer/organize/mail",
    icon: `${process.env.PUBLIC_URL}/assests/img/mail-Inactive.svg`,
    type: "customer"
  },
  //customer

];

export const BreadCrumbObj = [
  {
    id: 1,
    name: "Job Postings",
    url: "jobpostings/landing",
    icon: "",
    type: ""
  },
  {
    id: 2,
    name: "Applicants",
    url: "applicants/landing",
    icon: "",
    type: ""
  },
  {
    id: 3,
    name: "Clients",
    url: "clients/landing",
    icon: "",
    type: ""
  },
  {
    id: 4,
    name: "HelpSection",
    url: "helpsection/postings",
    icon: "",
    type: ""
  },
  {
    id: 5,
    name: "customer",
    url: "customer/view",
    icon: "",
    type: ""
  }
]