import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { decrypt } from "../../../../_utilities/_encryptDecryptHelper";
import { customFieldsAsync } from "../../../../_redux/_services/UserManagerSlice";
import { getCandidateStatusCustomValues } from "../../../../_utilities/internalexternalhelper";
import { getClientswithFieldsAsync } from "../../../../_redux/_services/ClientsSlice";
import { widgetTypes } from "../widget";
import "./AddEditModal.css";

// Images
import {GroupOfStatus} from '../Images/GroupOfStatus';
import {GroupByClients} from '../Images/GroupByClients';
import {ResumeContributed} from '../Images/ResumeContributed';
import {PieChart} from '../Images/PieChart';
import {BarChart} from '../Images/BarChart';
import {Table} from '../Images/Table';

const AddEditModal = ({ open, submit, selectedCard, closeDialog }) => {
  const dispatch = useDispatch();
  const [widgetTitle, setWidgetTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [selectWidgetError, setSelectWidgetError] = useState(false);
  const [selectStatusError, setSelectStatusError] = useState(false);
  const [selectClientsError, setSelectClientError] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState("");
  const [showStatusTab, setShowStatusTab] = useState(false);
  const [candidateStatusValues, setCandidateStatusValues] = useState([]);
  const [selectedCandidateStatus, setSelectedCandidateStatus] = useState([]);
  const [selectedClients, setSelectedClients] = useState({});
  const [appTheme, setTheme] = useState({});

  const customFieldsData = useSelector((state) => state.CustomFields.data);
  const clients = useSelector((state) => state.GetClientsWithFields.data);

  useEffect(() => {
    let userId = JSON.parse(decrypt("GetUserId"));
    dispatch(customFieldsAsync(userId));
    dispatch(getClientswithFieldsAsync("customerId"));
    const storedTheme = localStorage.getItem("themeSettings");
    if (storedTheme) {
      setTheme(JSON.parse(storedTheme));
    }
  }, []);

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const array = getCandidateStatusCustomValues(customFieldsData);
      setCandidateStatusValues(array);
    }
  }, [customFieldsData]);

  useEffect(() => {
    if (selectedCard) {
      setWidgetTitle(selectedCard.title);
      // handleWidgetSelection(selectedCard.type);
      handleWidgetSelection({ target: { value: selectedCard.type } });
      setSelectedClients(selectedCard?.client);
      setSelectedCandidateStatus(selectedCard?.status);
    }
  }, [selectedCard]);

  const handleWidgetSelection = (e) => {
    const widget = widgetTypes.find((item) => item.type === e.target.value);

    setSelectedWidget(widget);
    if(selectWidgetError && e.target.value?.trim()){
      setSelectWidgetError(false);
    }
  };

  const handleTitleChange = (title) => {
    if(titleError && title?.trim()){
      setTitleError(false);
    }
    setWidgetTitle(title);
  }

  const proceedToStatusSection = () => {
    setTitleError(false);
    setSelectWidgetError(false);
    if (
      widgetTitle &&
      (selectedWidget?.showStatus || selectedWidget?.showClient)
    ) {
      setShowStatusTab(true);
    } else if (widgetTitle && selectedWidget.type) {
      submit({
        ...selectedCard,
        ...selectedWidget,
        title: widgetTitle,
        client: selectedClients,
        status: selectedCandidateStatus,
      });
    } else {
      if (!widgetTitle || !widgetTitle?.trim()) {
        setTitleError(true);
      }

      if (!selectedWidget?.type) {
        setSelectWidgetError(true);
      }
    }
  };

  const handleStatusSelection = (value) => {
    setSelectedCandidateStatus(value);
    if (value.length === 0) {
      setSelectStatusError(true);
    } 
      setSelectStatusError(false);
  };

  const handleClientSelection = (id) => {
    const widget = clients.find((item) => item.id === id);
    if (widget) {
      setSelectedClients(widget);  
      setSelectClientError(false); 
    } else {

      setSelectClientError(true);
    }
  };

  const proceedToSave = () => {
    setSelectStatusError(false);
    setSelectClientError(false);
    if (
      (selectedWidget.type === "GraphbyClient" &&
        selectedClients?.id &&
        selectedCandidateStatus.length) ||
      ((selectedWidget?.type === "GraphofStatus" || selectedWidget?.type === "ResumeCount") &&
        selectedCandidateStatus.length)
    ) {
      submit({
        ...selectedCard,
        ...selectedWidget,
        title: widgetTitle,
        client: selectedClients,
        status: selectedCandidateStatus,
      });
    } else {
      if (!selectedClients.length) {
        setSelectClientError(true);
      }

      if (!selectedCandidateStatus.length) {
        setSelectStatusError(true);
      }
    }
  };

  const handleClose= ()=>{
    closeDialog(false);
  }

  const showOnlyStatus = () => {
    return (
      <div className="status-container">
        <FormControl fullWidth>
          <InputLabel className="boldSelectlabel" shrink={true}>
            Select Status
          </InputLabel>
          <Select
            className="select-widget"
            error={selectStatusError}
            onChange={(e) => handleStatusSelection(e.target.value)}
            multiple
            value={selectedCandidateStatus}
            renderValue={(selected) => selected.map((id) => candidateStatusValues.find((option) => option.id === id)?.internal).join(", ")}
            MenuProps={{
              PaperProps: {
                className: "dashBoardDropdownStatusvalues", 
              },
            }}
          >
            {candidateStatusValues.map((widget) => (
              <MenuItem key={widget.id} value={widget.id} >
                <Checkbox
                  checked={
                    selectedCandidateStatus.indexOf(widget.id) > -1
                  }
                />
                <ListItemText primary={widget.internal}/>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const showClientAndStatus = () => {
    return (
      <>
        <div className="form-div">
          <FormControl fullWidth>
            <InputLabel className="boldSelectlabel" shrink={true}>
              Client Name
            </InputLabel>
            <Select
              className="select-widget"
              error={selectClientsError}
              onChange={(e) => handleClientSelection(e.target.value)}
              value={selectedClients}
              renderValue={(selected) => selected.clientName}
              MenuProps={{
                PaperProps: {
                  className: "dashBoardDropdownStatusvalues", 
                },
              }}
            >
              {clients?.map((widget) => (
                <MenuItem key={widget.id} value={widget.id}>
                  {widget.clientName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="form-div">
          <FormControl fullWidth>
            <InputLabel className="boldSelectlabel" shrink={true}>
              Select Status
            </InputLabel>
            <Select
              className="select-widget"
              error={selectStatusError}
              onChange={(e) => handleStatusSelection(e.target.value)}
              multiple
              value={selectedCandidateStatus}
              renderValue={(selected) => selected.map((id) => candidateStatusValues.find((option) => option.id === id)?.internal).join(", ")}
              MenuProps={{
                PaperProps: {
                  className: "dashBoardDropdownStatusvalues", 
                },
              }}
            >
              {candidateStatusValues.map((widget) => (
                <MenuItem key={widget.id} value={widget.id}>
                  <Checkbox
                    checked={
                      selectedCandidateStatus.indexOf(widget.id) > -1
                    }
                  />
                  <ListItemText primary={widget.internal} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </>
    );
  };

  const getSampleImage = (type) => {
    //appTheme
    switch (type) {
      case "GraphofStatus":
        return (
          <GroupOfStatus
            primaryColor={appTheme.primaryColor}
            primaryFontColor={appTheme.primaryFontColor}
          />
        );
      case "GraphbyClient":
        return (
          <GroupByClients
            primaryColor={appTheme.primaryColor}
            primaryFontColor={appTheme.primaryFontColor}
          />
        );
      case "ResumeCount":
        return (
          <ResumeContributed
            primaryColor={appTheme.primaryColor}
            primaryFontColor={appTheme.primaryFontColor}
            secondaryColor={appTheme.secondaryColor}
          />
        );
      case "pieChart":
        return (
          <PieChart
            primaryColor={appTheme.primaryColor}
            primaryFontColor={appTheme.primaryFontColor}
          />
        );
      case "barChart":
        return (
          <BarChart
            primaryColor={appTheme.primaryColor}
            primaryFontColor={appTheme.primaryFontColor}
          />
        );
      case "requirementsWidget":
        return (
          <Table
            primaryColor={appTheme.primaryColor}
            primaryFontColor={appTheme.primaryFontColor}
            secondaryColor={appTheme.secondaryColor}
          />
        );
    }
  };

  const showImgAndDescription = () => {
    return (
      <>
        {selectedWidget.description && (
          <div className="message-text">{selectedWidget.description}</div>
        )}
        <div className="sample-img-container">
          {getSampleImage(selectedWidget.type)}
        </div>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog"
      className="add-dialouge"
    >
      <Box>
        <DialogTitle className="diabtn">
          {selectedCard ? "Edit Widget" : "New Widget"}
          <img src={process.env.PUBLIC_URL + "/assests/img/close.svg" }alt="" className='closeicon-modelpopup' onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="form-container">
            <div className="form-div">
              <FormControl fullWidth>
                <InputLabel className="boldSelectlabel" shrink={true}>
                  Widget Name
                </InputLabel>
                <TextField
                  fullWidth
                  value={widgetTitle}
                  variant="outlined"
                  disabled={showStatusTab}
                  error={titleError} // Indicates an error visually
                  onChange={(e) => handleTitleChange(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="form-div">
              <FormControl fullWidth>
                <InputLabel className="boldSelectlabel" shrink={true}>
                  Select Widget
                </InputLabel>
                <Select
                  className="select-widget"
                  size="small"
                  error={selectWidgetError}
                  value={(selectedWidget && selectedWidget.title) || ""}
                  renderValue={(selected) => selected}
                  disabled={showStatusTab || selectedCard?.i}
                  onChange={(e) => handleWidgetSelection(e)}
                >
                  {widgetTypes.map((widget) => (
                    <MenuItem key={widget.type} value={widget.type}>
                      {widget.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {showStatusTab &&
              selectedWidget.showStatus &&
              !selectedWidget.showClient &&
              showOnlyStatus()}
            {showStatusTab &&
              selectedWidget.showStatus &&
              selectedWidget.showClient &&
              showClientAndStatus()}
          </Box>
          <Box className="message-container">
            {selectedWidget && !showStatusTab && showImgAndDescription()}
          </Box>
        </DialogContent>
        <DialogActions className="actions-container">
          {!showStatusTab ? (
            <Button
              variant="contained"
              onClick={proceedToStatusSection}
              className="dialog-button"
            >
              Continue
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={proceedToSave}
              className="dialog-button"
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default  React.memo(AddEditModal);
