import { Box, Button, Chip, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import { React, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import readXlsxFile from 'read-excel-file';
import * as Yup from "yup";
import { Portals } from "../../../API";
import { updateLoader } from '../../../_redux/LoaderSlice';
import { updateSnackbar } from '../../../_redux/SnackbarSlice';
import GetJobId from "../../../_utilities/GetJobId";
import DynamicTable from '../../../components/DynamicTable/DynamicTable';
import RightSideBar from '../../../components/Rightsidebar/RightSideBar';
import { Encrypt, decrypt } from "../../../_utilities/_encryptDecryptHelper";
import "./User.css";
import { PlusIcon } from "../../../Icons/PlusIcon";
import { ProfileIcon } from "../../../Icons/profileIcon";


const User = () => {
  const [isRightSidePannel, setIsRightSidePannel] = useState(false);
  const [users, setUsers] = useState([]);
  const [config, setConfig] = useState(configuration);
  const [filterValue, setFilterValue] = useState("");
  const [isLoadDoc, setIsLoadDoc] = useState(true);
  const [portals, setPortals] = useState("");
  const [isEditUser, setIsEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const userForm = JSON.parse(JSON.stringify(iniValues));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialSnackbar = { type: "", message: "" };

  let getJobCode = GetJobId();
  const [GetUserInfo, setGetUserInfo] = useState(
    JSON.parse(localStorage.getItem("admingetAttributes"))
  );

  const getUserId = JSON.parse(decrypt("GetUserInfo"));
 

  useEffect(() => {
    if (isLoadDoc === true && getUserId[0].id !== null) {
      let obj1 = { current: config.currentPage, offset: config.currentPage !== 1 ? (config.currentPage - 1) * config.limitData + 1 : 1,  };
      fetchData(obj1);
      fetchPortalsList(getUserId[0].id);
      setIsLoadDoc(false)
      
    }
  }, [isLoadDoc, getUserId]);

  const fetchData = async (obj) => {
    setUsers([]);
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await fetch(
      `${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${getUserId[0].id}/user?limit=${config.limitData}&offset=${obj.offset}`,{
        method: "GET",
        headers: { "customerid": `${getUserId[0].id}`, 'Content-Type': 'Application/json' }
      }
    );
    const jsonData = await res.json();
    const myData = jsonData.user;
    if (jsonData.code === 200) {
      if (myData !== undefined && myData.length > 0) {
        myData.forEach((e) => {
          e["isChecked"] = false;
          e["applicationString"] = JSON.parse(e.applications).join(",");
          dispatch(updateLoader(false));
        });
        let configure = JSON.parse(JSON.stringify(config));
        configure.totalRecords = myData[0].total_count / config.limitData;
        configure.currentPage = obj.current;
        setConfig(configure);
        setUsers(myData);
      } else {
        dispatch(updateLoader(false));
      }
    } else {
      dispatch(updateLoader(false));
      dispatch(
        updateSnackbar({ type: "error", message: "" })
      );
    }
  };

  const fetchPortalsList = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}${Portals}/${id}`);
    const data = await res.json();
    const ListofPortals = data.data;
    let obj = JSON.parse(ListofPortals[0].selectedPortals);
    obj.forEach((x) => {
      x["name"] = x.portalName;
      x["id"] = x.portalId.toString();
    });
    setPortals(obj);
  }

  

  // for dynamic component received props
  const onReceiveActionHandler = async (type, object) => {
    if (type === "confirmDel") {
      deleteRecords(object);
    } else if (type === "download") {
      alert("download option is not implemented!");
    } else if (type === "pagination") {
      if (filterValue !== '') {
        getSearchResult(object, true);
      } else {
        fetchData(object);
      }
    } else if (type === "searchResult") {
      // setFilterValue(object.keyword);
      getSearchResult(object, true);
    } else if (type === "cancelSearchResult") {
      setFilterValue("");
      let obj1 = {
        current: 1,
        offset: 1
      };
      fetchData(obj1);
    }
    else if (type === "detailspage"){
      setIsRightSidePannel(true);
      setIsEditUser(true);
      let UserObj = {
        id: object.id,
        firstName: object.firstName,
        lastName: object.lastName,
        emailId: object.emailId,
        roles: object.roles,
        teamRoles: object.teamRoles,
        applications: JSON.parse(object.applications),
      }
         console.log(UserObj);
      setSelectedUser(UserObj)
    } else if(type === "pageRange"){
      setConfig({...config,limitData:config.limitData=object})
      let obj1 = { current: config.currentPage, offset: config.currentPage !== 1 ? (config.currentPage - 1) * config.limitData + 1 : config.currentPage, limit: config.limitData };
      fetchData(obj1);
    }
  };

  

  const deleteRecords = async (object) => {
    let ids;
    if (typeof object === "object" && object.length > 0) {
      ids = object.map((x) => x.id.toString());
    } else {
      ids = [object.toString()];
    }
    let obj = { ids: ids };
    dispatch(updateSnackbar(initialSnackbar));
    const deleteJobPost = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/user`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    });
    const jsonData = await deleteJobPost.json();
    dispatch(
      updateSnackbar({ type: jsonData.code === 200 ? "success" : "error", message: jsonData.message })
    );
    let obj1 = {
      current: config.currentPage,
      offset:
        config.currentPage !== 1
          ? (config.currentPage - 1) * 20 + 1
          : config.currentPage,
    };
    setTimeout(() => {
      fetchData(obj1);
    }, 2000);
  };

  const getSearchResult = async (object, paging) => {
    setUsers([]);
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    if (paging === true) {
      if (object.keyword !== undefined){
        object["limit"] = config.limitData;
        object["offset"] = 1;
        setFilterValue(object.keyword);
      } else {
        object.keyword = filterValue;
      }
      searchResultWithPaging(object);
    } else {
      setFilterValue(object.keyword);
      searchResultWithoutPaging(object);
    }
  };

  const searchResultWithoutPaging = async (object) => {
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${getUserId[0].id}/user?keyword=${object.keyword}`);
    const jsonData = await res.json();
    const myData = jsonData.user;
    if (jsonData.code === 200) {
      if (myData !== undefined && myData.length > 0) {
        myData.forEach((e) => {
          e["isChecked"] = false;
        });
        dispatch(updateLoader(false));
        let configure = JSON.parse(JSON.stringify(config));
        // configure.totalRecords = myData[0].total_count / config.limitData;
        configure.totalRecords = myData[0].total_count;
        configure.currentPage = object.current;
        setConfig(configure);
        setUsers(myData.slice(0, config.limitData));
      } else if (myData.length === 0) {
        dispatch(updateLoader(false));
        let configure = JSON.parse(JSON.stringify(config));
        configure.totalRecords = 0;
        configure.currentPage = 1;
        setConfig(configure);
        setUsers('null');
      }
    } else {
      dispatch(updateLoader(false));
      dispatch(
        updateSnackbar({ type: "error", message: "" })
      );
    }
  }

  const searchResultWithPaging = async (object) => {
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${getUserId[0].id}/user?limit=${object.limit}&offset=${object.offset}&keyword=${object.keyword}`);
    const jsonData = await res.json();
    const myData = jsonData.user;
    if (jsonData.code === 200) {
      if (myData !== undefined && myData.length > 0) {
        myData.forEach((e) => {
          e["isChecked"] = false;
        });
        dispatch(updateLoader(false));
        let configure = JSON.parse(JSON.stringify(config));
        // configure.totalRecords = myData[0].total_count / config.limitData;
        configure.totalRecords = myData[0].total_count;
        configure.currentPage = object.current;
        setConfig(configure);
        setUsers(myData.slice(0, config.limitData));
      } else if (myData.length === 0) {
        dispatch(updateLoader(false));
        let configure = JSON.parse(JSON.stringify(config));
        configure.totalRecords = 0;
        configure.currentPage = 1;
        setConfig(configure);
        setUsers('null');
      }
    } else {
      dispatch(updateLoader(false));
      dispatch(
        updateSnackbar({ type: "error", message: "" })
      );
    }
  }

  const onReceivePropsHandler = (pannel, load) => {
    setIsRightSidePannel(pannel);
    setIsLoadDoc(load);
  };

  const userHandler = (type, data)=>{
    setIsRightSidePannel(true);
    setSelectedUser("");
    setIsEditUser(false);
  }
  const storedTheme = JSON.parse(localStorage.getItem("themeSettings")) || '';

  return (
    <>
      <div
        className="addusersection"
        style={{ display: "flex", height: "34px", gap: "10px" }}
        onClick={() => userHandler("Create", " ")}
      >
        {/* <img src={process.env.PUBLIC_URL + "/assests/img/Group 2196.png"} alt="" /> */}
        <PlusIcon primaryColor={storedTheme?.primaryColor}
            primaryFontColor={storedTheme?.primaryFontColor} />
        <h4 className="addnewusertext">New User</h4>
      </div>

      <div id="customerUser" style={{ background: "#fff", padding: "0px 30px 47px" }}>
        {/* for dynamic Table */}
        {users.length > 0 && (
          <DynamicTable
            config={config}
            data={users}
            colmns={columnsData}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            filterValue={filterValue}
            noDataText={"No Data"}
            addModel={addNewModel}
            searchKeyWord="Search by Name, Email ID"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
        )}
        {users.length === 0 && (
          <DynamicTable
            config={config}
            data={users}
            colmns={columnsData}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            filterValue={filterValue}
            noDataText={"No Data"}
            addModel={addNewModel}
            searchKeyWord="Search by Name, Email ID"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
        )}
      </div>

      <div className="rightsidebarbtn" id="customerUser">
        {isRightSidePannel ? (
          <RightSideBar 
            componentData={
              <ComponentRenderData
              componentType={isEditUser ? "EditUser" : "AddUser"}
                onReceivechildProps={onReceivePropsHandler}
                portalData={portals}
                getJobCode={getUserId[0].id}
                input={isEditUser ? selectedUser : userForm}
              />
            }
            componentLayout={isEditUser ? editPannelobj : pannelobj}
            onReceiveProps={onReceivePropsHandler}
          />
        ) : (
          ""
        )}
      </div>
    </>
  )
}




const ComponentRenderData = ({ onReceivechildProps, componentType, portalData, getJobCode, input}) => {
  
  const [radioValue, setRadioValue] = useState("user");
  const [userDetails, setUserDetails] = useState(input);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [imgData, setImgData] = useState([]);
  const [fileType, setFileType] = useState("jpg not supported");
  const [showUploadImg, setShowUploadImg] = useState('');
  const [previewData, setPreviewData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialSnackbar = { type: "", message: "" };

  const RadiohandleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if(name === "emailId"){
      value = value.toLowerCase();
      value = value.trim();
    }
    if(name !== "applications"){
      value = value.trimStart()
    }
    formik.values[name] = value;
    setUserDetails((prevValues) => ({ ...prevValues, [name]: value }));
  };

  useEffect(() => {
    console.log("userDetails", userDetails)
  }, [userDetails])

  const validRegex = /^[a-zA-Z0-9_!#$%&*+/=?`{}~^.-]+@[a-zA-Z0-9.-]+$/

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(3, "Must be min 3 characters")
      .max(20, "Must be less than 20 ")
      .required("Please enter first name"),
    lastName: Yup.string()
      .required("Please enter last name"),
    emailId: Yup.string().email("Please enter email address").matches(validRegex,"Invalid email address")
      .required("Please enter email address"),
    roles: Yup.string().required("Please select at least one system role"),
    teamRoles: Yup.string().required("Please select at least one role"),
    // applications: Yup.array().min(1,"Please select at least one application").required("Select at least one application"),
  });

  const formik = useFormik({
    initialValues: input,
    validationSchema,
    // validate: () => { onValidate() },
    onSubmit: async (value) => {
      if(componentType !== "EditUser"){
        setDisableSubmitBtn(true);
        value.customerId = getJobCode;
        dispatch(updateLoader(true));
        dispatch(updateSnackbar(initialSnackbar));
        const userPost = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/user`, {
          method: "POST",
          headers: { "content-Type": "application/json" },
          body: JSON.stringify(value),
        });
        let res = await userPost.json();
        setDisableSubmitBtn(false)
        if (res.code == 200) {
          setUserDetails([]);
          setTimeout(() => {
            dispatch(updateLoader(false));
            dispatch(
              updateSnackbar({
                type: res.code === 200 ? "success" : "error",
                message:
                  res.code === 200 ? res.message : res.message,
              })
            );
          }, 1200);
          onReceivechildProps(false, true);
        } else {
          dispatch(updateLoader(false));
          dispatch(
            updateSnackbar({
              type: res.code === 200 ? "success" : "error",
              message:
                res.code === 200 ? res.message : res.message,
            })
          );
        }
      }else{
        setDisableSubmitBtn(true);
        dispatch(updateLoader(true));
        dispatch(updateSnackbar(initialSnackbar));
        const userEdit = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/user/${userDetails.id}`, {
          method: "PUT",
          headers: { "content-Type": "application/json" },
          body: JSON.stringify(value),
        });
        let res = await userEdit.json();
        setDisableSubmitBtn(false)
        if (res.code == 200) {
          setUserDetails([]);
          setTimeout(() => {
            dispatch(updateLoader(false));
            dispatch(
              updateSnackbar({
                type: res.code === 200 ? "success" : "error",
                message:
                  res.code === 200 ? res.message : res.message,
              })
            );
          }, 1200);
          onReceivechildProps(false, true);
        } else {
          dispatch(updateLoader(false));
          dispatch(
            updateSnackbar({
              type: res.code === 200 ? "success" : "error",
              message:
                res.code === 200 ? res.message : res.message,
            })
          );
        }

      }
   
    }
  });


//user edit


  // const onGetValidData = async (obj) => {
  //   obj["id"] = getJobCode;
  //   dispatch(updateLoader(true));
  //     const postData = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customer/${getJobCode}/user`, {
  //         method: "PUT",
  //         headers: { 'Content-Type': 'Application/json' }, 
  //         body: JSON.stringify(obj),
  //     });
  //     let res = await postData.json();
  //     setIsRightSidePannel(false)
  //     if (res.code === 200) {
  //       dispatch(updateSnackbar({ type: 'success', message: res.message}));
  //       setTimeout(() => {
  //         navigate(`/admin/layout/customers/users/${getJobCode}`); 
  //       }, 1000);
  //     } else {
  //       dispatch(updateSnackbar({ type: 'error', message: res.message }));
  //     }
  //     dispatch(updateLoader(false));
  // }


  // useEffect(() => {
  //   if (imgData.length === 0 && imgList.length === 0) {
  //     setIsUploaded(true);
  //   }
  // }, [imgData, imgList]);

  const uploadImgHandler = (files) => {
    setShowUploadImg(URL.createObjectURL(files[0]));
    // let imgNames = JSON.parse(JSON.stringify(imgList));
    // let imgObj = JSON.parse(JSON.stringify(imgData));
    // let tempImgNames = [],
    //   tempImgObj = [];
    // for (var i = 0; i < files.length; i++) {
    //   let obj = { id: i, name: files[i].name };
    //   tempImgNames.push(obj);
    //   tempImgObj.push(files[i]);
    // }
    // imgNames.push(...tempImgNames);
    // imgObj.push(...tempImgObj);
    // setImgList(imgNames);
    // setImgData(imgObj);
    // setIsUploaded(false);
  };

  const onRemoveItem = (item) => {
    let imgNames = JSON.parse(JSON.stringify(imgList));
    let findIndex = imgNames.findIndex((x) => x.id === item.id);
    if (findIndex !== -1) {
      imgNames.splice(findIndex, 1);
      setImgList(imgNames);
    }
    imgData.splice(parseInt(item.id), 1);
    setImgData(imgData);
  };

  useEffect(() => {
    console.log("imgList", imgList);
  }, [imgList]);

  useEffect(() => {
    console.log("imgData", imgData);
  }, [imgData]);

  const uploadDocuments = () => {
    let formData = new FormData();
    let checkFileType = imgData.filter((elem) => elem.name.match(/jpg/) || elem.name.match(/png/) || elem.name.match(/jpeg/) ||
      elem.name.match(/SVG/));
    if (checkFileType.length > 0) {
      alert("file does not support");
    } else {
      for (let i = 0; i < imgData.length; i++) {
        formData.append("userFiles", imgData[i]);
      }
      fetch(`http://3.97.4.103:2005/mgmt/customer/mulitpleuser`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          onReceivechildProps(false, true);
        });
    }
  }

  const uploadExcel = (xlfiles) => {
    let getColums = [], getExcelData = [];
    readXlsxFile(xlfiles[0]).then((rows) => {
      getColums = rows[0];
      for (var i = 1; i < rows.length; i++) {
        let getEachObj = {};
        rows[i].forEach((each, j) => {
          let column = JSON.parse(JSON.stringify(getColums));
          let obj = { [column[j]]: each }
          getEachObj = { ...getEachObj, ...obj };
        });
        getEachObj["customerId"] = 2;   //need to be update;
        getExcelData.push(getEachObj);
      }
      // setIsUploaded(false);
      setPreviewData(getExcelData);
    })
  }

  const storedTheme = JSON.parse(localStorage.getItem("themeSettings")) || '';


  return (
    <>
      {/* <div id="radiobtn" >
      {componentType !== "EditUser" ? (
        <FormControl >
          <RadioGroup
            style={{ display: "flex", flexDirection: "row", gap: "40px" }}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioValue}
            onChange={RadiohandleChange}
          >
            <FormControlLabel value="user" control={<Radio />} label="User" />
            <FormControlLabel value="bulkupload" control={<Radio />} label="Bulk Upload"   disabled/>
          </RadioGroup>
        </FormControl>
         ) : ( "")}
      </div> */}
      {radioValue === "user" && userDetails.firstName !== undefined ? (
        <div className="groupfields" >
          {/* <img src={process.env.PUBLIC_URL + "/assests/img/profileIcon.svg"} alt=""  className='userProfileicon'/> */}
          <ProfileIcon primaryColor={storedTheme?.primaryColor}
            primaryFontColor={storedTheme?.primaryFontColor} />
          <FocusError formik={formik} />
          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginTop: "40px",
              gap: "25px",
            }}
          >
            <div style={{ width: "48%" }}>
              <TextField
                className="boldlabeluser"
                id=""
                label="First Name* &nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="firstName"
                value={userDetails.firstName}
                onChange={handleChange}
                onKeyUp={formik.handleBlur}
                InputLabelProps={{shrink :  true }}
                error={
                  formik.errors.firstName && formik.touched.firstName
                    ? true
                    : false
                }
                helperText={
                  formik.errors.firstName && formik.touched.firstName
                    ? formik.errors.firstName
                    : ""
                }
              />
            </div>

            <div style={{ width: "48%" }}>
              <TextField
                className="boldlabeluser"
                id=""
                label="Last Name* &nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="lastName"
                value={userDetails.lastName}
                onChange={handleChange}
                onKeyUp={formik.handleBlur}
                InputLabelProps={{shrink :  true }}
                error={
                  formik.errors.lastName && formik.touched.lastName
                    ? true
                    : false
                }
                helperText={
                  formik.errors.lastName && formik.touched.lastName
                    ? formik.errors.lastName
                    : ""
                }
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginTop: "30px",
              gap: "25px",
            }}
          >
            <div style={{ width: "48%" }}>
              <TextField
                className="boldlabeluser"
                id=""
                label="Email Address* &nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="emailId"
                value={userDetails.emailId}
                onChange={handleChange}
                onKeyUp={formik.handleBlur}
                InputLabelProps={{shrink :  true }}
                error={
                  formik.errors.emailId && formik.touched.emailId
                    ? true
                    : false
                }
                helperText={
                  formik.errors.emailId && formik.touched.emailId
                    ? formik.errors.emailId
                    : ""
                }
              />
            </div>
            <div style={{ width: "48%" }}>
              <FormControl fullWidth
                error={
                  formik.errors.teamRoles && formik.touched.teamRoles
                    ? true
                    : false}
              >
                <InputLabel className="boldSelectlabel" shrink={true}>Team Roles*</InputLabel>
                <Select
                  style={{ width: "100%", height: "53px" }}
                  id="adduserfield"
                  value={userDetails.teamRoles}
                  label="Team Roles* &nbsp;"
                  name="teamRoles"
                  onFocus={formik.handleBlur}
                  onChange={handleChange}
                >
                  <MenuItem className="selectOptions" value="Team Lead">Team Lead</MenuItem>
                  <MenuItem className="selectOptions" value="Manager">Manager</MenuItem>
                  <MenuItem className="selectOptions" value="Recruiter">Recruiter</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.errors.teamRoles && formik.touched.teamRoles
                    ? formik.errors.teamRoles
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>
          </div>


          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginTop: "30px",
              gap: "25px",
            }}
          >
            {/* <div style={{ width: "48%" }}>
            <FormControl sx={{ width: 380 }}
             error={
              formik.errors.applications && formik.touched.applications
                ? true
                : false}>
              <InputLabel className="boldSelectlabel" id="demo-multiple-chip-label" shrink={true}>Assign Application*</InputLabel>
              <Select
                className='applicationfieldcss'
                name="applications"
                labelId="demo-multiple-chip-label"
                id=""
                multiple
                onFocus={formik.handleBlur}
                value={userDetails.applications}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Assign Application* &nbsp;&nbsp;" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      let obj = portalData.filter((x) => x.name === value)[0];
                      if(obj && obj.name){
                        return <Chip key={obj.id} label={obj.name} />;
                      }
                    })}
                  </Box>
                )}
              >
                {portalData && portalData.map((name) => (
                  <MenuItem
                    className="selectOptions"
                    key={name.id}
                    value={name.name}
                  >
                    {name.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.applications && formik.touched.applications
                  ? formik.errors.applications
                  : ""}
              </FormHelperText>
            </FormControl>
            </div> */}

            <div style={{ width: "48%" }}>
              <FormControl fullWidth
                error={
                  formik.errors.roles && formik.touched.roles
                    ? true
                    : false}
              >
                <InputLabel className="boldSelectlabel" shrink={true}>System Roles*</InputLabel>
                <Select
                  style={{ width: "100%", height: "53px" }}
                  id="adduserfield"
                  value={userDetails.roles}
                  label="System Roles* &nbsp;"
                  name="roles"
                  onFocus={formik.handleBlur}
                  onChange={handleChange}
                >
                  {/* <MenuItem className="selectOptions" value="Super Admin">Super Admin</MenuItem> */}
                  <MenuItem className="selectOptions" value="Admin">Admin</MenuItem>
                  <MenuItem className="selectOptions" value="Manager">Manager</MenuItem>
                  <MenuItem className="selectOptions" value="Standard User">Standard User</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.errors.roles && formik.touched.roles
                    ? formik.errors.roles
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
{/* 
          <div
            style={{
              marginTop: "30px",
              gap: "25px",
              width: "48%"
            }}
          >
            
          </div> */}

          <div className='savebtnposition'>
            <Button
              className="usersavebtn"
              variant="contained"
              type="submit"
              size="small"
              disableSubmitBtn={disableSubmitBtn}
              onClick={formik.handleSubmit}
            >
             {componentType === "EditUser" ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      ) : radioValue === "bulkupload" ? (
        <>
          <a href={process.env.PUBLIC_URL + "/assests/OnboardingTemplateFormat.xlsx"} download style={{ float: "right" }}>
            Onboarding Template Format
          </a>
          <div style={{ marginTop: "80px" }} className={previewData.length > 0 ? "d-none" : ''}>
            {/* reduceuploadSize */}
            <img src={process.env.PUBLIC_URL + "/assests/img/uploadBgIcon.svg"} alt="upload image" />
            <Button
              variant="contained"
              component="label"
              className="uploadSection"
            >
              <span>Upload</span> <span>Browse file to upload</span>
              <input hidden accept=".xls,.xlsx" type="file"
                onChange={(e) => uploadExcel(e.target.files)}
              />
            </Button>
            <p className="docsupporttext">
              Supported file formats: .xls, .xlsx
            </p>
            <div className='imgBox'>
              <img src={process.env.PUBLIC_URL + "/assests/img/Bulkuploadbg.svg"} alt="" />
            </div>
          </div>
          {previewData.length > 0 ?
            <div>
              <div style={{ maxHeight: "calc(100vh - 500px)", overflow: "hidden auto", display: "block", background: "#fff", boxShadow: "0px 0px 5px #d4d4d4", padding: "10px", width: "100%",     marginTop: "49px" }}>
                <table border="1" cellPadding={5} cellSpacing={0} style={{ width: "100%", border: "none" }}>
                  <thead>
                    <tr align="center">
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email Id</th>
                      <th>Roles</th>
                      <th>Applications</th>
                    </tr>
                  </thead>
                  <tbody>
                    {previewData.length > 0 && previewData.map((item, i) => {
                      return (
                        <tr key={i} align="left">
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                          <td>{item.emailId}</td>
                          <td>{item.roles}</td>
                          <td>{item.applications}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="actionBtns">
                <Button
                  type="button"
                  className="cancelBtn"
                  onClick={() => { onReceivechildProps(false, false) }}>
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  className="saveBtn"
                  onClick={uploadDocuments}
                  disabled={previewData.length > 0 ? false : true}
                >
                  Save
                </Button>
              </div>
              <div className='imgBox'>
                <img src={process.env.PUBLIC_URL + "/assests/img/Bulkuploadbg.svg"} alt="" />
              </div>
            </div>
            : ''}
        </>
      ) : ''}

    </>
  );
};
export default User

const pannelobj = {
  title: "User Onboarding",
  description: "",
  bgImage: "",
};

const editPannelobj = {
  title: "Edit User",
  description: "",
  bgImage: "",
};


// for dynamic Table config Object
const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: true,
  isCheckbox: true,
  isActions: true,
  recordperPage: 1,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  isCheckAlignRight: false,
  isPaginationTop: false,
  isSearchServer: true,
  isPagingServer: true,
  isPageRange: true,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: true,
};

const iniValues = {
  firstName: "",
  lastName: "",
  emailId: "",
  roles: "",
  applications: [],
  teamRoles: '',
  customerId: ''
}

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "First Name",
    mappingName: "firstName",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: " dynatablebodytext text-center ",
    type:"users",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 2,
    displayName: "Last Name",
    mappingName: "lastName",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: " dynatablebodytext text-center",
    width: "",
    type:"users",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 3,
    displayName: "Email ID",
    mappingName: "emailId",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: " text-center",
    type:"users",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 4,
    displayName: "Role",
    mappingName: "roles",
    theadClass: " dynatableheadtext text-center",
    tbodyClass: " dynatablebodytext text-center",
    type:"users",
    width: "",
    isEdit: true,
    nagigate: "detailspage",
  },
  // {
  //   id: 5,
  //   displayName: "Applications",
  //   mappingName: "applicationString",
  //   theadClass: " dynatableheadtext text-center",
  //   tbodyClass: " dynatablebodytext text-center",
  //   type:"users",
  //   width: "",
  //   isEdit: true,
  //   nagigate: "detailspage",
  // },
];

const addNewModel = {
  url: "",
  displayName: ""
}