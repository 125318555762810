import React, { useState, useEffect } from "react";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import { useSelector, useDispatch, } from "react-redux";
import * as _props from '../../../_redux/_services/JobPostingSlice';
import * as _ser from '../../../_redux/_services/JobPostingSlice';
import GetJobId from "../../../_utilities/GetJobId";
import { GetTimeZone } from "../../../_utilities/GetTimeZone";

const initalState = { data: null, message: '', isloader: true }

const PostingsAuditlogging = () => {
  const [AuditlogData, setAuditlogData] = useState([]);
  const [config, setConfig] = useState(configuration);
  
  const dispatch = useDispatch();
  const getJobId = GetJobId();

  const AuditlogResponse = useSelector((state) => state.GetPostingsComments.data);
  const AuditlogResponseCode = useSelector((state) => state.GetPostingsComments.code);

  
  useEffect(() => {
    if (getJobId !== null) {
      Getdata();
     
    }
  }, [getJobId]);

  const Getdata = async (event) => {
     let obj = {
       type: "jobopening",
       typeId: getJobId,
     };
    dispatch(_ser.getPostingsCommentsAsync(obj));
  };

  useEffect(()=>{
    if(AuditlogResponse  && AuditlogResponse.length >0){
      let auditlog = JSON.parse(JSON.stringify(AuditlogResponse));
      auditlog.forEach(item => { item.createdAt = GetTimeZone(item.createdAt) })
      setAuditlogData(auditlog);
      let configObj = JSON.parse(JSON.stringify(config));
      configObj.totalRecords = AuditlogResponse.length;
      setConfig(configObj);
      dispatch(_ser.getPostingsCommentsStartReducer(initalState))
    }
  },[AuditlogResponse])

  const onReceiveActionHandler = (type,object) => {
    if(type === "pageRange") {
      setConfig({...config,limitData:config.limitData=object, recordperPage:config.limitData=object})
    }
  }

  return (
    <>
      <div style={{ padding: "0 0px", marginBottom: "80px" }} >
        <div style={{ background: "#fff", padding: "25px 15px" }} id="auditLog">
          {AuditlogData === null || AuditlogData.length === 0 && (
            <DynamicTable
              config={config}
              data={AuditlogData}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              actions={actionsList}
              filterValue={''}
              noDataText={"No Audit logs found"}
              addModel={addNewModel}
              searchKeyWord="Search by Actions"
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
          {AuditlogData && AuditlogData.length > 0 && (
            <DynamicTable
              config={config}
              data={AuditlogData}
              colmns={columnsData}
              actionDropdown={actionsDropdownData}
              actions={actionsList}
              filterValue={''}
              noDataText={"No Audit logs found"}
              addModel={addNewModel}
              searchKeyWord="Search by Actions"
              onReceiveActionProps={(type, obj) =>
                onReceiveActionHandler(type, obj)
              }
            />
          )}
        </div>
    </div>
    </>
  )
}

export default PostingsAuditlogging;

// for dynamic Table config Object
const configuration = {
  isSearchbar: false,
  isPaging: true,
  isDropdownActions: false,
  isCheckbox: false,
  isActions: false,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 10,
  isCheckAlignRight: false,
  isPaginationTop: false,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange: true,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: false,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Name",
    mappingName: "firstName",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "180px",
    isEdit: false,
    nagigate: "",
  },
  {
    id: 2,
    displayName: "Action Performed",
    mappingName: "action",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center NameCapitalise",
    width: "",
    isEdit: false,
    nagigate: "",
  },
  {
    id: 3,
    displayName: "Date and Time",
    mappingName: "createdAt",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: "dynatablebodytext text-center",
    width: "",
    isEdit: false,
    nagigate: "",
  },
  {
    id: 4,
    displayName: "Comments",
    mappingName: "comments",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "500px",
    isEdit: false,
    nagigate: "",
  },
];

const addNewModel = {
  url: "",
  displayName: ""
}