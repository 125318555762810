
export const ProfileIcon = ({ primaryColor = "#ffd800", primaryFontColor = "rgba(0,0,0,0.8)" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="96" height="96" viewBox="0 0 96 96">
    <defs>
      <filter id="Ellipse_10" x="0" y="0" width="96" height="96" filterUnits="userSpaceOnUse">
        <feOffset dy="3" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="3" result="blur"/>
        <feFlood flood-opacity="0.161"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Group_42" data-name="Group 42" transform="translate(-1720.51 -23.511)">
      <g transform="matrix(1, 0, 0, 1, 1720.51, 23.51)" filter="url(#Ellipse_10)">
        <circle id="Ellipse_10-2" data-name="Ellipse 10" cx="39" cy="39" r="39" transform="translate(9 6)" fill={primaryColor}/>
      </g>
      <g id="customer" transform="translate(1753.242 47.168)">
        <g id="Group_5209" data-name="Group 5209">
          <circle id="Ellipse_1" data-name="Ellipse 1" cx="8.882" cy="8.882" r="8.882" transform="translate(6.581)" fill={primaryFontColor}/>
          <path id="Path_2" data-name="Path 2" d="M138.463,239.958c-5.007-.334-9.359,0-13.113,2.48-6.282,4.021-5.073,9.434-5.073,16.057,0,1.752,1.784,3.2,4.01,3.2,24.171,0,25.133.623,26.567-1.913.47-.858.341-.585.341-8.791C151.2,244.478,144.134,239.958,138.463,239.958Z" transform="translate(-120.186 -219.572)" fill={primaryFontColor}/>
        </g>
      </g>
    </g>
  </svg>
  
  )
}

