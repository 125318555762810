import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import * as _props from '../../../_redux/_services/JobPostingSlice';
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import { GetTimeZone } from "../../../_utilities/GetTimeZone";


const initalState = { data: null, message: '', isloader: true }

const PostingsTable = () => {
  const [users, setUsers] = useState([]);
  const [config, setConfig] = useState(configuration);
  const [filterValue, setFilterValue] = useState("");
  const dispatch = useDispatch();
  const [GetUserId, setGetUserId ] = useState(null);
  const [selectCountryId, setselectCountryId ] = useState(null);
  const [finalColumns, setFinalColumns] = useState([]);

  useEffect(() => {
    if(GetUserId && selectCountryId){
      setUsers([]);
      let obj1 = { current: config.currentPage, limit: config.limitData, offset: users !== 'null' ? users.length + 1 : 1 };
      FetchData(obj1);
    } else {
      setGetUserId(JSON.parse(decrypt('GetUserId')));
      setselectCountryId(JSON.parse(decrypt('selectCountryId')))
    }
  }, [GetUserId, selectCountryId]);

  const responseData = useSelector((state) => state.Listjobpostings.data);

  useEffect(() => {
    let userInfo = JSON.parse(decrypt("GetUserInfo"));
    if(userInfo){
      let modifyConfig = JSON.parse(JSON.stringify(config));
      modifyConfig.isDropdownActions = userInfo[0].systemroles === "Super Admin" || userInfo[0].systemroles === "Manager" || userInfo[0].systemroles === "Admin" ? true : false
      setConfig(modifyConfig)
    } else {
      setConfig(configuration);
    }
    // to remove clients based on customer preference //
    if(userInfo[0]?.clientStatus === 0){
      let array = [...columnsData];
      let clientsIndex = array.findIndex(x => x.displayName === "Client");
      if(clientsIndex !== -1){
        array.splice(clientsIndex, 1);
        setFinalColumns(array);
        console.log(array);
     }
    } else {
      setFinalColumns(columnsData);
    }
  }, []);

  const FetchData = async (obj) => {
    setUsers([]);
    // var params = { limit: config.limitData, offset: obj.offset }
    dispatch(_props.listjobpostingsAsync(obj, "list"));
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = obj.current;
    setConfig(configure);
  };

  useEffect(() => {
    if (responseData && responseData.rows.length > 0) { // responseData !== null && responseData !== undefined
      setUsers([]);
      // if (responseData.length > 0) {
        let myData = JSON.parse(JSON.stringify(responseData));
        myData.rows.forEach((e) => {
          e["isChecked"] = false;
          e["createdAt"] = GetTimeZone(e.createdAt);
        });
        let configure = JSON.parse(JSON.stringify(config));
        configure.totalRecords = myData.total_count / config.limitData;
        setConfig(configure);
        dispatch(_props.listjobpostingsStartReducer(initalState));
        setUsers(myData.rows);
      // }
    }
    else if(responseData || responseData?.rows){      // !== null && responseData !== undefined && responseData.length === 0
        let configure = JSON.parse(JSON.stringify(config));
        configure.totalRecords = 0;
        configure.currentPage = 1;
        setConfig(configure);
        setUsers(filterValue !== '' ? 'null' :[]);
      }
  }, [responseData])

  // for dynamic component received props
  const onReceiveActionHandler = async (type, object) => {
    if (type === "confirmDel") {
      DeleteRecords(object);
    } else if (type === "download") {
      alert("download option is not implemented!");
    } else if (type === "pagination") {
      if (filterValue !== '') {
        getSearchResult(object, true, type);
      } else {
        FetchData(object);
      }
    } else if (type === "searchResult") {
      getSearchResult(object, true, type);
    } else if (type === "cancelSearchResult") {
      setFilterValue("");
      setUsers([]);
      let obj1 = { current: 1, offset: 1, limit: config.limitData };
      FetchData(obj1);
    }else if(type === "pageRange"){
        setConfig({...config,limitData:config.limitData=object})
        let obj1 = { current: config.currentPage, offset: config.currentPage !== 1 ? (config.currentPage - 1) * config.limitData + 1 : config.currentPage, limit: config.limitData };
        FetchData(obj1);
      }
    
  };

  const Deletejobpostingscode = useSelector((state) => state.Deletejobpostings.data);

  const DeleteRecords = async (object) => {
    let ids;
    if (typeof object === "object" && object.length > 0) {
      ids = object.map((x) => x.id.toString());
    } else { ids = [object.toString()]; }
    let obj = { ids: ids };
    dispatch(_props.deletejobpostingsAsync(obj));
  };

  useEffect(() => {
    if (Deletejobpostingscode === 200) {
      setUsers([]);
      dispatch(_props.deletejobpostingsStartReducer(initalState));
      let obj1 = { current: config.currentPage, offset: config.currentPage !== 1 ? (config.currentPage - 1) * 20 + 1 : config.currentPage, limit: config.limitData };
      FetchData(obj1);
      setFilterValue("");
    }
  }, [Deletejobpostingscode])

  const getSearchResult = async (object, paging, type) => {
    if (paging === true) {
      SearchResultWithPaging(object, type);
    } else {
      setFilterValue(object.keyword);
      SearchResultWithoutPaging(object);
    }
  };

  const SearchResultWithoutPaging = async (object) => {
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = object.current;
    setConfig(configure);
    setUsers([]);
    dispatch(_props.listjobpostingsAsync(object, 'searchWithoutPaging'));
  }

  const SearchResultWithPaging = async (object, type) => {
    let configure = JSON.parse(JSON.stringify(config));
    configure.currentPage = object.current;
    setConfig(configure);
    let value = object.keyword? object.keyword : filterValue;
    setFilterValue(value);
    object['keyword'] = value;
    if(type === "searchResult"){
      object['offset'] = 1;
      object['limit'] = config.limitData;
    }
    setUsers([]);
    dispatch(_props.listjobpostingsAsync(object, 'searchWithPaging'));
  }

  return (
    <div style={{ marginTop: "80px", marginBottom: "60px" }} >
      <div style={{ background: "#fff", padding: "22px 47px 47px" }}>
        {/* for dynamic Table */}
        {users && users.length === 0 && (
          <DynamicTable
            config={config}
            data={users}
            colmns={finalColumns}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            filterValue={filterValue}
            noDataText={"No Postings Found"}
            addModel={addNewModel}
            searchKeyWord="Search by Job Code, Job Title, Location, Client Name"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
        )}
        {users && users.length > 0 && (
          <DynamicTable
            config={config}
            data={users}
            colmns={finalColumns}
            actionDropdown={actionsDropdownData}
            actions={actionsList}
            filterValue={filterValue}
            noDataText={"No Postings Found"}
            addModel={addNewModel}
            searchKeyWord="Search by Job Code, Job Title, Location,Client Name"
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)
            }
          />
        )}
      </div>
    </div>
  );
};

export default PostingsTable;

// for dynamic Table config Object
const configuration = {
  isSearchbar: true,
  isPaging: true,
  isDropdownActions: true,
  isCheckbox: true,
  isActions: true,
  recordperPage: 1,
  currentPage: 1,
  totalRecords: 0,
  limitData: 20,
  isCheckAlignRight: true,
  isPaginationTop: true,
  isSearchServer: true,
  isPagingServer: true,
  isPageRange:true,
};

// for dynamic Table Action dropdownData
const actionsDropdownData = ["Delete"];

// for dynamic Table row Action buttons
const actionsList = {
  isDownload: false,
  isDelete: false,
};

// for dynamic Table Headers and columns
const columnsData = [
  {
    id: 1,
    displayName: "Job Code",
    type:"Job Code",
    mappingName: "jobCode",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center ",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/jobpostings/view",
  },
  {
    id: 2,
    displayName: "Job Title",
    type:"Job Code",
    mappingName: "jobTitle",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: "dynatablebodytext text-center NameCapitalise",
    width: "400px",
    isEdit: true,
    nagigate: "/ats/layout/jobpostings/view",
  },
  {
    id: 3,
    displayName: "Date and Time",
    type:"Job Code",
    mappingName: "createdAt",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: "dynatablebodytext text-center NameCapitalise",
    width: "400px",
    isEdit: true,
    nagigate: "/ats/layout/jobpostings/view",
  },
  {
    id: 3,
    displayName: "Location",
    type:"Job Code",
    mappingName: "location",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center NameCapitalise",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/jobpostings/view",
  },
  {
    id: 4,
    displayName: "Job Status",
    type:"Job Code",
    mappingName: "jobStatus",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/jobpostings/view",
  },
  {
    id: 5,
    displayName: "Client",
    type:"Job Code",
    mappingName: "clientName",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "",
    isEdit: true,
    nagigate: "/ats/layout/jobpostings/view",
  }
];

const addNewModel = {
  url: "/ats/layout/jobpostings/create/jobdetails",
  displayName: "New Job"
}