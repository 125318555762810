import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as _ser from '../_apiHelpers/UserManagerApihelper';
import { applyTheme } from '../../_utilities/themeUtils';
import { updateLoader } from '../LoaderSlice';

// Default theme configuration
const DEFAULT_THEME = {
  primaryColor: "#FFD800",
  secondaryColor: "#3B4046",
  primaryFontColor: "#3B4046",
  secondaryFontColor: "#FFFFFF",
  fontFamily: '"Roboto", sans-serif',
  logo: ''
};

// Get initial theme from localStorage if available
const getInitialTheme = () => {
  try {
    const storedTheme = localStorage.getItem("themeSettings");
    if (storedTheme) {
      const parsedTheme = JSON.parse(storedTheme);
      return parsedTheme;
    }
  } catch (error) {
    console.warn('Error parsing stored theme, using default:', error);
  }
  return DEFAULT_THEME;
};

const initialState = {
  themeSettings: getInitialTheme(),
  loading: false,
  error: null
};

// Async thunk for fetching logo URL
export const fetchLogoURL = createAsyncThunk(
  'theme/fetchLogoURL',
  async ({ path, customerId }) => {
    if (!path) return null;
    try {
      const response = await _ser.fetchLogoPreview({ path });
      return response.status === 200 && response.data.path ? response.data.path : null;
    } catch (error) {
      console.warn('Error fetching logo:', error);
      return null;
    }
  }
);

// Async thunk for fetching ATS theme config
export const fetchATSThemeConfig = createAsyncThunk(
  'theme/fetchATSThemeConfig',
  async (customerId, { dispatch }) => {
    dispatch(updateLoader(true)); // Show loader while fetching

    try {
      if (!customerId) {
        console.warn("No customer ID provided for theme fetch.");
        return DEFAULT_THEME;
      }

      const response = await _ser.fetchATSUXConfig(customerId);

      if (response.status === 200 && response.data.atsUxData?.length > 0) {
        const themeSettings = response.data.atsUxData[0];

        let primaryData = [], typography = {};
        try {
          primaryData = JSON.parse(themeSettings.primaryData) || [];
          typography = JSON.parse(themeSettings.typography) || {};
        } catch (error) {
          console.warn("Error parsing theme settings, using default:", error);
        }

        // Ensure fontFamily is stored as an array
        let fontFamily = typography.fontName;
        if (typeof fontFamily === "string") {
          fontFamily = fontFamily.replace(/"/g, "").split(",").map(f => f.trim());
        } else if (!Array.isArray(fontFamily)) {
          fontFamily = DEFAULT_THEME.fontFamily;
        }

        const useCustomHeader = primaryData.find(item => item.title === "UseCustomHeader")?.value === "true";

        // Create theme object
        const theme = {
          primaryColor: primaryData.find(item => item.title === "PrimaryColor")?.value || DEFAULT_THEME.primaryColor,
          secondaryColor: primaryData.find(item => item.title === "SecondaryColor")?.value || DEFAULT_THEME.secondaryColor,
          primaryFontColor: primaryData.find(item => item.title === "PrimaryFontColor")?.value || DEFAULT_THEME.primaryFontColor,
          secondaryFontColor: primaryData.find(item => item.title === "SecondaryFontColor")?.value || DEFAULT_THEME.secondaryFontColor,
          fontFamily,
          useCustomHeader,
          headerBackgroundColor: useCustomHeader
            ? primaryData.find(item => item.title === "HeaderBackgroundColor")?.value || DEFAULT_THEME.headerBackgroundColor
            : null, // Do not apply if `useCustomHeader` is false
          headerTextColor: useCustomHeader
            ? primaryData.find(item => item.title === "HeaderTextColor")?.value || DEFAULT_THEME.headerTextColor
            : null, // Do not apply if `useCustomHeader` is false
          logo: ""
        };

        // Fetch logo if available
        if (themeSettings.logo) {
          try {
            const logoUrl = await dispatch(fetchLogoURL({ path: themeSettings.logo, customerId })).unwrap();
            theme.logo = logoUrl || DEFAULT_THEME.logo;
          } catch (logoError) {
            console.warn("⚠️ Error fetching logo, using default:", logoError);
          }
        }

        // Apply theme to UI
        applyTheme(theme);
        return theme;
      }

      console.warn("⚠️ No theme data found, applying default theme.");
      return DEFAULT_THEME;
    } catch (error) {
      console.error("Error fetching theme, applying default:", error);
      return DEFAULT_THEME;
    } finally {
      dispatch(updateLoader(false)); // Hide loader after fetching
    }
  }
);


const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    resetTheme: (state) => {
      state.themeSettings = DEFAULT_THEME;
      state.error = null;
      localStorage.removeItem("themeSettings");
    },
    setThemeFromStorage: (state) => {
      state.themeSettings = getInitialTheme();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchATSThemeConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchATSThemeConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.themeSettings = action.payload;
        state.error = null;
        localStorage.setItem("themeSettings", JSON.stringify(action.payload));
      })
      .addCase(fetchATSThemeConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        console.warn("Theme fetch failed, keeping existing theme.");
      })
      .addCase(fetchLogoURL.fulfilled, (state, action) => {
        if (state.themeSettings) {
          state.themeSettings.logo = action.payload || DEFAULT_THEME.logo;
          localStorage.setItem("themeSettings", JSON.stringify(state.themeSettings));
        }
      });
  }
});

export const { resetTheme, setThemeFromStorage } = themeSlice.actions;
export default themeSlice.reducer;