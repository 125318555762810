import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import Cookies from "js-cookie";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./ApplicantsNewDashboard.css";
import "../ApplicantsDashboard/applicantsdashboard.css";
import { useDispatch, useSelector } from "react-redux";

import { decrypt } from "../../../_utilities/_encryptDecryptHelper";
import { customFieldsAsync } from "../../../_redux/_services/UserManagerSlice";
import {
  getCandidateInternalStatus,
  getCandidateStatusCustomValues,
} from "../../../_utilities/internalexternalhelper";
import { updateDashboardConfigAsync } from "../../../_redux/_services/UserManagerSlice";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";

import DateTimeContainer from "./DateTime/Datetime";
import InformationWidgetCard from "./InfoWidget/InformationWidget";
import PieChartWidgetCard from "./PieChartWidget/PieChartWidget";
import BarChartWidgetCard from "./BarChartWidget/BarChartWidget";
import TableWidgetCard from "./TableWidget/TableWidget";
import AddEditModal from "./AddEditModal/AddEditModal";

import {
  commonWidgetContent,
  infoWidgetList,
  initialLayoutConfig,
} from "./widget";
import * as _appSer from "../../../_redux/_services/ApplicantsSlice";

import dayjs from "dayjs";
import {
  getInterviewInfo,
  getResumeInfo,
  getSelectedInfo,
  getSubmissionInfo,
} from "./utils";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";

const ApplicantsNewDashboard = () => {
  const startDate = dayjs().subtract(7, "day").toDate();
  const endDate = new Date();
  const formatStartDate = dayjs(startDate).format("YYYY-MM-DD");
  const formatEndDate = dayjs(endDate).format("YYYY-MM-DD");

  const [layoutConfig, setLayoutConfig] = useState([]);
  const [gridWidth, setGridWidth] = useState(1200);
  const [editMode, setEditMode] = useState(false);

  const [pieChartSwitchList, setPieChartSwitch] = useState([]);
  const [barChartSwitchList, setBarChartSwitch] = useState([]);
  const [tableSwitchList, setTableSwitch] = useState([]);
  const [selectedCardTitle, setSelectedCardTitle] = useState("");

  const [infoWidgetsData, setInfoWidgetscData] = useState({});
  const [pieChartData, setPieChartData] = useState({});
  const [barChartData, setBarChartData] = useState({});
  const [tableData, setTableData] = useState({});
  const [graphOfStatusData, setGraphOfStatusData] = useState({});
  const [graphByClientData, setGraphByClientData] = useState({});
  const [resumeCountByStatus, setResumeCountByStatus] = useState({});

  const [addEditCard, setAddEditCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [candidateStatusValues, setCandidateStatusValues] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    startDate: formatStartDate,
    endDate: formatEndDate,
  });

  const widgetsResponse = useSelector((state) => state.GetResumeCount.data);
  const pieChartResponse = useSelector((state) => state.GetPieChartData.data);
  const barChartResponse = useSelector((state) => state.GetBarChartData.data);
  const customFieldsData = useSelector((state) => state.CustomFields.data);
  const graphOfStatus = useSelector((state) => state.GetGraphOfStatus.data);
  const graphbyclients = useSelector((state) => state.GetGraphByClient.data);
  const resumeContributedByStatus = useSelector(
    (state) => state.GetResumeCountByStatus.data
  );

  const requirementAssignedResponse = useSelector(
    (state) => state.GetRequirementAssigned.data
  );

  const containerRef = useRef(null);
  const newCardRef = useRef(null);
  const dispatch = useDispatch();

  useUnsavedChangesWarning(
    unsavedChanges,
    "You have unsaved changes. Are you sure you want to leave?"
  );

  const reloadPage = (config = []) => {
    config.forEach((card) => {
      getNewGraphDetails(card);
    });
  };

  const selectedCountry = JSON.parse(decrypt('selectCountry'));
  const allCountries = JSON.parse(decrypt('allCountryId'));
  const prevCountry = Cookies.get("selectCountryId");

  //getGraphOfStatusAsync
  const getGraphOfStatusData = (widget) => {
    const requestBody = {
      widgetId: widget.i,
      status: widget.status.join(","),
      startDate: selectedDate?.startDate,
      endDate: selectedDate?.endDate,
      countryId: allCountries === "all" ? allCountries : selectedCountry?.id ? selectedCountry?.id : prevCountry
    };
    dispatch(_appSer.getGraphOfStatusAsync(requestBody));
  };

  //getGraphByClientAsync
  const getGraphbyClientData = (widget) => {
    const requestBody = {
      client: widget.client.id,
      widgetId: widget.i,
      status: widget.status.join(","),
      startDate: selectedDate?.startDate,
      endDate: selectedDate?.endDate,
      countryId: allCountries === "all" ? allCountries : selectedCountry?.id ? selectedCountry?.id : prevCountry
    };
    dispatch(_appSer.getGraphByClientAsync(requestBody));
  };

  //getGraphByClientAsync
  const getResumeContributed = (widget) => {
    const getAttributes = JSON.parse(localStorage.getItem("getAttributes"));
    const requestBody = {
      widgetId: widget.i,
      status: widget.status.join(","),
      startDate: selectedDate?.startDate,
      endDate: selectedDate?.endDate,
      userId: getAttributes[0].userId,
      countryId: allCountries === "all" ? allCountries : selectedCountry?.id ? selectedCountry?.id : prevCountry
    };
    dispatch(_appSer.getResumeCountByStatusAsync(requestBody));
  };

  const movePositionToNewCard = (id) => {
    if (id) {
      const element = document.querySelector(`[data-grid-id='${id}']`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth", // Smooth scroll effect
          block: "end", // Scroll to the center vertically
          inline: "nearest", // Keep it in the nearest horizontal alignment
        });
        newCardRef.current = element;
      }
    }
  };

  const getNewGraphDetails = (card) => {
    switch (card.type) {
      case "GraphofStatus":
        getGraphOfStatusData(card);
        return null;
      case "GraphbyClient":
        getGraphbyClientData(card);
        return null;
      case "ResumeCount":
        getResumeContributed(card);
        return null;
      default:
        return standedCardMappingData(card);
    }
  };

  const standedCardMappingData = (card) => {
    if (infoWidgetList.includes(card.type)) {
      return { ...card, data: infoWidgetsData[card.type] };
    } else if (card.type === "pieChart") {
      if (pieChartSwitchList.includes(card.i)) {
        return {
          ...card,
          data: pieChartData.company,
          h: pieChartData?.company?.length > 0 ? 7 : 3,
        };
      }
      return {
        ...card,
        data: pieChartData.user,
        h: pieChartData?.user?.length > 0 ? 7 : 3,
      };
    } else if (card.type === "barChart") {
      if (barChartSwitchList.includes(card.i)) {
        return {
          ...card,
          data: barChartData.company,
          h:
            barChartData?.company && barChartData?.company[0]?.length > 0
              ? 7
              : 3,
        };
      }
      return {
        ...card,
        data: barChartData.user,
        h: barChartData?.user && barChartData?.user[0]?.length > 0 ? 7 : 3,
      };
    } else if (card.type === "requirementsWidget") {
      if (tableSwitchList.includes(card.i)) {
        return {
          ...card,
          data: tableData.company,
          h: tableData?.company?.length > 0 ? 10 : 4,
        };
      }
      return {
        ...card,
        data: tableData.user,
        h: tableData?.user?.length > 0 ? 10 : 4,
      };
    } else if (card.type === "GraphofStatus") {
      return {
        ...card,
        data: graphOfStatusData[card.i],
        h:
          graphOfStatusData[card.i] && graphOfStatusData[card.i][0]?.length > 0
            ? 7
            : 3,
      };
    } else if (card.type === "GraphbyClient") {
      return {
        ...card,
        data: graphByClientData[card.i],
        h: graphByClientData[card.i]?.length > 0 ? 7 : 3,
      };
    } else if (card.type === "ResumeCount") {
      return {
        ...card,
        data: resumeCountByStatus[card.i],
      };
    } else {
      return card;
    }
  };

  const pieChartSwitchChange = (value, card) => {
    const list = [...pieChartSwitchList];
    const updatedList = updateList(list, value, card.i);
    setPieChartSwitch(updatedList);
  };

  const barChartSwitchChange = (value, card) => {
    const list = [...barChartSwitchList];
    const updatedList = updateList(list, value, card.i);
    setBarChartSwitch(updatedList);
  };

  const tableSwitchChange = (value, card) => {
    const list = [...tableSwitchList];
    const updatedList = updateList(list, value, card.i);
    setTableSwitch(updatedList);
  };

  const updateList = (list = [], value, i) => {
    if (value) {
      list.push(i);
    } else {
      const index = list.findIndex((value) => i === value);
      list.splice(index, 1);
    }
    return list;
  };

  /**
   * Card actions
   */
  const handleSaveCardTitle = (id, title) => {
    setSelectedCardTitle("");
    setLayoutConfig((prevLayout) =>
      prevLayout.map((card) => {
        if (card.i === id) {
          return { ...card, title: title };
        }
        return card;
      })
    );
  };
  const handleAddCard = (updatedCard) => {
    if (updatedCard.i) {
      setLayoutConfig((prevLayout) =>
        prevLayout.map((card) => {
          if (card.i === updatedCard.i) {
            delete updatedCard.description;
            delete updatedCard.sampleImg;
            const finalCard = { ...card, ...updatedCard, h: card.h };
            return finalCard;
          }
          return card;
        })
      );
      getNewGraphDetails(updatedCard);
    } else {
      // Find the highest key from the layout to avoid duplicates
      const maxKey = layoutConfig.reduce(
        (max, card) => Math.max(max, parseInt(card.i, 10)),
        0
      );

      // Find the first available position in the grid (empty slot)
      let newCardX = 0;
      let newCardY = 0;
      let foundSlot = false;

      // Traverse the grid to find the first empty slot
      while (!foundSlot) {
        foundSlot = true;
        // Check if the new slot (newCardX, newCardY) is occupied
        for (const card of layoutConfig) {
          const isOverlap =
            card.x < newCardX + 3 &&
            newCardX < card.x + card.w &&
            card.y < newCardY + 6 && // Adjust based on card height
            newCardY < card.y + card.h;

          if (isOverlap) {
            // If overlap, move to next column
            newCardX += card.w;
            if (newCardX >= 12) {
              // Move to next row if no more columns are available
              newCardX = 0;
              newCardY++;
            }
            foundSlot = false;
            break;
          }
        }
      }

      // Add a new card with a unique key
      const newId = (maxKey + 1).toString(); // Ensure the new ID is unique
      delete updatedCard.description;
      delete updatedCard.sampleImg;
      const newCard = { i: newId, x: newCardX, y: newCardY, ...updatedCard };
      const widget = getNewGraphDetails(newCard);
      setLayoutConfig((prevLayout) => [
        ...prevLayout,
        widget ? { ...widget } : { ...newCard, data: [] },
      ]);
      setTimeout(() => {
        movePositionToNewCard(newId);
      }, 100);

    }
    setUnsavedChanges(true);
    setAddEditCard(false);
  };

  const handleEditCard = (card) => {
    setSelectedCard(card);
    setAddEditCard(true);
  };

  const handleCardTitle = (id) => {
    setSelectedCardTitle(id);
    setUnsavedChanges(true);
  };

  const handleDeleteCard = (id) => {
    setLayoutConfig((prevLayout) => prevLayout.filter((card) => card.i !== id));
    setUnsavedChanges(true);
  };

  const handleSaveConfig = () => {
    if (selectedCardTitle) {
      alert("Please save card title");
      return;
    }

    setEditMode(false);
    const getAttributes = JSON.parse(localStorage.getItem("getAttributes"));

    // If all widgets are deleted, restore initial layout
    if (layoutConfig.length === 0) {
      const defaultConfig = initialLayoutConfig.map((configWidget) => {
        if (infoWidgetList.includes(configWidget.type)) {
          return { ...configWidget, config: commonWidgetContent };
        }
        return configWidget;
      });

      const payload = {
        userId: getAttributes[0].userId,
        dashboardConfig: JSON.stringify(defaultConfig),
      };

      dispatch(updateDashboardConfigAsync(payload));
      reloadPage(defaultConfig); // Fetch new data for default widgets
      dispatch(updateSnackbar({ type: "info", message: "No widgets on dashboard. Resetting to default layout." }));
      const mappedDefault = defaultConfig.map((widget) =>
        standedCardMappingData(widget)
      );
      setLayoutConfig(mappedDefault);
    } else {
      // Save current layout
      const newLayout = JSON.parse(JSON.stringify(layoutConfig));
      const finalLayout = newLayout.map((lay) => {
        delete lay.data;
        return lay;
      });

      const payload = {
        userId: getAttributes[0].userId,
        dashboardConfig: JSON.stringify(finalLayout),
      };

      dispatch(updateDashboardConfigAsync(payload));
    }

    setUnsavedChanges(false);
  };

  const onLayoutChange = useCallback(
    (newLayout) => {
      const updatedLayout = newLayout.map((item, index) => ({
        ...layoutConfig[index],
        ...item,
      }));
      setLayoutConfig(updatedLayout);
    },
    [layoutConfig]
  );

  useEffect(() => {
    const updateGridWidth = () => {
      if (containerRef.current) {
        const containerPadding = 40; // 20px padding on each side
        setGridWidth(containerRef.current.offsetWidth - containerPadding);
      }
    };

    updateGridWidth();
    window.addEventListener("resize", updateGridWidth);

    return () => {
      window.removeEventListener("resize", updateGridWidth);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);
  }, []);

  useEffect(() => {
    let userId = JSON.parse(decrypt("GetUserId"));
    dispatch(customFieldsAsync(userId));
  }, []);

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const array = getCandidateStatusCustomValues(customFieldsData);
      setCandidateStatusValues(array);
    }
  }, [customFieldsData]);

  useLayoutEffect(() => {
    const newLayout = JSON.parse(JSON.stringify(layoutConfig));
    const finalWidget = newLayout.map((widget) => {
      return standedCardMappingData(widget);
    });
    setLayoutConfig(finalWidget);
  }, [
    infoWidgetsData,
    pieChartData,
    barChartData,
    tableData,
    pieChartSwitchList,
    barChartSwitchList,
    tableSwitchList,
    graphOfStatusData,
    graphByClientData,
    resumeCountByStatus,
  ]);

  useEffect(() => {
    if (widgetsResponse) {
      const infoWidgetData = {};
      layoutConfig.forEach((configWidget) => {
        if (infoWidgetList.includes(configWidget.type)) {
          switch (configWidget.type) {
            case "resume":
              infoWidgetData[configWidget.type] = getResumeInfo(
                widgetsResponse,
                configWidget.type
              );
              break;
            case "submission":
              infoWidgetData[configWidget.type] = getSubmissionInfo(
                widgetsResponse,
                configWidget.type
              );
              break;
            case "interview":
              infoWidgetData[configWidget.type] = getInterviewInfo(
                widgetsResponse,
                configWidget.type
              );
              break;
            case "selected":
              infoWidgetData[configWidget.type] = getSelectedInfo(
                widgetsResponse,
                configWidget.type
              );
              break;
            default:
              break;
          }
        }
      });
      setInfoWidgetscData(infoWidgetData);
    }
  }, [widgetsResponse]);

  useEffect(() => {
    if (pieChartResponse) {
      const company = pieChartResponse.companyData.map((list) => ({
        value: list.resumecount,
        label: list.resumesource_name,
      }));
      const user = pieChartResponse.userData.map((list) => ({
        value: list.resumecount,
        label: list.resumesource_name,
      }));
      setPieChartData({ company, user });
    }
  }, [pieChartResponse]);

  useEffect(() => {
    if (barChartResponse && candidateStatusValues.length) {
      const companyBarChart = barChartResponse.companyData;
      const companyBarData = companyBarChart.map((list) => list.statusCount);
      const companyBarLabel = companyBarChart.map((list) =>
        getCandidateInternalStatus(list.candidateStatus, candidateStatusValues)
      );
      const company = [companyBarData, companyBarLabel];

      const userBarChart = barChartResponse.userData;
      const userBarData = userBarChart.map((list) => list.statusCount);
      const userBarLabel = userBarChart.map((list) =>
        getCandidateInternalStatus(list.candidateStatus, candidateStatusValues)
      );
      const user = [userBarData, userBarLabel];

      setBarChartData({ company, user });
    }
  }, [barChartResponse, candidateStatusValues]);

  useEffect(() => {
    if (
      requirementAssignedResponse !== null &&
      requirementAssignedResponse !== undefined
    ) {
      if (
        requirementAssignedResponse?.companyData ||
        requirementAssignedResponse?.userData
      ) {
        const company = JSON.parse(
          JSON.stringify(requirementAssignedResponse?.companyData)
        );
        const user = JSON.parse(
          JSON.stringify(requirementAssignedResponse?.userData)
        );
        setTableData({ company, user });
      }
    }
  }, [requirementAssignedResponse]);

  useEffect(() => {
    const widgetKeys = Object.keys(graphOfStatus || {});
    if (widgetKeys.length) {
      const graphOfStatusObj = {};
      widgetKeys.forEach((key) => {
        const barData = graphOfStatus[key].map((list) => list.statusCount);
        const barLabel = graphOfStatus[key].map((list) =>
          getCandidateInternalStatus(
            list.candidateStatus,
            candidateStatusValues
          )
        );
        const data = [barData, barLabel];
        graphOfStatusObj[key] = data;
      });
      setGraphOfStatusData(graphOfStatusObj);
    }
  }, [graphOfStatus, candidateStatusValues]);

  useEffect(() => {
    const widgetKeys = Object.keys(graphbyclients || {});
    if (widgetKeys.length) {
      const clientData = {};
      widgetKeys.forEach((key) => {
        const newArray = graphbyclients[key].map((list) => ({
          value: list.statusCount,
          label: getCandidateInternalStatus(
            list.candidateStatus,
            candidateStatusValues
          ),
        }));
        clientData[key] = newArray;
      });
      setGraphByClientData(clientData);
    }
  }, [graphbyclients, candidateStatusValues]);

  useEffect(() => {
    const widgetKeys = Object.keys(resumeContributedByStatus || {});
    if (resumeContributedByStatus && widgetKeys.length) {
      const clientData = {};
      widgetKeys.forEach((key) => {
        const resumeCountObj = resumeContributedByStatus[key];
        const currentValue = resumeCountObj.totalResumeCount || 0;
        const avgValue = resumeCountObj?.averageResumes?.toFixed(0) || 0;
        const highestValue = resumeCountObj?.highestResumes?.toFixed(0) || 0;
        const avgPercentage =
          highestValue > 0 ? (avgValue / highestValue) * 100 : 0;
        clientData[key] = {
          currentValue,
          avgValue,
          highestValue,
          avgPercentage,
        };
      });
      setResumeCountByStatus(clientData);
    }
  }, [resumeContributedByStatus, candidateStatusValues]);

  useLayoutEffect(() => {
    const dashboardConfig = JSON.parse(decrypt("DashboardConfig")); // Get dashboardConfig from local storage
    const config = dashboardConfig?.length > 0 ? dashboardConfig : initialLayoutConfig; //initialLayoutConfig
    const finalConfig = config.map((configWidget) => {
      if (infoWidgetList.includes(configWidget.type)) {
        return { ...configWidget, config: commonWidgetContent };
      } else {
        return { ...configWidget };
      }
    });
    reloadPage(finalConfig);
    setLayoutConfig(finalConfig);
  }, []);

  const loadWidgets = (card) => {
    switch (card.type) {
      case "GraphbyClient":
        return (
          <PieChartWidgetCard
            card={card}
            editMode={editMode}
            cardTitleChangeId={selectedCardTitle}
            handleCardTitle={(id) => handleCardTitle(id)}
            handleSaveCardTitle={(id, title) => handleSaveCardTitle(id, title)}
            handleDeleteCard={(id) => handleDeleteCard(id)}
            handleEditCard={(card) => handleEditCard(card)}
          />
        );
      case "pieChart":
        return (
          <PieChartWidgetCard
            card={card}
            editMode={editMode}
            showToggle={true}
            cardTitleChangeId={selectedCardTitle}
            handleCardTitle={(id) => handleCardTitle(id)}
            switchChange={(value) => pieChartSwitchChange(value, card)}
            handleSaveCardTitle={(id, title) => handleSaveCardTitle(id, title)}
            handleDeleteCard={(id) => handleDeleteCard(id)}
            handleEditCard={(card) => handleEditCard(card)}
          />
        );
      case "GraphofStatus":
        return (
          <BarChartWidgetCard
            card={card}
            editMode={editMode}
            cardTitleChangeId={selectedCardTitle}
            handleCardTitle={(id) => handleCardTitle(id)}
            handleSaveCardTitle={(id, title) => handleSaveCardTitle(id, title)}
            handleDeleteCard={(id) => handleDeleteCard(id)}
            handleEditCard={(card) => handleEditCard(card)}
          />
        );
      case "barChart":
        return (
          <BarChartWidgetCard
            card={card}
            editMode={editMode}
            showToggle={true}
            cardTitleChangeId={selectedCardTitle}
            handleCardTitle={(id) => handleCardTitle(id)}
            switchChange={(value) => barChartSwitchChange(value, card)}
            handleSaveCardTitle={(id, title) => handleSaveCardTitle(id, title)}
            handleDeleteCard={(id) => handleDeleteCard(id)}
            handleEditCard={(card) => handleEditCard(card)}
          />
        );
      case "requirementsWidget":
        return (
          <TableWidgetCard
            card={card}
            editMode={editMode}
            showToggle={true}
            cardTitleChangeId={selectedCardTitle}
            handleCardTitle={(id) => handleCardTitle(id)}
            switchChange={(value) => tableSwitchChange(value, card)}
            handleSaveCardTitle={(id, title) => handleSaveCardTitle(id, title)}
            handleDeleteCard={(id) => handleDeleteCard(id)}
            handleEditCard={(card) => handleEditCard(card)}
          />
        );
      default:
        return (
          <InformationWidgetCard
            card={card}
            editMode={editMode}
            cardTitleChangeId={selectedCardTitle}
            handleCardTitle={(id) => handleCardTitle(id)}
            handleSaveCardTitle={(id, title) => handleSaveCardTitle(id, title)}
            handleDeleteCard={(id) => handleDeleteCard(id)}
            handleEditCard={(card) => handleEditCard(card)}
          />
        );
    }
  };

  return (
    <div ref={containerRef} className="dashboard">
      <DateTimeContainer
        setEditMode={(value) => setEditMode(value)}
        updateDate={(range) => setSelectedDate(range)}
        handleSaveConfig={() => handleSaveConfig()}
        reloadWidgets={() => reloadPage(layoutConfig)}
        editMode={editMode}
        handleAddCard={() => {
          setSelectedCard(null);
          setAddEditCard(true);
        }}
      />
      {addEditCard && (
        <AddEditModal
          open={addEditCard}
          submit={(data) => handleAddCard(data)}
          selectedCard={selectedCard}
          closeDialog={() => {
            setSelectedCard(null);
            setAddEditCard(false);
          }}
        />
      )}
      <GridLayout
        className="dashboard__grid layout"
        layout={layoutConfig}
        cols={12}
        rowHeight={50}
        width={gridWidth}
        isResizable={editMode}
        isDraggable={editMode}
        onLayoutChange={onLayoutChange}
        margin={[20, 20]}
        draggableCancel=".dashboard__card-icons, .save-btn, .dashboard__card-edit-icon, .dashboard__card-header-section, .switch-container"
      >
        {layoutConfig.map((card) => {
          return (
            <div
              key={card.i}
              data-grid-id={card.i}
              data-grid={card}
              className={card.className}
            >
              {loadWidgets(card)}
            </div>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default ApplicantsNewDashboard;
