
export const Jobportal = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.003" height="19.996" viewBox="0 0 22.003 19.996">
    <g id="_009-job-portal" data-name="009-job-portal" transform="translate(0.002)">
      <path id="Path_6309" data-name="Path 6309" d="M440.8,0h4.713V2.712H440.8Z" transform="translate(-423.509)" fill={primaryFontColor}/>
      <path id="Path_6310" data-name="Path 6310" d="M203.531,236.141h4v1.427h-4Z" transform="translate(-194.531 -225.901)" fill={primaryFontColor}/>
      <path id="Path_6311" data-name="Path 6311" d="M0,0H15.647V2.712H0Z" fill={primaryFontColor}/>
      <path id="Path_6312" data-name="Path 6312" d="M0,101.2H22v1.427H0Z" transform="translate(0 -96.815)" fill={primaryFontColor}/>
      <path id="Path_6313" data-name="Path 6313" d="M22,179.179V168.672H0v10.507H2.335v-6.51H7.456V170.1h7.087v2.57h5.122v6.51Z" transform="translate(-0.002 -161.181)" fill={primaryFontColor}/>
      <path id="Path_6314" data-name="Path 6314" d="M99.911,407.062H94.335v2.57H89.913v-2.57H84.336v4H99.911Z" transform="translate(-81.128 -391.063)" fill={primaryFontColor}/>
      <path id="Path_6315" data-name="Path 6315" d="M84.336,303.61H99.911v2.8H84.336Z" transform="translate(-81.127 -290.866)" fill={primaryFontColor}/>
      <path id="Path_6316" data-name="Path 6316" d="M237.266,407.062h1.427v1.427h-1.427Z" transform="translate(-226.979 -389.844)" fill={primaryFontColor}/>
    </g>
  </svg>
  


  )
}

