import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import * as _ser from '../../../_redux/_services/JobPostingSlice';
import GetJobId from '../../../_utilities/GetJobId';
import { setBreadCrumb } from '../../../_utilities/breadCrumb';
import PostingsComponent from "../PostingsComponent";

const initalState = { data: null, message: '', isloader: true }

const PostingsEdit = () => {
  const [ viewJob, setViewJob ] = useState(null);
  const [ updateJob, setUpdateJob ] = useState(null);
  const getJobCode = GetJobId();
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);
  const updateCode = useSelector((state) => state.Editjobpostings.data);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  useEffect(() => {
    console.log("getJobCode", getJobCode);
  }, [getJobCode])

  useEffect(() => {
    console.log("getDetails", getDetails);
  }, [getDetails])


  useEffect(() => {
    if (getDetails === null && viewJob === null) {
      dispatch(_ser.jobpostingsbyidAsync(getJobCode));
    } else if(getDetails !== null && getDetails){
      let postingData = JSON.parse(JSON.stringify(getDetails));
      postingData.jobType = postingData.jobType.split(",");
      postingData.recruiters = postingData.recruiters ? JSON.parse(postingData.recruiters) : [];;
      postingData.clientName = { id: postingData.clientId, clientName: postingData.clientName }
      setViewJob(postingData);
      let name =`${postingData.jobTitle} (${postingData.jobCode})`
      setBreadCrumb(postingData.id, name, "Job Code")
    }
  }, [getDetails, dispatch]);

  useEffect(() => {
    console.log("viewJob", viewJob);
  }, [viewJob])

  const actionBtns = [
    {
      variant: "text",
      style: { color: "red", fontWeight: "bolder", width:"6rem" },
      navigateTo: `/ats/layout/jobpostings/view/${getJobCode}`,
      label: "Cancel",
      type: "back",
      class: 'buttonHover',
    },
    {
      variant: "contained",
      style: { backgroundColor: "var(--primary-bg-color)", color: "var(--primary-background-text-color)", borderRadius: "10px",fontSize:"14px", Height:"40px !important", width: "6rem" },
      navigateTo: null,
      label: "Save",
      type: "submit",
      class: 'buttonHover1',
    }
  ]

  const GetValidData = async (data) => {
    let Obj = JSON.parse(JSON.stringify(viewJob));
    Obj.workModel = data.workModel;
    Obj.jobStatus = data.jobStatus;
    Obj.jobTitle = data.jobTitle;
    Obj.externalSystemID = data.externalSystemID;
    Obj.jobType = data.jobType;
    Obj.clientId = data.clientId;
    Obj.clientName = data.clientName.clientName;
    Obj.clientBillRate = data.clientBillRate;
    Obj.payBillRate = data.payBillRate;
    Obj.location = data.location;
    Obj.teamLead = data.teamLead;
    Obj.manager = data.manager;
    Obj.recruiters = data.recruiters;
    // Obj.recruitersemails = data.recruitersemails;
    Obj.priority = data.priority;
    Obj.primarySkills = data.primarySkills;
    Obj.secondarySkills = data.secondarySkills;
    Obj.jobDesc = data.jobDesc;
    Obj.jobDescForSM = data.jobDescForSM;
    Obj.customerId = data.customerId;
    Obj.userId = data.userId;
    Obj.userFirstName = data.userFirstName;
    Obj.userLastName = data.userLastName;
    Obj.customerId = data.customerId;
    Obj.customFields = data.customFields;
    // let emails = JSON.parse(data.recruitersemails);
    //   emails.forEach((item, index) => {
    //     Obj["recruitersemails"+"["+[index]+"]"] = item;
      // })
    setUpdateJob(Obj);
    let newobj = { id: data.id, name : `${data.jobTitle} (${data.jobCode})`, type : 'Job Code' };
      let subPathObj = JSON.parse(localStorage.getItem("subpathObj"));
      if(subPathObj && subPathObj.length > 0){
        let index = subPathObj.findIndex(x => x.id === newobj.id);
        if(index !== -1){
          subPathObj[index] = newobj;
        } else {
          subPathObj.push(newobj);
        }
      } else {
        subPathObj = [newobj];
      }
    localStorage.setItem("subpathObj", JSON.stringify(subPathObj));
    setDisableSubmitBtn(false)
  }

  useEffect(()=>{
    if(updateJob !== null){
      setDisableSubmitBtn(false)
      dispatch(_ser.editjobpostingsAsync(getJobCode, updateJob));
    }
  }, [dispatch, updateJob])
  
  useEffect(()=>{
    if (updateCode === 200) {
      dispatch(_ser.editjobpostingsStartReducer(initalState));
      dispatch(_ser.jobpostingsbyidStartReducer(initalState));
      setUpdateJob(null);
      navigate(`/ats/layout/jobpostings/view/${getJobCode}`)
    }
  },[updateCode, dispatch])

  return (
    <React.Fragment>
      <div style={{ padding: "0 10px" }}>
        {viewJob && viewJob.length !== 0 && viewJob?.jobcode !== '' ?
          <PostingsComponent componentType="Edit" input={viewJob} iniValue={viewJob} //initialDetails
            actions={actionBtns} onValidData={GetValidData} getJobDetails={false} /> : 
            <div className="nodatasection">
          <img src={process.env.PUBLIC_URL + "/assests/nodata.svg"} alt="noData" />
          {"No data found"}
          </div>
            }
      </div>
    </React.Fragment>
  );
}

export default React.memo(PostingsEdit);

const initialDetails = {
  jobCode: '',
  workModel: '',
  jobStatus: '',
  jobTitle: '',
  externalSystemID:'',
  jobType: '',
  clientId: '',
  clientName: {},
  clientBillRate: '',
  payBillRate: '',
  location: '',
  teamLead: '',
  manager: [],
  recruiters: [],
  priority: '',
  primarySkills: '',
  secondarySkills: '',
  jobDesc: '',
  jobDescForSM: '',
  customerId: 0,
  userId: '',
  userFirstName: '',
  userLastName: '',
  customerId: '',
}