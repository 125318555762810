export const ResumeContributed = ({primaryColor="#ffd800", primaryFontColor="rgba(0,0,0,0.8)", secondaryColor="#3b4046"}) => {
    return (
        <svg  width="420.403" height="350.808" viewBox="0 0 420.403 350.808">
            <g id="Group_38565" data-name="Group 38565" transform="translate(-81.378 -303.986)">
                <g id="Rectangle_2944" data-name="Rectangle 2944" transform="translate(81.378 303.986)" fill="#fff" stroke="#c3c3c3" stroke-width="0.3">
                <rect width="420.403" height="350.808" rx="20" stroke="none"/>
                <rect x="0.15" y="0.15" width="420.103" height="350.508" rx="19.85" fill="none"/>
                </g>
                <text id="My_Contribution" data-name="My Contribution" transform="translate(102.432 470.965)" fill="rgba(34,34,34,0.8)" font-size="16" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">My Contribution</tspan></text>
                <text id="Company_s_average" data-name="Company&apos;s average" transform="translate(102.432 523.08)" fill="rgba(34,34,34,0.8)" font-size="16" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">Company&apos;s average</tspan></text>
                <text id="_7" data-name="7" transform="translate(102.432 438.153)" fill="#404040" font-size="70" font-family="RobotoCondensed-Bold, Roboto" font-weight="700"><tspan x="0" y="0">7</tspan></text>
                <text id="_5" data-name="5" transform="translate(435.832 548.378)" fill="#404040" font-size="34" font-family="RobotoCondensed-Bold, Roboto" font-weight="700"><tspan x="0" y="0">5</tspan></text>
                <text id="_9" data-name="9" transform="translate(436.547 613.203)" fill="#404040" font-size="34" font-family="RobotoCondensed-Bold, Roboto" font-weight="700"><tspan x="0" y="0">9</tspan></text>
                <text id="Top_Performer" data-name="Top Performer" transform="translate(102.431 590.496)" fill="rgba(34,34,34,0.8)" font-size="16" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">Top Performer</tspan></text>
                <g id="Group_38524" data-name="Group 38524" transform="translate(102.432 532.333)">
                <g id="Group_38377" data-name="Group 38377" transform="translate(0 0.174)">
                    <rect id="Rectangle_2866" data-name="Rectangle 2866" width="278.969" height="16.843" rx="8" fill="rgba(191,191,191,0.3)"/>
                    <rect id="Rectangle_2865" data-name="Rectangle 2865" width="194.752" height="16.843" rx="8" fill={primaryColor}/>
                </g>
                <g id="Group_38373" data-name="Group 38373" transform="translate(15.082 0)">
                    <g id="Group_5367" data-name="Group 5367" transform="translate(0 0.116)">
                    <path id="Path_16572" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38362" data-name="Group 38362" transform="translate(15.824 0.174)">
                    <path id="Path_16572-2" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38366" data-name="Group 38366" transform="translate(63.926 0.233)">
                    <path id="Path_16572-3" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38369" data-name="Group 38369" transform="translate(112.028)">
                    <path id="Path_16572-4" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38363" data-name="Group 38363" transform="translate(31.858 0.233)">
                    <path id="Path_16572-5" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38365" data-name="Group 38365" transform="translate(79.96 0.291)">
                    <path id="Path_16572-6" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38368" data-name="Group 38368" transform="translate(128.063 0.058)">
                    <path id="Path_16572-7" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38364" data-name="Group 38364" transform="translate(47.892 0.233)">
                    <path id="Path_16572-8" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38367" data-name="Group 38367" transform="translate(95.994 0.291)">
                    <path id="Path_16572-9" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38370" data-name="Group 38370" transform="translate(144.097 0.058)">
                    <path id="Path_16572-10" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38371" data-name="Group 38371" transform="translate(160.131 0.291)">
                    <path id="Path_16572-11" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                </g>
                </g>
                <g id="Group_38527" data-name="Group 38527" transform="translate(102.431 599.75)">
                <rect id="Rectangle_2862" data-name="Rectangle 2862" width="278.969" height="16.843" rx="8" transform="translate(0 0.174)" fill="#ececec"/>
                <rect id="Rectangle_2863" data-name="Rectangle 2863" width="277.916" height="16.843" rx="8" transform="translate(0 0.174)" fill={secondaryColor}/>
                <g id="Group_38374" data-name="Group 38374" transform="translate(15.082 0)">
                    <g id="Group_5367-2" data-name="Group 5367" transform="translate(0 0.116)">
                    <path id="Path_16572-12" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38362-2" data-name="Group 38362" transform="translate(15.824 0.174)">
                    <path id="Path_16572-13" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38366-2" data-name="Group 38366" transform="translate(63.926 0.233)">
                    <path id="Path_16572-14" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38369-2" data-name="Group 38369" transform="translate(112.028)">
                    <path id="Path_16572-15" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38363-2" data-name="Group 38363" transform="translate(31.858 0.233)">
                    <path id="Path_16572-16" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38365-2" data-name="Group 38365" transform="translate(79.96 0.291)">
                    <path id="Path_16572-17" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38368-2" data-name="Group 38368" transform="translate(128.063 0.058)">
                    <path id="Path_16572-18" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38364-2" data-name="Group 38364" transform="translate(47.892 0.233)">
                    <path id="Path_16572-19" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38367-2" data-name="Group 38367" transform="translate(95.994 0.291)">
                    <path id="Path_16572-20" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38370-2" data-name="Group 38370" transform="translate(144.097 0.058)">
                    <path id="Path_16572-21" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38371-2" data-name="Group 38371" transform="translate(160.131 0.291)">
                    <path id="Path_16572-22" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38375" data-name="Group 38375" transform="translate(176.165 0.291)">
                    <path id="Path_16572-23" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38376" data-name="Group 38376" transform="translate(192.199 0)">
                    <path id="Path_16572-24" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38385" data-name="Group 38385" transform="translate(208.233 0.291)">
                    <path id="Path_16572-25" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38386" data-name="Group 38386" transform="translate(224.267 0.291)">
                    <path id="Path_16572-26" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                    <g id="Group_38387" data-name="Group 38387" transform="translate(240.301 0.291)">
                    <path id="Path_16572-27" data-name="Path 16572" d="M0,16.906.762.054,1.834,0,1.072,16.852Z" transform="matrix(0.999, -0.035, 0.035, 0.999, 0, 0.064)" fill="#fff"/>
                    </g>
                </g>
                </g>
                <path id="Union_12" data-name="Union 12" d="M248.288,50H0V19A19,19,0,0,1,19,0H316L297.108,50Z" transform="translate(81.378 303.987)" fill={primaryColor}/>
                <text id="Total_Resume_Contributed" data-name="Total Resume Contributed" transform="translate(102.432 336.24)" fill={primaryFontColor} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">Recruiter Performance by Status</tspan></text>
                <path id="Union_13" data-name="Union 13" d="M6,90.533a6,6,0,0,1-6-6V22.844a6,6,0,0,1,6-6H7.168V14.422a6,6,0,0,1,6-6h1.167V6a6,6,0,0,1,6-6H68.743a6,6,0,0,1,6,6V68.743a6,6,0,0,1-6,6H67.576v1.369a6,6,0,0,1-6,6H60.409v2.422a6,6,0,0,1-6,6Z" transform="translate(398.244 349.78)" fill="#404040"/>
                <g id="noun-reading-resumes-5256724" transform="translate(397.8 348.727)">
                <g id="Group_11673" data-name="Group 11673" transform="translate(0 0)">
                    <g id="Group_11660" data-name="Group 11660" transform="translate(0 15.526)">
                    <path id="Path_16572-28" data-name="Path 16572" d="M90.7,155.787H43.924A7.255,7.255,0,0,1,36.8,148.43V86.557A7.255,7.255,0,0,1,43.924,79.2h46.76a7.242,7.242,0,0,1,7.124,7.357V148.43A7.238,7.238,0,0,1,90.7,155.787ZM43.924,81.3a5.2,5.2,0,0,0-5.106,5.273v61.874a5.2,5.2,0,0,0,5.106,5.272h46.76a5.2,5.2,0,0,0,5.106-5.272V86.557a5.2,5.2,0,0,0-5.106-5.273l-46.76.021Z" transform="translate(-36.8 -79.2)" fill="#fff"/>
                    </g>
                    <g id="Group_11661" data-name="Group 11661" transform="translate(7.528 7.773)">
                    <path id="Path_16573" data-name="Path 16573" d="M127.984,118.587h-1.413a1.043,1.043,0,0,1,0-2.084h1.413a5.2,5.2,0,0,0,5.106-5.273V49.357a5.2,5.2,0,0,0-5.106-5.273H81.224a5.2,5.2,0,0,0-5.106,5.273v1.459a1.01,1.01,0,1,1-2.018,0V49.357A7.255,7.255,0,0,1,81.224,42h46.76a7.242,7.242,0,0,1,7.124,7.357V111.23A7.255,7.255,0,0,1,127.984,118.587Z" transform="translate(-74.1 -42)" fill="#fff"/>
                    </g>
                    <g id="Group_11662" data-name="Group 11662" transform="translate(15.055)">
                    <path id="Path_16574" data-name="Path 16574" d="M165.284,81.287h-1.413a1.043,1.043,0,0,1,0-2.084h1.413a5.2,5.2,0,0,0,5.106-5.273V12.057a5.2,5.2,0,0,0-5.106-5.273h-46.76a5.2,5.2,0,0,0-5.106,5.273v1.459a1.01,1.01,0,1,1-2.018,0V12.057A7.242,7.242,0,0,1,118.524,4.7h46.76a7.242,7.242,0,0,1,7.124,7.357V73.93A7.255,7.255,0,0,1,165.284,81.287Z" transform="translate(-111.4 -4.7)" fill="#fff"/>
                    </g>
                    <g id="Group_11672" data-name="Group 11672" transform="translate(8.799 22.591)">
                    <g id="Group_11663" data-name="Group 11663" transform="translate(4.904)">
                        <path id="Path_16575" data-name="Path 16575" d="M131.723,146.611H111.3a6.712,6.712,0,0,1-6.6-6.815V119.915a6.712,6.712,0,0,1,6.6-6.815h20.4a6.725,6.725,0,0,1,6.6,6.815V139.8A6.682,6.682,0,0,1,131.723,146.611ZM111.3,115.184a4.667,4.667,0,0,0-4.581,4.731V139.8a4.667,4.667,0,0,0,4.581,4.731h20.4a4.667,4.667,0,0,0,4.581-4.731V119.915a4.667,4.667,0,0,0-4.581-4.731Z" transform="translate(-104.7 -113.1)" fill="#fff"/>
                    </g>
                    <g id="Group_11664" data-name="Group 11664" transform="translate(11.705 16.047)">
                        <path id="Path_16576" data-name="Path 16576" d="M157.411,207.564a1.016,1.016,0,0,1-1.009-1.042V200.1a7.807,7.807,0,0,0-7.669-7.919h-.646a7.807,7.807,0,0,0-7.669,7.919v6.419a1.01,1.01,0,1,1-2.018,0V200.1a9.865,9.865,0,0,1,9.687-10h.646a9.865,9.865,0,0,1,9.687,10v6.419A1.016,1.016,0,0,1,157.411,207.564Z" transform="translate(-138.4 -190.1)" fill="#fff"/>
                    </g>
                    <g id="Group_11665" data-name="Group 11665" transform="translate(14.631 3.564)">
                        <path id="Path_16577" data-name="Path 16577" d="M159.984,144.83a7.319,7.319,0,1,1,7.084-7.315A7.211,7.211,0,0,1,159.984,144.83Zm0-12.546a5.234,5.234,0,1,0,5.066,5.231A5.154,5.154,0,0,0,159.984,132.284Z" transform="translate(-152.9 -130.2)" fill="#fff"/>
                    </g>
                    <g id="Group_11666" data-name="Group 11666" transform="translate(16.488 23.737)">
                        <path id="Path_16578" data-name="Path 16578" d="M163.109,236.774a1.016,1.016,0,0,1-1.009-1.042v-7.69a1.01,1.01,0,1,1,2.018,0v7.69A1.016,1.016,0,0,1,163.109,236.774Z" transform="translate(-162.1 -227)" fill="#fff"/>
                    </g>
                    <g id="Group_11667" data-name="Group 11667" transform="translate(24.924 23.737)">
                        <path id="Path_16579" data-name="Path 16579" d="M204.909,236.774a1.016,1.016,0,0,1-1.009-1.042v-7.69a1.01,1.01,0,1,1,2.018,0v7.69A1.029,1.029,0,0,1,204.909,236.774Z" transform="translate(-203.9 -227)" fill="#fff"/>
                    </g>
                    <g id="Group_11668" data-name="Group 11668" transform="translate(0 39.388)">
                        <path id="Path_16580" data-name="Path 16580" d="M122.821,304.184H81.409a1.043,1.043,0,0,1,0-2.084h41.412a1.043,1.043,0,0,1,0,2.084Z" transform="translate(-80.4 -302.1)" fill="#fff"/>
                    </g>
                    <g id="Group_11669" data-name="Group 11669" transform="translate(0 46.411)">
                        <path id="Path_16581" data-name="Path 16581" d="M122.821,337.884H81.409a1.043,1.043,0,0,1,0-2.084h41.412a1.043,1.043,0,0,1,0,2.084Z" transform="translate(-80.4 -335.8)" fill="#fff"/>
                    </g>
                    <g id="Group_11670" data-name="Group 11670" transform="translate(0 53.413)">
                        <path id="Path_16582" data-name="Path 16582" d="M122.821,371.484H81.409a1.043,1.043,0,0,1,0-2.084h41.412a1.043,1.043,0,0,1,0,2.084Z" transform="translate(-80.4 -369.4)" fill="#fff"/>
                    </g>
                    <g id="Group_11671" data-name="Group 11671" transform="translate(0 60.415)">
                        <path id="Path_16583" data-name="Path 16583" d="M122.821,405.084H81.409a1.043,1.043,0,0,1,0-2.084h41.412a1.043,1.043,0,0,1,0,2.084Z" transform="translate(-80.4 -403)" fill="#fff"/>
                    </g>
                    </g>
                </g>
                </g>
            </g>
        </svg>
    )
}