
export const UserIcon3 = ({ primaryColor = "#ffd800", primaryFontColor = "rgba(0,0,0,0.8)" }) => {
    return (
       
      <svg xmlns="http://www.w3.org/2000/svg"  width="99.146" height="99.197" viewBox="0 0 137.146 137.197">
      <defs>
        <filter id="Path_6202" x="0" y="0" width="137.146" height="137.197" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha"/>
          <feGaussianBlur stdDeviation="3" result="blur"/>
          <feFlood flood-opacity="0.161"/>
          <feComposite operator="in" in2="blur"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g id="Group_1934" data-name="Group 1934" transform="translate(4 14)">
        <g transform="matrix(1, 0, 0, 1, -4, -14)" filter="url(#Path_6202)">
          <path id="Path_6202-2" data-name="Path 6202" d="M3209.2,762.4a119.121,119.121,0,0,0,0-47.471,39.51,39.51,0,0,0-32.662-33.309,119.086,119.086,0,0,0-49,0,39.514,39.514,0,0,0-32.533,32.479,119.1,119.1,0,0,0,0,49.131,39.516,39.516,0,0,0,32.255,32.433,119.1,119.1,0,0,0,49.56,0A39.513,39.513,0,0,0,3209.2,762.4Z" transform="translate(-3083.44 -673.07)" fill={primaryColor}/>
        </g>
        <g id="Group_1933" data-name="Group 1933" transform="translate(41.475 24.694)">
          <path id="Path_6203" data-name="Path 6203" d="M3258.953,807.284a13.561,13.561,0,1,0-13.559-13.558A13.576,13.576,0,0,0,3258.953,807.284Z" transform="translate(-3235.857 -780.161)" fill={primaryFontColor}/>
          <path id="Path_6204" data-name="Path 6204" d="M3252.26,901.393a19.479,19.479,0,0,0-8.844-7,5.269,5.269,0,0,0-4.657.182,16.874,16.874,0,0,1-7.323,1.849h-.145a16.822,16.822,0,0,1-7.333-1.849,5.288,5.288,0,0,0-4.665-.182,19.421,19.421,0,0,0-8.837,7,13.428,13.428,0,0,0-2.188,6.53,5.319,5.319,0,0,0,1.692,4.215c6.344,5.943,17.457,6.386,20.622,6.386.342,0,.591-.005.732-.009l.312,0c2.3.031,14.372-.061,21.13-6.379a5.365,5.365,0,0,0,1.691-4.214h0A13.42,13.42,0,0,0,3252.26,901.393Z" transform="translate(-3208.26 -864.716)" fill={primaryFontColor}/>
        </g>
      </g>
    </svg>
    
      
    )
}

