import React, { useEffect, useRef, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import "./RightSideBar.css";

const RightSideBar = (props) => {
  const { componentLayout, componentData} = props;
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const footerRef = useRef(null);
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    if (props.onReceivechildProps !== undefined) {
      console.log(props.onReceivechildProps);
    }
  }, [props.onReceivechildProps]);

  // Handle outside click to close sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!sidebarRef.current) return;

      const isClickInsideSidebar = sidebarRef.current.contains(event.target);
      const isMuiComponent = event.target.closest(
        ".MuiPopover-root, .MuiMenu-root, .MuiPaper-root, .twoduplicatecustomModalDiv, .duplicatecustomModalDiv"
      );

      if (!isClickInsideSidebar && !isMuiComponent) {
        props.onReceiveProps(false, "close");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  // Function to check height
  const checkHeight = () => {
    if (!sidebarRef.current || !contentRef.current || !footerRef.current) return;

    const sidebarHeight = sidebarRef.current.clientHeight;
    const contentHeight = contentRef.current.clientHeight;
    const footerHeight = footerRef.current.clientHeight;

    // Hide footer if content + footer exceeds sidebar height
    setShowFooter(contentHeight + footerHeight <= sidebarHeight);
  };

   // 🔹 Track changes using MutationObserver
   useEffect(() => {
    if (!sidebarRef.current) return;

    const observer = new MutationObserver(() => {
      console.log("ComponentData updated!");
      checkHeight(); // Recalculate height on content change
    });

    observer.observe(contentRef.current, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, []);


  return (
    <section className="sidebarBg">
      <div className="sidebar" ref={sidebarRef}>
        <div className="headertext">
          <p className={componentLayout.pageName === "vendorportal" ? "heading1" : ""}>
            {componentLayout.title}
          </p>
          {componentLayout.navigateTo ? (
            <Link to={componentLayout.navigateTo} target="_blank" type="button">
              <OpenInNewIcon />
            </Link>
          ) : (
            " "
          )}
          <img
            src={process.env.PUBLIC_URL + "/assests/img/close.svg"}
            alt=""
            className="closeicon"
            onClick={() => props.onReceiveProps(false, "close")}
          />
        </div>

        <div className="contentSection pannelContent" ref={contentRef}>
          {componentLayout.description?.length > 0 && <h6>{componentLayout.description}</h6>}
          <article>{componentData}</article>
        </div>

        <footer className="footerimg" style={{visibility : !showFooter ? "hidden": "visible"}} ref={footerRef}>
          {props.footerImg}
        </footer>
      </div>
    </section>
  );
};

export default RightSideBar;
