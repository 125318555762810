export const GroupByClients = ({primaryColor="#ffd800", primaryFontColor="rgba(0,0,0,0.8)"}) => {
    return (
        <svg width="664.058" height="272.539" viewBox="0 0 664.058 272.539">
          <defs>
            <filter id="Rectangle_2872" x="0" y="0" width="664.058" height="272.539" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_38759" data-name="Group 38759" transform="translate(-630.191 -831.861)">
            <g transform="matrix(1, 0, 0, 1, 630.19, 831.86)" filter="url(#Rectangle_2872)">
              <rect id="Rectangle_2872-2" data-name="Rectangle 2872" width="646.058" height="254.539" rx="20" transform="translate(9 6)" fill="#fff"/>
            </g>
            <text id="Option_1_Option_2_Option_3_" data-name="Option 1
        Option 2
        Option 3
        " transform="translate(1148 962)" font-size="12" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">Option 1</tspan><tspan x="0" y="23">Option 2</tspan><tspan x="0" y="46">Option 3</tspan><tspan x="0" y="69"></tspan></text>
            <rect id="Rectangle_2897" data-name="Rectangle 2897" width="13.242" height="12.29" transform="translate(1129.326 949.082)" fill="#dcc882"/>
            <rect id="Rectangle_2896" data-name="Rectangle 2896" width="13.242" height="12.29" transform="translate(1129.326 974.182)" fill="#e76f51"/>
            <path id="Path_16770" data-name="Path 16770" d="M0,0H13.242V12.29H0Z" transform="translate(1129.326 999.283)" fill="#9fbb7d"/>
            <path id="Union_6" data-name="Union 6" d="M468.653,32.7H0V19A19,19,0,0,1,19,0H596.461L560.8,32.7Z" transform="translate(638.538 837.861)" fill={primaryColor}/>
            <text id="Graph_by_Client" data-name="Graph by Client" transform="translate(653 859)" fill={primaryFontColor} font-size="13" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">Client Specific Candidate Status</tspan></text>
            <g id="Group_38513" data-name="Group 38513" transform="translate(762.574 897.16)">
              <path id="Path_16768" data-name="Path 16768" d="M86.675,0A86.675,86.675,0,1,1,0,86.675,86.675,86.675,0,0,1,86.675,0Z" fill="#e76f51"/>
              <path id="Path_16683" data-name="Path 16683" d="M207.549,147.78V321.13a86.675,86.675,0,0,0,0-173.35Z" transform="translate(-120.874 -147.778)" fill="#dcc882"/>
              <path id="Path_16685" data-name="Path 16685" d="M252.642,147.78h0a86.675,86.675,0,0,0-86.675,86.675h86.675Z" transform="translate(-165.967 -147.778)" fill="#9fbb7d"/>
            </g>
            <g id="Rectangle_19236" data-name="Rectangle 19236" transform="translate(900.918 1017.844)" fill="#fff" stroke="#c8c8c8" stroke-width="1">
              <rect width="46.932" height="17.658" rx="4" stroke="none"/>
              <rect x="0.5" y="0.5" width="45.932" height="16.658" rx="3.5" fill="none"/>
            </g>
            <text id="_12_" data-name="12 " transform="translate(922.831 1029.672)" fill="#6d6d6d" font-size="8" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">12 </tspan></text>
            <circle id="Ellipse_73" data-name="Ellipse 73" cx="3.27" cy="3.27" r="3.27" transform="translate(910.038 1023.73)" fill="#dcc882"/>
          </g>
      </svg>
    )
}