
export const Suitcase = ({primaryColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="75.586" height="62.988" viewBox="0 0 75.586 62.988">
      <g id="suitcase" transform="translate(0.001)">
        <path id="Path_6288" data-name="Path 6288" d="M30.046,16.172A3.151,3.151,0,0,1,26.9,13.023V8.3H14.3v4.724a3.149,3.149,0,0,1-6.3,0V8.3A6.305,6.305,0,0,1,14.3,2H26.9a6.305,6.305,0,0,1,6.3,6.3v4.724A3.151,3.151,0,0,1,30.046,16.172Z" transform="translate(17.194 -2)" fill={primaryColor} />
        <path id="Path_6289" data-name="Path 6289" d="M40.029,23.462a6.487,6.487,0,0,1-2.236.378,6.9,6.9,0,0,1-2.425-.441L0,11.62V35.65a8.656,8.656,0,0,0,8.661,8.661H66.925a8.656,8.656,0,0,0,8.661-8.661V11.62Z" transform="translate(-0.001 18.677)" fill={primaryColor} />
        <path id="Path_6290" data-name="Path 6290" d="M75.586,13.661v7.212L38.549,33.219a2.331,2.331,0,0,1-1.512,0L0,20.873V13.661A8.656,8.656,0,0,1,8.661,5H66.925A8.656,8.656,0,0,1,75.586,13.661Z" transform="translate(-0.001 4.448)" fill={primaryColor} />
      </g>
    </svg>




  )
}

