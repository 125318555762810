export const BarChart = ({ primaryColor = "#ffd800", primaryFontColor = "rgba(0,0,0,0.8)" }) => {
  return (
    <svg width="784.167" height="393.199" viewBox="0 0 784.167 393.199">
      <defs>
        <filter id="Rectangle_2875" x="0" y="0" width="784.167" height="393.199" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_38750" data-name="Group 38750" transform="translate(-73.077 -1114.801)">
        <g transform="matrix(1, 0, 0, 1, 73.08, 1114.8)" filter="url(#Rectangle_2875)">
          <rect id="Rectangle_2875-2" data-name="Rectangle 2875" width="766.168" height="375.199" rx="20" transform="translate(9 6)" fill="#fff" />
        </g>
        <rect id="Rectangle_2888" data-name="Rectangle 2888" width="109" height="113" transform="translate(668 1331)" fill="#9fbb7d" />
        <rect id="Rectangle_2889" data-name="Rectangle 2889" width="105" height="90" transform="translate(417 1354)" fill="#dcc882" />
        <rect id="Rectangle_2890" data-name="Rectangle 2890" width="111" height="138" transform="translate(160 1305.301)" fill="#e76f51" />
        <line id="Line_1" data-name="Line 1" y2="236.301" transform="translate(140.5 1207.5)" fill="none" stroke="#707070" stroke-width="1" />
        <text id="Selected" transform="translate(234.5 1466.301)" font-size="14" font-family="Roboto-Regular, Roboto"><tspan x="-53.751" y="0">Selected</tspan></text>
        <text id="Associated" transform="translate(469.5 1467.301)" font-size="14" font-family="Roboto-Regular, Roboto"><tspan x="-34.891" y="0">Associated</tspan></text>
        <text id="Undefined" transform="translate(722.5 1467.301)" font-size="14" font-family="Roboto-Regular, Roboto"><tspan x="-31.452" y="0">Undefined</tspan></text>
        <line id="Line_2" data-name="Line 2" x2="665" transform="translate(133.5 1443.801)" fill="none" stroke="#707070" stroke-width="1" />
        <g id="Group_38515" data-name="Group 38515" transform="translate(61.332 590.697)">
          <text id="Team" transform="translate(727.668 610.301)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">Team</tspan></text>
          <text id="Individual" transform="translate(598 610.301)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">Individual</tspan></text>
          <rect id="Rectangle_2910" data-name="Rectangle 2910" width="50" height="20" rx="10" transform="translate(671.668 595.301)" fill="#5d5d5d" />
          <circle id="Ellipse_68" data-name="Ellipse 68" cx="6.5" cy="6.5" r="6.5" transform="translate(677.668 598.801)" fill="#ebc700" />
        </g>
        <path id="Union_10" data-name="Union 10" d="M433.134,50H0V19A19,19,0,0,1,19,0H551.255L518.3,50Z" transform="translate(81.077 1120.801)" fill={primaryColor} />
        <text id="Total_Resumes_Uploaded_vs_Status" data-name="Total Resumes Uploaded vs Status" transform="translate(102 1153)" fill={primaryFontColor} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">⁠Candidates Grouped By Status (All Status)</tspan></text>
        <text id="_0" data-name="0" transform="translate(118 1441)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">0</tspan></text>
        <text id="_5" data-name="5" transform="translate(118 1396)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">5</tspan></text>
        <text id="_10" data-name="10" transform="translate(113 1351)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">10</tspan></text>
        <text id="_20" data-name="20" transform="translate(113 1306)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">20</tspan></text>
        <text id="_30" data-name="30" transform="translate(113 1261)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">30</tspan></text>
        <text id="_50" data-name="50" transform="translate(113 1216)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">50</tspan></text>
      </g>
    </svg>
  )
}