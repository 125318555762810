
export const Notescall = ({ primaryColor = "#ffd800", primaryFontColor = "rgb(255, 255, 255)" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="69" height="69" viewBox="0 0 69 69">
      <defs>
        <filter id="Ellipse_10" x="0" y="0" width="69" height="69" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_5047" data-name="Group 5047" transform="translate(-309 -304.003)">
        <g id="Group_42" data-name="Group 42" transform="translate(-1411.511 280.492)">
          <g transform="matrix(1, 0, 0, 1, 1720.51, 23.51)" filter="url(#Ellipse_10)">
            <circle id="Ellipse_10-2" data-name="Ellipse 10" cx="25.5" cy="25.5" r="25.5" transform="translate(9 6)" fill={primaryColor} />
          </g>
        </g>
        <g id="phone-call" transform="translate(332 324)">
          <g id="Group_3500" data-name="Group 3500" transform="translate(0 0)">
            <path id="Path_15710" data-name="Path 15710" d="M22.889,16.88l-3.21-3.21a2.135,2.135,0,0,0-3.554.8A2.184,2.184,0,0,1,13.6,15.848c-2.293-.573-5.388-3.554-5.961-5.961A2.078,2.078,0,0,1,9.018,7.365a2.135,2.135,0,0,0,.8-3.554L6.611.6a2.289,2.289,0,0,0-3.1,0L1.338,2.78c-2.178,2.293.229,8.368,5.617,13.756s11.463,7.91,13.756,5.617l2.178-2.178A2.289,2.289,0,0,0,22.889,16.88Z" transform="translate(-0.539 0)" fill={primaryFontColor} />
          </g>
        </g>
      </g>
    </svg>



  )
}

