
export const PlusIcon = ({ primaryColor = "#ffd800", primaryFontColor = "rgb(255, 255, 255)" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
    <g id="Group_41231" data-name="Group 41231" transform="translate(-185 -203)">
      <circle id="Ellipse_21" data-name="Ellipse 21" cx="18" cy="18" r="18" transform="translate(185 203)" fill={primaryColor}/>
      <text id="_" data-name="+" transform="translate(196 228)" fill={primaryFontColor} font-size="25" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">+</tspan></text>
    </g>
  </svg>
  



  )
}

