export const PieChart = ({primaryColor="#ffd800", primaryFontColor="rgba(0,0,0,0.8)"}) => {
    return (
        <svg  width="784.168" height="407.199" viewBox="0 0 784.168 407.199">
          <defs>
            <filter id="Rectangle_2872" x="0" y="0" width="784.168" height="407.199" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_38569" data-name="Group 38569" transform="translate(-74 -690.801)">
            <g transform="matrix(1, 0, 0, 1, 74, 690.8)" filter="url(#Rectangle_2872)">
              <rect id="Rectangle_2872-2" data-name="Rectangle 2872" width="766.168" height="389.199" rx="20" transform="translate(9 6)" fill="#fff"/>
            </g>
            <g id="Group_38353" data-name="Group 38353" transform="translate(-524.007 -1351)">
              <text id="LinkedIn_Indeed_Zoho_Recruit_Zip_Recruit_Monster_" data-name="LinkedIn
        Indeed
        Zoho Recruit
        Zip Recruit
        Monster
        " transform="translate(1161.349 2188)" font-size="19" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">LinkedIn</tspan><tspan x="0" y="37">Indeed</tspan><tspan x="0" y="74">Zoho Recruit</tspan><tspan x="0" y="111">Zip Recruit</tspan><tspan x="0" y="148">Monster</tspan><tspan x="0" y="185"></tspan></text>
              <path id="Path_16769" data-name="Path 16769" d="M0,0H20.248V18.792H0Z" transform="translate(1129 2208.551)" fill="#9c9494"/>
              <rect id="Rectangle_2897" data-name="Rectangle 2897" width="20.248" height="18.792" transform="translate(1129 2171.862)" fill="#dcc882"/>
              <rect id="Rectangle_2896" data-name="Rectangle 2896" width="20.248" height="18.792" transform="translate(1129 2245.24)" fill="#e76f51"/>
              <rect id="Rectangle_2896-2" data-name="Rectangle 2896" width="20.248" height="18.792" transform="translate(1129 2281.929)" fill="#8ba3bc"/>
              <path id="Path_16770" data-name="Path 16770" d="M0,0H20.248V18.792H0Z" transform="translate(1129 2318.618)" fill="#9fbb7d"/>
            </g>
            <g id="Group_38514" data-name="Group 38514" transform="translate(58.008 166.169)">
              <text id="Team" transform="translate(732.559 610.301)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">Team</tspan></text>
              <text id="Individual" transform="translate(598 610.301)" font-size="16" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">Individual</tspan></text>
              <rect id="Rectangle_2910" data-name="Rectangle 2910" width="50" height="20" rx="10" transform="translate(674.654 595.301)" fill="#5d5d5d"/>
              <circle id="Ellipse_68" data-name="Ellipse 68" cx="6.5" cy="6.5" r="6.5" transform="translate(680.139 598.801)" fill="#ebc700"/>
            </g>
            <path id="Union_6" data-name="Union 6" d="M433.194,50H0V19A19,19,0,0,1,19,0H551.332L518.371,50Z" transform="translate(82 696.801)" fill={primaryColor}/>
            <text id="Breakdown_of_Source_of_Resumes" data-name="Breakdown of Source of Resumes" transform="translate(103 729)" fill={primaryFontColor} font-size="18" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">Candidates Grouped By Resume source</tspan></text>
            <g id="Group_38513" data-name="Group 38513" transform="translate(11.18 639.691)">
              <path id="Path_16768" data-name="Path 16768" d="M132.529,0A132.529,132.529,0,1,1,0,132.529,132.529,132.529,0,0,1,132.529,0Z" transform="translate(165.967 147.779)" fill="#e76f51"/>
              <path id="Path_16683" data-name="Path 16683" d="M207.549,147.78V412.837a132.529,132.529,0,0,0,0-265.057Z" transform="translate(90.947 0.002)" fill="#dcc882"/>
              <path id="Path_16684" data-name="Path 16684" d="M304.766,279.4l-97.218-90.034V321.89A132.142,132.142,0,0,0,304.766,279.4Z" transform="translate(90.944 90.947)" fill="#9c9494"/>
              <path id="Path_16685" data-name="Path 16685" d="M298.5,147.78h0A132.529,132.529,0,0,0,165.967,280.309H298.5Z" transform="translate(0 0.002)" fill="#9fbb7d"/>
              <path id="Path_16686" data-name="Path 16686" d="M298.5,189.361H165.967a132.266,132.266,0,0,0,7.907,45.175Z" transform="translate(0 90.947)" fill="#8ba3bc"/>
            </g>
          </g>
        </svg>
    )
}