export const infoWidgetList = ["resume", "submission", "interview", "selected"];

export const commonWidgetContent = {
  currentTitle: "My Contribution",
  avgTitle: `Company's Average`,
  highestTitle: "Top Performer",
  defaultImg: `${process.env.PUBLIC_URL + "/assests/TotalContributed.svg"}`,
};

export const widgetTypes = [
  {
    w: 6,
    h: 4,
    type: "GraphofStatus",
    title: "⁠Candidates Grouped By Status (Select Status)",
    showStatus: true,
    description:
      "This bar graph allows selection of status for which user can generate graph to shows count of candidates under each status.",
    sampleImg: `${process.env.PUBLIC_URL}/assests/dashboard/GroupOfStatus.svg`,
  },
  {
    w: 6,
    h: 4,
    type: "GraphbyClient",
    title: "Client Specific Candidate Status",
    showStatus: true,
    showClient: true,
    description:
      "A bar chart displaying Candidate status specific to a Client, this chart is region agnostic and can display data regionally if same client is available under different regions.",
    sampleImg: `${process.env.PUBLIC_URL}/assests/dashboard/GroupByClients.svg`,
  },
  {
    w: 3,
    h: 6,
    type: "ResumeCount",
    title: "Recruiter Performance by Status",
    config: commonWidgetContent,
    showStatus: true,
    description: "A progress bar that shows individual, team and top performer's contribution as per the different status of the candidates.",
    sampleImg: `${process.env.PUBLIC_URL}/assests/dashboard/ResumeContributed.svg`,
  },
  {
    w: 6,
    h: 4,
    type: "pieChart",
    title: "Candidates Grouped By Resume source",
    description: "A pie chart displaying distribution of resume uploads as per the different source of resumes.",
    sampleImg: `${process.env.PUBLIC_URL}/assests/dashboard/PieChart.svg`,
  },
  {
    w: 6,
    h: 4,
    type: "barChart",
    title: "⁠Candidates Grouped By Status (All Status)",
    description: "A bar graph displaying distribution of resume uploads against all the status in the candidate journey.",
    sampleImg: `${process.env.PUBLIC_URL}/assests/dashboard/BarChart.svg`,
  },
  {
    w: 6,
    h: 4,
    type: "requirementsWidget",
    title: "List of Requirements Assigned",
    description: "Displays a list of requirements sorted by date, this list allows data to be filtered by current user vs all users.",
    sampleImg: `${process.env.PUBLIC_URL}/assests/dashboard/Table.svg`,
  },
];

export const initialLayoutConfig = [
  {
    i: "1",
    x: 0,
    y: 0,
    w: 3,
    h: 6,
    type: "resume",
    title: "Total Resume Contributed",
    className: "dashboard__grid-item",
  },
  {
    i: "2",
    x: 3,
    y: 0,
    w: 3,
    h: 6,
    type: "submission",
    title: "Total Submissions",
    className: "dashboard__grid-item",
  },
  {
    i: "3",
    x: 6,
    y: 0,
    w: 3,
    h: 6,
    type: "interview",
    title: "Total Profiles in Interview",
    className: "dashboard__grid-item",
  },
  {
    i: "4",
    x: 9,
    y: 0,
    w: 3,
    h: 6,
    type: "selected",
    title: "Total Profiles Selected",
    className: "dashboard__grid-item",
  },
  {
    i: "5",
    x: 0,
    y: 7,
    w: 6,
    h: 4,
    type: "pieChart",
    title: "Candidates Grouped By Resume source",
    className: "dashboard__grid-item",
  },
  {
    i: "6",
    x: 0,
    y: 11,
    w: 6,
    h: 4,
    type: "barChart",
    title:"⁠Candidates Grouped By Status (All Status)",
    className: "dashboard__grid-item",
  },
  {
    i: "7",
    x: 6,
    y: 7,
    w: 6,
    h: 4,
    type: "requirementsWidget",
    title: "List of Requirements Assigned",
    className: "requirement-widget dashboard__grid-item",
  },
];

export const widgetsMockData = [
  {
    i: "1",
    type: "resume",
    title: "Total Resume Contributed",
    currentValue: 0,
    currentTitle: "My Contribution",
    avgValue: 0,
    avgPercentage: 0,
    avgTitle: `Company's Average`,
    highestValue: "0",
    highestPercentage: 100,
    highestTitle: "Top Performer",
    img: `${process.env.PUBLIC_URL + "/assests/TotalContributed.svg"}`,
  },
  {
    i: "2",
    type: "submission",
    title: "Total Submissions",
    currentValue: 0,
    currentTitle: "My Contribution",
    avgValue: 0,
    avgPercentage: 0,
    avgTitle: `Company's Average`,
    highestValue: "0",
    highestPercentage: 100,
    highestTitle: "Top Performer",
    img: `${process.env.PUBLIC_URL + "/assests/TotalSubmissions.svg"}`,
  },
  {
    i: "3",
    type: "interview",
    title: "Total Profiles in Interview",
    currentValue: 0,
    currentTitle: "My Contribution",
    avgValue: 0,
    avgPercentage: 0,
    avgTitle: `Company's Average`,
    highestValue: "0",
    highestPercentage: 100,
    highestTitle: "Top Performer",
    img: `${process.env.PUBLIC_URL + "/assests/TotalInterview.svg"}`,
  },
  {
    i: "4",
    type: "selected",
    title: "Total Profiles Selected",
    currentValue: 0,
    currentTitle: "My Contribution",
    avgValue: 0,
    avgTitle: `Company's Average`,
    highestValue: "0",
    avgPercentage: 0,
    highestPercentage: 100,
    highestTitle: "Top Performer",
    img: `${process.env.PUBLIC_URL + "/assests/TotalSelected.svg"}`,
  },
  {
    i: "5",
    type: "pieChart",
    title: "Breakdown of Source of Resumes",
    data: [],
  },
  {
    i: "6",
    type: "barChart",
    title: "Total Resumes Uploaded vs Status",
    data: [],
  },
  {
    i: "7",
    type: "requirementsWidget",
    title: "List of Requirements Assigned to Recruiters",
    data: [],
  },
];

export const columnsData = [
  {
    id: 1,
    displayName: "Job Code",
    type: "Job Code",
    mappingName: "jobCode",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center ",
    width: "150px",
    isEdit: true,
    nagigate: "countrywithflag",
  },
  {
    id: 2,
    displayName: "Job Title",
    type: "Job Code",
    mappingName: "jobTitle",
    theadClass: "dynatableheadtext text-center ",
    tbodyClass: "dynatablebodytext text-center NameCapitalise",
    width: "300px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 3,
    displayName: "Location",
    type: "Job Code",
    mappingName: "location",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center NameCapitalise",
    width: "200px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 4,
    displayName: "Job Status",
    type: "Job Code",
    mappingName: "jobStatus",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "120px",
    isEdit: true,
    nagigate: "detailspage",
  },
  {
    id: 5,
    displayName: "Client",
    type: "Job Code",
    mappingName: "clientName",
    theadClass: "dynatableheadtext text-center",
    tbodyClass: "dynatablebodytext text-center",
    width: "200px",
    isEdit: true,
    nagigate: "detailspage",
  },
];

export const configuration = {
  isSearchbar: false,
  isPaging: false,
  isDropdownActions: false,
  isCheckbox: false,
  isActions: false,
  recordperPage: 10,
  currentPage: 1,
  totalRecords: 0,
  limitData: 20,
  isCheckAlignRight: true,
  isPaginationTop: true,
  isSearchServer: false,
  isPagingServer: false,
  isPageRange: false,
};

export const disabled_Configured_Card = ["resume", "submission", "interview", "selected"];