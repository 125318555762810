import React from 'react'
import { decrypt } from "../../../../_utilities/_encryptDecryptHelper";

const CountryFlag = ({editMode}) => {

   const selectedCountry = JSON.parse(decrypt('selectCountry'));
   const allCountries = JSON.parse(decrypt('allCountryId'));


  return (
    <div>
       {!editMode && allCountries === 'all' ? (  
                <div className="cardTitleGlobe">
                  <img src={`${process.env.PUBLIC_URL}/assests/img/grayglobe.svg`} alt="allcontries" style={{ height: "" }} />
                </div>
              ) : !editMode && (allCountries === "" || allCountries === null) ? (
                <div className="cardTitleGlobe">
                  <img src={`${process.env.PUBLIC_URL}/assests/img/${selectedCountry?.logo}`} alt="country" style={{ height: "" }} />
                </div>
              ) : ("")}
    </div>
  )
}

export default CountryFlag
