import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { FocusError } from "focus-formik-error";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,Link } from "react-router-dom";
import * as Yup from "yup";
import * as _clientser from '../../_redux/_services/ClientsSlice';
import * as _props from '../../_redux/_services/JobPostingSlice';
import { customFieldsAsync, customFieldsStartReducer } from "../../_redux/_services/UserManagerSlice";
import { decrypt } from "../../_utilities/_encryptDecryptHelper";
import { defaultPostingsData } from "../../_utilities/defaultfieldsModel";
import CkEditor from "../../components/CkEditor/CkEditor";

const PostingsComponent = ({ componentType, input, iniValue, actions, onValidData, disableSubmitBtn, isJobDetails, onResetValue }) => {
  const [getDetails, setGetDetails] = useState(iniValue);
  const [isjobDesc, setIsjobDesc] = useState(false);
  const [isjobDescForSM, setIsjobDescForSM] = useState(false);
  const [clientNameObj, setClientNameObj] = useState({});
  const [managerObj, setManagerObj] = useState([]);
  const [teamLeadObj, setteamLeadObj] = useState([]);
  const [recruiterObj, setRecruiterObj] = useState([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState([])
  const [disableBtn, setDisableBtn] = useState(disableSubmitBtn);
  const [clientList, setClientList] = useState([])
  const [GetUserId, setUserId] = useState(null);
  const [recruitersObj, setRecruitersObj] = useState([]);
  const [systemRole, setSystemRole] = useState(false);

  const clientVal = useSelector(state => state.FilterClients.data);
  const teamleadList = useSelector(state => state.GetManagerTeamlead.teamLeads);
  const managerList = useSelector(state => state.GetManagerTeamlead.manager);
  const recruiterList = useSelector(state => state.GetManagerTeamlead.recruiter);
  const customFieldsData = useSelector(state => state.CustomFields.data);

  // defaultDropdownOptionValues
  const [workModelValues, setWorkModelValues] = useState([]);
  const [jobStatusValues, setJobStatusValues] = useState([]);
  const [jobTypeValues, setJobTypeValues] = useState([]);
  const [priorityValues, setPriorityValues] = useState([]);

  // customFields
  const [customFieldsArray, setCustomFieldsArray] = useState([]);
  const [getcustomFields, setGetCustomFields] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("isjobDesc", isjobDesc);
    console.log("isjobDescForSM", isjobDescForSM);
  }, [isjobDesc, isjobDescForSM]);

  useEffect(() => {
    defaultPostingsData.forEach(list => {
      if (list.fieldName === "Work model") {
        setWorkModelValues(list.customValues);
      }
      if (list.fieldName === "JobStatus") {
        setJobStatusValues(list.customValues);
      }
      if (list.fieldName === "JobType") {
        setJobTypeValues(list.customValues);
      }
      if (list.fieldName === "priority") {
        setPriorityValues(list.customValues);
      }
    })
  }, [])


  useEffect(() => {
    if (GetUserId === null) {
      let userId = JSON.parse(decrypt('GetUserId'))
      setUserId(userId);
      dispatch(customFieldsAsync(userId));
    }
  }, [GetUserId]);

  useEffect(() => {
    if (teamleadList && teamleadList.length > 0) {
      const data = JSON.parse(JSON.stringify(teamleadList));
      console.log("teamleadList data", data);
      setteamLeadObj(data);
      dispatch(_props.getManagerTeamleadStartReducer({}))
    }
  }, [teamleadList])

  useEffect(() => {
    if (managerList && managerList.length > 0) {
      const data = JSON.parse(JSON.stringify(managerList));
      console.log("managerList data", data);
      setManagerObj(data);
      dispatch(_props.getManagerTeamleadStartReducer({}))
    }
  }, [managerList])

  useEffect(() => {
    if (recruiterList && recruiterList.length > 0) {
      const data = JSON.parse(JSON.stringify(recruiterList));
      let newArray = [];
      data.forEach(list => {
        let obj = { id: list.id.toString(), name: `${list.firstName} ${list.lastName}`, emailId:list.emailId}
        newArray.push(obj);
      })
      setRecruiterObj(newArray);
      dispatch(_props.getManagerTeamleadStartReducer({}))
    }
  }, [recruiterList])

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const data = JSON.parse(JSON.stringify(customFieldsData));
      let customPostingFieldsData = data.filter(x => x.moduleId.toString() === "1" && x.fieldType === "custom");
      if (customPostingFieldsData.length > 0) {
        let Array = {};
        customPostingFieldsData.forEach(item => {
          item.customValues = (item.dataType.toLowerCase() !== "text box") ? JSON.parse(item.customValues) : '';
          item.moduleId = item.moduleId.toString();
          Array[item.fieldName] = (item.dataType.toLowerCase() !== "text box") ? [] : '';
        });
        setCustomFieldsArray(customPostingFieldsData);
        if (componentType === "Create") {
          setGetCustomFields(Array);
        }
      }
      let overrideDefaultPostingFieldsData = data.filter(x => x.moduleId.toString() === "1" && x.fieldType === "default");
      if (overrideDefaultPostingFieldsData.length > 0) {
        overrideDefaultPostingFieldsData.forEach(list => {
          if (list.fieldName === "Work model") {
            setWorkModelValues(JSON.parse(list.customValues));
          }
          if (list.fieldName === "Job status") {
            setJobStatusValues(JSON.parse(list.customValues));
          }
          if (list.fieldName === "Job type") {
            setJobTypeValues(JSON.parse(list.customValues));
          }
          if (list.fieldName === "Priority") {
            setPriorityValues(JSON.parse(list.customValues));
          }
        })
      }
      dispatch(customFieldsStartReducer({}))
    }
  }, [customFieldsData])

  useEffect(() => {
    let userId = JSON.parse(decrypt('GetUserId'))
    setUserId(userId);
    dispatch(_props.getManagerTeamleadAsync("team Lead", userId))
    dispatch(_props.getManagerTeamleadAsync("manager", userId))
    dispatch(_props.getManagerTeamleadAsync(" ", userId))
  }, [])

  useEffect(() => {
    if (componentType !== "Create" && clientList.length > 0 && recruiterObj.length > 0) {
      let data = JSON.parse(JSON.stringify(getDetails));
      let clientValue = JSON.parse(JSON.stringify(clientList));
      let findClientIndex = clientValue.findIndex((x) => x.clientName === data.clientName);
      if (findClientIndex !== -1) {
        data.clientName = clientValue[findClientIndex];
        data["clientId"] = clientValue[findClientIndex].id.toString();
        formik.values.clientId = clientValue[findClientIndex].id.toString();
        setClientNameObj(clientValue[findClientIndex]);
      }
      let filterObj = [];
      data.recruiters.forEach(id => {
        let findIndex = recruiterObj.findIndex((x) => x.id === id);
        if (findIndex !== -1) {
          filterObj.push(recruiterObj[findIndex]);
        }
      })
      setRecruitersObj(filterObj);
    }
  }, [clientList, recruiterObj, dispatch]);

  useEffect(() => {
    if (clientVal && clientVal.length > 0) {
      let clientValue = JSON.parse(JSON.stringify(clientVal));
      let ClientData = [];
      clientValue.forEach(e => {
        if (e.status === "Active") {
          e.clientName = e.clientName;
          ClientData.push(e);
        }
      })
      setClientList(ClientData);
    } else {
      dispatch(_clientser.filterClientsAsync(""));
    }
  }, [clientVal])

  useEffect(() => {
    if (componentType === "Create") {
      formik.values.jobStatus = "Active";
    }
  }, []);

  useEffect(() => {
    if (componentType !== "Create") {
      let data = JSON.parse(JSON.stringify(input));
      // data.recruiters = undefined;
      let stringJobType = data.jobType.toString();
      let listofjobType = stringJobType !== "" ? stringJobType.split(",") : [];
      setSelectedJobTypes(listofjobType);
      formik.values.jobTitle = data.jobTitle;
      formik.values.externalSystemID = data.externalSystemID;
      formik.values.workModel = data.workModel;
      formik.values.jobStatus = data.jobStatus;
      formik.values.jobType = data.jobType.toString();
      formik.values.clientId = data.clientId;
      formik.values.clientBillRate = data.clientBillRate;
      formik.values.payBillRate = data.payBillRate;
      formik.values.location = data.location;
      formik.values.manager = data.manager;
      formik.values.teamLead = data.teamLead;
      formik.values.primarySkills = data.primarySkills;
      // for customfields
      let customFieldsData = JSON.parse(data.customFields);
      let formatCustomFields = {};
      if (customFieldsData && customFieldsData.length > 0) {
        customFieldsData.forEach(list => {
          formatCustomFields[list.key] = list.value;
        })
        setGetCustomFields(formatCustomFields);
      }
      setGetDetails(data);
    }
  }, [componentType]);

  const onChangeHandler = (data, type) => {
    let obj = JSON.parse(JSON.stringify(getDetails));
    if (type === "jobDesc" || type === "jobDescForSM") {
      // obj = data.initData;
      data = data.data;
    }
    if (type === "clientName") {
      obj["clientName"] = data;
    }
    if (type !== "jobType") {
      formik.values[type] = data;
    }
    if (type === "country") {
      obj.state = "";
      formik.values.state = "";
    }
    if (type === "clientId") {
      let filterData = clientList.filter((x) => parseInt(x.id) === parseInt(data));
      if (filterData.length > 0) {
        obj["clientName"] = filterData[0];
        obj["clientId"] = filterData[0].id;
        setClientNameObj(filterData[0]);
      } else {
        obj["clientName"] = {};
        setClientNameObj({});
      }
      obj["clientId"] = data !== undefined ? data : '';
      formik.values.clientId = data !== undefined ? data : '';
    }
    if (type === "jobDesc") {
      setIsjobDesc(data === " " ? true : false);
    }
    if (type === "jobDescForSM") {
      setIsjobDescForSM(data === " " ? true : false);
    }
    if (type === "jobType") {
      obj[type] = data.toString();
      formik.values[type] = data.toString();
      setSelectedJobTypes(data);
    }
    if (type === "recruiters") {
      let array = [];
      data.forEach(selected => {
        let data = recruiterObj.filter(x => x.id === selected);
        if (data.length > 0) {
          array.push(data[0]);
        }
      })
      setRecruitersObj(array);
      obj[type] = data;
    }
    else {
      obj[type] = data;
    }
    setGetDetails(obj);
  };

    const onChangeCustomFieldsHandler = (value, type) => {
    setGetCustomFields((prevousValue) => ({ ...prevousValue, [type]: value }))
  }

  useEffect(() => {
    console.log("getDetails", getDetails)
  }, [getDetails])

  const validationSchema = Yup.object({
    jobTitle: Yup.string()
      .min(3, "Must be 2 characters")
      .max(100, "Must be less than 100")
      .required("Please enter a job title"),
    externalSystemID: Yup.string()
      .max(40, "Must be less than 40 characters"),
    workModel: Yup.string().required("Please select a work model"),
    jobStatus: Yup.string(),
    jobType: Yup.string().required("Please select a job type"),
    // clientId: Yup.string().required("Please select a client"),
    clientBillRate: Yup.string(),
    // payBillRate: Yup.string().matches(/^[0-9]*[A-Za-z][0-9A-Za-z]*$/, "Please provide a valid pay rate or salary").required("Please provide a valid pay rate or salary"),
    payBillRate: Yup.string().matches(/^[0-9]+[a-z]|[a-z]+[0-9]|[0-9]/, "Please provide a valid pay rate or salary").required("Please provide a valid pay rate or salary"),
    location: Yup.string().required("Please enter a location"),
    jobDesc: Yup.string().required("Job description is required. Please provide a description"),
  });

  const onCkeditorValidate = () => {
    if (getDetails.jobDesc === " ") {  //&& getDetails.jobDesc
      setIsjobDesc(true);
      return true;
    } else {
      return false;
    }
  };

  const formik = useFormik({
    initialValues: iniValue,
    validationSchema,
    validate: (values) => {
      // onCkeditorValidate();
      if (isJobDetails === true) {
        onResetValue(true)
      }
    },
    onSubmit: async (values) => {
      let ArrayOfCustomFields = [];
      if (getcustomFields !== null) {
        let keys = Object.keys(getcustomFields);
        keys.forEach((items, i) => {
          let obj = { key: items, value: getcustomFields[items] }
          ArrayOfCustomFields.push(obj);
        })

      }
      setDisableBtn(true);
      if (!isjobDesc && !isjobDescForSM) {
        values.clientName = getDetails.clientName ? getDetails.clientName : clientNameObj;
        values.customerId = getDetails.customerId;
        values.jobCode = getDetails.jobCode;
        values.externalSystemID = getDetails.externalSystemID;
        values.jobDesc = getDetails.jobDesc;
        values.jobDescForSM = getDetails.jobDescForSM;
        values.priority = getDetails.priority;
        values.secondarySkills = getDetails.secondarySkills;
        values.userFirstName = getDetails.userFirstName;
        values.userId = getDetails.userId;
        values.userLastName = getDetails.userLastName;
        values['customFields'] = JSON.stringify(ArrayOfCustomFields);
        values['recruiters'] = JSON.stringify(getDetails.recruiters);
        let recruitersEmailids = recruitersObj.map(x => x.emailId);
        // values['recruitersemails'] = JSON.stringify(recruitersEmailids);
        values['listofRecruitersemails'] = [];
        recruitersObj.forEach((item, index) => {
          let key = `recruitersemails[${index}]`;
          let obj = { [key] : {name: item.name, emailid: item.emailId}};
          values['listofRecruitersemails'].push(obj);
        })
        await onValidData(values);
      }
    }
  });

  useEffect(() => {
    if (isJobDetails === true) {
      formik.handleSubmit()
    }
  }, [isJobDetails])

  const userInfo = JSON.parse(decrypt("GetUserInfo"));
  useEffect(() => {
    if (userInfo) {
      let bool = userInfo[0].systemroles === "Super Admin" || userInfo[0].systemroles === "Manager" || userInfo[0].systemroles === "Admin" ? true : false
      setSystemRole(bool)
    }
  }, [])

  return (
    <React.Fragment>
      {componentType !== "Create" ? (
        <div className="actionBtnRtl">
          {actions &&
            actions.map((item) => {
              if (item.type === "back") {
                return (
                  <Button
                    variant={item.variant}
                    className={item.class}
                    style={item.style}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(item.navigateTo);
                    }}
                  >
                  {componentType !== "view"? <Link href="#" style={{color:"red", fontSize:"14px"}} underline="always"> {item.label}{" "}</Link> :<>{item.label}{" "} </>}
                  </Button>
                );
              } else if (item.type === "submit") {
                return (
                  <Button
                    variant={item.variant}
                    className={item.class}
                    disabled={disableBtn}
                    style={item.style}
                    size="small"
                    onClick={formik.handleSubmit}
                  >
                    {item.label}{" "}
                  </Button>
                );
              }
            })}
        </div>
      ) : (
        ""
      )}
      <FocusError formik={formik} />
      <div
        style={{ width: "100%" }}
        className={componentType === "view" ? "viewSection" : ""}
      >
        <div style={{ display: "flex", flexFlow: "wrap", marginTop: "50px", justifyContent: "space-between" }}>
          {/* <div
            style={{ width: "32%" }}
            className={componentType !== "Create" ? "d-block" : "d-none"}
          >
            <InputLabel className="SelectLabel">Job Code</InputLabel>
            <TextField
              id="outlined-basic"
              variant="outlined"
              autoComplete="off"
              style={{ width: "100%" }}
              name="jobCode"
              value={getDetails.jobCode ? getDetails.jobCode : ""}
              disabled
              onBlur={formik.handleBlur}
              onChange={(e) => onChangeHandler(e.target.value, "jobCode", getDetails)}
            />
          </div> */}
          <div
            style={{ width: "32%" }}
          // className={componentType === "Create" ? "d-block" : "d-none"}
          >
            <TextField
              className="boldlabel"
              error={
                formik.errors.jobTitle && formik.touched.jobTitle ? true : false
              }
              helperText={
                formik.errors.jobTitle && formik.touched.jobTitle
                  ? formik.errors.jobTitle
                  : ""
              }
              id="outlined-basic"
              variant="outlined"
              autoComplete="off"
              autoFocus={formik.errors.jobTitle !== "" ? true : false}
              label="Job Title*&nbsp;&nbsp;&nbsp;"
              style={{ width: "100%" }}
              name="jobTitle*"
              value={getDetails.jobTitle}
              disabled={componentType === "view" ? true : false}
              onChange={(e) => onChangeHandler(e.target.value, "jobTitle", getDetails)}
              InputLabelProps={{
                shrink :  true, 
              }}
              onBlur={formik.handleBlur}
            />
          </div>
          <div
            style={{ width: "32%" }}
          // className={componentType === "Create" ? "d-block" : "d-none"}
          >
            <TextField
              className="boldlabel"
              error={
                formik.errors.externalSystemID && formik.touched.externalSystemID ? true : false
              }
              helperText={
                formik.errors.externalSystemID && formik.touched.externalSystemID
                  ? formik.errors.externalSystemID
                  : ""
              }
              id="outlined-basic"
              variant="outlined"
              autoComplete="off"
              // autoFocus={formik.errors.externalSystemID !== "" ? true : false}
              label="External Job ID &nbsp;&nbsp;&nbsp;&nbsp;"
              style={{ width: "100%" }}
              name="externalSystemID"
              value={getDetails.externalSystemID}
              disabled={componentType === "view" ? true : false}
              onChange={(e) => onChangeHandler(e.target.value, "externalSystemID", getDetails)}
              onBlur={formik.handleBlur}
              InputLabelProps={{
                shrink : true ,
              }}
            />
          </div>
          <div style={{ width: "31.8%" }}>
            <FormControl
              fullWidth
              autoComplete="off"
              error={
                formik.errors.jobStatus && formik.touched.jobStatus
                  ? true
                  : false
              }
            >
              <InputLabel className="boldSelectlabel">Job Status*</InputLabel>
              <Select
                value={
                  componentType === "Create" && getDetails.jobStatus === ""
                    ? "Active"
                    : getDetails.jobStatus
                }
                name="jobStatus"
                disabled={componentType === "view" ? true : false}
                label="Job Status*&nbsp;&nbsp;&nbsp;"
                autoComplete="off"
                onChange={(e) => onChangeHandler(e.target.value, "jobStatus", getDetails)}
                onBlur={formik.handleBlur}
                style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
              >
                {jobStatusValues.map((name) => (
                  <MenuItem className="selectOptions" key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.jobStatus && formik.touched.jobStatus
                  ? formik.errors.jobStatus
                  : ""}
              </FormHelperText>
            </FormControl>
          </div>


        </div>
        <div style={{ display: "flex", flexFlow: "wrap", marginTop: "50px", justifyContent: "space-between" }}>
          {/* <div
            style={{ width: "32%", }}
            className={
              componentType === "Create" ? "d-none" : "d-block "
            }
          >
            <TextField
              className="boldlabel"
              error={
                formik.errors.jobTitle && formik.touched.jobTitle ? true : false
              }
              helperText={
                formik.errors.jobTitle && formik.touched.jobTitle
                  ? formik.errors.jobTitle
                  : ""
              }
              onBlur={formik.handleBlur}
              id="outlined-basic"
              autoComplete="off"
              label="Job Title*&nbsp;&nbsp;&nbsp;"
              variant="outlined"
              style={{ width: "100%" }}
              name="jobTitle"
              onChange={(e) => onChangeHandler(e.target.value, "jobTitle", getDetails)}
              value={getDetails.jobTitle}
              disabled={componentType === "view" ? true : false}
            />
          </div> */}

          <div
            style={{
              width: "32%",
            }}
          >
            <FormControl sx={{ width: "100%" }}
              fullWidth
              error={
                formik.errors.jobType && formik.touched.jobType ? true : false
              }
            >
              <InputLabel className="boldSelectlabel" id="demo-multiple-chip-label"shrink = {true} >Job Type*</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id=""
                multiple
                name="jobType"
                disabled={componentType === "view" ? true : false}
                value={selectedJobTypes}
                onChange={(e) => onChangeHandler(e.target.value, "jobType", getDetails)}
                onFocus={formik.handleBlur}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Job Type* &nbsp;&nbsp;" />
                }
                renderValue={(selected) => {
                  if (typeof selected === 'string') { selected = selected.split(",") }
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, height: "23px" }}>
                      {typeof selected !== 'string' && selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )
                }}
              >
                {jobTypeValues.map((name) => (
                  <MenuItem className="selectOptions" key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.jobType && formik.touched.jobType
                  ? formik.errors.jobType
                  : ""}
              </FormHelperText>
            </FormControl>
          </div>
          <div style={{ width: "32%" }}>
            <FormControl
              fullWidth
              autoComplete="off"
              error={
                formik.errors.workModel && formik.touched.workModel
                  ? true
                  : false
              }
            >
              <InputLabel className="boldSelectlabel"shrink = {true} >Work Model*</InputLabel>
              <Select
                value={getDetails.workModel}
                name="workModel"
                disabled={componentType === "view" ? true : false}
                autoComplete="off"
                label="Work Model*&nbsp;&nbsp;&nbsp;&nbsp;"
                onChange={(e) => onChangeHandler(e.target.value, "workModel", getDetails)}
                onBlur={formik.handleBlur}
                style={{
                  width: "100%",
                  height: "3.5rem",
                  borderRadius: "4px",
                }}
              >
                {workModelValues.map((name) => (
                  <MenuItem className="selectOptions" key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {formik.errors.workModel && formik.touched.workModel
                  ? formik.errors.workModel
                  : ""}
              </FormHelperText>
            </FormControl>
          </div>
          {userInfo[0]?.clientStatus === 1 ? ( 
          <div
            style={{
              width: "32%",
            }}
          >
            <div
              className="boldlabel"
              style={{ display: "flex", width: "100%" }}
            >
              <Autocomplete
                options={clientList?.length ? clientList : []}
                className="clientsearch1"
                autoHighlight
                id="controlled-demo"
                autoComplete="off"
                clearOnEscape
                value={
                  getDetails.clientName ? getDetails.clientName : clientNameObj ? clientNameObj : { id: "", clientName: "" }
                }
                disabled={componentType === "view" ? true : false}
                style={{
                  width: componentType !== "create" ? "100%" : "",
                  borderRadius: "120px",
                }}
                getOptionLabel={(option) => option?.clientName ? option?.clientName : ''}
                blurOnSelect={true}
                onChange={(e, value) => {
                  onChangeHandler(value?.id.toString(), "clientId", getDetails);
                }}
                sx={{ width: 503 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="boldlabel"
                    label="Client&nbsp;"
                    autoComplete="off"
                    error={
                      formik.errors.clientId && formik.touched.clientId
                        ? true
                        : false
                    }
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink :  true, 
                    }}
                    helperText={
                      formik.errors.clientId && formik.touched.clientId
                        ? formik.errors.clientId
                        : ""
                    }
                  />
                )}
              />
              <BsSearch style={{ paddingTop: "1.2rem", marginLeft: "-2rem" }} />
            </div>
          </div>
            ) : <div style={{ width: "32%" }}></div> }
          <div
            style={{ width: "32%", }}
            className={
              componentType === "Create" ? "d-block " : "d-none"
            }
          >
          </div>
        </div>
        <div style={{ display: "flex", flexFlow: "wrap", marginTop: "50px", justifyContent: "space-between" }}>
          <TextField
            className="boldlabel"
            autoComplete="off"
            error={
              formik.errors.clientBillRate && formik.touched.clientBillRate
                ? true
                : false
            }
            helperText={
              formik.errors.clientBillRate && formik.touched.clientBillRate
                ? formik.errors.clientBillRate
                : ""
            }
            id="outlined-basic"
            label="Client Bill Rate &nbsp;&nbsp;&nbsp;&nbsp;"
            variant="outlined"
            style={{ width: "31.9%" }}
            name="clientBillRate"
            value={getDetails.clientBillRate}
            onChange={(e) => onChangeHandler(e.target.value, "clientBillRate", getDetails)}
            onBlur={formik.handleBlur}
            multiline={true}
            rows={5}
            disabled={componentType === "view" ? true : false}
            InputLabelProps={{
              shrink :  true, //componentType !== "Create" || getDetails.clientBillRate !== "" ?
            }}  
          />
          <TextField
            className="boldlabel"
            autoComplete="off"
            error={
              formik.errors.payBillRate && formik.touched.payBillRate
                ? true
                : false
            }
            helperText={
              formik.errors.payBillRate && formik.touched.payBillRate
                ? formik.errors.payBillRate
                : ""
            }
            id="outlined-basic"
            label="Pay rate/Salary* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            variant="outlined"
            style={{ width: "31.9%" }}
            name="payBillRate"
            value={getDetails.payBillRate}
            InputLabelProps={{
              shrink :  true, 
            }}
            onChange={(e) => onChangeHandler(e.target.value, "payBillRate", getDetails)}
            onBlur={formik.handleBlur}
            multiline={true}
            rows={5}
            disabled={componentType === "view" ? true : false}
          />
          <TextField
            className="boldlabel"
            autoComplete="off"
            error={
              formik.errors.location && formik.touched.location ? true : false
            }
            helperText={
              formik.errors.location && formik.touched.location
                ? formik.errors.location
                : ""
            }
            id="outlined-basic"
            label="Location*&nbsp;&nbsp;&nbsp;"
            variant="outlined"
            style={{ width: "32%" }}
            name="location"
            value={getDetails.location}
            InputLabelProps={{
              shrink :  true, 
            }}
            onChange={(e) => onChangeHandler(e.target.value, "location", getDetails)}
            onBlur={formik.handleBlur}
            multiline={true}
            rows={5}
            disabled={componentType === "view" ? true : false}
          />
        </div>
        <div style={{ display: "flex", flexFlow: "wrap", marginTop: "50px", justifyContent: "space-between" }}>
          <div style={{ width: "32%" }}>
            <FormControl
              fullWidth
              error={
                formik.errors.manager && formik.touched.manager ? true : false
              }
            >
              <InputLabel className="boldSelectlabel"  
              shrink = { true  }
              >Manager</InputLabel>
              <Select
                name="manager"
                id="manager"
                label="Manager*&nbsp;&nbsp;&nbsp;"
                autoComplete="off"
                value={getDetails.manager !== 0 ? getDetails.manager : ""}
                variant="outlined"
                style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                onChange={(e) => onChangeHandler(e.target.value, "manager", getDetails)}
                onBlur={formik.handleBlur}
                disabled={componentType === "view" ? true : false}
              >
                {managerObj && managerObj.length > 0 &&
                  managerObj.map((Manager) => {
                    return (
                      <MenuItem value={Manager.id}>
                        {Manager.firstName + " " + Manager.lastName}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText>
                {formik.errors.manager && formik.touched.manager
                  ? formik.errors.manager
                  : ""}
              </FormHelperText>
            </FormControl>
          </div>
          <div style={{ width: "32%" }}>
            <FormControl
              fullWidth
              error={formik.errors.teamLead && formik.touched.teamLead ? true : false}
            >
              <InputLabel className="boldSelectlabel" 
                shrink = {true }
              
              >Team Lead </InputLabel>
              <Select
                name="teamLead"
                label="Team Lead*&nbsp;&nbsp;&nbsp;"
                autoComplete="off"
                value={getDetails.teamLead !== 0 ? getDetails.teamLead : ""}
                style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                onChange={(e) => onChangeHandler(e.target.value, "teamLead", getDetails)}
                onBlur={formik.handleBlur}
               
                disabled={componentType === "view" ? true : false}
              >
                {teamLeadObj && teamLeadObj.length > 0 &&
                  teamLeadObj.map((teamLead) => {
                    return (
                      <MenuItem value={teamLead.id}>
                        {teamLead.firstName + " " + teamLead.lastName}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText>
                {formik.errors.teamLead && formik.touched.teamLead
                  ? formik.errors.teamLead
                  : ""}
              </FormHelperText>
            </FormControl>
          </div>
          <div style={{ width: "32%" }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" 
                shrink = {true}
                className={componentType === "view" || !systemRole ? "viewSectionboldSelectlabel" :"boldSelectlabel" }>Recruiter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                name="recruiters"
                disabled={componentType === "view" || !systemRole ? true : false}
                value={getDetails.recruiters}
                onFocus={formik.handleBlur}
                onChange={(e) => onChangeHandler(e.target.value, "recruiters", getDetails)}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(array) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5,height: "23px" }}>
                    {recruitersObj && recruitersObj.map((value) => (
                      <Chip key={value.id} label={value.name} />
                    ))}
                  </Box>)}
              >
                {recruiterObj && recruiterObj.length > 0 && recruiterObj.map((list) => (
                  <MenuItem className="selectOptions" key={list.id} value={list.id}>
                    {list.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

        </div>
       
        {/* customFields Ui render start here */}
        <div id="customFields" style={{ display: "flex", flexFlow: "wrap", marginTop: "50px", gap: "0% 2%" }}>
        <div style={{ width: "32%" }}>
            <FormControl fullWidth>
              <InputLabel className="boldSelectlabel" 
                shrink = { true }
              
              > Priority&nbsp;</InputLabel>
              <Select
                style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                value={getDetails.priority}
                name="priority"
                autoComplete="off"
                label="Priority&nbsp;&nbsp;"
                disabled={componentType === "view" ? true : false}
                onChange={(e) => onChangeHandler(e.target.value, "priority", getDetails)}
                onBlur={formik.handleBlur}
              >
                {priorityValues.map((name) => (
                  <MenuItem className="selectOptions" key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {customFieldsArray && customFieldsArray.length > 0 && customFieldsArray.map((data, index) => {
            if (data.dataType.toLowerCase().trim() === "text box") {
              return (
                <div style={{ width: "32%", marginBottom: "45px" }}>
                  <TextField
                    className="boldlabel"
                    id={data.fieldName}
                    variant="outlined"
                    autoComplete="off"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink : true ,
                    }}
                    label={data.fieldName}
                    name={data.fieldName}
                    disabled={componentType === "view" ? true : false}
                    value={getcustomFields && Object.keys(getcustomFields).includes(data.fieldName) ? getcustomFields[data.fieldName] : ''}
                    onBlur={formik.handleBlur}
                    onChange={(e) => onChangeCustomFieldsHandler(e.target.value, data.fieldName)}
                  />
                </div>
              )
            } else if (data.dataType.toLowerCase().trim() === "multi select") {
              return (
                <div style={{ width: "32%", marginBottom: "45px" }}>
                  <FormControl sx={{ width: "100%" }} fullWidth>
                    <InputLabel className="boldSelectlabel" 
                    shrink = { true }
                    id="demo-multiple-chip-label">{data.fieldName}</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id={data.fieldName}
                      multiple
                      name={data.fieldName}
                      disabled={componentType === "view" ? true : false}
                      value={getcustomFields && Object.keys(getcustomFields).includes(data.fieldName) ? getcustomFields[data.fieldName] : []}
                      onChange={(e) => onChangeCustomFieldsHandler(e.target.value, data.fieldName)}
                      onBlur={formik.handleBlur}
                      input={
                        <OutlinedInput id="select-multiple-chip" label={data.fieldName} />
                      }
                      renderValue={(selected) => {
                        return (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, height: "23px" }}>
                            {typeof selected !== 'string' && selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )
                      }}
                    >
                      {data.customValues && data.customValues.length > 0 && data.customValues.map(name => (
                        <MenuItem className="selectOptions" key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )
            } else if (data.dataType.toLowerCase().trim() === "single select") {
              return (
                <div style={{ width: "32%", marginBottom: "45px" }}>
                  <FormControl fullWidth >
                    <InputLabel className="boldSelectlabel"
                                    shrink = { true }
                    >{data.fieldName}</InputLabel>
                    <Select
                      name={data.fieldName}
                      id={data.fieldName}
                      label={data.fieldName}
                      autoComplete="off"
                      value={getcustomFields && Object.keys(getcustomFields).includes(data.fieldName) ? getcustomFields[data.fieldName] : []}
                      variant="outlined"
                      style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                      onChange={(e) => onChangeCustomFieldsHandler(e.target.value, data.fieldName)}
                      onBlur={formik.handleBlur}
                      disabled={componentType === "view" ? true : false}
                    >
                      {data.customValues && data.customValues.length > 0 && data.customValues.map(Manager => {
                        return (
                          <MenuItem value={Manager}>
                            {Manager}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {formik.errors.manager && formik.touched.manager
                        ? formik.errors.manager
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </div>
              )
            }
          }
          )}
        </div>
        {/* customFields Ui render end here */}

      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: "0px" }}>
        <FormControl fullWidth error={formik.errors.jobDesc && formik.touched.jobDesc ? true : false}>
          <InputLabel
            style={{ marginLeft: "-13px!important" }}
            autoComplete="off"
            className={formik.errors.jobDesc && formik.touched.jobDesc ? "text-danger headerLabel" : "headerLabel"}
          >
            Job Description*
          </InputLabel>
          <div
            id="jobDesc"
            className={formik.errors.jobDesc && formik.touched.jobDesc ? "brd-danger" : ""}>
            <CkEditor
              data={getDetails.jobDesc && getDetails.jobDesc === "" ? " " : getDetails.jobDesc}
              error={formik.errors.jobDesc && formik.touched.jobDesc ? true : false}
              name="jobDesc"
              autoComplete="off"
              initRawData={getDetails}
              isReadonly={componentType === "view" ? true : false}
              onUpdatehandler={(update) => onChangeHandler(update, "jobDesc", getDetails)}
              onBlur={formik.handleBlur}
            />
          </div>
          {formik.errors.jobDesc && formik.touched.jobDesc ? (
            <FormHelperText className="text-danger">
              Please enter a job description
            </FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      </div>
      <div id="eqlColHeight" style={{ display: "flex", flexFlow: "wrap", marginTop: "30px", justifyContent: "space-between", width: "100%" }}>
        <div style={{ width: "32%" }}>
          <TextField
            className="boldlabel1"
            autoComplete="off"
            error={
              formik.errors.primarySkills && formik.touched.primarySkills
                ? true
                : false
            }
            helperText={
              formik.errors.primarySkills && formik.touched.primarySkills
                ? formik.errors.primarySkills
                : ""
            }
            id="primarySkills"
            label="Primary Skills &nbsp; &nbsp; &nbsp;"
            variant="outlined"
            multiline={true}
            rows={10}
            style={{ width: "100%", marginTop: "40px" }}
            name="primarySkills"
            value={getDetails.primarySkills}
            onChange={(e) => onChangeHandler(e.target.value, "primarySkills", getDetails)}
            onKeyDown={formik.handleBlur}
            onBlur={formik.handleBlur}
            disabled={componentType === "view" ? true : false}
            InputLabelProps={{
              shrink : true ,
            }}
          />
        </div>
        <div style={{ width: "32%" }}>
          <TextField
            className="boldlabel1"
            id="secondarySkills"
            autoComplete="off"
            label="Secondary Skills &nbsp; &nbsp; &nbsp;"
            variant="outlined"
            multiline={true}
            rows={10}
            style={{ width: "100%", marginTop: "40px" }}
            name="secondarySkills"
            value={getDetails.secondarySkills}
            onChange={(e) => onChangeHandler(e.target.value, "secondarySkills", getDetails)}
            onBlur={formik.handleBlur}
            disabled={componentType === "view" ? true : false}
            InputLabelProps={{
              shrink : true ,
            }}
          />
        </div>
        <div style={{ width: "32%", marginTop: "-5px" }}>
          <FormControl fullWidth error={isjobDescForSM === true ? true : false}>
            <InputLabel
              autoComplete="off"
              className={
                isjobDescForSM === true
                  ? " headerLabel"
                  : "headerLabel"
              }
            >
              Job Description For Social Media
            </InputLabel>
            <div
              id="jobDescForSMnew"
              onKeyDown={formik.handleBlur}
            // className={isjobDescForSM === true ? "brd-danger" : ""}
            >
              <CkEditor
                data={getDetails.jobDescForSM && getDetails.jobDescForSM === "" ? " " : getDetails.jobDescForSM}
                initRawData={getDetails}
                name="jobDescForSMnew"
                autoComplete="off"
                isReadonly={componentType === "view" ? true : false}
                onUpdatehandler={(update) =>
                  onChangeHandler(update, "jobDescForSM", getDetails)
                }
              />
            </div>
            {/* {isjobDescForSM === true ? (
              <FormHelperText className="text-danger" style={{ position: "absolute", bottom: "-28px", letterSpacing: "-0.3px" }}>
                Please enter description for social media
              </FormHelperText>

            ) : (
              ""
            )} */}
          </FormControl>
        </div>
      </div>
      {componentType === "Create" ? (
        <div className="jpacionbuttons" style={{ marginLeft: "auto!important", width: "fit-content", marginTop: "60px", marginLeft: "auto", marginRight: "-13px" }}>
          {actions &&
            actions.map((item) => {
              if (item.type === "back") {
                return (
                  <Button
                    className={item.class}
                    variant={item.variant}
                    style={item.style}
                    size="small"
                    onClick={() => navigate(item.navigateTo)}
                  >
                  {item.label}{" "}
                  </Button>
                );
              } else if (item.type === "submit") {
                return (
                  <Button
                    className={item.class}
                    variant={item.variant}
                    style={item.style}
                    size="small"
                    disabled={disableBtn}
                    onClick={formik.handleSubmit}
                  >
                    {item.label}{" "}
                  </Button>
                );
              }
            })}
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default React.memo(PostingsComponent);