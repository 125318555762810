import React, { useState, useEffect } from "react";

export const UpdateStausillus = () => {
    const [primaryColor, setPrimaryColor] = useState('#ffd800');

    const storedTheme = JSON.parse(localStorage.getItem("themeSettings")) || '';

    useEffect(() => {
        if (storedTheme !== '') {
            setPrimaryColor(storedTheme?.primaryColor);
        }
    }, [storedTheme])

    return (
        <svg id="Group_41193" data-name="Group 41193" xmlns="http://www.w3.org/2000/svg" width="325.404" height="210.475" viewBox="0 0 325.404 210.475">
            <path id="Path_16178" data-name="Path 16178" d="M1166.853,691.268h-92.245l18.146-182.75a45.077,45.077,0,0,0,14.882-10.047,54.831,54.831,0,0,1,9.146-7.225q.755-.479,1.525-.934a61.957,61.957,0,0,1,6.1-3.151q2.115-.949,4.309-1.755h0a73.04,73.04,0,0,1,7.142-2.213,84.393,84.393,0,0,1,10.652-1.989l.7,7.267Z" transform="translate(-905.089 -480.795)" fill="#fff4ba" />
            <g id="Group_5073" data-name="Group 5073" transform="translate(186.46 79.012)">
                <path id="Path_16179" data-name="Path 16179" d="M1281.957,1550.975h-11.649a6.393,6.393,0,0,1,.329-3.122c.583-1.292,1.49-2.091,1.573-3.081a3.5,3.5,0,0,0,3.336,1.026,12.772,12.772,0,0,0,2.021,2.62C1278.327,1548.965,1281.723,1548.77,1281.957,1550.975Z" transform="translate(-1257.279 -1420.692)" fill="#f3ad4c" />
                <path id="Path_16180" data-name="Path 16180" d="M1372.831,1550.975h-11.649a6.4,6.4,0,0,1,.329-3.122c.583-1.292,1.491-2.091,1.573-3.081a3.5,3.5,0,0,0,3.335,1.026,12.779,12.779,0,0,0,2.021,2.62C1369.2,1548.965,1372.6,1548.77,1372.831,1550.975Z" transform="translate(-1328.833 -1420.692)" fill="#f3ad4c" />
                <path id="Path_16181" data-name="Path 16181" d="M1352.965,1218.021c.291,5.475-2.472,60.651-2.472,60.651a4.273,4.273,0,0,1-2.342,1.12,9.327,9.327,0,0,1-3.26-.127s-8.865-60.712-2.257-68.873q.224-.277.47-.533c.216-.225.717-.359.638-.636-1.881-6.519,6.37-3.249,6.4-3.159C1351.147,1209.73,1352.576,1210.711,1352.965,1218.021Z" transform="translate(-1312.351 -1153.599)" fill="#2e2e2e" />
                <path id="Path_16182" data-name="Path 16182" d="M1296.684,1191.119l-15.1,3.584s-4.011,2.582-5.162,16.883c-1.117,13.88-2.245,55.654-2.245,55.654a3.945,3.945,0,0,0,2.447,1.3,7.548,7.548,0,0,0,2.965-.4c3.2-19.338,16.522-64.053,16.522-64.053Z" transform="translate(-1260.373 -1142.224)" fill="#2e2e2e" />
                <path id="Path_16183" data-name="Path 16183" d="M1257.786,990.123s-8.16-.329-9.326,10.057c-.985,8.776-14.623,8.106-10.248,17.432s16.465,4.03,18.537,2.418,13.817-5.3,15.083-7.83-2.043-4.973-3.08-8.3S1271.084,987.575,1257.786,990.123Z" transform="translate(-1231.377 -983.701)" fill="#56130f" />
                <g id="Group_5070" data-name="Group 5070" transform="translate(52.825 0)">
                    <path id="Path_16184" data-name="Path 16184" d="M1463.128,967.608s-.281-1.4-.6-2.469c-.14-.473.835-3.578,1.51-3.961s2.433,1.709,1.935,3.315c-.255.825-.675,3.509-.675,3.509Z" transform="translate(-1461.5 -961.133)" fill="#ef8f6a" />
                    <path id="Path_16185" data-name="Path 16185" d="M1459.361,980.269s-.367-.812-.7-.943-.686-1.459-.929-1.985c0,0,.235-.345.646.1.251.27.81,1.063.81,1.063Z" transform="translate(-1457.733 -973.794)" fill="#ef8f6a" />
                </g>
                <path id="Path_16186" data-name="Path 16186" d="M1213.378,966.841c-.072.147-.171.411-.26.658,0-.067,0-.134,0-.2,0-.493-1.846-3.177-2.6-3.345s-1.825,2.346-.88,3.736c.486.714,1.981,3.915,1.981,3.915l1.654-1.771s.113-.884.392-1.107.229-1.6.308-2.171C1213.967,966.558,1213.641,966.3,1213.378,966.841Z" transform="translate(-1209.261 -963.348)" fill="#ef8f6a" />
                <path id="Path_16187" data-name="Path 16187" d="M1319.963,1014.675a16.389,16.389,0,0,1-3.5-3.851c-.786-1.4-1.96-7.176,3.861-8.324a7.975,7.975,0,0,1,3.536.086c1.488.391,3.84,1.537,3.584,7.03l.065,1.8Z" transform="translate(-1293.156 -993.582)" fill="#56130f" />
                <path id="Path_16188" data-name="Path 16188" d="M1296.773,1098.3l3.285,13.408,15.567.677s3.153-11.215,1.848-16.321a39.24,39.24,0,0,0-3.3-8.651s-4.836-1.162-13.4-.484Z" transform="translate(-1278.168 -1060.015)" fill="#6fa9c9" />
                <path id="Path_16189" data-name="Path 16189" d="M1356.538,1011.19c5.591-.518,13.867-3.638,14.9-5.584s6.977-11.446,7.948-14.37a6.8,6.8,0,0,1,1.161.014,5.48,5.48,0,0,1,1.3.441s-3.465,14.308-5.516,17.885-10.522,9.9-11.676,13.526S1354.854,1011.346,1356.538,1011.19Z" transform="translate(-1325.057 -984.82)" fill="#6fa9c9" />
                <path id="Path_16190" data-name="Path 16190" d="M1241.6,1011.055c-5.591-.518-13.867-3.638-14.9-5.584s-6.533-11.492-7.817-14.3a2.217,2.217,0,0,0-1.282-.059,2.618,2.618,0,0,0-1.208.815s3.152,13.936,5.2,17.512,10.733,9.9,11.886,13.526S1243.288,1011.211,1241.6,1011.055Z" transform="translate(-1214.877 -984.686)" fill="#6fa9c9" />
                <g id="Group_5071" data-name="Group 5071" transform="translate(23.281 9.312)">
                    <path id="Path_16191" data-name="Path 16191" d="M1332.838,1067.4a17.767,17.767,0,0,0,.809-5.814l.326.012,5.49.214s.027,1.327.164,2.566c0,.01,0,.021,0,.031a13.838,13.838,0,0,1,.114,2.961c-.039.613-2.011,1.06-4.306,1.007C1334.619,1068.362,1332.637,1067.86,1332.838,1067.4Z" transform="translate(-1329.835 -1049.545)" fill="#ef8f6a" />
                    <path id="Path_16192" data-name="Path 16192" d="M1343.72,1061.861s.027,1.328.164,2.566a6.208,6.208,0,0,1-5.654-2.779Z" transform="translate(-1334.092 -1049.591)" fill="#e27452" />
                    <path id="Path_16193" data-name="Path 16193" d="M1334.54,1021.473s-6.924,2.555-8.449-1.782-3.445-6.927.911-8.742,5.8-.464,6.662.86S1336.3,1020.7,1334.54,1021.473Z" transform="translate(-1323.144 -1008.978)" fill="#ef8f6a" />
                    <path id="Path_16194" data-name="Path 16194" d="M1325.76,1006.866s-.124,5.758-2.786,7.535-4.207,1.438-4.207,1.438a8.782,8.782,0,0,0,1-5.845S1323.473,1004.948,1325.76,1006.866Z" transform="translate(-1318.767 -1006.113)" fill="#56130f" />
                    <path id="Path_16195" data-name="Path 16195" d="M1350.118,1006.266s1.018-.38,2.572.959a22.22,22.22,0,0,1,2.308,3.554s.224-3.963-2.076-5.354C1350.483,1003.95,1350.118,1006.266,1350.118,1006.266Z" transform="translate(-1343.453 -1004.934)" fill="#56130f" />
                    <path id="Path_16196" data-name="Path 16196" d="M1359.359,1051.3a3.249,3.249,0,0,0,1.963-.007,1.208,1.208,0,0,0,.682-1.1,2.813,2.813,0,0,0-1.267.245A5.507,5.507,0,0,0,1359.359,1051.3Z" transform="translate(-1350.729 -1040.565)" fill="#fff" />
                </g>
                <path id="Path_16197" data-name="Path 16197" d="M1331.7,1082.527s-3.341-.672-4.488-2.8a4.58,4.58,0,0,0-2.161.864s1.829,3.487,5.329,3.962A4.62,4.62,0,0,0,1331.7,1082.527Z" transform="translate(-1300.431 -1054.516)" fill="#fff" />
                <path id="Path_16198" data-name="Path 16198" d="M1358.256,1080.7a4.274,4.274,0,0,1,1.675.4,4.152,4.152,0,0,1-1.406,4s-1.081-1.706-2.2-1.814C1356.322,1083.288,1358.41,1082.23,1358.256,1080.7Z" transform="translate(-1325.057 -1055.278)" fill="#fff" />
                <path id="Path_16199" data-name="Path 16199" d="M1354.7,1092.272a.346.346,0,1,1,.345.345A.345.345,0,0,1,1354.7,1092.272Z" transform="translate(-1323.777 -1064.12)" fill="#163560" />
                <path id="Path_16200" data-name="Path 16200" d="M1363.912,1114.557a.345.345,0,1,1,.345.345A.345.345,0,0,1,1363.912,1114.557Z" transform="translate(-1331.034 -1081.667)" fill="#163560" />
                <path id="Path_16201" data-name="Path 16201" d="M1368.665,1140.755a.346.346,0,1,1,.346.345A.345.345,0,0,1,1368.665,1140.755Z" transform="translate(-1334.776 -1102.296)" fill="#163560" />
                <ellipse id="Ellipse_51" data-name="Ellipse 51" cx="0.345" cy="0.345" rx="0.345" ry="0.345" transform="translate(34.235 44.418)" fill="#163560" />
                <path id="Path_16202" data-name="Path 16202" d="M1325.811,1196.1a7.635,7.635,0,0,1-4.366,1.215c-3.469.07-12.063-.885-12.063-.885a7.105,7.105,0,0,0-.367,2.194s10.5,2.648,16.579.387A3.844,3.844,0,0,0,1325.811,1196.1Z" transform="translate(-1287.807 -1146.148)" fill="#0b1a3b" />
                <g id="Group_5072" data-name="Group 5072" transform="translate(32.888 50.574)">
                    <path id="Path_16203" data-name="Path 16203" d="M1367.119,1200.75a.082.082,0,0,0,0-.025,1.862,1.862,0,0,0-.584-1.307,1.47,1.47,0,0,0-.995-.4,1.5,1.5,0,0,0-1.178.6,1.847,1.847,0,0,0-.378.874c-.006.04-.013.081-.015.121a1.937,1.937,0,0,0,.529,1.546,1.478,1.478,0,0,0,1.042.444,1.532,1.532,0,0,0,1.28-.738,1.933,1.933,0,0,0,.3-1.054C1367.12,1200.79,1367.12,1200.769,1367.119,1200.75Zm-1.582,1.329c-.03,0-.06,0-.09,0a1.2,1.2,0,0,1-.969-1.263,1.242,1.242,0,0,1,.013-.177,1.147,1.147,0,0,1,.038-.213,1.165,1.165,0,0,1,.736-.833.843.843,0,0,1,.272-.045.707.707,0,0,1,.115.008,1.157,1.157,0,0,1,.927,1.048,1.858,1.858,0,0,1,.017.211,1.253,1.253,0,0,1-.761,1.214A.819.819,0,0,1,1365.537,1202.078Z" transform="translate(-1363.955 -1199.019)" fill="#fff" />
                </g>
            </g>
            <g id="Group_5076" data-name="Group 5076" transform="translate(49.644 84.682)">
                <path id="Path_16204" data-name="Path 16204" d="M724.86,1274.6s.818,3.623.584,4.616-2.775,2.571-3.725,2.279-.132-5.259,1.5-6.078Z" transform="translate(-688.238 -1213.621)" fill="#ef8f6a" />
                <path id="Path_16205" data-name="Path 16205" d="M635.771,1008.529c-3.266-3.812-2.93-6.964-2.832-9.9s-2.034-11.463-9.348-10.6a5.45,5.45,0,0,0-4.305.461c-4.057,2.1-11.87,17.829-9.947,24.321a3.542,3.542,0,0,0,3.184,2.755,7.75,7.75,0,0,0,2.672-.232c-.17-.33,1.151-.972,2.9-1.214,3-.415,7.25.349,7.356,5.91a32.969,32.969,0,0,0,3.631-.343,26.022,26.022,0,0,0,4.1-.929,13.63,13.63,0,0,0,2.16-.9C638.387,1016.242,639.965,1013.425,635.771,1008.529Z" transform="translate(-599.831 -987.785)" fill="#56130f" />
                <path id="Path_16206" data-name="Path 16206" d="M702.466,1087.8s3.651.833,4.735,4.182,2.417,15.381,2.534,20.715c.1,4.739.117,16.217.117,16.217a5.1,5.1,0,0,1-3.725,0l-4.613-23.492Z" transform="translate(-672.646 -1066.54)" fill={primaryColor} />
                <path id="Path_16207" data-name="Path 16207" d="M702.144,1170.906s-.794-13.089-.133-19.525c.529-5.148-5.917,9.9-5.478,12.629S702.144,1170.906,702.144,1170.906Z" transform="translate(-668.707 -1115.762)" fill="#eb9235" />
                <path id="Path_16208" data-name="Path 16208" d="M567.566,1526.18s-2.214,2.02-1.782,3.456,2.43,2.393,2.7,4.147,1.35,2.712,2.322,2.712h4.634s.716-1.234-1.012-1.607-2.379-2.434-2.271-4.348a33.724,33.724,0,0,0-.162-4.466l-3.672-.85Z" transform="translate(-565.728 -1410.965)" fill="#c62e45" />
                <path id="Path_16209" data-name="Path 16209" d="M661.374,1533.759s-.8,8.559.176,9.038l12.439-.025s0-1.436-2-1.755-5.508-1.489-6.1-4.094S661.374,1533.759,661.374,1533.759Z" transform="translate(-640.798 -1417.686)" fill="#c62e45" />
                <path id="Path_16210" data-name="Path 16210" d="M617.2,1095.047a49.524,49.524,0,0,0,0,5.749c.123,2.078,2.226,4.863,2.524,5.552.749,1.727,1.593,2.008,1.593,2.008l6.531,1.6,2.3.562,7.242-2.163s3.3-9.206,1.974-14.388a39.83,39.83,0,0,0-3.348-8.781s-4.909-1.179-13.6-.491Z" transform="translate(-606.192 -1063.923)" fill={primaryColor} />
                <path id="Path_16211" data-name="Path 16211" d="M626.5,1157.429a20.339,20.339,0,0,0,.612,4.7c.749,1.727,1.593,2.008,1.593,2.008l6.531,1.6,2.837-.833s-9.469-1.435-8.42-9.582Z" transform="translate(-613.578 -1119.707)" fill="#f0caa8" />
                <path id="Path_16212" data-name="Path 16212" d="M665.787,1203.361s3.586,11.163,3.816,14.963-6.855,53.989-7.14,55.74c-.269,1.65-2.319,2.7-2.319,2.7a3.1,3.1,0,0,1-2.288.177,5.661,5.661,0,0,1-2.457-1.158s-2.791-35.794-1.064-48.58a202.484,202.484,0,0,0,1.748-23.844Z" transform="translate(-635.054 -1157.53)" fill="#3b4046" />
                <path id="Path_16213" data-name="Path 16213" d="M590.874,1201.8s-5.47,7.339-5.98,12.3-.8,22.39-1.887,27.227-5.078,14.145-5.078,29.232a3.008,3.008,0,0,0,1.7,1.325,13.881,13.881,0,0,0,1.492.413,2.345,2.345,0,0,0,2.623-1.245c2.7-5.434,11.3-22.959,11.736-26.6.312-2.574,3.026-19.613,8.947-29.611,1.421-2.4-.872-11.946-.872-11.946Z" transform="translate(-575.335 -1156.298)" fill="#3b4046" />
                <path id="Path_16214" data-name="Path 16214" d="M651.715,1195.256a7.52,7.52,0,0,1-4.3,1.2c-3.416.069-11.881-.871-11.881-.871a7,7,0,0,0-.361,2.16s10.339,2.608,16.328.381A3.784,3.784,0,0,0,651.715,1195.256Z" transform="translate(-620.409 -1151.148)" fill="#c62e45" />
                <g id="Group_5074" data-name="Group 5074" transform="translate(26.268 44.719)">
                    <path id="Path_16215" data-name="Path 16215" d="M692.289,1199.241a1.743,1.743,0,0,0-.438-.691l-.032-.03a1.492,1.492,0,0,0-.372-.253,1.382,1.382,0,0,0-.6-.138,1.465,1.465,0,0,0-1.156.589,1.666,1.666,0,0,0-.289.523.181.181,0,0,0-.015.045,1.833,1.833,0,0,0-.1.553.519.519,0,0,0,0,.055,1.865,1.865,0,0,0,.533,1.329,1.444,1.444,0,0,0,1.025.436,1.392,1.392,0,0,0,.71-.2,1.588,1.588,0,0,0,.547-.53,1.943,1.943,0,0,0,.189-1.692Zm-1.446,1.9h-.015a.614.614,0,0,1-.062,0,1.178,1.178,0,0,1-.965-1.244v-.01a1.418,1.418,0,0,1,.155-.642,1.015,1.015,0,0,1,.094-.155,1.026,1.026,0,0,1,.527-.4.853.853,0,0,1,.266-.042.663.663,0,0,1,.113.009.8.8,0,0,1,.272.081c.025.013.051.025.074.04a1.134,1.134,0,0,1,.427.465,1.435,1.435,0,0,1,.155.653,1.317,1.317,0,0,1-.448,1.022,1,1,0,0,1-.3.177A.887.887,0,0,1,690.843,1201.142Z" transform="translate(-689.285 -1198.129)" fill="#fff" />
                </g>
                <rect id="Rectangle_2784" data-name="Rectangle 2784" width="14.045" height="19.225" transform="matrix(-0.754, -0.657, 0.657, -0.754, 14.587, 55.35)" fill="#6fa9c9" />
                <path id="Path_16216" data-name="Path 16216" d="M645.383,1204.913s4.146.365,4.133,1.286-1.315,2.527-1.928,2.527c-1.4,0-2.941-2.1-2.941-2.1Z" transform="translate(-627.869 -1158.752)" fill="#ef8f6a" />
                <path id="Path_16217" data-name="Path 16217" d="M594.145,1085.5s-5.181.363-7.168,5.192-5.755,12.935-5.755,14.3,2.105,5.568,13.41,7.985c0,0,1.169-1.23,1.1-2.2,0,0-7.057-6.062-7.038-6.77.036-1.34,1.157-3.109,7.353-9.307C597.731,1093.007,594.145,1085.5,594.145,1085.5Z" transform="translate(-577.929 -1064.723)" fill={primaryColor} />
                <path id="Path_16218" data-name="Path 16218" d="M651.034,1204.39a9.451,9.451,0,0,1,1.554-.215c.614,0,1.35-.124,1.355.084.008.384-1.227.515-1.227.69S651.034,1204.39,651.034,1204.39Z" transform="translate(-632.898 -1158.142)" fill="#ef8f6a" />
                <g id="Group_5075" data-name="Group 5075" transform="translate(17.813 3.757)">
                    <path id="Path_16219" data-name="Path 16219" d="M655.922,1061.731a21.271,21.271,0,0,0,1.482-6.321l.328.055,5.513.925s-.125,1.47-.129,2.854c0,.012,0,.023,0,.035a16.831,16.831,0,0,1-.225,3.285c-.109.672-2.15.919-4.458.572C657.609,1063.012,655.668,1062.209,655.922,1061.731Z" transform="translate(-654.543 -1044.79)" fill="#ef8f6a" />
                    <path id="Path_16220" data-name="Path 16220" d="M670.034,1056.593s-.125,1.47-.129,2.854a6.33,6.33,0,0,1-5.384-3.779Z" transform="translate(-661.331 -1044.993)" fill="#e27452" />
                    <path id="Path_16221" data-name="Path 16221" d="M667.942,1022.511s-7.3.12-7.3-4.427-.951-7.593,3.71-7.863,5.568,1.465,5.938,2.982S669.841,1022.363,667.942,1022.511Z" transform="translate(-658.209 -1009.187)" fill="#ef8f6a" />
                    <path id="Path_16222" data-name="Path 16222" d="M658.979,1006.379s-2,5.334-5.065,6.123-4.4-.033-4.4-.033a8.686,8.686,0,0,0,2.841-5.131S657.472,1003.84,658.979,1006.379Z" transform="translate(-649.516 -1005.455)" fill="#56130f" />
                    <path id="Path_16223" data-name="Path 16223" d="M691.7,1008.617a4.528,4.528,0,0,1,2.092,1.635,7.35,7.35,0,0,1,.987,4.174s1.506-3.626-.186-5.677C692.8,1006.575,691.7,1008.617,691.7,1008.617Z" transform="translate(-682.73 -1007.26)" fill="#56130f" />
                </g>
            </g>
            <path id="Path_16224" data-name="Path 16224" d="M657.62,1573.949v1.178H332.72a.506.506,0,0,1-.5-.5v-.172a.5.5,0,0,1,.5-.5Z" transform="translate(-332.216 -1364.652)" fill="#fff" />
            <g id="Group_5077" data-name="Group 5077" transform="translate(101.926 79.012)">
                <path id="Path_16225" data-name="Path 16225" d="M991.565,1546.171c-1.052-.642-.453-5.433-.453-5.433s4.9-3.922,5.576-.919,4.657,4.351,6.924,4.719a2.433,2.433,0,0,1,2.2,1.633Z" transform="translate(-952.822 -1415.9)" fill="#6fa9c9" />
                <path id="Path_16226" data-name="Path 16226" d="M891.341,1546.171c-1.052-.642-.454-5.433-.454-5.433s4.9-3.922,5.577-.919,4.657,4.351,6.925,4.719a2.433,2.433,0,0,1,2.2,1.633Z" transform="translate(-873.905 -1415.9)" fill="#6fa9c9" />
                <path id="Path_16227" data-name="Path 16227" d="M909.727,1242.158a53.606,53.606,0,0,0,1.832,11.369c1.4,6.765,13.514,52.879,14.681,53.318,1.946.734,6.909-.807,6.832-1.5-3.778-33.9-5.108-62.026-5.327-63.115C927.124,1241.753,912.072,1236.9,909.727,1242.158Z" transform="translate(-888.873 -1180.502)" fill="#3b4046" />
                <path id="Path_16228" data-name="Path 16228" d="M938.814,964.869s-3.311-3.612-4.334-3.732-5.237,1.9-5.478,2.695,4.635-.8,4.906,0,.572,1.339,1.956,1.4a5.844,5.844,0,0,1,2.589.843Z" transform="translate(-904.043 -961.132)" fill="#ef8f6a" />
                <path id="Path_16229" data-name="Path 16229" d="M918.284,1033.608l0,.851a2.858,2.858,0,0,1-2.5,3.049c-2.688.39-4.724-1.037-4.651-3.09a28.978,28.978,0,0,0,.526-4,11.2,11.2,0,0,0-.1-1.593l5.208-.127,1.534.305Z" transform="translate(-889.978 -1014.336)" fill="#ef8f6a" />
                <path id="Path_16230" data-name="Path 16230" d="M919.9,1029.006l-.015,3.424c-5.9-.441-6.653-3.017-6.728-3.6l5.208-.127Z" transform="translate(-891.578 -1014.336)" fill="#e27452" />
                <path id="Path_16231" data-name="Path 16231" d="M912.861,990.955s-8.13,1.643-9.074-3.436-2.631-8.287,2.52-9.55,6.523.487,7.251,2.105S914.952,990.4,912.861,990.955Z" transform="translate(-883.379 -974.048)" fill="#ef8f6a" />
                <path id="Path_16232" data-name="Path 16232" d="M897.035,1216.5c-8.849,40.3-9.983,68.39-9.983,68.39s-2.653.775-7.942.116c0,0-1.211-17.852-1.828-31.225-.181-3.92-2.028-27.068.518-38.29C878.826,1210.968,898.04,1211.924,897.035,1216.5Z" transform="translate(-862.683 -1159.094)" fill="#3b4046" />
                <path id="Path_16233" data-name="Path 16233" d="M893,1093.8s-2.627,2.392-9.568,2.348a76.987,76.987,0,0,1-12.9-1.59c-3.551-.735-2.986-2.02-2.7-4.649.37-3.369.518-9.477-1.378-19.83q-.419-2.292-.978-4.865c-1.595-7.307,7.593-11.541,8.777-13.265,6.748,6.189,8.632.448,8.632.448h0a10.188,10.188,0,0,0,6.058-1.256c2.733-1.635,2.5,8.819,2.506,12.194,0,3.625.757,12.563.862,16.5C892.449,1084.951,893,1093.8,893,1093.8Z" transform="translate(-853.885 -1031.872)" fill={primaryColor} />
                <path id="Path_16234" data-name="Path 16234" d="M979.034,981.912a8.408,8.408,0,0,1-.237.92s0,0,0,0c-2,6.342-10.335,13.6-11.442,15.969a22.816,22.816,0,0,0-.9,5.361l-6.109-13.785s8.8-6.61,9.164-7.547c.56-1.42-8.044-9.224-8.044-9.224a2.14,2.14,0,0,1,.1-1.915,2.3,2.3,0,0,1,1.1-1.246S979.64,978.791,979.034,981.912Z" transform="translate(-928.731 -968.468)" fill={primaryColor} />
                <path id="Path_16235" data-name="Path 16235" d="M869.8,1139.448c-.624-4.265-.652-5.233-2.187-15.584.578.117,2.587-.858,3.564.8C874.013,1129.49,874.94,1133.181,869.8,1139.448Z" transform="translate(-855.712 -1089.126)" fill="#eb9235" />
                <path id="Path_16236" data-name="Path 16236" d="M866.447,1199.165s4.22-2.551,5.528-2.228,4.146,1.556,3.97,2.311-3.472,1.269-4.046,1.18a2.822,2.822,0,0,0-1.875.34c-.376.324-3.366.445-3.731-.43S866.447,1199.165,866.447,1199.165Z" transform="translate(-854.57 -1146.783)" fill="#ef8f6a" />
                <path id="Path_16237" data-name="Path 16237" d="M832,1055.588s-6.451,1.26-8.962,3.336c-2.565,2.121-11.975,14.669-11.38,19.017.6,4.412,11.068,11.24,11.068,11.24s2.054.683,2.126-2.588c0,0-5.521-8.161-5.23-9.106.183-.594,8.32-7.121,8.32-7.121Z" transform="translate(-811.635 -1035.507)" fill={primaryColor} />
                <path id="Path_16238" data-name="Path 16238" d="M945.8,1105.682a4.435,4.435,0,0,1-1.26,2.936l-1.211,1.239a.747.747,0,0,1-1.1-.031l-1.681-1.919a2.66,2.66,0,0,1-.656-1.866c.068-1.522.19-4.455.263-7.283.06-2.291.087-4.516.028-5.865-.19-4.308-1.762-17.995-1.762-17.995h1.035a55.38,55.38,0,0,1,4.564,12.379,48.015,48.015,0,0,1,1.009,5.616A104.793,104.793,0,0,1,945.8,1105.682Z" transform="translate(-911.468 -1050.711)" fill="#c62e45" />
                <path id="Path_16239" data-name="Path 16239" d="M931.2,1067.313l2.876-.689a.435.435,0,0,1,.365.5,3.659,3.659,0,0,1-1.164,1.947C932.924,1069.182,930.521,1068.261,931.2,1067.313Z" transform="translate(-905.687 -1044.197)" fill="#c62e45" />
                <path id="Path_16240" data-name="Path 16240" d="M901.869,1051.861c-.022-.448,2.14-1.165,2.14-1.165a6.568,6.568,0,0,0,5.63,3.7s-1.141,2.221-2.485,2.8C907.154,1057.194,902,1054.523,901.869,1051.861Z" transform="translate(-882.686 -1031.655)" fill="#eb9235" />
                <path id="Path_16241" data-name="Path 16241" d="M938.421,1055.254a8.96,8.96,0,0,0,1.353-3.472,9.4,9.4,0,0,1,2.745,1.343,8.763,8.763,0,0,1-1.191,3.937A11.05,11.05,0,0,0,938.421,1055.254Z" transform="translate(-911.467 -1032.51)" fill="#eb9235" />
                <path id="Path_16242" data-name="Path 16242" d="M890.274,974.754c1.238,4.162,4.1,4.741,4.1,4.741a5.707,5.707,0,0,1-.376-1.747,1.72,1.72,0,0,1,.093-.614,2.647,2.647,0,0,0-.035-1.624c-.122-.358-.12-.7.177-.871.476-.272,1.054.225,1.415.627a.9.9,0,0,0,.948.257l.007,0c1.4-.466.125-3.654,1.657-3.587,5.065.221,5.516-.567,6.338-1.412.594-.61.347-2.693-.474-2.919a15.474,15.474,0,0,1-3.4-1.344,7.3,7.3,0,0,0-7.166.008C890.219,967.584,889.428,971.911,890.274,974.754Z" transform="translate(-873.308 -964.431)" fill="#56130f" />
            </g>
            <g id="Group_5079" data-name="Group 5079" transform="translate(192.231 0)">
                <path id="Path_16243" data-name="Path 16243" d="M1239.688,709.167s5.286,2,6.354,3.616,1.3,6.272,2.963,7.5c.518.383,7.011,3.506,8.347,3.186.9-.216,1.035-2.821-1.119-4.191-2.181-1.387-4.428-5.207-5.127-7.036-1.193-3.121-2.62-11.071-1.577-9.975l6-4.378-7.476.635Z" transform="translate(-1234.401 -660.725)" fill="#e27452" />
                <path id="Path_16244" data-name="Path 16244" d="M1255.385,737.811s3.259,7.787,5.3,10.353c.817,1.03,3.814,4.151,5.113,3.988,2.144-.269,1.506-3.951-1.046-7-.7-.833-2.194-7.415-2.567-10.584-.926-7.878-1.48-12.622-1.48-12.622Z" transform="translate(-1247.435 -680.705)" fill="#e27452" />
                <g id="Group_5078" data-name="Group 5078" transform="translate(0)">
                    <path id="Path_16245" data-name="Path 16245" d="M1257.807,494.865c-1.121,1.76-2.161,3.416-3.058,4.872-.5.812-.954,1.562-1.351,2.232-.641,1.081-1.132,1.951-1.423,2.539a37.785,37.785,0,0,0-2.852,5.829v0s0,.007-.005.011a7.7,7.7,0,0,0-.092,7.381c1.566,3.716.936,10.683-.884,18.264a47.178,47.178,0,0,0-.915,11.5c.034.672.223,2.175.379,3.787.219,2.246.374,4.7-.046,5.418a1.128,1.128,0,0,1-.86.372,5.964,5.964,0,0,1-3.5-.764c-2.2-1.659-3.092-5.455-3.783-11.829-.656-6.073-2.251-8.492-4.163-10.374a6.014,6.014,0,0,0-5.294-2.017,18.487,18.487,0,0,0-2.517.311,10.394,10.394,0,0,0-3.9,1.608,7.379,7.379,0,0,0-1.236,1.1,7.554,7.554,0,0,0-1.811,3.948c-.009.054-.018.107-.029.161v0h0a12.74,12.74,0,0,1-.531,1.946c-.451,1.279-1.056,2.533-1.575,3.754a12.488,12.488,0,0,0-1.053,3.51h0a4.908,4.908,0,0,0,.179,1.989,28.389,28.389,0,0,1,.76,7.178h0a31.024,31.024,0,0,0,.7,6.007c1.177,5.017-1.1,6.778-3.115,6.113l-.076-.026c-.629-.229-1.867-2.638-2.869-4.861-.751-1.664-1.37-3.224-1.5-3.691-.418-1.453-.878-7.767-2.57-13.673-1.262-4.405,1.933-13.586,2.3-23.735.184-5.05,8.07-10.455,14.269-20.091.334-.517.758-.972,1.127-1.465h0q1.583-2.115,3-4.344a65.342,65.342,0,0,1,6.1-2.78q2.115-.837,4.309-1.549h0q3.483-1.13,7.143-1.952A78.487,78.487,0,0,1,1257.807,494.865Z" transform="translate(-1208.518 -489.384)" fill="#ef8f6a" />
                    <path id="Path_16246" data-name="Path 16246" d="M1343.556,509.655l-1.289,2.229-4.738,8.191-.463-.166-22.634-8.117.024-.058h0l1.47-3.519q.756-.423,1.526-.824a65.418,65.418,0,0,1,6.1-2.78q2.115-.837,4.31-1.549h0l14.832,6.236Z" transform="translate(-1296.467 -498.942)" fill="#fff" />
                    <path id="Path_16247" data-name="Path 16247" d="M1395.109,478.787l-4.087,6.823-3.749,6.256-.348-.136-.1-.038-.82-.32h0l-17.792-6.919q2.115-.837,4.31-1.549h0q3.483-1.13,7.143-1.952a94.586,94.586,0,0,1,10.652-1.755Q1392.694,478.938,1395.109,478.787Z" transform="translate(-1341.124 -478.787)" fill="#3b4046" />
                    <path id="Path_16248" data-name="Path 16248" d="M1446.047,569.157a.927.927,0,1,1,.851,1A.927.927,0,0,1,1446.047,569.157Z" transform="translate(-1405.755 -553.12)" fill="#6fa9c9" />
                </g>
            </g>
            <g id="Group_5081" data-name="Group 5081" transform="translate(277.274 82.428)">
                <path id="Path_16249" data-name="Path 16249" d="M1637.988,1271.418s-.862,3.137-.2,4.41,1.922,2.367,2.53,1.645-.576-5.321-.576-5.321Z" transform="translate(-1637.301 -1208.866)" fill="#ef8f6a" />
                <path id="Path_16250" data-name="Path 16250" d="M1650.22,977.218a10.576,10.576,0,0,1,6.246,1.565c2.936,1.914,2.618,6.85,5.163,8.02s6.684,4.962,6.282,8.476-1.615,4.635,0,6.736-1.228,7.321-5.926,5.727-19.342-6.48-20.809-9.248-.712-6,.771-8.643S1640.19,977.785,1650.22,977.218Z" transform="translate(-1639.547 -977.193)" fill="#56130f" />
                <path id="Path_16251" data-name="Path 16251" d="M1650.666,1229.942c2.347,15.113,4.262,31.4,3.352,39.96-.726,2.4-2.934,3.183-4.884,3.484l1.331,1.467,3.769.235,5.042-1.957a20.228,20.228,0,0,1-.508-2.73c-.257-2.016.7-6.616.7-6.616s2.537-15.889,1.159-19.468a14.611,14.611,0,0,1-.8-3.441h0a57.76,57.76,0,0,1,.146-10.826c.314-4.076.676-8.023.305-10.876a205.949,205.949,0,0,1-1.653-24.273l-9.873-.052s-2.84,7.933-1.571,14.754C1648.258,1215.4,1649.514,1222.533,1650.666,1229.942Z" transform="translate(-1644.638 -1148.577)" fill="#e27452" />
                <path id="Path_16252" data-name="Path 16252" d="M1641.355,1077.623a5.175,5.175,0,0,0-3.2,3.078c-1.04,2.532-1.5,21.866-1.7,23.949s.45,15.079.45,15.079a3.092,3.092,0,0,0,2.66-.334c4.128-3.158,2.238-20.669,2.238-20.669Z" transform="translate(-1636.428 -1056.272)" fill={primaryColor} />
                <path id="Path_16253" data-name="Path 16253" d="M1643.58,1158.712s-1.114-15.2-.5-19.142c.216-1.394,2.714,1.026,3,4.593S1643.58,1158.712,1643.58,1158.712Z" transform="translate(-1641.519 -1104.734)" fill="#eb9235" />
                <path id="Path_16254" data-name="Path 16254" d="M1652.632,1565.578c-2.2-.284-1.619.28-4.7.282s-4.127-1.029-4.271-1.471c-.128-.392,1.36-.7,2.951-1.067l.056.041a8.475,8.475,0,0,0,4.469.857c2.069-.18,4.189-1.015,5.617-1.151a5.417,5.417,0,0,1,.127,1.431C1656.765,1565.873,1654.827,1565.862,1652.632,1565.578Z" transform="translate(-1642.119 -1438.514)" fill="#c62e45" />
                <path id="Path_16255" data-name="Path 16255" d="M1683.519,1228.829c2.347,15.113,6.062,33.046,5.152,41.61-.726,2.4-2.934,3.183-4.884,3.484l1.331,1.468,3.77.235,5.042-1.956a20.227,20.227,0,0,1-.508-2.73c-.257-2.016.7-6.615.7-6.615s2.537-15.89,1.159-19.468a14.584,14.584,0,0,1-.8-3.441h0a57.7,57.7,0,0,1,.146-10.826c.314-4.076.676-8.023.305-10.876a206.025,206.025,0,0,1-1.653-24.273l-9.873-.051s-2.839,7.933-1.571,14.754C1682.912,1215.941,1682.368,1221.42,1683.519,1228.829Z" transform="translate(-1671.925 -1148.999)" fill="#ef8f6a" />
                <path id="Path_16256" data-name="Path 16256" d="M1687.285,1566.114c-2.2-.284-1.619.281-4.7.283s-4.127-1.029-4.271-1.471c-.128-.392,1.36-.7,2.951-1.066l.057.04a8.482,8.482,0,0,0,4.469.857c2.069-.18,4.189-1.015,5.617-1.151a5.416,5.416,0,0,1,.127,1.43C1691.418,1566.408,1689.481,1566.4,1687.285,1566.114Z" transform="translate(-1669.405 -1438.936)" fill="#c62e45" />
                <path id="Path_16257" data-name="Path 16257" d="M1664.664,1083.963a50.5,50.5,0,0,1-.03,5.851c-.136,2.115-2.291,4.937-2.6,5.636-.771,1.754-1.632,2.035-1.632,2.035l-6.655,1.595-2.34.56-7.358-2.239s-3.308-9.386-1.932-14.653a40.512,40.512,0,0,1,3.453-8.918s8.485-2.159,15.038-.208Z" transform="translate(-1640.646 -1052.477)" fill={primaryColor} />
                <path id="Path_16258" data-name="Path 16258" d="M1645.006,1212.374c.767,9.978,1.686,18.384,1.686,18.384a24.594,24.594,0,0,0,11.213,3.591h0a21.786,21.786,0,0,0,9.977-1.673s1.48-24.079.638-27.92-4.632-9.14-4.632-9.14l-17.176.574S1644.239,1202.394,1645.006,1212.374Z" transform="translate(-1643.066 -1149.18)" fill="#6fa9c9" />
                <path id="Path_16259" data-name="Path 16259" d="M1651.129,1186.936a7.65,7.65,0,0,0,4.369,1.24c3.476.088,12.1-.823,12.1-.823a7.115,7.115,0,0,1,.356,2.2s-10.535,2.6-16.619.3A3.85,3.85,0,0,1,1651.129,1186.936Z" transform="translate(-1647.836 -1142.345)" fill="#0b1a3b" />
                <g id="Group_5080" data-name="Group 5080" transform="translate(5.236 45.232)">
                    <path id="Path_16260" data-name="Path 16260" d="M1664.219,1191.569a1.805,1.805,0,0,0-.085-.434c-.012-.04-.027-.083-.042-.123a1.914,1.914,0,0,0-.264-.459,1.506,1.506,0,0,0-1.175-.6,1.469,1.469,0,0,0-1,.391c-.011.011-.021.021-.032.03a1.851,1.851,0,0,0-.151.164,1.816,1.816,0,0,0-.272.463,1.906,1.906,0,0,0-.142.678.557.557,0,0,0,0,.062,1.948,1.948,0,0,0,.3,1.058,1.785,1.785,0,0,0,.236.294,1.446,1.446,0,0,0,2.088.01,1.893,1.893,0,0,0,.548-1.346C1664.228,1191.692,1664.225,1191.63,1664.219,1191.569Zm-1.492,1.441c-.032,0-.062,0-.091,0a.836.836,0,0,1-.3-.055,1.262,1.262,0,0,1-.755-1.22.562.562,0,0,1,0-.081,1.4,1.4,0,0,1,.2-.657,1.094,1.094,0,0,1,.474-.438.894.894,0,0,1,.274-.081,1.044,1.044,0,0,1,.115-.006.832.832,0,0,1,.272.045,1.055,1.055,0,0,1,.533.406.524.524,0,0,1,.051.083,1.375,1.375,0,0,1,.189.578,1.408,1.408,0,0,1,.008.161A1.206,1.206,0,0,1,1662.727,1193.01Z" transform="translate(-1661.056 -1189.949)" fill="#fff" />
                </g>
                <rect id="Rectangle_2785" data-name="Rectangle 2785" width="14.293" height="19.565" transform="translate(7.476 41.299) rotate(-40.749)" fill="#c62e45" />
                <path id="Path_16261" data-name="Path 16261" d="M1703.276,1197.106s-3.952.442-3.945,1.379.969,2.869,1.457,2.957,3.228-2.588,3.228-2.588Z" transform="translate(-1685.958 -1150.353)" fill="#ef8f6a" />
                <path id="Path_16262" data-name="Path 16262" d="M1715.716,1075.614s5.271.4,7.266,5.321,5.788,13.194,5.781,14.582-2.172,5.655-13.69,8.055c0,0-1.183-1.258-1.106-2.248,0,0,7.214-6.131,7.2-6.852-.029-1.364-1.161-3.17-7.433-9.51C1712.027,1083.238,1715.716,1075.614,1715.716,1075.614Z" transform="translate(-1696.943 -1054.69)" fill={primaryColor} />
                <path id="Path_16263" data-name="Path 16263" d="M1678.855,1046.962a3.178,3.178,0,0,1-2.31-2.03,16.2,16.2,0,0,1-.76-5.377l-.334.064-5.616,1.082s.119,1.766.114,3.43v.042a3.227,3.227,0,0,1-2.345,3.108c-.71.2,3.35,5.6,3.818,5.5C1672.4,1052.566,1679.194,1047.039,1678.855,1046.962Z" transform="translate(-1660.91 -1026.297)" fill="#ef8f6a" />
                <path id="Path_16264" data-name="Path 16264" d="M1678.407,1040.938s.118,1.767.114,3.43c3.14-.072,4.814-2.927,5.5-4.511Z" transform="translate(-1669.482 -1026.534)" fill="#e27452" />
                <path id="Path_16265" data-name="Path 16265" d="M1664.434,1005.956s7.427.161,7.457-4.466,1.007-7.722-3.733-8.021-5.674,1.461-6.059,3S1662.5,1005.795,1664.434,1005.956Z" transform="translate(-1656.567 -989.983)" fill="#ef8f6a" />
                <path id="Path_16266" data-name="Path 16266" d="M1675.04,989.529s2.006,5.439,5.123,6.258,4.475-.01,4.475-.01a8.838,8.838,0,0,1-2.864-5.237S1676.587,986.953,1675.04,989.529Z" transform="translate(-1666.831 -986.173)" fill="#56130f" />
                <path id="Path_16267" data-name="Path 16267" d="M1663.416,991.758a4.606,4.606,0,0,0-2.137,1.653,7.482,7.482,0,0,0-1.027,4.243s-1.512-3.7.22-5.776C1662.31,989.674,1663.416,991.758,1663.416,991.758Z" transform="translate(-1654.706 -987.959)" fill="#56130f" />
                <path id="Path_16268" data-name="Path 16268" d="M1685.871,1073.864s5.052-5.341,5.252-7.533a7.311,7.311,0,0,1,2.778,1.836s-1.223,3.019-2.67,4.009a9.15,9.15,0,0,1-1.873-.5,16.033,16.033,0,0,0,.933,1.395A7.152,7.152,0,0,1,1685.871,1073.864Z" transform="translate(-1675.36 -1047.38)" fill="#eb9235" />
                <path id="Path_16269" data-name="Path 16269" d="M1668.941,1067.126s-2,3.611,1.722,7.364a6.4,6.4,0,0,1-3.545-2.124,3.41,3.41,0,0,1,.7-1.091,1.2,1.2,0,0,0-1.149.125,7.776,7.776,0,0,1,.013-2.534A4.142,4.142,0,0,1,1668.941,1067.126Z" transform="translate(-1660.152 -1048.006)" fill="#eb9235" />
                <path id="Path_16270" data-name="Path 16270" d="M1684.86,1100.381a.374.374,0,1,0-.376.372A.374.374,0,0,0,1684.86,1100.381Z" transform="translate(-1673.974 -1073.895)" fill="#c62e45" />
                <path id="Path_16271" data-name="Path 16271" d="M1675.342,1125.287a.374.374,0,1,0-.376.372A.375.375,0,0,0,1675.342,1125.287Z" transform="translate(-1666.48 -1093.506)" fill="#c62e45" />
                <path id="Path_16272" data-name="Path 16272" d="M1673.427,1154.545a.374.374,0,1,0-.376.372A.374.374,0,0,0,1673.427,1154.545Z" transform="translate(-1664.972 -1116.544)" fill="#c62e45" />
            </g>
        </svg>

    )
}