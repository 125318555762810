import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

/**
 * Custom hook to warn users about unsaved changes when attempting to navigate away from the current page.
 * This hook handles both browser/tab navigation (like refresh or close) and in-app route changes.
 *
 * @param {boolean} unsavedChanges - A boolean indicating if there are unsaved changes.
 * @param {string} message - A custom warning message to show when navigating away with unsaved changes.
 */
const useUnsavedChangesWarning = (
  unsavedChanges,
  message = "You have unsaved changes. Are you sure you want to leave?"
) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const previousPathRef = useRef(currentPath);
  const unsavedRef = useRef(unsavedChanges);

  // Keep the latest unsavedChanges value in a ref
  useEffect(() => {
    unsavedRef.current = unsavedChanges;
  }, [unsavedChanges]);

  // Handle browser refresh or tab close
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedRef.current) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [message]);

  // Handle in-app navigation and browser history events
  useEffect(() => {
    const handlePopState = () => {
      const nextPath = window.location.pathname;
      if (unsavedRef.current && nextPath !== currentPath) {
        const confirmLeave = window.confirm(message);
        if (!confirmLeave) {
          window.history.pushState(null, "", previousPathRef.current);
        } else {
          previousPathRef.current = nextPath;
        }
      }
    };

    window.addEventListener("popstate", handlePopState);

    // Save original methods
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    // Override pushState
    window.history.pushState = function (...args) {
      const nextPath = typeof args[2] === "string" ? args[2] : window.location.pathname;
      if (unsavedRef.current && nextPath !== currentPath) {
        const confirmLeave = window.confirm(message);
        if (confirmLeave) {
          previousPathRef.current = nextPath;
          return originalPushState.apply(this, args);
        }
      } else {
        return originalPushState.apply(this, args);
      }
    };

   // Override `replaceState` to handle in-app redirects.
    window.history.replaceState = function (...args) {
      const nextPath = typeof args[2] === "string" ? args[2] : window.location.pathname;
      if (unsavedRef.current && nextPath !== currentPath) {
        const confirmLeave = window.confirm(message);
        if (confirmLeave) {
          previousPathRef.current = nextPath;
          return originalReplaceState.apply(this, args);
        }
      } else {
        return originalReplaceState.apply(this, args);
      }
    };

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [message, currentPath]);
};

export default useUnsavedChangesWarning;
