import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PostingsComponent from "../PostingsComponent";
import { getUserDetails } from '../../../_redux/CognitoSlice'
import * as _ser from "../../../_redux/_services/JobPostingSlice";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";

const initalState = { data: null, message: '', isloader: true }

const PostingsCreate = ({isJobDetails = false, onReceiveData, onResetValue}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initData = JSON.parse(JSON.stringify(initialDetails));
  const [disableSubmitBtn, setDisableSubmitBtn] = React.useState(false);
  const [GetUserId, setGetUserId] = useState(null);
  const Addjobpostingscode = useSelector((state) => state.Addjobpostings.data);

  useEffect(() => {
    if(GetUserId === null){
      setGetUserId(JSON.parse(decrypt('GetUserId')))
    }
  }, [GetUserId])

  const GetValidData = async (data) => {
    const userInfo = await getUserDetails();
    let emails = data.listofRecruitersemails;
    let formData = new FormData();
    formData.append("jobTitle", data.jobTitle);
    formData.append("externalSystemID", data.externalSystemID);
    formData.append("workModel", data.workModel);
    formData.append("jobStatus", data.jobStatus);
    formData.append("jobType", data.jobType);
    formData.append("clientId", data.clientId);
    formData.append("clientBillRate", data.clientBillRate);
    formData.append("payBillRate", data.payBillRate);
    formData.append("location", data.location);
    formData.append("manager", data.manager);
    formData.append("teamLead", data.teamLead);
    formData.append("recruiters", data.recruiters);
    emails.forEach(item => {
      formData.append(Object.keys(item)[0], JSON.stringify(item[Object.keys(item)]));
    })
    formData.append("priority", data.priority);
    formData.append("jobDesc", data.jobDesc);
    formData.append("primarySkills", data.primarySkills);
    formData.append("secondarySkills", data.secondarySkills);
    formData.append("jobDescForSM", data.jobDescForSM);
    formData.append("customFields", data.customFields);
    formData.append("userId", userInfo.userId);
    formData.append("userFirstName", userInfo.firstName);
    formData.append("userLastName", userInfo.lastName);
    formData.append("customerId", GetUserId);
    formData.append("ATSPortal", 1);
    formData.append("VSPortal", 0);
    formData.append("CSPortal", 0);
    formData.append("IPortal", 0);
    formData.append("EPortal", 0);
    formData.append("JBPortal", 0);
    formData.append("source", 1);
    if(isJobDetails === false){
      dispatch(_ser.addjobpostingsAsync(formData, true));
      setDisableSubmitBtn(false)
    } else if(isJobDetails === true){
      let obj = {
        jobTitle : data.jobTitle,
        externalSystemID : data.externalSystemID,
        workModel : data.workModel,
        jobStatus : data.jobStatus,
        jobType : data.jobType,
        clientId : data.clientId,
        clientBillRate : data.clientBillRate,
        payBillRate : data.payBillRate,
        location : data.location,
        manager : data.manager,
        teamLead : data.teamLead,
        recruiters : data.recruiters,
        priority : data.priority,
        jobDesc : data.jobDesc,
        primarySkills : data.primarySkills,
        secondarySkills : data.secondarySkills,
        jobDescForSM : data.jobDescForSM,
        customFields : data.customFields,
        userId : userInfo.userId,
        userFirstName : userInfo.firstName,
        userLastName : userInfo.lastName,
        customerId : GetUserId,
        ATSPortal : 1,
        VSPortal : 0,
        CSPortal : 0,
        IPortal : 0,
        EPortal : 0,
        JBPortal:0,
        source : 1,
        listofRecruitersemails: data.listofRecruitersemails
      }
      // let emails = JSON.parse(data.recruitersemails);
      // emails.forEach((item, index) => {
      //   obj["recruitersemails"+"["+[index]+"]"] = item;
      // })
      onReceiveData(obj, false, "jobdetails");
    }
  }
  
  useEffect(()=>{
    const url = window.location.pathname;
    const path = url.substring(url.lastIndexOf("/") + 1);
    if(Addjobpostingscode === 200 && isJobDetails === false && path !== "jobdetails"){
      setDisableSubmitBtn(false)
      // var params = { limit: 0, offset: 0 }
      dispatch(_ser.addjobpostingsStartReducer(initalState))
      // dispatch(_ser.listjobpostingsAsync(params, "list"));
      navigate("/ats/layout/jobpostings/landing");
    }
  }, [Addjobpostingscode])

  return (
    <React.Fragment>
      <Box style={{ margin: "0 3.3rem 0 2rem" }}
        component="form"
        sx={{ bgcolor: "transparent", height: "100%", "& > :not(style)": { m: 2, width: "29ch" } }}
        noValidate
        autoComplete="off" >
        <div id="jpn_breadcrumb" style={{ display: "flex", gap: "1rem", width: "100%", marginBottom: "40px" }} >
          <img src={process.env.PUBLIC_URL + "/assests/img/applicant.svg"} alt="" />
          <div style={{ display: "flex" }}>
            <h4> Job Posting / {" "}
              <span style={{ fontWeight: "normal" }}>New Create Job</span>
            </h4>
          </div>
        </div>
        {initData &&
          <PostingsComponent componentType="Create" input={initData} iniValue={initData} onResetValue={onResetValue}
            actions={actionBtns} onValidData={GetValidData} isJobDetails={isJobDetails} disableSubmitBtn={disableSubmitBtn} />
        }
      </Box>
    </React.Fragment>
  );
};

export default PostingsCreate;

const initialDetails = {
  jobCode: '',
  jobTitle: '',
  externalSystemID:'',
  workModel: '',
  jobStatus: '',
  jobType: '',
  clientId: '',
  clientName: null,
  clientBillRate: '',
  payBillRate: '',
  location: '',
  manager: '',
  teamLead: '',
  recruiters: [],
  priority: '',
  jobDesc: '',
  primarySkills: '',
  secondarySkills: '',
  jobDescForSM: '',
  customerId: 0,
}

const actionBtns = [
  {
    variant: "text",
    style: { color: "red", marginRight: "25px", fontWeight: "bolder", hover: "textbold" },
    navigateTo: "/ats/layout/jobpostings/landing",
    label: "Cancel",
    type: "back",
    class: 'buttonHover',

  },
  {
    variant: "contained",
    style: { backgroundColor: "var(--primary-bg-color)", color: "var(--primary-background-text-color)", borderRadius: "25px", width: "6rem", fontWeight: "" },
    navigateTo: null,
    label: "Save",
    type: "submit",
    class: "buttonHover",
  }
]