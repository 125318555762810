
export const Editing = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg id="editing" xmlns="http://www.w3.org/2000/svg" width="22.627" height="22.627" viewBox="0 0 22.627 22.627">
    <g id="Group_50" data-name="Group 50" transform="translate(0 3.624)">
      <path id="Path_13" data-name="Path 13" d="M12.929,92.762a2,2,0,0,1-.777.48l-2.813.937a1.984,1.984,0,0,1-.629.1h0a1.989,1.989,0,0,1-1.887-2.618l.938-2.812a2,2,0,0,1,.48-.777L14.316,82H2.431A2.433,2.433,0,0,0,0,84.431V98.572A2.433,2.433,0,0,0,2.431,101H16.573A2.433,2.433,0,0,0,19,98.572V86.687Z" transform="translate(0 -82)" fill={primaryFontColor}/>
    </g>
    <path id="Path_14" data-name="Path 14" d="M183.217,61.566a.663.663,0,0,0-.16.259l-.938,2.812a.663.663,0,0,0,.838.838l2.812-.937a.662.662,0,0,0,.259-.16l8.281-8.281L191.5,53.285Z" transform="translate(-174.039 -50.93)" fill={primaryFontColor}/>
    <path id="Path_15" data-name="Path 15" d="M418.945.323a1.1,1.1,0,0,0-1.562,0l-1.094,1.094L419.1,4.23,420.2,3.136a1.1,1.1,0,0,0,0-1.562Z" transform="translate(-397.892 0)" fill={primaryFontColor}/>
  </svg>
  



  
  )
}

