import React, { useState } from "react";
import { Card, CardContent, Switch } from "@mui/material";
import {
  BarPlot,
  BarElement,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import "../ApplicantsNewDashboard.css";
import CardTitle from "../CardTitle/CardTitle";
const barChartColors = [
  "#9c9494",
  "#8ba3bc",
  "#9fbb7d",
  "#dcc882",
  "#e76f51",
  "#2a9d8f",
  "#c88d68",
  "#6e6a52",
  "#5c6c74",
  "#6c5444",
  "#c9c9c9",
];

const BarCustomElement = (props) => {
  const { data, ...other } = props;
  const color = barChartColors[other.ownerState.dataIndex];
  return (
    <BarElement
      {...other}
      style={{
        ...props.style,
        fill: color,
      }}
      id={other.ownerState.id}
      dataIndex={other.ownerState.dataIndex}
    />
  );
};

const BarChartWidgetCard = ({
  card,
  showToggle = false,
  switchChange,
  editMode,
  handleSaveCardTitle,
  handleDeleteCard,
  handleEditCard,
  handleCardTitle,
  cardTitleChangeId,
}) => {
  const data = card.data;
  const [toggle, switchToggle] = useState(false);

  return (
    <Card className="dashboard__card">
      <CardTitle
        card={card}
        editMode={editMode}
        handleSaveCardTitle={handleSaveCardTitle}
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        handleCardTitle={handleCardTitle}
        cardTitleChangeId={cardTitleChangeId}
      />
      <CardContent style={{ position: "relative" }}>
        {showToggle && (
          <div style={{ textAlign: "right" }}>
            <div className="switch-container" id="dashboardBtn">
              <span className="text">Individual</span>
              <Switch
                className="activeBtns"
                checked={toggle}
                name="pieChartIndividualORTeam"
                onChange={() => {
                  const value = !toggle;
                  switchToggle(value);
                  switchChange(value);
                }}
              />
              <span className="text">Team</span>
            </div>
          </div>
        )}
        {data && data[0]?.length > 0 ? (
          <ResponsiveChartContainer
            series={[{ type: "bar", data: data[0] || [] }]}
            xAxis={[{ scaleType: "band", data: data[1] || [] }]}
            height={300}
            key={`barchart-${card.i}`}
          >
            <BarPlot
              skipAnimation
              slots={{ bar: BarCustomElement }}
              slotProps={{
                bar: { data: data[0] || [] },
              }}
            />
            <ChartsXAxis />
            <ChartsYAxis position="left" />
            <ChartsTooltip />
          </ResponsiveChartContainer>
        ) : (
          <div className="dashboard__card-no-data-container">
            No data available
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default  React.memo(BarChartWidgetCard);
