import React, { useState, useEffect } from "react";
import JobboardPortalComponent from "./JobboardPortalComponent";
import { useDispatch } from "react-redux";
import GetJobId from "../../../_utilities/GetJobId";
import { updateLoader } from "../../../_redux/LoaderSlice";
import { updateSnackbar } from "../../../_redux/SnackbarSlice";
import { useLocation } from "react-router-dom";
import { decrypt } from "../../../_utilities/_encryptDecryptHelper";

const JobboardPortalView = () => {
  const location = useLocation();

  const [initalData, setInitalData] = useState({
    logo: process.env.PUBLIC_URL + "/assests/nopreview.png",
    preview: "nopreview.png",
    isJobPortal: false,
    primaryData: initialPrimary,
    typography: initialTypography,
    url: process.env.REACT_APP_JOBBOARD_PORTAL_URL
  })

  const [ selectedCustomer, setSelectedCustomer ] = useState("");

  const [Data, setData] = useState(null);

  let getJobCode = GetJobId();

   const getUserId = JSON.parse(decrypt("GetUserInfo"));


  const initialSnackbar = { type: "", message: "" };

  const dispatch = useDispatch();

  const actionBtns = [
    {
      variant: "contained",
      label: "Edit",
      type: "back",
      style: { backgroundColor: "var(--primary-bg-color)", color: "var(--primary-background-text-color)", borderRadius: "10px", width: "6rem", textTransform: "capitalize", fontSize: "14px" },
      class: "buttonHoverjobBoard",
      navigateTo: `/ats/layout/customer/jobboardportal/edit`,
    },
  ];

  useEffect(() => {
    const url = location.pathname;
    const path = url.substring(url.lastIndexOf("/") + 1);
    if(Number.isInteger(parseInt(path))){
      let newObj = JSON.parse(JSON.stringify(initalData))
      newObj.url = newObj.url + getUserId[0].id + "/layout/jobfeed";
      setInitalData(newObj);
    }
    // if (getUserId !== null) {
    //   getData(getUserId[0].id);
    // }
  }, [location])

  useEffect(() => {
    let newObj = JSON.parse(JSON.stringify(initalData))
      newObj.url = newObj.url + getUserId[0].id + "/layout/jobfeed";
      setInitalData(newObj);
    if (getUserId !== null) {
      getData(getUserId[0].id);
    }
  }, []);


  const getData = async (id) => {
    dispatch(updateLoader(true));
    dispatch(updateSnackbar(initialSnackbar));
    const res = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customers/jobBoard`, {
      method: "GET",
      headers: { 'Content-Type': 'Application/json', "customerId": id },
    });
    const jsonData = await res.json();
    if (jsonData.jobBoardData.length > 0) {
      let newData = jsonData.jobBoardData[0];
      newData.isJobPortal = parseInt(newData.isJobPortal) === 1 ? true : false;
      newData.primaryData = newData.primaryData ? JSON.parse(newData.primaryData) : initialPrimary;
      newData.typography = newData.typography ? JSON.parse(newData.typography) : initialTypography;
      newData.url = newData.url ? newData.url : initalData.url;
      newData.preview = newData.logo ? newData.logo.substring(newData.logo.lastIndexOf("/") + 1) : initalData.preview;
      // newData.logo = newData.logo ? newData.logo : initalData.logo;
      if(newData.logo){
        const res1 = await fetch(`${process.env.REACT_APP_USE_MANAGEMENT}/mgmt/customers/previewDocument`, {
          method: "POST",
          headers: { 'Content-Type': 'Application/json', "customerId": id },
          body: JSON.stringify({ path : newData.logo })
        });
        const docData = await res1.json();
        if(docData.code === 200){
          newData.logo = docData.path;
          setData(newData);
          console.log(newData);
        }
      } else {
        newData.logo = initalData.logo;
        setData(newData);
      }
    }
    dispatch(updateLoader(false));
  };

  const onUpdateHandler = () => { }
  const onSubmitHandler = () => { }

  return (
    <React.Fragment>
      <JobboardPortalComponent actions={actionBtns} componentType="View"
        initial={initalData} data={Data} updateHandler={onUpdateHandler} handleSubmit={onSubmitHandler} />
    </React.Fragment>
  );
};

export default JobboardPortalView;

const initialPrimary = [
  { title: 'Primary Color', value: "var(--primary-bg-color)" },
  { title: 'Secondary Color', value: "#3B4046" },
]

const initialTypography = [
  {
    title: "Primary Font",
    name: "mainheading",
    fontName: '"Roboto", sans-serif',
    color: "#000000",
  },
  {
    title: "Secondary Font",
    name: "bodyheading",
    fontName: '"Roboto", sans-serif',
    color: "#000000",
  }
]