
export const MailInactive = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.049" height="22" viewBox="0 0 27.049 22">
    <path id="mail_1_" data-name="mail (1)" d="M28.051,79.149,36.179,87.7a4.418,4.418,0,0,0,6.445,0l8.13-8.549a.2.2,0,0,0,.054-.155.2.2,0,0,0-.079-.142,4.929,4.929,0,0,0-2.924-.969H31a4.93,4.93,0,0,0-2.924.969.2.2,0,0,0-.079.142.2.2,0,0,0,.054.155Zm-2.172,4.113a5.564,5.564,0,0,1,.585-2.493.185.185,0,0,1,.136-.1.179.179,0,0,1,.159.056l8.026,8.438a6.315,6.315,0,0,0,9.235,0l8.026-8.438a.179.179,0,0,1,.159-.056.185.185,0,0,1,.136.1,5.564,5.564,0,0,1,.585,2.493V94.5A5.263,5.263,0,0,1,47.8,99.883H31A5.263,5.263,0,0,1,25.879,94.5Z" transform="translate(-25.879 -77.883)" fill={primaryFontColor} fill-rule="evenodd"/>
  </svg>
  


  )
}

