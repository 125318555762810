
export const GroupUsersInactive = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22.993" viewBox="0 0 28 22.993">
    <g id="Group_41223" data-name="Group 41223" transform="translate(-31 -354)">
      <g id="Group_5201" data-name="Group 5201" transform="translate(47.27 360.549)">
        <g id="customer">
          <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="3.358" cy="3.358" rx="3.358" ry="3.358" transform="translate(2.488)" fill={primaryFontColor}/>
          <path id="Path_2" data-name="Path 2" d="M127.1,239.891a7.7,7.7,0,0,0-4.957.938c-2.375,1.52-1.918,3.567-1.918,6.07a1.392,1.392,0,0,0,1.516,1.211c9.138,0,9.5.236,10.044-.723.178-.324.129-.221.129-3.323C131.909,241.6,129.239,239.891,127.1,239.891Z" transform="translate(-120.185 -232.184)" fill={primaryFontColor}/>
        </g>
      </g>
      <g id="Group_5202" data-name="Group 5202" transform="translate(31 360.549)">
        <g id="customer-2" data-name="customer">
          <ellipse id="Ellipse_1-2" data-name="Ellipse 1" cx="3.358" cy="3.358" rx="3.358" ry="3.358" transform="translate(2.488)" fill={primaryFontColor}/>
          <path id="Path_2-2" data-name="Path 2" d="M127.1,239.891a7.7,7.7,0,0,0-4.957.938c-2.375,1.52-1.918,3.567-1.918,6.07a1.392,1.392,0,0,0,1.516,1.211c9.138,0,9.5.236,10.044-.723.178-.324.129-.221.129-3.323C131.909,241.6,129.239,239.891,127.1,239.891Z" transform="translate(-120.185 -232.184)" fill={primaryFontColor}/>
        </g>
      </g>
      <g id="Group_5200" data-name="Group 5200" transform="translate(36.557 354.5)">
        <g id="customer-3" data-name="customer">
          <circle id="Ellipse_1-3" data-name="Ellipse 1" cx="5.052" cy="5.052" r="5.052" transform="translate(3.744)" fill={primaryFontColor} stroke="#fff" stroke-width="1"/>
          <path id="Path_2-3" data-name="Path 2" d="M130.582,239.911c-2.848-.19-5.323,0-7.458,1.41-3.573,2.287-2.885,5.366-2.885,9.133a2.094,2.094,0,0,0,2.281,1.823c13.749,0,14.3.354,15.111-1.088.267-.488.194-.333.194-5C137.824,242.482,133.807,239.911,130.582,239.911Z" transform="translate(-120.185 -230.316)" fill={primaryFontColor} stroke="#fff" stroke-width="1"/>
        </g>
      </g>
    </g>
  </svg>
  


  )
}

