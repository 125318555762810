import React, { useState, useEffect } from "react";

export const SendSMSillu = () => {
    const [primaryColor, setPrimaryColor] = useState('#ffd800');

    const storedTheme = JSON.parse(localStorage.getItem("themeSettings")) || '';

    useEffect(() => {
        if (storedTheme !== '') {
            setPrimaryColor(storedTheme?.primaryColor);
        }
    }, [storedTheme])

    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="601.43" height="169.956" viewBox="0 0 701.43 169.956">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_19319" data-name="Rectangle 19319" width="260.55" height="169.956" fill="none" />
                </clipPath>
                <clipPath id="clip-path-3">
                    <rect id="Rectangle_19298" data-name="Rectangle 19298" width="36.653" height="4.977" fill="none" />
                </clipPath>
                <clipPath id="clip-path-4">
                    <rect id="Rectangle_19307" data-name="Rectangle 19307" width="25.517" height="11.467" fill="none" />
                </clipPath>
                <clipPath id="clip-path-5">
                    <rect id="Rectangle_19314" data-name="Rectangle 19314" width="21.245" height="19.497" fill="none" />
                </clipPath>
                <clipPath id="clip-path-6">
                    <rect id="Rectangle_19316" data-name="Rectangle 19316" width="1.262" height="1.118" fill="none" />
                </clipPath>
                <clipPath id="clip-path-7">
                    <rect id="Rectangle_19317" data-name="Rectangle 19317" width="1.014" height="1.114" fill="none" />
                </clipPath>
            </defs>
            <g id="Group_38674" data-name="Group 38674" transform="translate(-2780.331 -3221.242)">
                <g id="Group_4245" data-name="Group 4245" transform="translate(3345.643 3299.583)">
                    <g id="Group_4218" data-name="Group 4218" transform="translate(0 15.076)">
                        <rect id="Rectangle_2687" data-name="Rectangle 2687" width="129.304" height="58.04" rx="19.669" transform="translate(6.813 18.071)" fill="#e0e0e0" />
                        <rect id="Rectangle_2688" data-name="Rectangle 2688" width="129.304" height="58.04" rx="19.669" transform="translate(0 18.499)" fill="#f5f5f5" />
                        <path id="Rectangle_2689" data-name="Rectangle 2689" d="M13.738,0h89.214A13.738,13.738,0,0,1,116.69,13.738v7.915a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V13.738A13.738,13.738,0,0,1,13.738,0Z" transform="translate(6.687 23.972)" fill="#e0e0e0" />
                        <path id="Rectangle_2690" data-name="Rectangle 2690" d="M0,0H116.69a0,0,0,0,1,0,0V7.915a13.738,13.738,0,0,1-13.738,13.738H13.738A13.738,13.738,0,0,1,0,7.915V0A0,0,0,0,1,0,0Z" transform="translate(6.687 48.556)" fill="#e0e0e0" />
                        <g id="Group_4192" data-name="Group 4192" transform="translate(65.154 25.449)">
                            <rect id="Rectangle_2691" data-name="Rectangle 2691" width="21.365" height="17.605" transform="translate(5.472 2.715)" fill="#e6e6e6" />
                            <rect id="Rectangle_2692" data-name="Rectangle 2692" width="5.472" height="17.605" transform="translate(0 2.715)" fill="#f5f5f5" />
                            <path id="Path_15999" data-name="Path 15999" d="M362.495,333.226a1.8,1.8,0,1,1-1.8-1.8A1.8,1.8,0,0,1,362.495,333.226Z" transform="translate(-357.959 -317.643)" fill="#e0e0e0" />
                            <rect id="Rectangle_2693" data-name="Rectangle 2693" width="16.049" height="20.32" transform="translate(16.39)" fill="#e6e6e6" />
                            <g id="Group_4191" data-name="Group 4191" transform="translate(11.757)">
                                <rect id="Rectangle_2694" data-name="Rectangle 2694" width="4.64" height="20.32" fill="#f5f5f5" />
                                <circle id="Ellipse_48" data-name="Ellipse 48" cx="1.59" cy="1.59" r="1.59" transform="translate(0.73 1.362)" fill="#e0e0e0" />
                            </g>
                            <circle id="Ellipse_49" data-name="Ellipse 49" cx="1.59" cy="1.59" r="1.59" transform="translate(12.487 1.362)" fill="#e0e0e0" />
                            <rect id="Rectangle_2695" data-name="Rectangle 2695" width="13.531" height="17.605" transform="translate(21.119 2.715)" fill="#ebebeb" />
                            <rect id="Rectangle_2696" data-name="Rectangle 2696" width="3.091" height="17.605" transform="translate(18.028 2.715)" fill="#f5f5f5" />
                            <rect id="Rectangle_2697" data-name="Rectangle 2697" width="17.748" height="14.626" transform="translate(32.194 5.694)" fill="#e6e6e6" />
                            <rect id="Rectangle_2698" data-name="Rectangle 2698" width="4.545" height="14.626" transform="translate(27.649 5.694)" fill="#f5f5f5" />
                            <rect id="Rectangle_2699" data-name="Rectangle 2699" width="13.333" height="16.881" transform="translate(41.262 3.439)" fill="#e6e6e6" />
                            <rect id="Rectangle_2700" data-name="Rectangle 2700" width="3.855" height="16.881" transform="translate(37.414 3.439)" fill="#f5f5f5" />
                            <rect id="Rectangle_2701" data-name="Rectangle 2701" width="12.951" height="14.626" transform="translate(45.193 5.694)" fill="#e6e6e6" />
                            <rect id="Rectangle_2702" data-name="Rectangle 2702" width="2.566" height="14.626" transform="translate(42.62 5.694)" fill="#f0f0f0" />
                        </g>
                        <g id="Group_4193" data-name="Group 4193" transform="translate(20.146 49.223)">
                            <rect id="Rectangle_2703" data-name="Rectangle 2703" width="16.538" height="17.605" transform="translate(4.236 2.715)" fill="#e6e6e6" />
                            <rect id="Rectangle_2704" data-name="Rectangle 2704" width="4.236" height="17.605" transform="translate(0 2.715)" fill="#f5f5f5" />
                            <rect id="Rectangle_2705" data-name="Rectangle 2705" width="12.423" height="20.32" transform="translate(12.687)" fill="#e6e6e6" />
                            <rect id="Rectangle_2706" data-name="Rectangle 2706" width="3.592" height="20.32" transform="translate(9.101)" fill="#f5f5f5" />
                            <rect id="Rectangle_2707" data-name="Rectangle 2707" width="16.538" height="17.605" transform="translate(60.656 2.715)" fill="#e6e6e6" />
                            <rect id="Rectangle_2708" data-name="Rectangle 2708" width="4.236" height="17.605" transform="translate(56.42 2.715)" fill="#f5f5f5" />
                            <rect id="Rectangle_2709" data-name="Rectangle 2709" width="12.423" height="20.32" transform="translate(69.108)" fill="#e6e6e6" />
                            <rect id="Rectangle_2710" data-name="Rectangle 2710" width="3.592" height="20.32" transform="translate(65.521)" fill="#f5f5f5" />
                            <rect id="Rectangle_2711" data-name="Rectangle 2711" width="10.474" height="17.605" transform="translate(16.348 2.715)" fill="#ebebeb" />
                            <rect id="Rectangle_2712" data-name="Rectangle 2712" width="2.393" height="17.605" transform="translate(13.955 2.715)" fill="#f5f5f5" />
                            <rect id="Rectangle_2713" data-name="Rectangle 2713" width="13.739" height="14.626" transform="translate(24.921 5.694)" fill="#e6e6e6" />
                            <rect id="Rectangle_2714" data-name="Rectangle 2714" width="3.518" height="14.626" transform="translate(21.403 5.694)" fill="#f5f5f5" />
                            <rect id="Rectangle_2715" data-name="Rectangle 2715" width="10.321" height="16.881" transform="translate(31.94 3.439)" fill="#e6e6e6" />
                            <rect id="Rectangle_2716" data-name="Rectangle 2716" width="2.984" height="16.881" transform="translate(28.961 3.439)" fill="#f5f5f5" />
                            <rect id="Rectangle_2717" data-name="Rectangle 2717" width="10.025" height="14.626" transform="translate(34.983 5.694)" fill="#e6e6e6" />
                            <rect id="Rectangle_2718" data-name="Rectangle 2718" width="1.986" height="14.626" transform="translate(32.992 5.694)" fill="#f0f0f0" />
                        </g>
                        <g id="Group_4205" data-name="Group 4205" transform="translate(45.002 0)">
                            <g id="Group_4203" data-name="Group 4203">
                                <g id="Group_4195" data-name="Group 4195">
                                    <g id="Group_4194" data-name="Group 4194">
                                        <path id="Path_16000" data-name="Path 16000" d="M337.13,277.189a1.773,1.773,0,0,0-1.887-.943,1.872,1.872,0,0,0-1.4,1.631,1.574,1.574,0,0,0-1.791-.047,1.785,1.785,0,0,0-.72,1.733,1.318,1.318,0,0,0-1.914.389,1.48,1.48,0,0,0,.585,1.965.949.949,0,0,0,.047,1.889,1.025,1.025,0,0,0-.941.9,1.06,1.06,0,0,0,.709,1.113.863.863,0,0,0-.205,1.331.775.775,0,0,0,1.222-.388.253.253,0,0,0,.4.064.285.285,0,0,0-.077-.422.469.469,0,0,0,.809-.207,1.166,1.166,0,0,0,1.578,1.244.714.714,0,0,0,.467.96,1.623,1.623,0,0,0,1.14-.222,2.549,2.549,0,0,1,1.119-.377,3.545,3.545,0,0,1,1,.278,3.01,3.01,0,0,0,3.05-.8,1.3,1.3,0,0,0,2.173-.331.972.972,0,0,0,.855.773.957.957,0,0,0,.931-.668c.008.186.017.372.025.558a1.155,1.155,0,0,0,1.183-1.547,1.175,1.175,0,0,0,1.124-1.026,1.219,1.219,0,0,0-.838-1.3,2.289,2.289,0,0,0,.043-4.341,1.089,1.089,0,0,0-1.04.143.659.659,0,0,0,0,.987,2.1,2.1,0,0,0-.9-2.36,1.87,1.87,0,0,0-2.359.505,1.182,1.182,0,0,0,.012-1.305,1.036,1.036,0,0,0-1.177-.4,1.181,1.181,0,0,0-1.275-.967,1.229,1.229,0,0,0-.959,1.313.6.6,0,0,0-1.082-.2C336.715,277.025,337.13,277.189,337.13,277.189Z" transform="translate(-329.098 -275.994)" fill="#ebebeb" />
                                    </g>
                                </g>
                                <g id="Group_4202" data-name="Group 4202" transform="translate(1.684 1.302)">
                                    <g id="Group_4196" data-name="Group 4196" transform="translate(5.151 4.921)">
                                        <path id="Path_16001" data-name="Path 16001" d="M340.165,285.409a1.075,1.075,0,0,0-.809-.195.748.748,0,0,0-.59.551.578.578,0,0,0,.393.666c.337.081.674-.2,1.018-.148a3.048,3.048,0,0,1,.355.108,1.734,1.734,0,0,0,1.392-.5,1.472,1.472,0,0,0,.388-.369.5.5,0,0,0,.041-.511.665.665,0,0,0-.673-.219,2.684,2.684,0,0,0-1.372.573l.038-.235Z" transform="translate(-338.755 -284.786)" fill="#fafafa" />
                                    </g>
                                    <g id="Group_4197" data-name="Group 4197" transform="translate(12.787 4.879)">
                                        <path id="Path_16002" data-name="Path 16002" d="M350.49,284.967a1.3,1.3,0,0,0-.431-.226.44.44,0,0,0-.447.125.448.448,0,0,0,.125.592,1.309,1.309,0,0,0,.628.213c.227.035.515.043.627-.158a.391.391,0,0,0-.107-.454,1.126,1.126,0,0,0-.447-.221Z" transform="translate(-349.544 -284.727)" fill="#fafafa" />
                                    </g>
                                    <g id="Group_4201" data-name="Group 4201">
                                        <path id="Path_16003" data-name="Path 16003" d="M334.488,279.532a1.579,1.579,0,0,0-.917,1.1c-.032.146-.028.329.1.407a.419.419,0,0,0,.309.008l1.313-.312a1.865,1.865,0,0,0-.592.314.632.632,0,0,0-.224.6.608.608,0,0,0,.608.409,1.309,1.309,0,0,0,.723-.288.923.923,0,0,0,.457-.672c0-.282-.35-.542-.565-.362a4.818,4.818,0,0,0,1.409-.641,1.322,1.322,0,0,0,.408-.381.545.545,0,0,0,.041-.536c-.449-.815-1.016.381-1.292.488C335.742,279.863,335.152,279.255,334.488,279.532Z" transform="translate(-332.084 -278.142)" fill="#fafafa" />
                                        <path id="Path_16004" data-name="Path 16004" d="M331.84,284.353a.432.432,0,0,0,.115.455.279.279,0,0,0,.42-.086,1.477,1.477,0,0,0-.788.444c-.183.247-.138.678.154.774a.715.715,0,0,0,.544-.122,9.858,9.858,0,0,0,1.356-.894c-.129.3.183.642.51.67a1.575,1.575,0,0,0,.912-.319c.344-.208.737-.527.647-.919a.67.67,0,0,0-.85-.392,1.946,1.946,0,0,0-.822.63c.184-.268-.078-.667-.4-.71a.975.975,0,0,0-.822.379c.365-.2.447-.722-.042-.783C332.456,283.44,331.916,284.087,331.84,284.353Z" transform="translate(-331.477 -279.483)" fill="#fafafa" />
                                        <path id="Path_16005" data-name="Path 16005" d="M345.757,283.046a.528.528,0,1,0-.265,1.016.825.825,0,0,0-.556.29.432.432,0,0,0-.117.328.4.4,0,0,0,.393.3,1.152,1.152,0,0,0,.511-.143.942.942,0,0,0-.5.146.327.327,0,0,0-.075.463.433.433,0,0,0,.42.047,2.085,2.085,0,0,0,.861-.484.831.831,0,0,0,.72.3.494.494,0,0,0,.412-.588.523.523,0,0,0-.445-.324,1.349,1.349,0,0,0-.57.089,1.08,1.08,0,0,0,.458-.5c.069-.221-.08-.517-.311-.5-.26.021-.393.374-.6.5C345.839,283.746,346.312,283.253,345.757,283.046Z" transform="translate(-335.375 -279.348)" fill="#fafafa" />
                                        <g id="Group_4198" data-name="Group 4198" transform="translate(5.325 2.515)">
                                            <path id="Path_16006" data-name="Path 16006" d="M339.379,281.49a1.059,1.059,0,0,0-.377.951,1.1,1.1,0,0,0,.562.971.9.9,0,0,0,1.154-.7,1.268,1.268,0,0,0-.707-1.269A.693.693,0,0,0,339.379,281.49Z" transform="translate(-339 -281.387)" fill="#fafafa" />
                                        </g>
                                        <g id="Group_4199" data-name="Group 4199" transform="translate(8.095 1.799)">
                                            <path id="Path_16007" data-name="Path 16007" d="M343.837,280.77l.122.105a.592.592,0,1,0,0,.737.887.887,0,0,0,1.035-.194.677.677,0,0,0-.142-.984.548.548,0,0,0-.793.469l-.143-.159Z" transform="translate(-342.914 -280.375)" fill="#fafafa" />
                                        </g>
                                        <g id="Group_4200" data-name="Group 4200" transform="translate(12.086 2.768)">
                                            <path id="Path_16008" data-name="Path 16008" d="M349.143,281.76a1.88,1.88,0,0,0-.564.8.648.648,0,0,0,.375.8c.316.08.617-.181.8-.454a.994.994,0,0,0,.194-.832c-.094-.28-.5-.446-.7-.23Z" transform="translate(-348.554 -281.745)" fill="#fafafa" />
                                        </g>
                                        <path id="Path_16009" data-name="Path 16009" d="M341.517,278.7a.536.536,0,0,0-.746-.205.649.649,0,0,0-.231.773.825.825,0,0,0,.677.487c.36.04.785-.251.7-.6a.768.768,0,0,0,.689.26,1.815,1.815,0,0,0,.715-.269c.3-.173.623-.442.592-.789a.62.62,0,0,0-.569-.514,1.053,1.053,0,0,0-.759.283,6.444,6.444,0,0,1-.483.44.609.609,0,0,1-.615.087Z" transform="translate(-334.113 -277.833)" fill="#fafafa" />
                                    </g>
                                </g>
                            </g>
                            <g id="Group_4204" data-name="Group 4204" transform="translate(2.625 8.382)">
                                <path id="Path_16010" data-name="Path 16010" d="M344.19,298.023h-9.064l-.887-9.135h10.838Z" transform="translate(-333.226 -288.145)" fill="#f0f0f0" />
                                <path id="Path_16011" data-name="Path 16011" d="M345.361,289.053H333.029l-.222-1.215h12.775Z" transform="translate(-332.807 -287.838)" fill="#fafafa" />
                            </g>
                        </g>
                        <g id="Group_4217" data-name="Group 4217" transform="translate(19.022 33.71)">
                            <g id="Group_4206" data-name="Group 4206">
                                <path id="Path_16012" data-name="Path 16012" d="M330.7,335.663H320.3l3.26-10.5.477-1.536h2.353Z" transform="translate(-300.547 -323.625)" fill="#ebebeb" />
                                <path id="Path_16013" data-name="Path 16013" d="M318.593,323.625l-.477,1.536-3.26,10.5-9.618-.143-3.092-10.36-.477-1.536Z" transform="translate(-295.102 -323.625)" fill="#e0e0e0" />
                                <path id="Path_16014" data-name="Path 16014" d="M315.881,323.625l-.477,1.536-3.26,10.5-19.754-.294,4.208-11.745Z" transform="translate(-292.39 -323.625)" fill="#fafafa" />
                                <path id="Path_16015" data-name="Path 16015" d="M317.394,323.625l-.477,1.536h-19.35l.545-1.536Z" transform="translate(-293.903 -323.625)" fill="#ebebeb" />
                            </g>
                            <g id="Group_4211" data-name="Group 4211" transform="translate(2.3 2.252)">
                                <g id="Group_4207" data-name="Group 4207">
                                    <path id="Path_16016" data-name="Path 16016" d="M299.978,331.08H295.64l1.513-4.273,4.149.047Zm-3.837-.354h3.578l1.1-3.524-3.419-.038Z" transform="translate(-295.64 -326.807)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4208" data-name="Group 4208" transform="translate(4.853)">
                                    <path id="Path_16017" data-name="Path 16017" d="M306.834,331.08H302.5l1.513-4.273,4.148.047ZM303,330.726h3.577l1.1-3.524-3.42-.038Z" transform="translate(-302.497 -326.807)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4209" data-name="Group 4209" transform="translate(9.705)">
                                    <path id="Path_16018" data-name="Path 16018" d="M313.691,331.08h-4.338l1.514-4.273,4.148.047Zm-3.837-.354h3.577l1.1-3.524-3.42-.038Z" transform="translate(-309.353 -326.807)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4210" data-name="Group 4210" transform="translate(14.558)">
                                    <path id="Path_16019" data-name="Path 16019" d="M320.548,331.08H316.21l1.514-4.273,4.148.047Zm-3.837-.354h3.576l1.1-3.524-3.419-.038Z" transform="translate(-316.21 -326.807)" fill="#ebebeb" />
                                </g>
                            </g>
                            <g id="Group_4216" data-name="Group 4216" transform="translate(0.786 6.785)">
                                <g id="Group_4212" data-name="Group 4212">
                                    <path id="Path_16020" data-name="Path 16020" d="M297.839,337.485H293.5l1.514-4.273,4.148.047ZM294,337.131h3.577l1.1-3.524-3.42-.038Z" transform="translate(-293.501 -333.212)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4213" data-name="Group 4213" transform="translate(4.853)">
                                    <path id="Path_16021" data-name="Path 16021" d="M304.7,337.485h-4.338l1.513-4.273,4.149.047Zm-3.837-.354h3.576l1.1-3.524-3.419-.038Z" transform="translate(-300.358 -333.212)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4214" data-name="Group 4214" transform="translate(9.705)">
                                    <path id="Path_16022" data-name="Path 16022" d="M311.552,337.485h-4.338l1.514-4.273,4.149.047Zm-3.837-.354h3.577l1.1-3.524-3.42-.038Z" transform="translate(-307.214 -333.212)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4215" data-name="Group 4215" transform="translate(14.559)">
                                    <path id="Path_16023" data-name="Path 16023" d="M318.409,337.485h-4.337l1.513-4.273,4.148.047Zm-3.837-.354h3.577l1.1-3.524-3.419-.038Z" transform="translate(-314.072 -333.212)" fill="#ebebeb" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Group_4244" data-name="Group 4244" transform="translate(104.532)">
                        <g id="Group_4219" data-name="Group 4219" transform="translate(0 16.751)">
                            <path id="Path_16024" data-name="Path 16024" d="M413.211,289.28a8.617,8.617,0,0,0,.939,4.6,2.435,2.435,0,0,0,2.064,1.089h3.229a2.434,2.434,0,0,0,2.064-1.089,8.615,8.615,0,0,0,.94-4.6c0-3.234-1.41-5.824-1.871-7.892a11.675,11.675,0,0,1,.386-3.026H414.7a11.675,11.675,0,0,1,.386,3.026C414.621,283.456,413.211,286.046,413.211,289.28Z" transform="translate(-413.211 -278.362)" fill="#f0f0f0" />
                        </g>
                        <g id="Group_4243" data-name="Group 4243" transform="translate(0.176)">
                            <g id="Group_4233" data-name="Group 4233" transform="translate(4.671)">
                                <g id="Group_4221" data-name="Group 4221" transform="translate(0 1.135)">
                                    <g id="Group_4220" data-name="Group 4220">
                                        <path id="Path_16025" data-name="Path 16025" d="M421.922,256.3a3.34,3.34,0,0,0-1.672,1.084c-.616.82.447,2.75.447,2.75a2.6,2.6,0,0,0,2.253.719l1.249-.305s-1.321-3.783-2.268-4Z" transform="translate(-420.059 -256.297)" fill="#ebebeb" />
                                    </g>
                                </g>
                                <g id="Group_4227" data-name="Group 4227" transform="translate(1.456)">
                                    <g id="Group_4222" data-name="Group 4222">
                                        <path id="Path_16026" data-name="Path 16026" d="M427.451,256.733c-.166,1.644-1.554,3.5-3.023,3.348s-2.46-2.239-2.294-3.883,1.362-1.582,2.83-1.434S427.617,255.088,427.451,256.733Z" transform="translate(-422.115 -254.694)" fill="#ebebeb" />
                                    </g>
                                    <g id="Group_4223" data-name="Group 4223" transform="translate(1.106 0.763)">
                                        <path id="Path_16027" data-name="Path 16027" d="M423.766,255.771a4.189,4.189,0,0,0,3.309,1S423.035,258.49,423.766,255.771Z" transform="translate(-423.678 -255.771)" fill="#ebebeb" />
                                    </g>
                                    <g id="Group_4224" data-name="Group 4224" transform="translate(2.228 0.553)">
                                        <path id="Path_16028" data-name="Path 16028" d="M425.264,255.547s1.315.019,1.234.554c0,0,1.091.144,1.028-.336S425.264,255.547,425.264,255.547Z" transform="translate(-425.264 -255.475)" fill="#ebebeb" />
                                    </g>
                                    <g id="Group_4225" data-name="Group 4225" transform="translate(1.106 0.763)">
                                        <path id="Path_16029" data-name="Path 16029" d="M423.766,255.771a4.189,4.189,0,0,0,3.309,1S423.035,258.49,423.766,255.771Z" transform="translate(-423.678 -255.771)" fill="#e0e0e0" />
                                    </g>
                                    <g id="Group_4226" data-name="Group 4226" transform="translate(2.228 0.553)">
                                        <path id="Path_16030" data-name="Path 16030" d="M425.264,255.547s1.315.019,1.234.554c0,0,1.091.144,1.028-.336S425.264,255.547,425.264,255.547Z" transform="translate(-425.264 -255.475)" fill="#e0e0e0" />
                                    </g>
                                </g>
                                <g id="Group_4228" data-name="Group 4228" transform="translate(1.246 2.66)">
                                    <path id="Path_16031" data-name="Path 16031" d="M421.82,261.232a2.406,2.406,0,0,0,2.072,1.453,3.217,3.217,0,0,0,2.412-.957,4.922,4.922,0,0,0,1.263-2.318c.057-.224.182-.786-.1-.929-.258-.132-.5.218-.66.372a9.07,9.07,0,0,1-1.483,1.192,8.112,8.112,0,0,1-1.683.8C423.477,260.9,421.877,261.379,421.82,261.232Z" transform="translate(-421.82 -258.452)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4229" data-name="Group 4229" transform="translate(5.082 1.936)">
                                    <path id="Path_16032" data-name="Path 16032" d="M430.213,258.874a2.132,2.132,0,0,1-.636,1.974,3.717,3.717,0,0,1-1.243.677c-.31.115-.7.206-.938-.022-.258-.246-.149-.672-.017-1q.306-.764.653-1.512a5.4,5.4,0,0,1,.712-1.43c.3-.3.6-.032.908.285A2.107,2.107,0,0,1,430.213,258.874Z" transform="translate(-427.24 -257.429)" fill="#ebebeb" />
                                </g>
                                <g id="Group_4230" data-name="Group 4230" transform="translate(0.654 2.029)">
                                    <path id="Path_16033" data-name="Path 16033" d="M422.114,260.145A1.511,1.511,0,0,1,421,258.979c-.077-.413.11-1.389.632-1.418A7,7,0,0,0,422.114,260.145Z" transform="translate(-420.983 -257.561)" fill="#e0e0e0" />
                                </g>
                                <g id="Group_4231" data-name="Group 4231" transform="translate(5.77 2.66)">
                                    <path id="Path_16034" data-name="Path 16034" d="M428.212,260.694a1.713,1.713,0,0,0,1.335-.892c.143-.272.311-1.773-.246-1.234-.182.176-.278.83-.4,1.079A5.353,5.353,0,0,1,428.212,260.694Z" transform="translate(-428.212 -258.451)" fill="#e0e0e0" />
                                </g>
                                <g id="Group_4232" data-name="Group 4232" transform="translate(1.967 5.382)">
                                    <path id="Path_16035" data-name="Path 16035" d="M424.452,262.35a1.241,1.241,0,0,0,1.476.319c-.11.275-1.056.344-1.351.374-.768.076-1.088-.316-1.739-.745C422.838,262.3,423.924,262.679,424.452,262.35Z" transform="translate(-422.838 -262.298)" fill="#e0e0e0" />
                                </g>
                            </g>
                            <g id="Group_4235" data-name="Group 4235" transform="translate(3.576 6.817)">
                                <g id="Group_4234" data-name="Group 4234">
                                    <path id="Path_16036" data-name="Path 16036" d="M418.512,289.089l1.809-18.279,2.4-6.485.415.153-2.386,6.456-1.891,18.653Z" transform="translate(-418.512 -264.325)" fill="#e6e6e6" />
                                </g>
                            </g>
                            <g id="Group_4242" data-name="Group 4242" transform="translate(0 5.404)">
                                <g id="Group_4236" data-name="Group 4236" transform="translate(3.183 2.21)">
                                    <path id="Path_16037" data-name="Path 16037" d="M420.57,271.1l-.395.2a12.912,12.912,0,0,1-.693-1.912,15.756,15.756,0,0,0-1.525-3.706l.377-.232a16.081,16.081,0,0,1,1.571,3.808A12.657,12.657,0,0,0,420.57,271.1Z" transform="translate(-417.957 -265.451)" fill="#e6e6e6" />
                                </g>
                                <g id="Group_4237" data-name="Group 4237" transform="translate(4.671 2.379)">
                                    <path id="Path_16038" data-name="Path 16038" d="M422.159,268.775a2.592,2.592,0,0,0,1.646-.756,5.167,5.167,0,0,0,1.011-1.825c-.8.084-1.551-.44-2.356-.5a2.108,2.108,0,0,0-1.721.713c-.546.631-.865,1.4-.563,1.8A2.736,2.736,0,0,0,422.159,268.775Z" transform="translate(-420.059 -265.691)" fill="#f0f0f0" />
                                </g>
                                <g id="Group_4239" data-name="Group 4239" transform="translate(0 2.944)">
                                    <g id="Group_4238" data-name="Group 4238">
                                        <path id="Path_16039" data-name="Path 16039" d="M413.76,267.03a3.075,3.075,0,0,1,2.585-.415,2.312,2.312,0,0,1,1.411,1.572,1.451,1.451,0,0,1-1.384.544,4.27,4.27,0,0,1-1.457-.55,4.283,4.283,0,0,0-1.457-.549Z" transform="translate(-413.459 -266.488)" fill="#f0f0f0" />
                                    </g>
                                </g>
                                <g id="Group_4241" data-name="Group 4241" transform="translate(0.891)">
                                    <g id="Group_4240" data-name="Group 4240">
                                        <path id="Path_16040" data-name="Path 16040" d="M418.117,266.161c-1.049.117-1.989.326-2.943-.867-.639-.8-.434-2.009-.387-2.965a21.156,21.156,0,0,1,3.432,1.45,1.677,1.677,0,0,1,.923,1.12.964.964,0,0,1-.762,1.087C418.362,265.96,418.117,266.161,418.117,266.161Z" transform="translate(-414.718 -262.329)" fill="#f0f0f0" />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <path id="Path_16041" data-name="Path 16041" d="M414.982,286.3a8.716,8.716,0,0,0-.684,3.749c.026.241.1.515.275.558s.321-.159.379-.377a2.662,2.662,0,0,0,.042-.681c0-1.96,1.062-3.612,1.265-5.524.057-.544-.083-2.26-.4-1C415.573,284.147,415.371,285.2,414.982,286.3Z" transform="translate(-413.521 -262.841)" fill="#fafafa" />
                    </g>
                </g>
                <g id="Group_11689" data-name="Group 11689" transform="translate(2780.331 3221.242)">
                    <g id="Group_11688" data-name="Group 11688" clip-path="url(#clip-path)">
                        <g id="Group_11687" data-name="Group 11687">
                            <g id="Group_11686" data-name="Group 11686" clip-path="url(#clip-path)">
                                <path id="Path_16573" data-name="Path 16573" d="M573,149.554a11.644,11.644,0,0,1-12.247-.151,11.646,11.646,0,1,1,12.247.151" transform="translate(-332.245 -76.534)" fill="#ebebeb" />
                                <path id="Path_16574" data-name="Path 16574" d="M582.647,148.374a3.544,3.544,0,1,1-3.5-3.586,3.544,3.544,0,0,1,3.5,3.586" transform="translate(-344.336 -86.622)" fill="#fff" />
                                <path id="Path_16575" data-name="Path 16575" d="M581.071,168.9l-.017,1.462a11.644,11.644,0,0,1-12.248-.151l.017-1.457a6.124,6.124,0,0,1,12.248.145" transform="translate(-340.296 -97.337)" fill="#fff" />
                                <path id="Path_16576" data-name="Path 16576" d="M46.777,78.763a7.512,7.512,0,0,1-2.211.895,7.629,7.629,0,1,1,2.211-.895" transform="translate(-21.067 -38.646)" fill="#ebebeb" />
                                <path id="Path_16577" data-name="Path 16577" d="M53.094,77.99A2.321,2.321,0,1,1,50.8,75.641a2.322,2.322,0,0,1,2.294,2.349" transform="translate(-28.987 -45.253)" fill="#fff" />
                                <path id="Path_16578" data-name="Path 16578" d="M52.063,91.432l-.011.958a7.63,7.63,0,0,1-8.022-.1l.011-.954a4.011,4.011,0,0,1,8.022.1" transform="translate(-26.341 -52.272)" fill="#fff" />
                                <rect id="Rectangle_19218" data-name="Rectangle 19218" width="50.474" height="34.404" transform="translate(34.027 6.108)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="1" />
                                <path id="Path_16579" data-name="Path 16579" d="M91.085,18.779a1.013,1.013,0,1,1-1.013-1.013,1.013,1.013,0,0,1,1.013,1.013" transform="translate(-53.281 -10.629)" fill="#a6a6a6" />
                                <path id="Path_16580" data-name="Path 16580" d="M101.378,18.779a1.013,1.013,0,1,1-1.013-1.013,1.013,1.013,0,0,1,1.013,1.013" transform="translate(-59.439 -10.629)" fill="#ebebeb" />
                                <path id="Path_16581" data-name="Path 16581" d="M111.67,18.779a1.013,1.013,0,1,1-1.013-1.013,1.013,1.013,0,0,1,1.013,1.013" transform="translate(-65.597 -10.629)" fill={primaryColor} />
                                <line id="Line_91" data-name="Line 91" x2="50.474" transform="translate(34.027 10.133)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="1" />
                                <rect id="Rectangle_19219" data-name="Rectangle 19219" width="26.753" height="16.966" transform="matrix(0.991, -0.135, 0.135, 0.991, 12.976, 60.564)" fill="#ebebeb" />
                                <path id="Path_16582" data-name="Path 16582" d="M58.808,141.759c-.931,1.17-1.888,2.3-2.829,3.454q-1.429,1.7-2.87,3.393-1.505,1.761-3.022,3.5c-.031.037-.059.074-.09.111q-1.341,1.532-2.692,3.066l-.134.145-.164-.1q-1.71-1.116-3.411-2.238c-.04-.022-.08-.05-.12-.078-1.287-.845-2.571-1.7-3.853-2.565-1.223-.821-2.448-1.658-3.669-2.5-1.216-.861-2.441-1.692-3.651-2.57,1.258.722,2.5,1.49,3.751,2.236,1.247.762,2.495,1.525,3.736,2.3q2.074,1.29,4.149,2.624s0,0,.009,0q1.552.988,3.105,1.988c.816-.917,1.635-1.84,2.456-2.746,0-.011,0-.011.006-.012q1.642-1.83,3.295-3.639,1.483-1.617,2.98-3.215c1.008-1.053,2-2.127,3.019-3.16" transform="translate(-19.324 -84.809)" fill="#fff" />
                                <path id="Path_16583" data-name="Path 16583" d="M47.429,169.53c-.011.01-.017.022-.028.032-.123.139-.246.277-.37.411-.588.652-1.2,1.282-1.817,1.9-.756.76-1.536,1.494-2.32,2.226s-1.584,1.436-2.4,2.132-1.644,1.38-2.51,2.021c.708-.814,1.454-1.585,2.211-2.341s1.539-1.5,2.32-2.226,1.588-1.441,2.405-2.136c.65-.555,1.313-1.1,2-1.627.157-.123.316-.242.478-.361l.037-.029" transform="translate(-22.724 -101.424)" fill="#fff" />
                                <path id="Path_16584" data-name="Path 16584" d="M86.616,173.538c-1.006-.386-1.989-.824-2.96-1.275s-1.931-.929-2.888-1.41-1.9-.994-2.831-1.523c-.76-.428-1.513-.868-2.251-1.339-.16-.1-.315-.2-.471-.3-.013-.006-.023-.017-.036-.023a.2.2,0,0,0,.043.014c.189.076.373.148.558.224.8.321,1.58.673,2.359,1.033.974.451,1.931.928,2.885,1.415s1.9.994,2.831,1.523,1.86,1.068,2.761,1.663" transform="translate(-44.977 -100.308)" fill="#fff" />
                                <rect id="Rectangle_19220" data-name="Rectangle 19220" width="26.876" height="42.38" transform="matrix(0.23, -0.973, 0.973, 0.23, 208.872, 104.868)" fill="#ebebeb" />
                                <path id="Path_16585" data-name="Path 16585" d="M576.573,205.7c-2.043,1.2-4.1,2.322-6.151,3.493-2.054,1.134-4.114,2.266-6.176,3.38q-3.229,1.746-6.46,3.447c-.068.037-.129.076-.2.114q-2.857,1.5-5.726,3l-.281.138-.183-.248q-1.89-2.623-3.766-5.253c-.046-.055-.09-.119-.133-.183q-2.131-2.974-4.234-5.986c-1.34-1.91-2.674-3.846-4-5.78-1.307-1.965-2.643-3.892-3.932-5.879,1.448,1.785,2.849,3.629,4.271,5.444,1.409,1.838,2.819,3.675,4.209,5.536q2.33,3.088,4.638,6.242c.006,0,.006,0,.01.011q1.73,2.345,3.457,4.708c1.728-.89,3.465-1.788,5.195-2.66.01-.015.01-.015.016-.014q3.47-1.769,6.944-3.5,3.113-1.546,6.236-3.054c2.09-.983,4.169-2,6.264-2.95" transform="translate(-320.27 -117.221)" fill="#fff" />
                                <path id="Path_16586" data-name="Path 16586" d="M539.6,240.321c-.022.008-.039.024-.06.032-.261.134-.521.269-.781.4-1.241.628-2.5,1.214-3.768,1.77-1.551.692-3.122,1.334-4.7,1.969-1.578.614-3.16,1.22-4.764,1.783s-3.217,1.1-4.862,1.557c1.51-.8,3.053-1.514,4.6-2.2s3.128-1.333,4.7-1.969c1.586-.619,3.168-1.225,4.772-1.787,1.277-.45,2.57-.883,3.878-1.267.3-.092.6-.177.912-.262.022-.008.049-.014.071-.022" transform="translate(-311.494 -143.775)" fill="#fff" />
                                <path id="Path_16587" data-name="Path 16587" d="M602.636,260.557c-1.267-1.143-2.472-2.351-3.648-3.571s-2.325-2.473-3.466-3.731c-1.124-1.273-2.241-2.551-3.316-3.865-.879-1.065-1.742-2.145-2.563-3.262-.179-.239-.352-.476-.525-.713-.016-.016-.023-.038-.039-.055a.3.3,0,0,0,.055.046c.236.219.467.431.7.649,1,.929,1.952,1.894,2.9,2.87,1.182,1.222,2.325,2.473,3.458,3.735s2.241,2.551,3.317,3.866,2.142,2.639,3.134,4.031" transform="translate(-352.424 -146.79)" fill="#fff" />
                                <rect id="Rectangle_19221" data-name="Rectangle 19221" width="24.348" height="15.441" transform="translate(145.058)" fill="#ebebeb" />
                                <path id="Path_16588" data-name="Path 16588" d="M385.425,0c-.983.94-1.985,1.841-2.976,2.767-1,.906-2,1.811-3.005,2.707q-1.574,1.4-3.156,2.782c-.033.03-.062.059-.095.09q-1.4,1.217-2.8,2.433l-.139.114-.135-.114q-1.4-1.217-2.8-2.439c-.033-.025-.066-.055-.1-.084-1.057-.921-2.11-1.846-3.159-2.787-1-.891-2-1.8-3-2.7C363.062,1.841,362.06.94,361.077,0c1.046.806,2.073,1.652,3.108,2.478,1.031.841,2.062,1.682,3.086,2.538q1.711,1.419,3.419,2.876s0,0,.007,0q1.278,1.082,2.556,2.175c.848-.727,1.7-1.458,2.552-2.175,0-.01,0-.01.007-.01q1.706-1.448,3.419-2.876,1.536-1.276,3.083-2.533c1.038-.826,2.066-1.672,3.111-2.478" transform="translate(-216.019)" fill="#fff" />
                                <path id="Path_16589" data-name="Path 16589" d="M370.814,20.521c-.011.007-.018.018-.029.026-.128.11-.256.219-.384.325-.611.515-1.236,1.009-1.872,1.488-.775.592-1.569,1.159-2.366,1.722s-1.605,1.1-2.428,1.627-1.652,1.042-2.512,1.514c.738-.647,1.506-1.251,2.281-1.839s1.572-1.159,2.366-1.722,1.609-1.1,2.431-1.631c.654-.421,1.32-.834,2-1.221.157-.092.314-.179.475-.267.011-.008.026-.015.037-.022" transform="translate(-216.106 -12.277)" fill="#fff" />
                                <path id="Path_16590" data-name="Path 16590" d="M407.256,27.223c-.859-.472-1.693-.987-2.512-1.514s-1.627-1.075-2.431-1.627-1.59-1.13-2.366-1.722c-.633-.479-1.258-.969-1.865-1.484-.132-.11-.26-.219-.388-.329-.011-.008-.018-.018-.029-.026a.174.174,0,0,0,.037.018c.161.092.318.179.475.271.68.388,1.342.8,2,1.221.823.526,1.627,1.075,2.428,1.631s1.59,1.13,2.365,1.722,1.547,1.192,2.285,1.839" transform="translate(-237.908 -12.277)" fill="#fff" />
                                <path id="Path_16592" data-name="Path 16592" d="M0,317.507l32.569-.1,32.569-.035,65.138-.067,65.138.067,32.569.035,32.569.1-32.569.1-32.569.035-65.138.067-65.138-.068L32.569,317.6Z" transform="translate(0 -189.833)" fill="#263238" />
                                <rect id="Rectangle_19222" data-name="Rectangle 19222" width="177.345" height="118.631" transform="translate(41.265 13.686)" fill="#dbdbdb" />
                                <rect id="Rectangle_19223" data-name="Rectangle 19223" width="174.767" height="115.737" transform="translate(42.554 15.133)" fill="#263238" />
                                <rect id="Rectangle_19224" data-name="Rectangle 19224" width="223.542" height="8.142" transform="translate(18.167 141.212)" fill="#c7c7c7" />
                                <path id="Path_16593" data-name="Path 16593" d="M45.221,338.258l23.1-8.9H245.664l23.1,8.9Z" transform="translate(-27.054 -197.045)" fill="#dbdbdb" />
                                <path id="Path_16594" data-name="Path 16594" d="M107.851,335.1l14.906-5.74H266.165l14.906,5.74Z" transform="translate(-64.523 -197.045)" fill="#263238" />
                                <rect id="Rectangle_19225" data-name="Rectangle 19225" width="155.014" height="98.15" transform="translate(52.431 23.926)" fill="#fff" />
                                <rect id="Rectangle_19226" data-name="Rectangle 19226" width="155.145" height="3.777" transform="translate(52.3 23.926)" fill="#455a64" />
                                <path id="Path_16595" data-name="Path 16595" d="M134.866,62.863a.937.937,0,1,1-.936-.937.937.937,0,0,1,.936.937" transform="translate(-79.565 -37.048)" fill="#c7c7c7" />
                                <path id="Path_16596" data-name="Path 16596" d="M144.386,62.863a.937.937,0,1,1-.937-.937.937.937,0,0,1,.937.937" transform="translate(-85.26 -37.048)" fill="#fff" />
                                <path id="Path_16597" data-name="Path 16597" d="M153.907,62.863a.937.937,0,1,1-.936-.937.937.937,0,0,1,.936.937" transform="translate(-90.956 -37.048)" fill={primaryColor} />
                                <rect id="Rectangle_19227" data-name="Rectangle 19227" width="2.752" height="94.374" transform="translate(204.693 27.703)" fill="#dbdbdb" />
                                <path id="Path_16598" data-name="Path 16598" d="M511.882,102.5h0a.711.711,0,0,1-.711-.711V71.044a.711.711,0,1,1,1.421,0v30.744a.711.711,0,0,1-.711.711" transform="translate(-305.815 -42.078)" fill="#a6a6a6" />
                                <rect id="Rectangle_19228" data-name="Rectangle 19228" width="83.556" height="3.179" transform="translate(97.104 33.641)" fill="#fff" />
                                <rect id="Rectangle_19229" data-name="Rectangle 19229" width="83.621" height="3.243" transform="translate(97.072 33.608)" fill="#ebebeb" />
                                <rect id="Rectangle_19230" data-name="Rectangle 19230" width="3.245" height="3.245" transform="translate(177.448 33.607)" fill={primaryColor} />
                                <path id="Path_16599" data-name="Path 16599" d="M446.706,88.394l-.294.223-.544-.718-.2-.263a.46.46,0,0,0,.114-.064.476.476,0,0,0,.151-.2l.211.279Z" transform="translate(-266.627 -52.274)" fill="#fff" />
                                <path id="Path_16600" data-name="Path 16600" d="M444.67,85.235a.8.8,0,1,0-.26,1.2.823.823,0,0,0,.111-.072.8.8,0,0,0,.148-1.124m-.344.868a.479.479,0,1,1,.151-.2.476.476,0,0,1-.151.2" transform="translate(-265.17 -50.805)" fill="#fff" />
                                <rect id="Rectangle_19231" data-name="Rectangle 19231" width="0.171" height="1.929" transform="translate(97.768 34.265)" fill="#c7c7c7" />
                                <path id="Path_16601" data-name="Path 16601" d="M491.523,86.98a1.805,1.805,0,1,1,.8-1.964,1.788,1.788,0,0,1-.8,1.964" transform="translate(-292.412 -50.038)" fill={primaryColor} />
                                <path id="Path_16602" data-name="Path 16602" d="M492.994,86.8a.549.549,0,1,1-.55-.549.549.549,0,0,1,.55.549" transform="translate(-294.283 -51.602)" fill="#fff" />
                                <path id="Path_16603" data-name="Path 16603" d="M492.8,89.978V90.2a1.8,1.8,0,0,1-1.9,0v-.226a.949.949,0,1,1,1.9,0" transform="translate(-293.687 -53.263)" fill="#fff" />
                                <path id="Path_16604" data-name="Path 16604" d="M476.314,87.22h-1.255v-.445a.068.068,0,0,0-.039-.06,1.4,1.4,0,0,1-.135-.079.072.072,0,0,0-.072,0l-.386.223-.627-1.087.386-.223a.067.067,0,0,0,.031-.064c0-.025,0-.051,0-.078s0-.052,0-.078a.066.066,0,0,0-.032-.064l-.386-.223.627-1.087.385.223a.069.069,0,0,0,.072,0,1.544,1.544,0,0,1,.136-.079.067.067,0,0,0,.038-.059v-.445h1.255v.445a.067.067,0,0,0,.039.06,1.535,1.535,0,0,1,.136.079.067.067,0,0,0,.071,0l.385-.223.627,1.087-.386.223a.067.067,0,0,0-.031.064c0,.025,0,.051,0,.077s0,.052,0,.078a.068.068,0,0,0,.031.064l.386.223-.627,1.086-.386-.222a.07.07,0,0,0-.072,0,1.342,1.342,0,0,1-.136.08.067.067,0,0,0-.039.059Zm-.943-.313H476v-.132a.38.38,0,0,1,.209-.338,1.141,1.141,0,0,0,.107-.062.38.38,0,0,1,.4-.013l.115.067.314-.546-.116-.067a.38.38,0,0,1-.187-.35l0-.061,0-.061a.381.381,0,0,1,.187-.351l.115-.067-.314-.545-.114.066a.377.377,0,0,1-.4-.012,1.079,1.079,0,0,0-.108-.062.38.38,0,0,1-.209-.339V83.9h-.63v.132a.38.38,0,0,1-.208.338,1.047,1.047,0,0,0-.108.062.379.379,0,0,1-.4.013l-.114-.066-.315.545.115.066a.38.38,0,0,1,.188.352l0,.061,0,.062a.38.38,0,0,1-.188.351l-.115.066.315.545.115-.067a.38.38,0,0,1,.4.013,1.152,1.152,0,0,0,.107.062.379.379,0,0,1,.209.338Zm.315-.575a.928.928,0,1,1,.928-.928.929.929,0,0,1-.928.928m0-1.543a.615.615,0,1,0,.616.615.616.616,0,0,0-.616-.615" transform="translate(-283.457 -50.008)" fill="#dbdbdb" />
                                <rect id="Rectangle_19232" data-name="Rectangle 19232" width="115.74" height="6.56" transform="translate(88.953 49.922)" fill="#ebebeb" />
                                <rect id="Rectangle_19233" data-name="Rectangle 19233" width="1.971" height="1.971" transform="translate(94.087 52.217)" fill="#fff" />
                                <path id="Path_16605" data-name="Path 16605" d="M257.007,131.948h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -77.761)" fill="#fff" />
                                <path id="Path_16606" data-name="Path 16606" d="M245.795,129.977l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -77.761)" fill={primaryColor} />
                                <rect id="Rectangle_19234" data-name="Rectangle 19234" width="15.697" height="0.801" transform="translate(106.645 52.801)" fill="#263238" />
                                <rect id="Rectangle_19235" data-name="Rectangle 19235" width="14.25" height="0.801" transform="translate(181.836 52.801)" fill="#263238" />
                                <rect id="Rectangle_19236" data-name="Rectangle 19236" width="48.714" height="0.801" transform="translate(127.134 52.801)" fill="#455a64" />
                                <rect id="Rectangle_19237" data-name="Rectangle 19237" width="1.971" height="1.971" transform="translate(94.087 58.776)" fill="#fff" />
                                <rect id="Rectangle_19238" data-name="Rectangle 19238" width="1.971" height="1.971" transform="translate(94.087 58.776)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16607" data-name="Path 16607" d="M257.007,148.276h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -87.529)" fill="#fff" />
                                <path id="Path_16608" data-name="Path 16608" d="M257.007,148.276h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -87.529)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16609" data-name="Path 16609" d="M245.795,146.306l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -87.53)" fill={primaryColor} />
                                <rect id="Rectangle_19239" data-name="Rectangle 19239" width="15.697" height="0.801" transform="translate(106.645 59.361)" fill="#263238" />
                                <rect id="Rectangle_19240" data-name="Rectangle 19240" width="14.25" height="0.801" transform="translate(181.836 59.361)" fill="#263238" />
                                <rect id="Rectangle_19241" data-name="Rectangle 19241" width="48.714" height="0.801" transform="translate(127.134 59.361)" fill="#455a64" />
                                <rect id="Rectangle_19242" data-name="Rectangle 19242" width="115.74" height="6.56" transform="translate(88.953 63.041)" fill="#ebebeb" />
                                <rect id="Rectangle_19243" data-name="Rectangle 19243" width="1.971" height="1.971" transform="translate(94.087 65.336)" fill="#fff" />
                                <path id="Path_16610" data-name="Path 16610" d="M257.007,164.605h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -97.298)" fill="#fff" />
                                <path id="Path_16611" data-name="Path 16611" d="M245.795,162.635l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -97.298)" fill="#fff" />
                                <rect id="Rectangle_19244" data-name="Rectangle 19244" width="15.697" height="0.801" transform="translate(106.645 65.921)" fill="#dbdbdb" />
                                <rect id="Rectangle_19245" data-name="Rectangle 19245" width="14.25" height="0.801" transform="translate(181.836 65.921)" fill="#dbdbdb" />
                                <rect id="Rectangle_19246" data-name="Rectangle 19246" width="48.714" height="0.801" transform="translate(127.134 65.921)" fill="#c7c7c7" />
                                <rect id="Rectangle_19247" data-name="Rectangle 19247" width="1.971" height="1.971" transform="translate(94.087 71.896)" fill="#fff" />
                                <rect id="Rectangle_19248" data-name="Rectangle 19248" width="1.971" height="1.971" transform="translate(94.087 71.896)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16612" data-name="Path 16612" d="M257.007,180.934h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -107.067)" fill="#fff" />
                                <path id="Path_16613" data-name="Path 16613" d="M257.007,180.934h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -107.067)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16614" data-name="Path 16614" d="M245.795,178.963l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -107.067)" fill={primaryColor} />
                                <rect id="Rectangle_19249" data-name="Rectangle 19249" width="15.697" height="0.801" transform="translate(106.645 72.481)" fill="#dbdbdb" />
                                <rect id="Rectangle_19250" data-name="Rectangle 19250" width="14.25" height="0.801" transform="translate(181.836 72.481)" fill="#dbdbdb" />
                                <rect id="Rectangle_19251" data-name="Rectangle 19251" width="48.714" height="0.801" transform="translate(127.134 72.481)" fill="#c7c7c7" />
                                <rect id="Rectangle_19252" data-name="Rectangle 19252" width="115.74" height="6.56" transform="translate(88.953 76.161)" fill="#ebebeb" />
                                <rect id="Rectangle_19253" data-name="Rectangle 19253" width="1.971" height="1.971" transform="translate(94.087 78.456)" fill="#fff" />
                                <path id="Path_16615" data-name="Path 16615" d="M257.007,197.262h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -116.836)" fill="#fff" />
                                <path id="Path_16616" data-name="Path 16616" d="M245.795,195.292l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -116.836)" fill={primaryColor} />
                                <rect id="Rectangle_19254" data-name="Rectangle 19254" width="15.697" height="0.801" transform="translate(106.645 79.041)" fill="#dbdbdb" />
                                <rect id="Rectangle_19255" data-name="Rectangle 19255" width="14.25" height="0.801" transform="translate(181.836 79.041)" fill="#dbdbdb" />
                                <rect id="Rectangle_19256" data-name="Rectangle 19256" width="48.714" height="0.801" transform="translate(127.134 79.041)" fill="#c7c7c7" />
                                <rect id="Rectangle_19257" data-name="Rectangle 19257" width="1.971" height="1.971" transform="translate(94.087 85.016)" fill="#fff" />
                                <rect id="Rectangle_19258" data-name="Rectangle 19258" width="1.971" height="1.971" transform="translate(94.087 85.016)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16617" data-name="Path 16617" d="M257.007,213.591h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -126.605)" fill="#fff" />
                                <path id="Path_16618" data-name="Path 16618" d="M257.007,213.591h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -126.605)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16619" data-name="Path 16619" d="M245.795,211.62l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -126.605)" fill="#fff" />
                                <path id="Path_16620" data-name="Path 16620" d="M245.795,211.62l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -126.605)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <rect id="Rectangle_19259" data-name="Rectangle 19259" width="15.697" height="0.801" transform="translate(106.645 85.6)" fill="#dbdbdb" />
                                <rect id="Rectangle_19260" data-name="Rectangle 19260" width="14.25" height="0.801" transform="translate(181.836 85.6)" fill="#dbdbdb" />
                                <rect id="Rectangle_19261" data-name="Rectangle 19261" width="48.714" height="0.801" transform="translate(127.134 85.6)" fill="#c7c7c7" />
                                <rect id="Rectangle_19262" data-name="Rectangle 19262" width="115.74" height="6.56" transform="translate(88.953 89.281)" fill="#ebebeb" />
                                <rect id="Rectangle_19263" data-name="Rectangle 19263" width="1.971" height="1.971" transform="translate(94.087 91.575)" fill="#fff" />
                                <path id="Path_16621" data-name="Path 16621" d="M257.007,229.92h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -136.374)" fill="#fff" />
                                <path id="Path_16622" data-name="Path 16622" d="M245.795,227.949l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -136.374)" fill={primaryColor} />
                                <rect id="Rectangle_19264" data-name="Rectangle 19264" width="15.697" height="0.801" transform="translate(106.645 92.16)" fill="#dbdbdb" />
                                <rect id="Rectangle_19265" data-name="Rectangle 19265" width="14.25" height="0.801" transform="translate(181.836 92.16)" fill="#dbdbdb" />
                                <rect id="Rectangle_19266" data-name="Rectangle 19266" width="48.714" height="0.801" transform="translate(127.134 92.16)" fill="#c7c7c7" />
                                <rect id="Rectangle_19267" data-name="Rectangle 19267" width="1.971" height="1.971" transform="translate(94.087 98.135)" fill="#fff" />
                                <rect id="Rectangle_19268" data-name="Rectangle 19268" width="1.971" height="1.971" transform="translate(94.087 98.135)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16623" data-name="Path 16623" d="M257.007,246.248h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -146.142)" fill="#fff" />
                                <path id="Path_16624" data-name="Path 16624" d="M257.007,246.248h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -146.142)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16625" data-name="Path 16625" d="M245.795,244.278l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -146.142)" fill="#fff" />
                                <path id="Path_16626" data-name="Path 16626" d="M245.795,244.278l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -146.142)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <rect id="Rectangle_19269" data-name="Rectangle 19269" width="15.697" height="0.801" transform="translate(106.645 98.72)" fill="#dbdbdb" />
                                <rect id="Rectangle_19270" data-name="Rectangle 19270" width="14.25" height="0.801" transform="translate(181.836 98.72)" fill="#dbdbdb" />
                                <rect id="Rectangle_19271" data-name="Rectangle 19271" width="48.714" height="0.801" transform="translate(127.134 98.72)" fill="#c7c7c7" />
                                <rect id="Rectangle_19272" data-name="Rectangle 19272" width="115.74" height="6.56" transform="translate(88.953 102.4)" fill="#ebebeb" />
                                <rect id="Rectangle_19273" data-name="Rectangle 19273" width="1.971" height="1.971" transform="translate(94.087 104.695)" fill="#fff" />
                                <path id="Path_16627" data-name="Path 16627" d="M257.007,262.577h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -155.911)" fill="#fff" />
                                <path id="Path_16628" data-name="Path 16628" d="M245.795,260.606l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -155.911)" fill="#fff" />
                                <rect id="Rectangle_19274" data-name="Rectangle 19274" width="15.697" height="0.801" transform="translate(106.645 105.28)" fill="#dbdbdb" />
                                <rect id="Rectangle_19275" data-name="Rectangle 19275" width="14.25" height="0.801" transform="translate(181.836 105.28)" fill="#dbdbdb" />
                                <rect id="Rectangle_19276" data-name="Rectangle 19276" width="48.714" height="0.801" transform="translate(127.134 105.28)" fill="#c7c7c7" />
                                <rect id="Rectangle_19277" data-name="Rectangle 19277" width="1.971" height="1.971" transform="translate(94.087 111.255)" fill="#fff" />
                                <rect id="Rectangle_19278" data-name="Rectangle 19278" width="1.971" height="1.971" transform="translate(94.087 111.255)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16629" data-name="Path 16629" d="M257.007,278.905h-1.971l.8-.985-.8-.986h1.971l.8.986Z" transform="translate(-152.579 -165.68)" fill="#fff" />
                                <path id="Path_16630" data-name="Path 16630" d="M257.007,278.905h-1.971l.8-.985-.8-.986h1.971l.8.986Z" transform="translate(-152.579 -165.68)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <path id="Path_16631" data-name="Path 16631" d="M245.795,276.935l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -165.68)" fill="#fff" />
                                <path id="Path_16632" data-name="Path 16632" d="M245.795,276.935l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -165.68)" fill="none" stroke="#ebebeb" stroke-miterlimit="10" stroke-width="0.5" />
                                <rect id="Rectangle_19279" data-name="Rectangle 19279" width="15.697" height="0.801" transform="translate(106.645 111.839)" fill="#dbdbdb" />
                                <rect id="Rectangle_19280" data-name="Rectangle 19280" width="14.25" height="0.801" transform="translate(181.836 111.839)" fill="#dbdbdb" />
                                <rect id="Rectangle_19281" data-name="Rectangle 19281" width="48.714" height="0.801" transform="translate(127.134 111.839)" fill="#c7c7c7" />
                                <rect id="Rectangle_19282" data-name="Rectangle 19282" width="115.74" height="6.56" transform="translate(88.953 115.52)" fill="#ebebeb" />
                                <rect id="Rectangle_19283" data-name="Rectangle 19283" width="1.971" height="1.971" transform="translate(94.087 117.815)" fill="#fff" />
                                <path id="Path_16633" data-name="Path 16633" d="M257.007,295.234h-1.971l.8-.985-.8-.985h1.971l.8.985Z" transform="translate(-152.579 -175.449)" fill="#fff" />
                                <path id="Path_16634" data-name="Path 16634" d="M245.795,293.263l.32.649.716.1-.518.5.123.713-.64-.337-.64.337.123-.713-.518-.5.716-.1Z" transform="translate(-146.43 -175.449)" fill={primaryColor} />
                                <rect id="Rectangle_19284" data-name="Rectangle 19284" width="15.697" height="0.801" transform="translate(106.645 118.399)" fill="#dbdbdb" />
                                <rect id="Rectangle_19285" data-name="Rectangle 19285" width="14.25" height="0.801" transform="translate(181.836 118.399)" fill="#dbdbdb" />
                                <rect id="Rectangle_19286" data-name="Rectangle 19286" width="48.714" height="0.801" transform="translate(127.134 118.399)" fill="#c7c7c7" />
                                <rect id="Rectangle_19287" data-name="Rectangle 19287" width="21.91" height="2.348" transform="translate(100.433 44.004)" fill="#dbdbdb" />
                                <rect id="Rectangle_19288" data-name="Rectangle 19288" width="2.984" height="2.348" transform="translate(94.087 44.004)" fill="#dbdbdb" />
                                <path id="Path_16635" data-name="Path 16635" d="M237.19,109.535c-.121.13-.243.255-.365.382s-.245.25-.369.374-.258.257-.387.385l-.012.012-.344.337-.017.016-.016-.016-.344-.337-.012-.012q-.195-.191-.388-.385t-.368-.374c-.122-.128-.245-.252-.365-.382.128.111.254.228.381.343s.253.232.379.351.28.264.419.4h0l.314.3c.1-.1.209-.2.313-.3h0q.209-.2.419-.4.189-.177.378-.35c.127-.114.254-.231.382-.343" transform="translate(-140.114 -65.531)" fill="#fff" />
                                <path id="Path_16636" data-name="Path 16636" d="M235.4,112.241l0,0-.047.061c-.075.1-.152.19-.23.28-.1.112-.192.218-.29.324s-.2.207-.3.306-.2.2-.309.285c.091-.122.185-.235.28-.346s.193-.218.29-.324.2-.208.3-.307c.08-.079.162-.157.245-.23l.058-.05,0,0" transform="translate(-140.125 -67.15)" fill="#fff" />
                                <path id="Path_16637" data-name="Path 16637" d="M239.868,113.5c-.105-.089-.208-.186-.308-.285s-.2-.2-.3-.306-.2-.213-.29-.324c-.078-.09-.155-.182-.229-.279l-.048-.062,0,0,0,0,.058.051c.084.073.165.151.245.23.1.1.2.2.3.307s.2.213.29.324.19.224.28.346" transform="translate(-142.8 -67.149)" fill="#fff" />
                                <rect id="Rectangle_19289" data-name="Rectangle 19289" width="21.91" height="2.348" transform="translate(137.305 44.004)" fill="#dbdbdb" />
                                <path id="Path_16638" data-name="Path 16638" d="M327.862,110.778a.5.5,0,1,1-.5-.5.5.5,0,0,1,.5.5" transform="translate(-195.553 -65.977)" fill="#dbdbdb" />
                                <path id="Path_16639" data-name="Path 16639" d="M327.683,113.65v.182h-1.719v-.182a.859.859,0,0,1,1.719,0" transform="translate(-195.012 -67.48)" fill="#dbdbdb" />
                                <path id="Path_16640" data-name="Path 16640" d="M331.03,110.032a.5.5,0,1,1-.5-.5.5.5,0,0,1,.5.5" transform="translate(-197.448 -65.531)" fill="#dbdbdb" />
                                <path id="Path_16641" data-name="Path 16641" d="M330.852,112.9v.182h-1.719V112.9a.859.859,0,0,1,1.719,0" transform="translate(-196.908 -67.033)" fill="#dbdbdb" />
                                <rect id="Rectangle_19290" data-name="Rectangle 19290" width="21.91" height="2.348" transform="translate(174.177 44.004)" fill="#dbdbdb" />
                                <path id="Path_16642" data-name="Path 16642" d="M420.745,109.54H417.76v2.346h2.985l.892-1.173Zm.024,1.35a.178.178,0,0,1-.177-.177.176.176,0,0,1,.177-.177.173.173,0,0,1,.177.177.176.176,0,0,1-.177.177" transform="translate(-249.93 -65.534)" fill="#dbdbdb" />
                                <rect id="Rectangle_19291" data-name="Rectangle 19291" width="36.653" height="94.374" transform="translate(52.299 27.703)" fill={primaryColor} />
                                <rect id="Rectangle_19292" data-name="Rectangle 19292" width="26.406" height="7.001" transform="translate(57.423 41.677)" fill="#fff" />
                                <rect id="Rectangle_19293" data-name="Rectangle 19293" width="3.682" height="0.592" transform="translate(57.423 33.607)" fill="#fff" />
                                <rect id="Rectangle_19294" data-name="Rectangle 19294" width="3.682" height="0.592" transform="translate(57.423 36.26)" fill="#fff" />
                                <rect id="Rectangle_19295" data-name="Rectangle 19295" width="3.682" height="0.592" transform="translate(57.423 34.949)" fill="#fff" />
                                <rect id="Rectangle_19296" data-name="Rectangle 19296" width="5.117" height="3.245" transform="translate(64.6 33.607)" fill="#ebebeb" />
                                <path id="Path_16643" data-name="Path 16643" d="M165.919,83.655V86.9H160.8V83.655c.22.125.435.255.653.383s.433.26.648.392q.36.219.718.444l.008,0-.006.005.537.336c.178-.112.357-.225.536-.336l-.006-.005.008,0c.239-.149.478-.3.718-.444q.323-.2.648-.391c.219-.128.434-.258.654-.383" transform="translate(-96.202 -50.048)" fill="#ebebeb" />
                                <path id="Path_16644" data-name="Path 16644" d="M165.919,83.655c-.206.145-.417.284-.626.427s-.42.28-.632.418-.442.287-.663.429l-.02.014-.589.376-.029.018-.029-.018-.589-.376-.02-.013q-.333-.213-.664-.43c-.211-.137-.421-.278-.631-.417s-.419-.282-.626-.427c.22.125.435.255.653.383s.433.26.648.392q.36.219.718.444h0l.537.336c.178-.112.357-.225.536-.336l0,0c.239-.149.478-.3.718-.444q.323-.2.648-.391c.219-.128.434-.258.654-.383" transform="translate(-96.202 -50.048)" fill="#fff" />
                                <path id="Path_16645" data-name="Path 16645" d="M162.848,86.677l-.006.005-.081.069c-.129.108-.26.212-.393.313-.163.125-.33.243-.5.362s-.337.231-.51.342-.347.219-.528.318c.155-.136.317-.263.48-.386s.33-.244.5-.362.338-.232.511-.343c.137-.088.277-.175.42-.257l.1-.056.008,0" transform="translate(-96.22 -51.856)" fill="#fff" />
                                <path id="Path_16646" data-name="Path 16646" d="M170.506,88.085c-.181-.1-.356-.207-.528-.318s-.342-.226-.511-.342-.334-.237-.5-.362c-.133-.1-.264-.2-.392-.312l-.081-.069-.006-.006.008,0,.1.057c.143.082.282.168.42.257.173.111.342.226.51.343s.334.237.5.362.325.25.48.386" transform="translate(-100.801 -51.856)" fill="#fff" />
                                <g id="Group_11673" data-name="Group 11673" transform="translate(52.299 54.784)" opacity="0.1">
                                    <g id="Group_11672" data-name="Group 11672">
                                        <g id="Group_11671" data-name="Group 11671" clip-path="url(#clip-path-3)">
                                            <rect id="Rectangle_19297" data-name="Rectangle 19297" width="36.653" height="4.977" transform="translate(0 0)" fill="#020100" />
                                        </g>
                                    </g>
                                </g>
                                <rect id="Rectangle_19299" data-name="Rectangle 19299" width="21.255" height="2.923" transform="translate(57.423 55.853)" fill="#fff" />
                                <rect id="Rectangle_19300" data-name="Rectangle 19300" width="21.255" height="2.923" transform="translate(57.423 61.58)" fill="#fff" />
                                <rect id="Rectangle_19301" data-name="Rectangle 19301" width="21.255" height="2.923" transform="translate(57.423 67.307)" fill="#fff" />
                                <rect id="Rectangle_19302" data-name="Rectangle 19302" width="21.255" height="2.923" transform="translate(57.423 73.033)" fill="#fff" />
                                <rect id="Rectangle_19303" data-name="Rectangle 19303" width="21.255" height="2.923" transform="translate(61.935 78.76)" fill="#455a64" />
                                <rect id="Rectangle_19304" data-name="Rectangle 19304" width="21.255" height="2.923" transform="translate(61.935 84.487)" fill="#455a64" />
                                <rect id="Rectangle_19305" data-name="Rectangle 19305" width="21.255" height="2.923" transform="translate(57.423 90.214)" fill="#fff" />
                                <rect id="Rectangle_19306" data-name="Rectangle 19306" width="21.255" height="2.923" transform="translate(57.423 95.941)" fill="#fff" />
                                <path id="Path_16647" data-name="Path 16647" d="M129.929,227.483q0,.4.033.8" transform="translate(-77.732 -136.095)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                                <path id="Path_16648" data-name="Path 16648" d="M130.872,176.043c2.646,10.107,18.786,22.338,36.747,14.558,19.36-8.386,18.639-40.279,43.639-53.748" transform="translate(-78.296 -81.874)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" stroke-dasharray="4.007 5.009" />
                                <path id="Path_16649" data-name="Path 16649" d="M333.191,135.156q.357-.181.72-.358" transform="translate(-199.336 -80.645)" fill="none" stroke="#263238" stroke-miterlimit="10" stroke-width="0.75" />
                                <path id="Path_16650" data-name="Path 16650" d="M327.339,117.168l-2.52,6.921,5.29-4.131,20.227-7.335Z" transform="translate(-194.327 -67.378)" fill={primaryColor} />
                                <g id="Group_11676" data-name="Group 11676" transform="translate(130.492 45.244)" opacity="0.1">
                                    <g id="Group_11675" data-name="Group 11675">
                                        <g id="Group_11674" data-name="Group 11674" clip-path="url(#clip-path-4)">
                                            <path id="Path_16651" data-name="Path 16651" d="M327.339,117.168l-2.52,6.921,5.29-4.131,20.227-7.335Z" transform="translate(-324.819 -112.622)" />
                                        </g>
                                    </g>
                                </g>
                                <path id="Path_16652" data-name="Path 16652" d="M330.788,132.493l-5.969,2.519,5.291-4.132Z" transform="translate(-194.327 -78.301)" fill="#263238" />
                                <path id="Path_16653" data-name="Path 16653" d="M337.987,119.957l20.226-7.335-16.59,16.19Z" transform="translate(-202.205 -67.377)" fill={primaryColor} />
                                <path id="Path_16654" data-name="Path 16654" d="M318.574,115.855l-8.406-5.427,31.4.881Z" transform="translate(-185.563 -66.065)" fill={primaryColor} />
                                <path id="Path_16655" data-name="Path 16655" d="M501.585,39.221a19.6,19.6,0,1,1-19.6-19.6,19.6,19.6,0,0,1,19.6,19.6" transform="translate(-276.632 -11.741)" fill="#455a64" />
                                <path id="Path_16661" data-name="Path 16661" d="M586.889,208.006a30.332,30.332,0,0,1,.705-4.959,11.031,11.031,0,0,1,1.772-4.182,5.307,5.307,0,0,1,3.473-2.164,4.849,4.849,0,0,1,3.655,1.3,17.634,17.634,0,0,1,3.132,3.83,47,47,0,0,1,2.705,4.735c.027.053-.055.1-.082.045a29.522,29.522,0,0,0-2.419-3.962,24.934,24.934,0,0,0-2.786-3.373,5.481,5.481,0,0,0-3.473-1.807,4.266,4.266,0,0,0-3.4,1.75,10.466,10.466,0,0,0-1.712,4.02,30.032,30.032,0,0,0-.7,4.721c-.266,3.267-.338,6.553-.412,9.828q-.063,2.78-.084,5.561-.013,1.409-.023,2.818a17.325,17.325,0,0,1-.109,2.717c-.03.182-.329.165-.36-.009a16.152,16.152,0,0,1-.125-2.574q-.018-1.335-.032-2.671-.023-2.6-.019-5.193c.01-3.473.052-6.961.289-10.427" transform="translate(-350.94 -117.662)" fill="#263238" />
                                <path id="Path_16662" data-name="Path 16662" d="M597.9,203.733a7.62,7.62,0,0,1,1.012-2.843c.946-1.286,1.966-.727,1.023.7a8.51,8.51,0,0,0-1.328,3.511,13.473,13.473,0,0,0,3.508,3.389,15.124,15.124,0,0,1-.158-3.511c.369-2.805,2.065-2.648,1.646-.419a19.286,19.286,0,0,0-.384,4.507,9.53,9.53,0,0,0,2.05,1c-.11-.325-.227-.716-.356-1.188-.681-2.515,1-3.3,1.049-1.885a19.843,19.843,0,0,0,.653,3.568c.92.309,1.683.52,2.15.684a7.846,7.846,0,0,1,3.146,2.15,9.9,9.9,0,0,0,2.143-2.774c-.586-.5-1.513-1.255-2.8-2.162-2.411-1.7-1.573-3.852.341-2.515a11.017,11.017,0,0,1,2.934,3.669,14.915,14.915,0,0,0,1.094-5.021c-1.895-1.778-6.578-4.255-5.166-4.255,1.27,0,4.113,1.863,5.157,2.582a13.434,13.434,0,0,0-.161-1.46c-1.784-1.169-7.58-2.988-6.657-3.511,1.015-.574,5.059,1.3,6.281,1.891a7.348,7.348,0,0,0-1.071-2.219c-2.465-3.3-6.91-4.437-8.849-3.808-1.372.445-.971,3.927-1.266,3.808-5.512-2.218-8.019-.65-5.992,6.112m13.513-.381c.391-.473,2.2.917,1.677,1.677s-2.181-1.072-1.677-1.677m-9.453-2.969c.476.3-.227,2.061-.961,1.529-.624-.454.482-1.834.961-1.529" transform="translate(-357.284 -115.852)" fill={primaryColor} />
                                <path id="Path_16663" data-name="Path 16663" d="M608.817,207.088c.249-.492.509-.981.792-1.454a8.465,8.465,0,0,1,.911-1.321c-.079-.1-.155-.2-.236-.29-.033-.039.024-.093.057-.055.093.105.18.213.27.32a.082.082,0,0,1,.038.045c.341.408.661.828.968,1.257a14.671,14.671,0,0,1,1.6.464c.541.181,1.076.369,1.6.591.055.023.028.114-.027.09-.521-.231-1.049-.453-1.589-.636-.51-.173-1.029-.305-1.547-.452.168.237.333.477.492.72a18.932,18.932,0,0,1,2.831,1.155c.956.454,1.877.983,2.794,1.513.052.03,0,.11-.049.079a30.86,30.86,0,0,0-2.779-1.438c-.887-.415-1.8-.756-2.7-1.151,2.251,3.513,3.6,7.567,5.406,11.319.026.055-.059.1-.082.045-.551-1.316-1.186-2.6-1.786-3.893-.539-1.162-1.065-2.331-1.6-3.493q-.237-.51-.485-1.018a50.785,50.785,0,0,0,.367,5.64.046.046,0,1,1-.092.01c-.13-2.005-.484-3.979-.469-6,0-.012.008-.016.011-.026-.382-.766-.789-1.523-1.228-2.259a26.284,26.284,0,0,0-1.878,5.424c-.01.057-.105.032-.089-.027a26.662,26.662,0,0,1,1.887-5.529,21.315,21.315,0,0,0-1.585-2.285c-.25.467-.571.908-.855,1.354s-.551.874-.807,1.323a19.114,19.114,0,0,0-1.408,2.976c-.021.057-.112.027-.089-.027.429-1,.864-2,1.356-2.975" transform="translate(-363.42 -122.019)" fill="#263238" />
                                <path id="Path_16664" data-name="Path 16664" d="M566.654,217.4c1.445-1.449,3.123-3.35,5.356-3.346,2.154,0,3.74,1.739,4.651,3.528a18.5,18.5,0,0,1,1.564,6.053,73.426,73.426,0,0,1,.571,7.441c.039,1.382.034,2.765,0,4.146a27.4,27.4,0,0,1-.317,4.184c-.04.223-.4.231-.411-.01-.065-1.163-.018-2.332-.012-3.5q.009-1.72-.054-3.44c-.085-2.318-.254-4.633-.526-6.937a33.608,33.608,0,0,0-1.029-6.026c-.543-1.753-1.561-3.759-3.339-4.529-2.426-1.051-4.8.869-6.382,2.49-.042.043-.115-.016-.072-.059" transform="translate(-339.001 -128.061)" fill="#263238" />
                                <path id="Path_16665" data-name="Path 16665" d="M548.306,215.662v0c.011.3.028.589.048.875a5.241,5.241,0,0,1,2.367-.981c2.05-.218,1.92,1.341.55,1.406a7.793,7.793,0,0,0-2.813.564,17.487,17.487,0,0,0,1.191,4.913,39.52,39.52,0,0,0,5.417-.685c-.509-1.086-1.67-2.53-1.079-2.647.606-.12,1.239,1.169,1.567,2.547a25.866,25.866,0,0,0,3.94-1.084c-.9-.781-2.3-1.752-1.763-2.166s1.639.954,2.3,1.948a7.929,7.929,0,0,0,1.7-.949c-1.215-1.785-4.176-2.148-3.79-3.438.394-1.316,3.157.5,4.4,2.888a7.063,7.063,0,0,0,1.735-5.491c-1.272-.148-3.721.121-3.544-.793.167-.869,1.946-.74,3.395-.206a5.079,5.079,0,0,0-.85-2.014c-1.341-1.722-5.434.468-5.434.468s.438-4.114-1.123-4.825a4.665,4.665,0,0,0-4.08.532,3.43,3.43,0,0,1,2.407.977c.555.791-.32,1.22-.877.856a4.446,4.446,0,0,0-2.445-1.18,8.105,8.105,0,0,0-1.478,1.635c-.151.213-.292.437-.432.68a5.621,5.621,0,0,1,4.791.087c1.774.881.783,2.724-.743,2.008-1.511-.707-2.981-1.42-4.6-1.014a11.456,11.456,0,0,0-.778,4.587,8.021,8.021,0,0,1,2.628-1.261c1.764-.463,2.3.591.36.788a7.752,7.752,0,0,0-2.974.972m2.146-2.832c-.122-.572,1.129-.736,1.337-.272.174.4-1.219.844-1.337.272m10.7,3.165c.118-.312.955.043.879.431-.1.519-.993-.12-.879-.431M555.247,218c.61-.244,1.563,1.273.9,1.62s-1.4-1.42-.9-1.62" transform="translate(-328.023 -123.084)" fill={primaryColor} />
                                <path id="Path_16666" data-name="Path 16666" d="M552.258,229.918c.919-1.537,1.828-3.083,2.793-4.591q.674-1.055,1.409-2.067c-.074-.015-.147-.037-.219-.052q-.182-.039-.366-.07c-.283-.049-.567-.09-.852-.119q-.4-.04-.808-.045a4.018,4.018,0,0,0-.862.045c-.035.007-.04-.047-.007-.046.287.012.581-.026.869-.03s.559,0,.838.02c.262.016.523.042.782.082q.215.033.428.075a2.62,2.62,0,0,1,.258.055q.747-1.027,1.549-2.013a18.414,18.414,0,0,0-5.032-1.606c-.03,0-.019-.05.011-.045a17.078,17.078,0,0,1,5.106,1.549q.441-.541.887-1.077c.333-.4.689-.791.989-1.215.02-.028.06,0,.037.027a17.747,17.747,0,0,0-1.167,1.428s.005,0,.008,0a17.724,17.724,0,0,1,1.871,1.5q.917.813,1.788,1.678c.021.021-.011.055-.032.034-.573-.57-1.167-1.114-1.789-1.63s-1.275-.99-1.9-1.5c0,0,0-.006,0-.01-.143.189-.285.378-.428.563-.072.094-.144.188-.216.282a.058.058,0,0,1-.016.02q-.359.467-.714.937a7.508,7.508,0,0,1,.71,1.282c.206.434.385.882.55,1.333a19.526,19.526,0,0,1,.767,2.88c.006.029-.039.043-.045.014a16.9,16.9,0,0,0-.837-2.824c-.178-.47-.382-.928-.592-1.385-.191-.416-.422-.815-.6-1.236-.206.274-.412.549-.613.826-.059.081-.115.163-.173.244.009.018,0,.041-.02.042h-.009c-.632.882-1.233,1.785-1.817,2.7a.823.823,0,0,1,.08.2c.038.111.073.223.106.335a6.8,6.8,0,0,1,.16.684c.039.217.07.435.092.654a6,6,0,0,0,.055.744.023.023,0,1,1-.045,0,2.436,2.436,0,0,0-.051-.694c-.033-.237-.078-.473-.132-.707-.05-.211-.106-.421-.169-.629-.031-.1-.065-.208-.1-.31-.019-.055-.046-.112-.064-.17q-.41.644-.811,1.293c-.529.86-1.054,1.722-1.582,2.582-.026.043-.1.006-.07-.038" transform="translate(-330.392 -130.872)" fill="#263238" />
                                <path id="Path_16667" data-name="Path 16667" d="M587.56,253.6c.024-.871.162-1.748.256-2.615s.193-1.74.286-2.61a39.5,39.5,0,0,1,.673-5.037,7.323,7.323,0,0,1,2.049-3.767,3.345,3.345,0,0,1,2.01-.959,3.463,3.463,0,0,1,2.134.658,7.438,7.438,0,0,1,1.795,1.891,18.463,18.463,0,0,1,1.4,2.272c.016.03-.031.049-.048.02a11.619,11.619,0,0,0-3.027-3.392,3.57,3.57,0,0,0-1.908-.8,2.587,2.587,0,0,0-1.85.738,6.947,6.947,0,0,0-1.954,3.457,35.069,35.069,0,0,0-.756,4.653c-.123.922-.241,1.844-.362,2.766s-.2,1.857-.392,2.762a.153.153,0,0,1-.3-.041" transform="translate(-351.515 -142.749)" fill="#263238" />
                                <path id="Path_16668" data-name="Path 16668" d="M596.572,246.2a4.868,4.868,0,0,0,.948.967,5.932,5.932,0,0,1,.112-3.164c.667-1.249,1.456-.626,1.058.46a8.82,8.82,0,0,0-.642,3.078,13.385,13.385,0,0,0,2.894,1.365,4.871,4.871,0,0,1-.1-1.49c.133-1.335,1.155-1.31.89.079a8.518,8.518,0,0,0-.14,1.631,30.085,30.085,0,0,0,4.51,1.017,13.188,13.188,0,0,0,1.866-3.323c-1.087-.272-3.287-.334-3.24-1.111.063-1.045,2.194-.24,3.441.517a8.335,8.335,0,0,0,.376-2.811,13.993,13.993,0,0,0-3-.5c-2.238-.193-2.128-1.462-.506-1.433a8.891,8.891,0,0,1,3.427,1.135c-.467-3.044-2.789-4.994-4.65-5.069-3.129-.127-2,3.769-2,3.769a8.286,8.286,0,0,0-4.122-1.453c-2,.043-3.267,3.354-1.127,6.331m8.359-5.63c-.07-.438,1.327-.543,1.4.019.083.631-1.328.427-1.4-.019m2.247,4.569c-.029-.286.574-.336.71.025.092.235-.686.258-.71-.025m-7.515.267c.472.066.74,1.753-.072,1.72-.62-.026-.328-1.777.072-1.72" transform="translate(-356.243 -142.12)" fill={primaryColor} />
                                <path id="Path_16669" data-name="Path 16669" d="M607.541,260.821c.078-.21.147-.423.214-.638-.061.217-.115.435-.156.654a.03.03,0,1,1-.058-.016" transform="translate(-363.468 -155.658)" fill="#263238" />
                                <path id="Path_16670" data-name="Path 16670" d="M608.757,250.419c.147-.432.311-.859.479-1.284.09-.227.183-.452.282-.674a2.745,2.745,0,0,1,.279-.564,4.339,4.339,0,0,1-.253-.546.017.017,0,0,1,.029-.017,6.589,6.589,0,0,1,.61.946c.087.147.169.3.255.446a4.4,4.4,0,0,1,.527.082q.281.051.561.115t.557.129c.184.044.368.105.555.134a.03.03,0,1,1-.016.058,3.6,3.6,0,0,0-.586-.152c-.18-.039-.359-.071-.542-.1s-.364-.049-.547-.067c-.137-.014-.276-.021-.414-.034.074.131.152.261.225.394.011.021.022.042.033.063a1.762,1.762,0,0,1,.45.053c.162.029.323.06.484.1.326.072.652.149.974.239.677.189,1.346.4,2.014.624.037.012.013.069-.024.056a19.47,19.47,0,0,0-2.023-.561c-.32-.072-.64-.146-.961-.213l-.482-.1a3.019,3.019,0,0,1-.374-.083c.344.636.669,1.283.967,1.942.649,1.436,1.2,2.916,1.752,4.389.014.037-.043.061-.056.024a40.142,40.142,0,0,0-1.865-4.291c-.125-.255-.254-.508-.38-.762-.011.051-.023.1-.033.155q-.048.261-.092.523c-.059.349-.118.7-.17,1.048a12.3,12.3,0,0,0-.252,2.175c0,.045-.067.043-.06,0,.056-.347.056-.709.078-1.059.023-.366.05-.733.092-1.1s.086-.7.144-1.052c.029-.174.06-.349.092-.523a1.045,1.045,0,0,1,.1-.378c-.184-.368-.365-.736-.55-1.1q-.261-.516-.529-1.028c-.076-.144-.157-.29-.238-.437a4.187,4.187,0,0,1-.208.527q-.12.3-.242.592c-.18.437-.346.878-.513,1.319-.237.626-.5,1.267-.693,1.919.191-.64.353-1.291.567-1.922" transform="translate(-363.858 -147.967)" fill="#263238" />
                                <path id="Path_16671" data-name="Path 16671" d="M608.077,259.957c.014-.051.03-.1.045-.151-.015.051-.03.1-.045.151" transform="translate(-363.79 -155.432)" fill="#263238" />
                                <path id="Path_16672" data-name="Path 16672" d="M574.535,271.708l.22,4.659.566,12.007h12.388l.567-12.007.22-4.659Z" transform="translate(-343.723 -162.553)" fill="#37474f" />
                                <path id="Path_16673" data-name="Path 16673" d="M574.535,271.708l.22,4.659h13.521l.22-4.659Z" transform="translate(-343.723 -162.553)" fill="#263238" />
                                <rect id="Rectangle_19309" data-name="Rectangle 19309" width="15.723" height="3.767" transform="translate(229.931 107.272)" fill="#37474f" />
                                <rect id="Rectangle_19310" data-name="Rectangle 19310" width="2.175" height="1.972" transform="translate(240.809 125.821)" fill="#37474f" />
                                <rect id="Rectangle_19311" data-name="Rectangle 19311" width="2.175" height="0.986" transform="translate(240.809 125.821)" fill="#263238" />
                                <rect id="Rectangle_19312" data-name="Rectangle 19312" width="2.173" height="1.972" transform="translate(232.601 125.821)" fill="#37474f" />
                                <rect id="Rectangle_19313" data-name="Rectangle 19313" width="2.173" height="0.986" transform="translate(232.601 125.821)" fill="#263238" />
                                <path id="Path_16674" data-name="Path 16674" d="M14.79,296.436l58.085.25c14.118-11.086,15.576-27.511,5.5-37.466-6.1-6.022-13.836-4.807-21.207-5.765-.007,0-.014,0-.021,0a21.739,21.739,0,0,1-7.476-2.194c-7.589-4-11.868-13.449-18.252-21.394q-1.194-1.494-2.5-2.9a13.744,13.744,0,0,0-21.167,2.093C-6.134,249.086.228,278.894,14.79,296.436" transform="translate(-0.018 -133.37)" fill="#c7c7c7" />
                                <path id="Path_16675" data-name="Path 16675" d="M33.428,258.832c1.521,23.831,29.042,32.726,46.231,25.588,14.946-6.208,10.055-20.376-2.56-22.746-.007,0-.014,0-.021,0a21.75,21.75,0,0,1-7.476-2.194c-7.589-4-11.868-13.449-18.252-21.394-9.512-4.941-19.038,3.3-17.921,20.749" transform="translate(-19.945 -141.589)" fill="#dbdbdb" />
                                <path id="Path_16676" data-name="Path 16676" d="M117.522,361.153c-.861-.3-20.411-15.477-20.791-16.527-.146-.4,1.9-3.374,4.47-6.9.555-.762,1.133-1.544,1.725-2.336,3.834-5.146,8.023-10.516,8.023-10.516l13.107,10.113-8.384,10.237-1.839,2.249s4.887,9.158,5.18,10.354-.631,3.627-1.492,3.325" transform="translate(-57.866 -194.361)" fill="#774442" />
                                <path id="Path_16677" data-name="Path 16677" d="M104.99,344.21c-.679.9-1.374,1.831-2.061,2.749-.594.793-1.172,1.574-1.727,2.334-2.572,3.528-4.614,6.5-4.468,6.9.38,1.05,19.928,16.224,20.791,16.527s1.778-2.132,1.491-3.327-5.181-10.352-5.181-10.352l1.84-2.248,1.871-2.285Z" transform="translate(-57.868 -205.928)" fill="#ebebeb" />
                                <path id="Path_16678" data-name="Path 16678" d="M117.335,376.515c-.874-.311-20.687-15.836-21.069-16.906-.176-.494,2.949-4.846,6.344-9.365l12.919,10.062-1.881,2.279s4.927,9.338,5.22,10.556-.659,3.685-1.533,3.375" transform="translate(-57.588 -209.538)" fill={primaryColor} />
                                <path id="Path_16679" data-name="Path 16679" d="M119.623,389.855c-3.258-2.684-16.65-13.2-20.074-15.608-.028-.02-.042,0-.016.021,3.171,2.731,16.648,13.14,20.047,15.642.072.053.111,0,.043-.055" transform="translate(-59.539 -223.893)" fill="#263238" />
                                <path id="Path_16680" data-name="Path 16680" d="M135.228,376.784a5.881,5.881,0,0,0-4.6-2.171c-.067.006-.058.1-.005.121a23.352,23.352,0,0,1,4.471,2.212.106.106,0,0,0,.135-.161" transform="translate(-78.122 -224.109)" fill="#263238" />
                                <path id="Path_16681" data-name="Path 16681" d="M136.467,380.041a5.882,5.882,0,0,0-4.6-2.171c-.067.006-.058.1-.005.121a23.329,23.329,0,0,1,4.471,2.212.106.106,0,0,0,.135-.161" transform="translate(-78.863 -226.057)" fill="#263238" />
                                <path id="Path_16682" data-name="Path 16682" d="M137.706,383.3a5.88,5.88,0,0,0-4.6-2.171c-.067.006-.058.1-.005.121a23.363,23.363,0,0,1,4.471,2.212.106.106,0,0,0,.135-.161" transform="translate(-79.604 -228.004)" fill="#263238" />
                                <path id="Path_16683" data-name="Path 16683" d="M138.946,386.552a5.881,5.881,0,0,0-4.6-2.171c-.067.006-.058.1-.005.12a23.356,23.356,0,0,1,4.471,2.212.106.106,0,0,0,.135-.161" transform="translate(-80.346 -229.952)" fill="#263238" />
                                <path id="Path_16684" data-name="Path 16684" d="M144.4,374.973c.041-1.11-1.165-1.541-2.07-1.51a8.861,8.861,0,0,0-4.359,1.417.044.044,0,0,0-.006.071.207.207,0,0,0,.077.324,14.759,14.759,0,0,0,4.313,1.1c.869,0,2.006-.353,2.044-1.4m-4.109.546c-.69-.2-1.366-.438-2.064-.615a26.637,26.637,0,0,1,2.564-.771,9.575,9.575,0,0,1,1.279-.206c.666-.049,2.8.4,1.442,1.658-.747.691-2.4.176-3.221-.066" transform="translate(-82.512 -223.429)" fill="#263238" />
                                <path id="Path_16685" data-name="Path 16685" d="M129.9,365.716a14.756,14.756,0,0,0,3.033,3.259.206.206,0,0,0,.321-.088.045.045,0,0,0,.06-.039,8.864,8.864,0,0,0-.844-4.505c-.407-.809-1.363-1.661-2.317-1.094-.9.537-.671,1.706-.252,2.467m.14-1.394c.452-1.789,1.87-.137,2.146.471a9.566,9.566,0,0,1,.432,1.221,26.556,26.556,0,0,1,.551,2.62c-.49-.527-1.02-1.008-1.529-1.517-.606-.607-1.85-1.81-1.6-2.8" transform="translate(-77.491 -217.208)" fill="#263238" />
                                <path id="Path_16686" data-name="Path 16686" d="M109.334,282.222c-.9,6.813-17.688,26.479-19.628,28.735l-.442.513L75.376,300.8l.16-.206,12.23-16.013s-20.223,11.8-30.342,6.444a28.649,28.649,0,0,1-7.912-6.68L62.718,277.3s3.654,1.181,5.99.841c8.051-1.179,26.09-8.028,30.37-7.855,5.521.227,11.08,5.684,10.256,11.935" transform="translate(-29.621 -161.701)" fill="#455a64" />
                                <path id="Path_16687" data-name="Path 16687" d="M148.225,291.039a16.319,16.319,0,0,0-1.261,1.617c-.432.567-.872,1.128-1.3,1.7l-.012.017c.076-.166.151-.333.231-.5.272-.564.491-1.147.813-1.686a.03.03,0,0,0-.051-.031c-.331.536-.715,1.045-1,1.609q-.2.4-.377.806c-.065.149-.131.3-.188.451a2.158,2.158,0,0,0-.08.218c-.193.263-.384.528-.57.8-.412.6-.775,1.227-1.192,1.819-.023.032.028.061.051.031.422-.556.9-1.075,1.324-1.627s.85-1.14,1.259-1.722.789-1.142,1.188-1.711a17.041,17.041,0,0,0,1.2-1.757c.011-.021-.016-.047-.035-.028" transform="translate(-85.689 -174.114)" fill="#263238" />
                                <path id="Path_16688" data-name="Path 16688" d="M133.314,349.771c-.762-.707-1.583-1.361-2.388-2.019s-1.619-1.318-2.434-1.971c-1.631-1.305-3.254-2.622-4.9-3.9-.906-.705-1.818-1.4-2.761-2.056-.042-.029-.089.038-.052.072,1.544,1.4,3.191,2.688,4.821,3.99s3.269,2.606,4.921,3.886c.9.7,1.8,1.423,2.749,2.067.038.026.08-.034.047-.064" transform="translate(-72.248 -203.298)" fill="#263238" />
                                <path id="Path_16689" data-name="Path 16689" d="M34.892,205.523a1.185,1.185,0,0,0,.871,2.136,1.252,1.252,0,0,0,.014.5,1.308,1.308,0,0,0,1.449,1,1.1,1.1,0,0,0,.018.421,1.193,1.193,0,0,0,1.428.891,1.179,1.179,0,0,0,.664-.423,1.185,1.185,0,0,0,1.4.792,1.159,1.159,0,0,0,.451-.215.793.793,0,0,0,.02.106,1.19,1.19,0,0,0,2.257.186,1.176,1.176,0,0,0,1.338.656,1.188,1.188,0,0,0,.876-.827,1.321,1.321,0,0,0,.863.093,1.28,1.28,0,0,0,.818-.6,1.3,1.3,0,0,0,2-1.037,1.222,1.222,0,0,0,.466-.015,1.184,1.184,0,0,0,.915-1.27,1.278,1.278,0,0,0,.576,0,1.307,1.307,0,0,0,.983-1.569,1.254,1.254,0,0,0-.167-.4.818.818,0,0,0,.206-.026,1.189,1.189,0,0,0,.893-1.424,1.176,1.176,0,0,0-.313-.565,12.717,12.717,0,0,1,1.069-3.55,1.3,1.3,0,0,0-.728-.889,1.183,1.183,0,0,0-.4-1.713,1.305,1.305,0,0,0-.9-2.138,1.185,1.185,0,0,0-1.26-1.484,1.23,1.23,0,0,0,0-.562,1.306,1.306,0,0,0-1.568-.979,1.146,1.146,0,0,0-.286.106,1.128,1.128,0,0,0-.031-.21,1.309,1.309,0,0,0-1.568-.979,1.151,1.151,0,0,0-.305.115,1.162,1.162,0,0,0-.028-.37,1.309,1.309,0,0,0-2.405-.369,1.3,1.3,0,0,0-2.033.187,1.187,1.187,0,0,0-2.171.184,1.187,1.187,0,0,0-1.9.828,1.285,1.285,0,0,0-1.038-.2,1.311,1.311,0,0,0-1.017,1.307,1.169,1.169,0,0,0-.435.016,1.189,1.189,0,0,0-.887,1.427,1.127,1.127,0,0,0,.17.39.438.438,0,0,0-.076.013,1.311,1.311,0,0,0-.982,1.573,1.2,1.2,0,0,0,.139.353,1.1,1.1,0,0,0-.317.029,11.612,11.612,0,0,1-.72,3.319,1.184,1.184,0,0,0,.52.734,1.305,1.305,0,0,0,.5,2.429,1.169,1.169,0,0,0-.324,1.106,1.2,1.2,0,0,0,.962.906" transform="translate(-19.761 -113.835)" fill="#263238" />
                                <path id="Path_16690" data-name="Path 16690" d="M63.652,247.662c2.472,3.55,10.949,12.761,16.81,14.1,3.23.736,18.836,2.919,20.975.973,2.5-2.279,1.343-10.872-.648-10.95-6.079-.239-17.328.951-20.137.33-1.271-.28-8.008-4.626-15.2-8.1-2.721-1.314-3.391,1.361-1.8,3.652" transform="translate(-37.571 -145.774)" fill="#774442" />
                                <path id="Path_16691" data-name="Path 16691" d="M97.252,251.341,96.209,264.2s-14.16-.224-18.435-2.627-11.233-8.534-13.9-12.794-1.772-6.584,3.425-3.964,12.212,6.825,13.131,7.047,16.817-.525,16.817-.525" transform="translate(-37.344 -145.805)" fill={primaryColor} />
                                <path id="Path_16692" data-name="Path 16692" d="M140.447,264.876c0-.033-.051-.047-.058-.01-.175.883-.248,1.791-.348,2.686-.1.925-.193,1.852-.276,2.779-.166,1.848-.361,3.708-.418,5.562a.058.058,0,1,0,.116,0c.278-1.821.426-3.672.6-5.507.085-.909.167-1.82.232-2.731s.165-1.857.153-2.783" transform="translate(-83.366 -158.447)" fill="#263238" />
                                <path id="Path_16693" data-name="Path 16693" d="M87.362,256.98a59.123,59.123,0,0,1-4.5,7.223c-.048-.048-.1-.092-.149-.133a59.021,59.021,0,0,0,4.475-7.191c.06.032.121.064.177.1" transform="translate(-49.482 -153.682)" fill="#fff" />
                                <path id="Path_16694" data-name="Path 16694" d="M91.323,259.326a57.538,57.538,0,0,1-4.66,7.593c-.052-.04-.1-.084-.153-.129a57.846,57.846,0,0,0,4.64-7.561c.06.032.117.064.173.1" transform="translate(-51.756 -155.088)" fill="#fff" />
                                <g id="Group_11679" data-name="Group 11679" transform="translate(25.075 97.907)" opacity="0.1">
                                    <g id="Group_11678" data-name="Group 11678">
                                        <g id="Group_11677" data-name="Group 11677" clip-path="url(#clip-path-5)">
                                            <path id="Path_16695" data-name="Path 16695" d="M83.662,263.205a19.5,19.5,0,0,1-5.889-1.631c-4.274-2.406-11.233-8.533-13.9-12.8-2.033-3.254-2-5.379.454-5.034Z" transform="translate(-62.418 -243.709)" fill="#020100" />
                                        </g>
                                    </g>
                                </g>
                                <path id="Path_16696" data-name="Path 16696" d="M33.732,270.808c.776.25,22.854-6.138,22.854-6.138l-2.607-7.665a7.77,7.77,0,0,0,.689-4.836c-.8-3.168-6.662-8.376-7.122-8.682a4.726,4.726,0,0,0-5.117-.391c-6.383,3.391-8.7,27.712-8.7,27.712" transform="translate(-20.181 -145.091)" fill={primaryColor} />
                                <path id="Path_16697" data-name="Path 16697" d="M57.929,260.719c-5.713,2.8-13.6,3.865-16.909,4.2.016-.068.036-.137.056-.2,3.334-.35,11.1-1.41,16.736-4.166.04.056.08.113.117.169" transform="translate(-24.541 -155.877)" fill="#fff" />
                                <path id="Path_16698" data-name="Path 16698" d="M58.027,264.615c-6.066,3.31-15.238,4.423-18.307,4.716a1.961,1.961,0,0,1,.048-.209c3.121-.3,12.185-1.418,18.175-4.692.032.064.06.125.084.185" transform="translate(-23.763 -158.199)" fill="#fff" />
                                <path id="Path_16699" data-name="Path 16699" d="M75.576,251.783c.568.662,1.179,1.291,1.766,1.939-.274-.483-.546-.968-.838-1.441-.006-.009.007-.02.014-.011a10.79,10.79,0,0,1,1.12,1.783,14.859,14.859,0,0,1,1.217,1.554,6.937,6.937,0,0,1,1,2.137,5.866,5.866,0,0,1,.067,2.364,15.141,15.141,0,0,1-.746,2.641c-.041.114-.2.043-.171-.069a9.843,9.843,0,0,0,.552-4.638,10.64,10.64,0,0,0-2.235-4.08,21.846,21.846,0,0,0-1.8-2.142c-.027-.027.021-.067.047-.037" transform="translate(-45.182 -150.626)" fill="#263238" />
                                <path id="Path_16700" data-name="Path 16700" d="M46.99,250.817c-1.775,10.07-.333,17.672,1.377,18.607,4.605,2.514,13.489-1.922,16.608-2.935,1.261-.41.048-10.876-1.341-10.593-3.422.7-9.539,4.251-9.71,4.234-.341-.035-.74-2.605-2.5-10.1-1.424-6.06-3.707-3.333-4.433.785" transform="translate(-27.626 -147.471)" fill="#774442" />
                                <path id="Path_16701" data-name="Path 16701" d="M86.377,267.073s6.473-2.707,11.709-2.467c1.775.082,8.978,2.349,8.5,4.11-.429,1.594-6.4-1.009-6.4-1.009s6.255,2.093,5.386,3.917-7.224-2.013-7.224-2.013,6.379,2.3,5.184,4.034c-.9,1.314-6.853-1.837-6.853-1.837s5.469,2.01,4.6,3.4c-.962,1.537-6.637-1.026-6.637-1.026a9.269,9.269,0,0,1-7.341,3.555c-.46.008-.928-10.666-.928-10.666" transform="translate(-51.676 -158.295)" fill="#774442" />
                                <path id="Path_16702" data-name="Path 16702" d="M115.46,270.2c.744.219,1.5.436,2.22.721s1.434.595,2.126.945a13.226,13.226,0,0,1,2.562,1.62,28.527,28.527,0,0,0-4.773-2.371c-.72-.284-1.426-.6-2.146-.889-.018-.008-.006-.033.012-.027" transform="translate(-69.063 -161.65)" fill="#263238" />
                                <path id="Path_16703" data-name="Path 16703" d="M112.791,276.639c.938.321,1.88.605,2.8.968s1.76.784,2.651,1.207c.068.033.029.059-.042.029-.908-.387-1.777-.675-2.69-1.052s-1.811-.789-2.733-1.13c-.014-.005,0-.027.009-.022" transform="translate(-67.469 -165.503)" fill="#263238" />
                                <path id="Path_16704" data-name="Path 16704" d="M109.48,281.752a22.151,22.151,0,0,1,4.174,1.808c.067.035.011.063-.059.031-1.376-.627-2.451-1.129-4.129-1.789-.026-.01-.016-.06.015-.051" transform="translate(-65.48 -168.562)" fill="#263238" />
                                <path id="Path_16705" data-name="Path 16705" d="M60.679,256.194l5.293,11.323s-16.429,4.794-18.659,1.909-2.073-11.956-1.027-18.232,3.87-6.4,5.106-.723,1.964,9.255,2.187,9.223,7.1-3.5,7.1-3.5" transform="translate(-27.251 -147.38)" fill={primaryColor} />
                                <path id="Path_16706" data-name="Path 16706" d="M52.791,272.235a46.012,46.012,0,0,1-7.231,3.9v-.217a45.612,45.612,0,0,0,7.183-3.885c.02.068.032.137.048.2" transform="translate(-27.257 -162.745)" fill="#fff" />
                                <path id="Path_16707" data-name="Path 16707" d="M53.359,277.1a46.535,46.535,0,0,1-7.617,4.078l-.012-.217A45.888,45.888,0,0,0,53.3,276.9c.02.072.04.141.056.2" transform="translate(-27.359 -165.659)" fill="#fff" />
                                <path id="Path_16708" data-name="Path 16708" d="M77.787,274.625c-.017-.029-.067-.014-.055.02.295.85.691,1.671,1.057,2.494.378.851.769,1.7,1.165,2.538.791,1.678,1.562,3.382,2.449,5.012.039.071.13.015.1-.055-.679-1.713-1.487-3.385-2.265-5.055-.386-.828-.775-1.655-1.179-2.474s-.8-1.686-1.274-2.479" transform="translate(-46.503 -164.289)" fill="#263238" />
                                <path id="Path_16709" data-name="Path 16709" d="M57.095,280.177a7.116,7.116,0,0,0-1.376.23c-.474.084-.95.159-1.428.222a27.049,27.049,0,0,1-2.789.239c-.918.024-3.189-.113-3.887-.746a4.39,4.39,0,0,1-1.188-2.045,29.842,29.842,0,0,1-.563-3.228,41.92,41.92,0,0,1-.216-6.914c.026-.642.066-1.283.112-1.924s.125-1.307.147-1.96a.023.023,0,0,0-.044-.005,23.335,23.335,0,0,0-.435,3.166c-.039-.662-.119-1.323-.13-1.986a.03.03,0,0,0-.059,0c.012.7-.035,1.4-.009,2.094.014.393.054.783.1,1.171q-.083,1.33-.071,2.664a27.368,27.368,0,0,0,.814,6.952,3.81,3.81,0,0,0,1.591,2.457,5.819,5.819,0,0,0,2.162.567,18.211,18.211,0,0,0,4.733-.3c.437-.081.872-.173,1.3-.272a5.158,5.158,0,0,0,1.246-.342c.02-.011.008-.041-.012-.041" transform="translate(-27.055 -157.96)" fill="#263238" />
                                <path id="Path_16710" data-name="Path 16710" d="M83.73,271.077v0h0v0" transform="translate(-50.093 -162.176)" fill="#263238" />
                                <path id="Path_16711" data-name="Path 16711" d="M70.109,265.989c-1.208.6-2.422,1.213-3.626,1.829-.594.3-1.19.607-1.783.914-.57.294-1.661.883-1.661.882-.013-.272-.222-1.063-.337-1.573-.126-.556-.254-1.112-.4-1.663-.037-.143-.078-.286-.116-.429-.026-.266-.05-.531-.074-.8-.039-.427-.076-.865-.142-1.288a.012.012,0,0,0-.024,0,6.076,6.076,0,0,0,.007,1.224q-.277-1-.572-2a.018.018,0,0,0-.034.01c.216,1.121.445,2.241.7,3.354.125.54.263,1.076.4,1.612a17.158,17.158,0,0,0,.479,1.716,17.847,17.847,0,0,0,1.811-.8q.928-.441,1.845-.9c1.188-.595,2.385-1.2,3.533-1.871,0,.007.178.372.189.389.031.048.069-.035.055-.087a2.392,2.392,0,0,0-.26-.523" transform="translate(-36.704 -157.389)" fill="#263238" />
                                <path id="Path_16712" data-name="Path 16712" d="M83.733,271.084h0" transform="translate(-50.094 -162.18)" fill="#263238" />
                                <rect id="Rectangle_19315" data-name="Rectangle 19315" width="23.426" height="1.051" transform="translate(34.263 116.907)" fill="#c7c7c7" />
                                <path id="Path_16713" data-name="Path 16713" d="M96.569,291l-.8,1.051h19.215V291Z" transform="translate(-57.294 -174.096)" fill="#a6a6a6" />
                                <path id="Path_16714" data-name="Path 16714" d="M100.257,270.525h22.83l1.9-15.505h-22.83Z" transform="translate(-59.98 -152.569)" fill="#c7c7c7" />
                                <path id="Path_16715" data-name="Path 16715" d="M127.578,272.248a1.361,1.361,0,0,0,1.365,1.39,1.419,1.419,0,0,0,1.414-1.39,1.36,1.36,0,0,0-1.365-1.39,1.419,1.419,0,0,0-1.414,1.39" transform="translate(-76.325 -162.044)" fill="#fff" />
                                <path id="Path_16716" data-name="Path 16716" d="M151.775,258.752c.86,1.328,2.985,2.882,3.277,4.021a1.349,1.349,0,0,1-.556,1.511s1.231.423,1.326,1.5c.049.554-.795,1.119-.795,1.119a1.61,1.61,0,0,1,1,1.346,1.555,1.555,0,0,1-.828,1.219,1.922,1.922,0,0,1,.531,1.59c-.468,1.6-7,2.463-8.33.189-.619-1.06.989-1.184.989-1.184s-2.355-.38-2.568-1.723,1.886-1.378,1.886-1.378-2.279-.388-2.359-1.8c-.083-1.472,2.022-1.148,2.022-1.148s-1.583-.793-1.4-1.89c.309-1.811,4.035.114,5.427-.417Z" transform="translate(-86.953 -154.802)" fill="#774442" />
                                <path id="Path_16717" data-name="Path 16717" d="M159.4,285.654a.017.017,0,0,1,.011.033,11.435,11.435,0,0,1-6.785.489c-.072-.019-.072-.143.01-.137a23.421,23.421,0,0,0,6.764-.384" transform="translate(-91.277 -170.896)" fill="#263238" />
                                <path id="Path_16718" data-name="Path 16718" d="M158.349,279.067c.025-.006.035.036.009.046a10.712,10.712,0,0,1-7.435.073c-.083-.033-.125-.145-.027-.115a19.273,19.273,0,0,0,7.453,0" transform="translate(-90.242 -166.955)" fill="#263238" />
                                <path id="Path_16719" data-name="Path 16719" d="M149.921,271.533a14.723,14.723,0,0,0,7.2.434c.032,0,.035.041.01.052a9.641,9.641,0,0,1-3.973.534,6.6,6.6,0,0,1-3.263-.937c-.039-.024-.022-.1.029-.083" transform="translate(-89.662 -162.447)" fill="#263238" />
                                <path id="Path_16720" data-name="Path 16720" d="M52.726,243.636s3.907,3.31,6.391,2.7.959-3.124.959-3.124a8.15,8.15,0,0,0-4.687-1.067,4.217,4.217,0,0,0-2.663,1.488" transform="translate(-31.544 -144.84)" fill="#774442" />
                                <path id="Path_16721" data-name="Path 16721" d="M57.729,234.262c.088.4,1.955,3.019,3.781,2.959.595-.02.628-3.27.628-3.27l.057-.239.8-3.35-4.478-2.934s-.824,6.665-.789,6.835" transform="translate(-34.537 -136.061)" fill="#774442" />
                                <path id="Path_16722" data-name="Path 16722" d="M63.761,230.361l-.8,3.351a6.924,6.924,0,0,1-3.953-4.007c.137-1.155.255-2.116.27-2.257a.062.062,0,0,0,0-.019l.568.343Z" transform="translate(-35.302 -136.062)" fill="#263238" />
                                <path id="Path_16723" data-name="Path 16723" d="M66.606,211.6c.125,6.045-3.481,6.8-4.785,6.828-1.183.023-5.215-.048-5.964-6.047s2.022-7.969,4.725-8.165,5.9,1.339,6.024,7.384" transform="translate(-33.343 -122.166)" fill="#774442" />
                                <path id="Path_16724" data-name="Path 16724" d="M79.237,219.21a3.975,3.975,0,0,1-.4-.106.675.675,0,0,1-.391-.187.215.215,0,0,1,.013-.262.533.533,0,0,1,.531-.1.784.784,0,0,1,.478.3.229.229,0,0,1-.227.353" transform="translate(-46.906 -130.738)" fill="#263238" />
                                <path id="Path_16725" data-name="Path 16725" d="M69.862,220.039a4.055,4.055,0,0,0,.412-.066.678.678,0,0,0,.408-.147.215.215,0,0,0,.012-.262.533.533,0,0,0-.519-.151.784.784,0,0,0-.5.253.23.23,0,0,0,.191.374" transform="translate(-41.648 -131.261)" fill="#263238" />
                                <path id="Path_16726" data-name="Path 16726" d="M71.532,223.335c0-.016-.033.018-.033.036,0,.411-.064.884-.44,1.011-.012,0-.011.025,0,.023.456-.052.538-.68.47-1.069" transform="translate(-42.508 -133.611)" fill="#263238" />
                                <path id="Path_16727" data-name="Path 16727" d="M70.546,222.244c-.66-.068-.747,1.259-.135,1.321s.688-1.265.135-1.321" transform="translate(-41.877 -132.959)" fill="#263238" />
                                <path id="Path_16728" data-name="Path 16728" d="M69.537,222.244c-.121.067-.243.192-.383.2-.163.006-.276-.141-.373-.282-.01-.014-.022,0-.023.014-.02.254.061.521.33.576.246.051.44-.163.519-.414.015-.047-.022-.119-.07-.092" transform="translate(-41.133 -132.906)" fill="#263238" />
                                <path id="Path_16729" data-name="Path 16729" d="M78.112,223.382c0-.015.031.022.029.039-.043.41-.023.886.339,1.049.011.005.008.026-.005.023-.449-.1-.469-.729-.362-1.11" transform="translate(-46.7 -133.639)" fill="#263238" />
                                <path id="Path_16730" data-name="Path 16730" d="M78.537,222.386c.664,0,.619,1.326,0,1.328s-.56-1.326,0-1.328" transform="translate(-46.726 -133.045)" fill="#263238" />
                                <path id="Path_16731" data-name="Path 16731" d="M79.759,222.554c.1.074.2.205.327.218.145.014.258-.125.355-.26.01-.014.02,0,.02.015,0,.254-.094.516-.34.556-.224.037-.38-.186-.432-.442-.01-.047.029-.117.07-.088" transform="translate(-47.674 -133.116)" fill="#263238" />
                                <path id="Path_16732" data-name="Path 16732" d="M71.466,232.371c.084.109.164.254.31.287a1.082,1.082,0,0,0,.452-.042.015.015,0,0,1,.015.024.555.555,0,0,1-.548.149.438.438,0,0,1-.27-.4c0-.025.029-.029.042-.013" transform="translate(-42.73 -139.014)" fill="#263238" />
                                <path id="Path_16733" data-name="Path 16733" d="M74.014,222.754s.035.62.011.909c0,.027-.073.034-.172.032,0,0-.008,0-.011,0a1.378,1.378,0,0,1-1.293-.622c-.018-.03.021-.057.046-.037a2.079,2.079,0,0,0,1.226.462c.027-.078-.1-1.059-.049-1.059a2.538,2.538,0,0,1,.644.2c-.035-1.293-.277-2.57-.292-3.857a.039.039,0,0,1,.077-.013,20.907,20.907,0,0,1,.492,4.142c.01.166-.567-.1-.679-.158" transform="translate(-43.401 -130.866)" fill="#263238" />
                                <path id="Path_16734" data-name="Path 16734" d="M73.639,230.06a1.689,1.689,0,0,1-.667.535.759.759,0,0,1-.461.037c-.335-.082-.348-.388-.292-.651a1.82,1.82,0,0,1,.143-.4,2.185,2.185,0,0,0,1.276.483" transform="translate(-43.191 -137.347)" fill="#263238" />
                                <path id="Path_16735" data-name="Path 16735" d="M72.972,231.2a.759.759,0,0,1-.461.037c-.335-.082-.348-.388-.292-.651a.784.784,0,0,1,.752.615" transform="translate(-43.191 -137.948)" fill="#ff9cbd" />
                                <path id="Path_16736" data-name="Path 16736" d="M55.55,211.441c.64-.041,2.3-1.809,2.341-4.9a22.692,22.692,0,0,1-.435,3.183c-.207.407.928-1,1-3.489a10.09,10.09,0,0,0,2.564,1.317,3.9,3.9,0,0,0,1.983.227c.084-.086-1.652-.625-2.122-1.164a20.529,20.529,0,0,0,4.583,1.549,4.881,4.881,0,0,0-2.117-4.408c-2.238-1.45-5.536-.327-6.484.595a6.218,6.218,0,0,0-1.879,1.96c-1.208,1.656-.119,5.176.57,5.132" transform="translate(-32.568 -121.483)" fill="#263238" />
                                <path id="Path_16737" data-name="Path 16737" d="M53.735,212.8c.307.841-2.075-2.97-2.128-3.591a7.82,7.82,0,0,1,3.252-5.366,7.939,7.939,0,0,1,5.288-.777,3.827,3.827,0,0,1,1.5.606,13.524,13.524,0,0,0-6.484.595c-1.386.581-2.577,5.384-1.429,8.534" transform="translate(-30.874 -121.4)" fill={primaryColor} />
                                <path id="Path_16738" data-name="Path 16738" d="M56.862,200.941a4.222,4.222,0,0,1,2.04-2.615,4.259,4.259,0,0,1,.047,2.342c-.2.354-1.436.87-1.652.707a3.071,3.071,0,0,1-.435-.434" transform="translate(-34.018 -118.651)" fill={primaryColor} />
                                <g id="Group_11682" data-name="Group 11682" transform="translate(22.844 81.635)" opacity="0.1">
                                    <g id="Group_11681" data-name="Group 11681">
                                        <g id="Group_11680" data-name="Group 11680" clip-path="url(#clip-path-6)">
                                            <path id="Path_16739" data-name="Path 16739" d="M56.863,203.861a3.146,3.146,0,0,0,.434.431.07.07,0,0,0,.033.016.121.121,0,0,0,.044.012c.011,0,.022,0,.036,0a1.88,1.88,0,0,0,.715-.187,4.041,4.041,0,0,0-.4-.643,1.112,1.112,0,0,0-.626-.288,4.094,4.094,0,0,0-.239.656" transform="translate(-56.863 -203.205)" fill="#050400" />
                                        </g>
                                    </g>
                                </g>
                                <path id="Path_16740" data-name="Path 16740" d="M51.13,205.7a8.409,8.409,0,0,0-3.3,1.209,3.211,3.211,0,0,0,2.193.92c.549-.031,1.74-1.426,1.575-1.666a2.385,2.385,0,0,0-.464-.463" transform="translate(-28.613 -123.062)" fill={primaryColor} />
                                <g id="Group_11685" data-name="Group 11685" transform="translate(21.983 82.636)" opacity="0.1">
                                    <g id="Group_11684" data-name="Group 11684">
                                        <g id="Group_11683" data-name="Group 11683" clip-path="url(#clip-path-7)">
                                            <path id="Path_16741" data-name="Path 16741" d="M54.719,205.8a1.389,1.389,0,0,0,.279.736,1.19,1.19,0,0,0,.415.276c.228-.294.367-.558.3-.652a2.42,2.42,0,0,0-.464-.462s-.212.032-.534.1" transform="translate(-54.719 -205.697)" fill="#050400" />
                                        </g>
                                    </g>
                                </g>
                                <path id="Path_16742" data-name="Path 16742" d="M55.525,205.177a.78.78,0,1,0,0-1.1.78.78,0,0,0,0,1.1" transform="translate(-33.081 -121.953)" fill={primaryColor} />
                                <path id="Path_16743" data-name="Path 16743" d="M54.8,223.269s-1.192-1.97-2.078-1.557-.076,3.208.9,3.648a1.022,1.022,0,0,0,1.4-.458Z" transform="translate(-31.312 -132.608)" fill="#774442" />
                                <path id="Path_16744" data-name="Path 16744" d="M53.89,223.777c-.016,0-.02.02-.007.027a2.586,2.586,0,0,1,1.227,1.7.565.565,0,0,0-.833-.21c-.019.012,0,.042.017.039a.629.629,0,0,1,.676.264,3.029,3.029,0,0,1,.294.581c.029.065.145.039.125-.035,0-.006,0-.011,0-.016a2.273,2.273,0,0,0-1.494-2.351" transform="translate(-32.231 -133.877)" fill="#263238" />
                                <path id="Path_16745" data-name="Path 16745" d="M219.613,361.07c-.317.855-15.83,20.138-16.886,20.5-.4.139-3.34-1.956-6.821-4.589-.752-.568-1.525-1.16-2.306-1.765-5.078-3.923-10.374-8.205-10.374-8.205l10.34-12.929,10.09,8.561,2.216,1.878s9.242-4.726,10.443-5,3.615.695,3.3,1.549" transform="translate(-109.617 -211.834)" fill="#774442" />
                                <path id="Path_16746" data-name="Path 16746" d="M202.3,381.139c.889.7,1.807,1.406,2.713,2.109.782.608,1.553,1.2,2.3,1.767,3.483,2.633,6.422,4.726,6.823,4.587,1.056-.362,16.568-19.642,16.886-20.5s-2.1-1.815-3.3-1.549-10.44,5-10.44,5l-2.216-1.879-2.251-1.91Z" transform="translate(-121.029 -219.866)" fill={primaryColor} />
                                <path id="Path_16747" data-name="Path 16747" d="M234.765,368.823c-.326.868-16.194,20.409-17.27,20.772-.5.168-4.794-3.034-9.253-6.506l10.286-12.742,2.245,1.92s9.422-4.764,10.645-5.035,3.674.723,3.348,1.591" transform="translate(-124.584 -219.675)" fill={primaryColor} />
                                <path id="Path_16748" data-name="Path 16748" d="M247.978,370.324c-2.74,3.21-13.491,16.417-15.955,19.8-.02.028,0,.042.021.016,2.786-3.122,13.428-16.416,15.989-19.772.054-.071,0-.111-.054-.043" transform="translate(-138.806 -221.531)" fill={primaryColor} />
                                <path id="Path_16749" data-name="Path 16749" d="M235.19,377.172a5.883,5.883,0,0,0-2.251,4.561c0,.068.1.06.121.007a23.341,23.341,0,0,1,2.289-4.431.106.106,0,0,0-.159-.137" transform="translate(-139.353 -225.633)" fill="#263238" />
                                <path id="Path_16750" data-name="Path 16750" d="M238.467,375.99a5.88,5.88,0,0,0-2.251,4.562c0,.067.1.06.121.007a23.351,23.351,0,0,1,2.289-4.431.106.106,0,0,0-.159-.137" transform="translate(-141.314 -224.926)" fill="#263238" />
                                <path id="Path_16751" data-name="Path 16751" d="M241.743,374.808a5.88,5.88,0,0,0-2.251,4.561c0,.067.1.06.121.007a23.334,23.334,0,0,1,2.289-4.431.106.106,0,0,0-.159-.137" transform="translate(-143.274 -224.219)" fill="#263238" />
                                <path id="Path_16752" data-name="Path 16752" d="M245.02,373.625a5.882,5.882,0,0,0-2.251,4.561c0,.067.1.06.121.007a23.329,23.329,0,0,1,2.289-4.431.106.106,0,0,0-.159-.137" transform="translate(-145.234 -223.511)" fill="#263238" />
                                <path id="Path_16753" data-name="Path 16753" d="M233.646,365.257c-1.109-.06-1.561,1.138-1.545,2.043a8.858,8.858,0,0,0,1.341,4.383.044.044,0,0,0,.071.007.206.206,0,0,0,.325-.071,14.756,14.756,0,0,0,1.176-4.294c.018-.869-.318-2.012-1.367-2.069m.475,4.117c-.215.686-.462,1.358-.651,2.053a26.623,26.623,0,0,1-.727-2.577,9.537,9.537,0,0,1-.183-1.283c-.038-.666.453-2.788,1.682-1.412.678.758.135,2.4-.121,3.219" transform="translate(-138.857 -218.518)" fill="#263238" />
                                <path id="Path_16754" data-name="Path 16754" d="M224.054,383.666a14.741,14.741,0,0,0,3.311-2.975.206.206,0,0,0-.082-.323.045.045,0,0,0-.039-.061,8.864,8.864,0,0,0-4.519.766c-.816.392-1.684,1.333-1.133,2.3.521.912,1.693.7,2.461.295m-1.392-.164c-1.781-.483-.1-1.872.509-2.137a9.415,9.415,0,0,1,1.229-.411,26.521,26.521,0,0,1,2.629-.506c-.536.48-1.026,1-1.543,1.5-.617.6-1.842,1.817-2.823,1.552" transform="translate(-132.467 -227.499)" fill="#263238" />
                                <path id="Path_16755" data-name="Path 16755" d="M114.288,317.507,103.4,332.193,66.673,306.706l-3.542-2.459s-1,.675-2.512,1.51a25.708,25.708,0,0,1-7.888,3.027c-4.56.675-12.174.6-16.017-4.537-3.237-4.327-1.479-9.973-1.479-9.973l5.007-2.15s11.848-9.872,19.523-10.169c3.26-.13,8.006,2.341,12.4,4.922s42.122,30.63,42.122,30.63" transform="translate(-20.794 -168.68)" fill="#455a64" />
                                <path id="Path_16756" data-name="Path 16756" d="M89,325.994s-1,.675-2.512,1.51c-2.669-3.2-8.319-9-8.491-9.2Z" transform="translate(-46.662 -190.427)" fill="#263238" />
                                <path id="Path_16757" data-name="Path 16757" d="M47.576,299.045c-.708.492-1.393,1.014-2.092,1.519s-1.4.963-2.05,1.505-1.294,1.14-1.92,1.734c-.3.286-.6.576-.894.873a8.138,8.138,0,0,0-.853.926c-.022.031.023.069.052.05a9.7,9.7,0,0,0,.949-.773c.331-.279.659-.562.989-.843.637-.541,1.286-1.067,1.922-1.61s1.216-1.13,1.841-1.676c.669-.585,1.374-1.129,2.069-1.682.013-.01,0-.033-.014-.023" transform="translate(-23.788 -178.906)" fill="#263238" />
                                <path id="Path_16758" data-name="Path 16758" d="M211.533,369.13c-.707.763-1.361,1.583-2.019,2.388s-1.319,1.619-1.971,2.434c-1.305,1.631-2.622,3.254-3.9,4.9-.705.906-1.4,1.818-2.056,2.761-.029.042.038.089.072.052,1.4-1.544,2.688-3.191,3.991-4.821s2.606-3.269,3.886-4.921c.7-.9,1.423-1.8,2.067-2.749.026-.038-.034-.08-.064-.047" transform="translate(-120.595 -220.83)" fill="#263238" />
                                <path id="Path_16759" data-name="Path 16759" d="M129.485,354.111c-1.827-1.44-3.723-2.8-5.617-4.151s-3.769-2.692-5.683-4c-.549-.374-1.1-.741-1.657-1.109s-1.128-.707-1.669-1.088c-.032-.022-.06.03-.029.052.5.356.967.756,1.455,1.126.466.353.929.71,1.4,1.059.918.679,1.844,1.346,2.77,2.015q2.815,2.035,5.677,4.006c1.084.744,2.163,1.49,3.272,2.2.066.042.145-.063.082-.112" transform="translate(-68.691 -205.661)" fill="#263238" />
                                <path id="Path_16760" data-name="Path 16760" d="M139.53,304.479c-1.689-1.4-3.5-2.658-5.269-3.952-1.791-1.309-3.572-2.63-5.369-3.929l-5.35-3.867c-1.729-1.25-3.449-2.52-5.275-3.626-1-.605-2.024-1.167-3.077-1.672a20.142,20.142,0,0,0-3.18-1.3.03.03,0,0,0-.022.055c.9.5,1.845.926,2.756,1.411s1.787.984,2.659,1.513c1.812,1.1,3.524,2.347,5.243,3.583,3.571,2.568,7.134,5.147,10.73,7.679,2.012,1.417,3.983,2.913,6.073,4.215.065.041.141-.06.08-.11" transform="translate(-66.99 -171.182)" fill="#263238" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}