
export const Editing1 = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg id="editing" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g id="Group_50" data-name="Group 50" transform="translate(0 4.004)">
        <path id="Path_13" data-name="Path 13" d="M14.284,93.891a2.21,2.21,0,0,1-.859.531l-3.107,1.036a2.192,2.192,0,0,1-.695.113h0a2.2,2.2,0,0,1-2.084-2.892l1.036-3.107a2.207,2.207,0,0,1,.531-.859L15.817,82H2.686A2.689,2.689,0,0,0,0,84.686v15.625A2.689,2.689,0,0,0,2.686,103H18.311A2.689,2.689,0,0,0,21,100.311V87.179Z" transform="translate(0 -82)" fill={primaryFontColor} />
      </g>
      <path id="Path_14" data-name="Path 14" d="M183.336,62.435a.732.732,0,0,0-.177.286l-1.036,3.107a.732.732,0,0,0,.926.926l3.107-1.036a.732.732,0,0,0,.286-.177l9.15-9.15-3.107-3.107Z" transform="translate(-173.195 -50.683)" fill={primaryFontColor} />
      <path id="Path_15" data-name="Path 15" d="M419.224.357a1.221,1.221,0,0,0-1.726,0l-1.208,1.208L419.4,4.673,420.6,3.465a1.221,1.221,0,0,0,0-1.726Z" transform="translate(-395.962 0)" fill={primaryFontColor} />
    </svg>


  )
}

