import React, { useState, useEffect, memo } from "react";
import { TextField, Button, Select, FormControl, Chip, InputLabel, MenuItem, OutlinedInput, Box, FormHelperText } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import ApplicantsDynamicTable from "../../components/DynamicTable/ApplicantsDynamicTable";
import { useFormik } from "formik";
import { FocusError } from "focus-formik-error";
import * as Yup from "yup";
import { defaultPostingsData, defaultApplicantsData } from "../../_utilities/defaultfieldsModel";
import { customFieldsAsync, customFieldsStartReducer } from "../../_redux/_services/UserManagerSlice";
import { useDispatch, useSelector } from "react-redux";
import { decrypt } from "../../_utilities/_encryptDecryptHelper";

const ApplicantsComponent = ({ componentType, input, iniValue, actions, onValidData, disableSubmitBtn }) => {
  const [getDetails, setGetDetails] = useState(iniValue);
  const [addSkills, setAddSkills] = useState("");
  const [addCertification, setAddCertification] = useState("");
  const [disableBtn, setDisableBtn] = useState(disableSubmitBtn);
  const [GetUserId, setUserId] = useState(null);
  const [isPortals, setIsPortals] = useState(false);
  const [portalFrom, setPortalFrom] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // defaultDropdownOptionValues
  const [sourceModelValues, setSourceModelValues] = useState([]);
  const [candidateStatusValues, setCandidateStatusValues] = useState([]);

  // customFields
  const [customFieldsArray, setCustomFieldsArray] = useState([]);
  const [getcustomFields, setGetCustomFields] = useState(null);

  const customFieldsData = useSelector(state => state.CustomFields.data);

  useEffect(() => {
    if (input !== undefined && input !== null) { //&& input.Applicants.firstName !== ""
      if (typeof input.skillDetails === "string") {
        input.skillDetails = JSON.parse(input.skillDetails);
      }
      formik.values.firstName = input.Applicants.firstName ? input.Applicants.firstName : '';
      formik.values.lastName = input.Applicants.lastName ? input.Applicants.lastName : '';
      formik.values.contactNumber = input.Applicants.contactNumber ? input.Applicants.contactNumber : '';
      formik.values.emailId = input.Applicants.emailId ? input.Applicants.emailId : '';
      formik.values.resumesourceId = input.Applicants.resumesourceId ? input.Applicants.resumesourceId : '';
      formik.values.candidateStatus = input.Applicants.candidateStatus ? input.Applicants.candidateStatus : '';

      // for customfields
      if (customFieldsData && customFieldsData.length > 0) {
        const data = JSON.parse(JSON.stringify(customFieldsData));
        let customPostingFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "custom");

        if (componentType !== "Create" && input.customFields) {
          let customFieldsData = JSON.parse(input.customFields);
          let formatCustomFields = {};
          if (customFieldsData && customFieldsData.length > 0) {
            customFieldsData.forEach(list => {
              if (customPostingFieldsData.length > 0) {
                customPostingFieldsData.forEach(item => {
                  if (item.fieldName === list.key) {    // when datatype of customfields with applicant data are modified in admin
                    if (item.dataType.toLowerCase().trim() !== "text box") {
                      if (item.dataType.toLowerCase().trim() === "single select") {
                        if (typeof list.value === 'string') {
                          formatCustomFields[list.key] = list.value
                        } else {
                          const commonValues = JSON.parse(item.customValues).filter(value => list.value.includes(value));
                          formatCustomFields[list.key] = commonValues.length > 0 ? commonValues[0] : []
                        }
                      } else {
                        if (typeof list.value === 'string') {
                          formatCustomFields[list.key] = (JSON.parse(item.customValues).includes(list.value)) ? [list.value] : []
                        } else {
                          const commonValues = JSON.parse(item.customValues).filter(value => list.value.includes(value));
                          formatCustomFields[list.key] = commonValues.length > 0 ? commonValues : []
                        }
                      }
                    } else {
                      formatCustomFields[list.key] = list.value;
                    }
                  }

                })
              } else {
                formatCustomFields[list.key] = list.value;  // (typeof list.value === 'string') ? [list.value] :
              }
            })
            setGetCustomFields(formatCustomFields);
          }
        }
      }
      // if (input.csAssociatedJobs && input.eAssociatedJobs && input.vsAssociatedJobs) {
      //   if (input.csAssociatedJobs.Newids.length > 0 || input.eAssociatedJobs.Newids.length > 0 || input.vsAssociatedJobs.Newids.length > 0) {
      //     setIsPortals(true);
      //     formik.values.resumesourceId = "01";
      //     input.Applicants.resumesourceId = "01";
      //     setPortalFrom(input.csAssociatedJobs.Newids.length > 0 ? "Cloud Sourcing" : input.eAssociatedJobs.Newids.length > 0 ? "Employee Portal" : input.vsAssociatedJobs.Newids.length > 0 ? "Vendor Sourcing" : '');
      //   }
      // }
      if(input.source === "Job Board Portal" || input.source === "Cloud Sourcing" || input.source === "Employee Portal" || input.source === "Vendor Sourcing"){
        setIsPortals(true);
        formik.values.resumesourceId = "10";
        input.Applicants.resumesourceId = "10";
        setPortalFrom(input.source);
      }
      setGetDetails(input);
      setDisableBtn(false);
    }
    // eslint-disable-next-line
  }, [input, customFieldsData]);

  useEffect(() => {
    let defaultPostingModules = defaultApplicantsData.filter(x => x.moduleId === "2");
    if (defaultPostingModules.length > 0) {
      defaultPostingModules.forEach(list => {
        if (list.fieldName === "Source") {
          setSourceModelValues(list.customValues);
        } else if (list.fieldName === "Candidate status") {
          setCandidateStatusValues(list.customValues);
        }
      })
    }
  }, [])

  useEffect(() => {
    if (GetUserId === null) {
      let userId = JSON.parse(decrypt('GetUserId'))
      setUserId(userId);
      dispatch(customFieldsAsync(userId));
    }
  }, [GetUserId]);

  useEffect(() => {
    if (customFieldsData && customFieldsData.length > 0) {
      const data = JSON.parse(JSON.stringify(customFieldsData));
      let customPostingFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "custom");
      if (customPostingFieldsData.length > 0) {
        let Array = {};
        customPostingFieldsData.forEach(item => {
          item.customValues = JSON.parse(item.customValues);
          item.moduleId = item.moduleId.toString();
          // item.customValues = (item.dataType.toLowerCase() !== "textbox") ? [] : '';
          Array[item.fieldName] = (item.dataType.toLowerCase() !== "text box") ? [] : '';
        });
        setCustomFieldsArray(customPostingFieldsData);
        if(componentType !== "view"){
          setGetCustomFields(Array);
        }
      }
      let overrideDefaultPostingFieldsData = data.filter(x => x.moduleId.toString() === "2" && x.fieldType === "default");
      if (overrideDefaultPostingFieldsData.length > 0) {
        overrideDefaultPostingFieldsData.forEach(list => {
          if (list.fieldName === "Source") {
            let updatedCustomValues = JSON.parse(list.customValues);
            let modifiedCustomValues = [];
            updatedCustomValues.forEach(list => {
              if (list !== "Cloudsourcing portal" && list !== "Employee portal" && list !== "Vendorsourcing portal" && list !== "JobBoard portal") {
                modifiedCustomValues.push(list);
              }
            })
            setSourceModelValues(modifiedCustomValues);
          }
          if (list.fieldName === "Candidate status") {
            setCandidateStatusValues(JSON.parse(list.customValues));
          }

        })
      }
      // dispatch(customFieldsStartReducer({}))
    }
  }, [customFieldsData])

  const onChangeHandler = (data, type) => {
    let details = JSON.parse(JSON.stringify(getDetails));
    if(type === "emailId"){
      details.Applicants[type] = data.trim();
      formik.values[type] = data.trim();
    } else {
      if (type === "contactNumber" || type === "AlternateContactNumber") {
        details.Applicants[type] = data.trimStart();
        formik.values[type] = data.trimStart();
      }
       else {
        details.Applicants[type] = data.trimStart();
        formik.values[type] = data.trimStart();
      }
    }
    setGetDetails(details);
  };

  useEffect(() => {
    console.log("getDetails", getDetails);
  }, [getDetails])

  const onChangeCustomFieldsHandler = (value, type) => {
    console.log("getcustomFields >>>>>>>>>>>", getcustomFields);
    setGetCustomFields((prevousValue) => ({ ...prevousValue, [type]: value }))
  }

  useEffect(() => {
    console.log("getcustomFields >>>>>>>>>>>", getcustomFields);
  }, [getcustomFields])

  const onTableData = (data, type) => {
    let Array = JSON.parse(JSON.stringify(getDetails));
    Array[type] = data;
    setGetDetails(Array);
  }

  const onRemoveHandler = (item, index, type) => {
    let details = JSON.parse(JSON.stringify(getDetails));
    details[type].splice(index, 1);
    setGetDetails(details);
  }

  const phoneRegExp =
    // /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    /^[0-9()+-\s\b]*$/

  // /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const validationSchema = Yup.object({
    firstName: Yup.string().trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .min(1, "must be 1 characters")
      .max(50, "must be less than 50")
      .required("Please enter first name"),
    lastName: Yup.string().trim()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .min(1, "must be 1 characters")
      .max(50, "must be less than 50")
      .required("Please enter last name"),
    contactNumber: Yup.string()
      .matches(phoneRegExp, "Invalid contact number format. Please enter a valid number")
      .min(10, "must be 10 characters")
      .max(20, "must be less than 20")
      .required("Please enter contact number"),
    AlternateContactNumber: Yup.string()
      .matches(phoneRegExp, "Invalid alternate contact number format. Please enter a valid number")
      .min(10, "must be 10 characters")
      .max(20, "must be less than 20"),
    emailId: Yup.string().email("Please enter a valid email address")
      .matches(/^[a-zA-Z0-9_!#$%&*+/=?`{}~^.-]+@[a-zA-Z0-9.-]+$/, "Invalid email address format. Please enter a valid email")
      .required("Please enter a email address"),
    resumesourceId: Yup.string().trim()
      .min(1, "must be 1 characters")
      .max(20, "must be less than 20")
      .required("Please select a source from the dropdown"),
    candidateStatus: Yup.string()
      .required("Please select Candidate's status from the dropdown")
  });

  const formik = useFormik({
    initialValues: {
      firstName: '', lastName: '', contactNumber: '', emailId: '',
      source: 0,
      resumesourceId:'',
      candidateStatus:''
    },
    validationSchema,
    onSubmit: async (values) => {
      let ArrayOfCustomFields = [];
      if (getcustomFields !== null) {
        let keys = Object.keys(getcustomFields);
        keys.forEach((items, i) => {
          let obj = { key: items, value: getcustomFields[items] }
          ArrayOfCustomFields.push(obj);
        })
      }
      setDisableBtn(true);
      let obj = { ...getDetails };
      obj['customFields'] = JSON.stringify(ArrayOfCustomFields);
      await onValidData(obj);
    },
  });

  useEffect(() => {
    if(candidateStatusValues.length > 0 && input  && componentType === "Create"){
      let details = JSON.parse(JSON.stringify(input));
      details.Applicants.candidateStatus = candidateStatusValues[0].internal;
      setGetDetails(details);
      formik.values.candidateStatus = candidateStatusValues[0].internal;
    }
  }, [candidateStatusValues, input, componentType])

  useEffect(() => {
    if(candidateStatusValues.length > 0 && input  && !input.Applicants.candidateStatus && componentType === "Edit"){
      let details = JSON.parse(JSON.stringify(input));
      details.Applicants.candidateStatus = candidateStatusValues[0].internal;
      setGetDetails(details);
      formik.values.candidateStatus = candidateStatusValues[0].internal;
    }
  }, [candidateStatusValues, input, componentType])

  return (
    <React.Fragment>
      {componentType !== "Create" ? (
        <div className="actionBtnRtl">
          {actions &&
            actions.map((item) => {
              if (item.type === "back") {
                return (
                  <Button
                    variant={item.variant}
                    className={item.class}
                    style={item.style}
                    onClick={() => navigate(item.navigateTo)}
                    size="small"
                  >
                  <Link href="#" style={{color:"red", fontSize:"14px"}} underline="always">  {item.label}{""}</Link>
                  </Button>
                );

              } else if (item.type === "submit") {
                return (
                  <Button
                    variant={item.variant}
                    className={item.class}
                    disabled={disableBtn}
                    style={item.style}
                    onClick={formik.handleSubmit}
                    size="small"
                  >
                    {item.label}{""}
                  </Button>
                );
              }
            })}
        </div>
      ) : (
        ""
      )}
      <FocusError formik={formik} />
      <div style={{ width: "98%", margin: "0 auto" }} className={componentType === "view" ? "viewSection" : ""}>
        <div style={{ width: "100%" }}>
          <div className="personaldetails">
            <img src={process.env.PUBLIC_URL + "/assests/img/profile.svg"} alt="" />
            <h3 className="personaldetailstext"> Personal Details </h3>
          </div>
          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginTop: "60px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "32%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.firstName && formik.touched.firstName ? true : false
                }
                helperText={
                  formik.errors.firstName && formik.touched.firstName
                    ? formik.errors.firstName
                    : ""
                }
                id="outlined-basic"
                label="First Name* &nbsp;&nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="firstName"
                autoComplete="off"
                value={
                  getDetails.Applicants.firstName
                    ? getDetails.Applicants.firstName
                    : ""
                }
                InputLabelProps={{
                  shrink :  true, 
                }}
                disabled={componentType === "view" ? true : false}
                onChange={(e) => onChangeHandler(e.target.value, "firstName")}
                onBlur={formik.handleBlur}
              />
            </div>

            <div style={{ width: "32%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.lastName && formik.touched.lastName ? true : false
                }
                helperText={
                  formik.errors.lastName && formik.touched.lastName
                    ? formik.errors.lastName
                    : ""
                }
                id="outlined-basic"
                label="Last Name* &nbsp;&nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="lastName"
                autoComplete="off"
                value={
                  getDetails.Applicants.lastName
                    ? getDetails.Applicants.lastName
                    : ""
                }
                InputLabelProps={{
                  shrink :  true, 
                }}
                disabled={componentType === "view" ? true : false}
                onChange={(e) => onChangeHandler(e.target.value, "lastName")}
                onBlur={formik.handleBlur}
              />
            </div>

            <div style={{ width: "32%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.contactNumber && formik.touched.contactNumber ? true : false
                }
                helperText={
                  formik.errors.contactNumber && formik.touched.contactNumber
                    ? formik.errors.contactNumber
                    : ""
                }
                id="outlined-basic"
                label="Contact Number* &nbsp; &nbsp;&nbsp;&nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="contactNumber"
                autoComplete="off"
                value={
                  getDetails.Applicants.contactNumber ? getDetails.Applicants.contactNumber : ""
                }
                InputLabelProps={{
                  shrink :  true, 
                }}
                disabled={componentType === "view" ? true : false}
                onChange={(e) => onChangeHandler(e.target.value, "contactNumber")}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "60px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "32%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.AlternateContactNumber && formik.touched.AlternateContactNumber ? true : false
                }
                helperText={
                  formik.errors.AlternateContactNumber && formik.touched.AlternateContactNumber
                    ? formik.errors.AlternateContactNumber
                    : ""
                }
                id="outlined-basic"
                label="Alternate Contact Number &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="AlternateContactNumber"
                autoComplete="off"
                // shrink={componentType !== "create" ? true : false}
                InputLabelProps={{
                  shrink : true ,
                }}
                value={
                  getDetails.Applicants.AlternateContactNumber
                    ? getDetails.Applicants.AlternateContactNumber
                    : ""
                }
                disabled={componentType === "view" ? true : false}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "AlternateContactNumber")
                }
                onBlur={formik.handleBlur}
              />
            </div>

            <div style={{ width: "32%" }}>
              <TextField
                className="boldlabel"
                error={
                  formik.errors.emailId && formik.touched.emailId ? true : false
                }
                helperText={
                  formik.errors.emailId && formik.touched.emailId
                    ? formik.errors.emailId
                    : ""
                }
                id="outlined-basic"
                label="Email Address* &nbsp;&nbsp;&nbsp;&nbsp;"
                variant="outlined"
                style={{ width: "100%" }}
                name="emailId"
                autoComplete="off"
                value={
                  getDetails.Applicants.emailId ? getDetails.Applicants.emailId : ""
                }
                InputLabelProps={{
                  shrink :  true, 
                }}
                disabled={componentType === "view" ? true : false}
                onChange={(e) => onChangeHandler(e.target.value, "emailId")}
                onBlur={formik.handleBlur}
              />
            </div>

            <div style={{ width: "32%" }}>
              <FormControl
                fullWidth
                error={
                  formik.errors.resumesourceId && formik.touched.resumesourceId ? true : false
                }
              >
                <InputLabel className={componentType !== "create" ? "boldSelectlabelview" : "boldSelectlabel"} shrink ={true} >Sourced From *</InputLabel>
                <Select
                  value={isPortals === true ? "10" : getDetails.Applicants.resumesourceId ? getDetails.Applicants.resumesourceId : ""}
                  name="Sourced From"
                  autoComplete="off"
                  label="Sourced From*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                  disabled={componentType !== "create" || isPortals === true ? true : false}
                  onChange={(e) => onChangeHandler(e.target.value, "resumesourceId")}
                  style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                  onFocus={formik.handleBlur}
                >
                  {sourceModelValues.map((name, index) => (
                    <MenuItem className="selectOptions" key={name} value={(index + 1).toString()}>
                      {name}
                    </MenuItem>
                  ))}
                  {isPortals === true && (<MenuItem className="selectOptions" key="10" value="10">{portalFrom}</MenuItem>)}
                </Select>
                <FormHelperText>
                  {formik.errors.resumesourceId && formik.touched.resumesourceId
                    ? formik.errors.resumesourceId
                    : ""}
                </FormHelperText>
              </FormControl>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "60px",
              gap: "2%",
              flexFlow: "wrap",
            }}
          >

            {/* customFields Ui render start here */}
            {/* {isPortals === true && (
              <div style={{ width: "32%" }}>
                <TextField
                  className="boldlabel"
                  id="outlined-basic"
                  label="Portal"
                  variant="outlined"
                  style={{ width: "100%" }}
                  name="portals"
                  autoComplete="off"
                  value={portalFrom}
                  disabled={true}

                // onChange={(e) => onChangeHandler(e.target.value, "portals")}
                />
              </div>
            )} */}
            
            {/* <div style={{ width: "31.8%" }}>
              <FormControl
                fullWidth
                error={
                  formik.errors.candidateStatus && formik.touched.candidateStatus ? true : false
                }
              >
                <InputLabel className="boldSelectlabel">Candidate's Status*</InputLabel>
                <Select
                  value={getDetails.Applicants.candidateStatus ? getDetails.Applicants.candidateStatus : '' }
                  name="candidateStatus"
                  autoComplete="off"
                  label="Candidate's Status*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                  disabled={componentType === "view" ? true : false}
                  onChange={(e) => onChangeHandler(e.target.value, "candidateStatus")}
                  style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                  onFocus={formik.handleBlur}
                >
                  {candidateStatusValues.map((name) => (
                    <MenuItem className="selectOptions" key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.errors.candidateStatus && formik.touched.candidateStatus
                    ? formik.errors.candidateStatus
                    : ""}
                </FormHelperText>
              </FormControl>
            </div> */}
            
            {customFieldsArray && customFieldsArray.length > 0 && customFieldsArray.map((data, index) => {
              if (data.dataType.toLowerCase().trim() === "text box") {
                return (
                  <div style={{ width: "32%", marginBottom: "3.2%" }}>
                    <TextField
                      className="boldlabel"
                      id={data.fieldName}
                      variant="outlined"
                      disabled={componentType === "view" ? true : false}
                      autoComplete="off"
                      style={{ width: "100%" }}
                      label={data.fieldName}
                      name={data.fieldName}
                      InputLabelProps={{
                        shrink : true ,
                      }}
                      value={getcustomFields && getcustomFields[data.fieldName] ? getcustomFields[data.fieldName] : ''}
                      onBlur={formik.handleBlur}
                      onChange={(e) => onChangeCustomFieldsHandler(e.target.value, data.fieldName)}
                    />
                  </div>
                )
              } else if (data.dataType.toLowerCase().trim() === "multi select") {
                return (
                  <div style={{ width: "32%", marginBottom: "3.2%" }}>
                    <FormControl sx={{ width: "100%" }} fullWidth>
                      <InputLabel className="boldSelectlabel" id="demo-multiple-chip-label" shrink={componentType !== "create" ? true : false} >{data.fieldName}</InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id={data.fieldName}
                        multiple
                        name={data.fieldName}
                        disabled={componentType === "view" ? true : false}
                        value={getcustomFields && getcustomFields[data.fieldName] ? getcustomFields[data.fieldName] : []}
                        onChange={(e) => onChangeCustomFieldsHandler(e.target.value, data.fieldName)}
                        onFocus={formik.handleBlur}
                        input={
                          <OutlinedInput id="select-multiple-chip" label={data.fieldName} />
                        }
                        renderValue={(selected) => {
                          return (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, height: "23px" }}>
                              {typeof selected !== 'string' && selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )
                        }}
                      >
                        {data.customValues && data.customValues.length > 0 && data.customValues.map(name => (
                          <MenuItem className="selectOptions" key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )
              } else if (data.dataType.toLowerCase().trim() === "single select") {
                return (
                  <div style={{ width: "32%", marginBottom: "3.2%" }}>
                    <FormControl fullWidth >
                      <InputLabel className="boldSelectlabel" shrink={componentType !== "create" ? true : false}>{data.fieldName}</InputLabel>
                      <Select
                        name={data.fieldName}
                        id={data.fieldName}
                        label={data.fieldName}
                        autoComplete="off"
                        value={getcustomFields && getcustomFields[data.fieldName] ? getcustomFields[data.fieldName] : []}
                        variant="outlined"
                        style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                        onChange={(e) => onChangeCustomFieldsHandler(e.target.value, data.fieldName)}
                        onBlur={formik.handleBlur}
                        disabled={componentType === "view" ? true : false}
                      >
                        {data.customValues && data.customValues.length > 0 && data.customValues.map(Manager => {
                          return (
                            <MenuItem value={Manager}>
                              {Manager}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {formik.errors.manager && formik.touched.manager
                          ? formik.errors.manager
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </div>
                )
              }
            }
            )}
          </div>
          {/* customFields Ui render end here */}
          <div className="educationTable">
          <p className="educationdetailstext" style={{ marginTop: 40 }}> Education Details </p>
          <ApplicantsDynamicTable componentType={componentType} typeofTable="educationDetails" columns={educationColoumn} data={getDetails.educationDetails} receiveProps={(data) => onTableData(data, "educationDetails")} />
          </div>
        </div>
        <div className="educationTable">
        <p className="workexperiencetext" style={{ marginTop: 40 }}> Work Experience </p>
        <ApplicantsDynamicTable componentType={componentType} typeofTable="workExp" columns={workExperiencesColoumn} data={getDetails.workExp} receiveProps={(data) => onTableData(data, "workExp")} />
        </div>
        <div className="additionalList">
          <div className="workexperiencetext">Skills</div>
          {componentType !== "view" && (
            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{ width: "100%" }}
              name="addskills"
              placeholder="Please enter skill and press the enter button"
              value={addSkills}
              autoComplete="off"
              onChange={e => setAddSkills(e.target.value)}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  setAddSkills('');
                  let details = JSON.parse(JSON.stringify(getDetails));
                  let obj = { name: e.target.value, numberOfMonths: null, lastUsed: "" };
                  details.skillDetails.push(obj);
                  setGetDetails(details);
                }
              }}
            />
          )
          }
          <ul>
            {getDetails.skillDetails && getDetails.skillDetails.length > 0 &&
              getDetails.skillDetails.map((item, i) => {
                return <li key={i}>{item.name} {componentType !== "view" && (<CloseIcon onClick={() => onRemoveHandler(item, i, 'skillDetails')} />)}</li>;
              })}
          </ul>
          <div className="workexperiencetext">Certifications</div>
          {componentType !== "view" && (
            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{ width: "100%", marginBottom: "16px" }}
              name="addCertification"
              placeholder="Please enter certification and press the enter button"
              value={addCertification}
              autoComplete="off"
              onChange={e => setAddCertification(e.target.value)}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  setAddCertification('');
                  let details = JSON.parse(JSON.stringify(getDetails));
                  details.certification.push(e.target.value);
                  setGetDetails(details);
                }
              }}
            />
          )
          }
          {getDetails.certification && getDetails.certification.length > 0 &&
            getDetails.certification.map((item, i) => {
              return <p key={i}>{item} {componentType !== "view" && (<CloseIcon onClick={() => onRemoveHandler(item, i, 'certification')} />)}</p>;
            })}
        </div>
        {componentType === "Create" ? (
          <div
            style={{
              marginLeft: "auto!important",
              width: "fit-content",
              marginTop: "40px",
              marginLeft: "auto"
            }}
          >
            {actions &&
              actions.map((item) => {
                if (item.type === "back") {
                  return (
                    <Button
                      className={item.class}
                      variant={item.variant}
                      style={item.style}
                      onClick={() => navigate(item.navigateTo)}
                      size="small"
                    >
                     <Link href="#" style={{color:"red", fontSize:"14px"}} underline="always">  {item.label}{" "} </Link>
                    </Button>
                  );
                } else if (item.type === "submit") {
                  return (
                    <Button
                      className={item.class}
                      variant={item.variant}
                      disabled={disableBtn}
                      style={item.style}
                      onClick={formik.handleSubmit}
                      size="small"
                    >
                      {item.label}{" "}
                    </Button>
                  );
                }
              })}
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(ApplicantsComponent);

const educationColoumn = [
  { id: 1, displayName: "Degree", name: "degree" },
  { id: 2, displayName: "School", name: "organization" },
  { id: 3, displayName: "Duration", name: "duration" },
  { id: 4, displayName: "Location", name: "locationFormated" },
  { id: 5, displayName: "Action", name: "" },
];

const workExperiencesColoumn = [
  { id: 1, displayName: "Title", name: "jobTitle" },
  { id: 2, displayName: "Employer", name: "organization" },
  { id: 4, displayName: "Duration", name: "duration" },
  { id: 3, displayName: "Employment Type", name: "empType" },
  { id: 5, displayName: "Action", name: "" },
]