import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Switch
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updateAuth } from "../_redux/AuthSlice";
import { getUserDetails, signOut } from "../_redux/CognitoSlice";
import * as jobPostingServices from "../_redux/_services/JobPostingSlice";
import { getCustDetailsAsync, getCustDetailsStartReducer } from "../_redux/_services/UserManagerSlice";
import { Encrypt, decrypt } from "../_utilities/_encryptDecryptHelper";
import { setBreadCrumb } from "../_utilities/breadCrumb";
import { persistor, resetStore } from '../store';
import { ProfileIcon } from "../Icons/profileIcon";

const label = { inputProps: { "aria-label": "Switch demo" } };

const pages = [
  {
    id: 1,
    path: "/ats/layout/jobpostings/landing",
    displayName: "Job Postings",
    isActive: false,
  },
  {
    id: 2,
    path: "/ats/layout/applicants/landing",
    displayName: "Applicants",
    isActive: false,
  },
  {
    id: 3,
    path: "/ats/layout/clients/landing",
    displayName: "Clients",
    isActive: false,
  },
];

const DEFAULT_LOGO = process.env.PUBLIC_URL + "/assests/HireWing.png";

function Header() {
  // const userDetails = useSelector((state) => state.Auth);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userCountries, setUserCountries] = useState(null);
  const [GetUserInfo, setgetUserInfo] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [finalPages, setFinalPages] = useState([]);
  const location = useLocation();
  const countriesData = useSelector((state) => state.GetCountries.data);
  const atsCustomerDetails = useSelector((state) => state.GetCustDetails.data);
  const { themeSettings } = useSelector((state) => state.theme);
  const [logoUrl, setLogoUrl] = useState("");
  const [isLogoLoading, setIsLogoLoading] = useState(true);
  const [allCountries,setAllCountries] = useState(JSON.parse(decrypt('allCountryId')) === "all" ? false : true);
  const [dashboardPath,setDashboardPath] = useState("");

  const [appTheme, setTheme] = useState({});
  useEffect(() => {
    const storedTheme = localStorage.getItem("themeSettings");

    if (storedTheme) {
      const parsedTheme = JSON.parse(storedTheme);
      setTheme(parsedTheme);

      if (parsedTheme?.logo && parsedTheme.logo.trim() !== "") {
        setLogoUrl(parsedTheme.logo);
      }
    }
    setIsLogoLoading(false);
  }, []);

  useEffect(() => {
    if (themeSettings?.logo && themeSettings.logo.trim() !== "") {
      setLogoUrl(themeSettings.logo);
      setIsLogoLoading(false);
    }
  }, [themeSettings]);

  const useCustomHeader = appTheme?.useCustomHeader || false;
  const headerBackgroundColor = useCustomHeader ? appTheme?.headerBackgroundColor : "var(--secondary-bg-color)";
  const headerTextColor = useCustomHeader ? appTheme?.headerTextColor : "var(--secondary-background-text-color)";


  useEffect(() => {
    async function fetchData() {
      try {
        const GetInfo = await getUserDetails();
        if (GetInfo) {
          if (!atsCustomerDetails) {
            let USERId = { userId: GetInfo.userId };
            dispatch(getCustDetailsAsync(USERId));
          }
          setUser(GetInfo);
        } else {
          // const userData = {
          //   Username: JSON.parse(decrypt("userName")),
          //   Pool: userPool,
          // };
          // const cognitoUser = new CognitoUser(userData);
          // cognitoUser.getUserAttributes((err, attributes) => {
          //   if (err) {
          //     console.error(err);
          //   } else {
          //     console.log(attributes);
          //   }
          // });
        }
      } catch (error) {
        navigate("/ats/login");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!countriesData && !loggedOut) {
      dispatch(jobPostingServices.getCountriesAsync());
    }
  }, [dispatch, countriesData, loggedOut]);

  useEffect(() => {
    if (GetUserInfo === null) {
      setgetUserInfo(JSON.parse(decrypt("GetUserInfo")));
    }
  }, [GetUserInfo])

  useEffect(() => {
    if (GetUserInfo && GetUserInfo.length > 0) {
      let assignedCountries = JSON.parse(GetUserInfo[0].selectedcountries);
      if (assignedCountries.length > 0) {
        let selCountry = decrypt('selectCountry') ? decrypt('selectCountry') : Cookies.get('selectCountry');
        if (selCountry) {
          let countryObj = JSON.parse(selCountry);
          setUserCountries(countryObj);
          Cookies.set('selectCountry', JSON.stringify(countryObj), { expires: 3 });
          Cookies.set('selectCountryId', JSON.stringify(countryObj.id), { expires: 3 });
          Encrypt(JSON.stringify(countryObj), "selectCountry");
          Encrypt(JSON.stringify(countryObj.id), "selectCountryId");
        } else {
          setUserCountries(assignedCountries[0]);
          Cookies.set('selectCountry', JSON.stringify(assignedCountries[0]), { expires: 3 });
          Cookies.set('selectCountryId', JSON.stringify(assignedCountries[0].id), { expires: 3 });
          Encrypt(JSON.stringify(assignedCountries[0]), "selectCountry");
          Encrypt(JSON.stringify(assignedCountries[0].id), "selectCountryId");
        }
        setSelectedCountry(assignedCountries);
      }
      // to remove clients based on customer preference //
      if (GetUserInfo[0]?.clientStatus === 0) {
        let array = [...pages];
        let clientsIndex = array.findIndex(x => x.displayName === "Clients");
        if (clientsIndex !== -1) {
          array.splice(clientsIndex, 1);
          setFinalPages(array);
          console.log(array);
        }
      } else {
        setFinalPages(pages);
      }
    }
  }, [GetUserInfo]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onActiveMenuHandler = (page, boolean) => {
    let obj = finalPages;
    obj.forEach((e) => (e.isActive = false));
    let findIndex = obj.findIndex((x) => x.id === page?.id);
    if (findIndex !== -1) {
        obj[findIndex].isActive = location.pathname.includes(obj[findIndex].path) ? true : false;
    }
    if (boolean) {
      sessionStorage.removeItem("AssociatedId");
      // navigate(page.path);
    }
  };

  useEffect(() => {
    const url = location.pathname;
    const isJobposting = url.includes("jobpostings");
    const isApplicants = url.includes("applicants");
    const isClients = url.includes("clients");
    let isAssociated = localStorage.getItem("AssociatedMenu");
    // if(isAssociated === "false"){
    if (isJobposting) {
      onActiveMenuHandler(finalPages[0], false);
    } else if (isApplicants) {
      onActiveMenuHandler(finalPages[1], false);
    } else if (isClients) {
      onActiveMenuHandler(finalPages[2], false);
    }
    else {
      finalPages.forEach((e) => (e.isActive = false));
    }
    // }
    setDashboardPath(url);
  }, [location, finalPages]);

  // useEffect(() => {
  //   if (user !== null) {
  //     dispatch(refreshToken());
  //     // return "Loading...";
  //   }
  // }, [location]);

  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, obj) => {
    setUserCountries(obj);
    Encrypt(JSON.stringify(obj), "selectCountry");
    Encrypt(JSON.stringify(obj.id), "selectCountryId");
    Cookies.set('selectCountry', JSON.stringify(obj), { expires: 3 });
    Cookies.set('selectCountryId', JSON.stringify(obj.id), { expires: 3 });
    // dispatch(updateCountry(JSON.stringify(obj)));
    const url = location.pathname;
    const isJobposting = url.includes("jobpostings");
    const isApplicants = url.includes("applicants");
    const isClients = url.includes("clients");

    if (isJobposting) {
      navigate("/ats/layout/jobpostings/landing");
    } else if (isApplicants) {
      navigate("/ats/layout/applicants/landing");
    } else if (isClients) {
      navigate("/ats/layout/clients/landing");
    }

    Encrypt(JSON.stringify(""), "allCountryId");

    window.location.reload();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelp = (type) => {
    if (type === "help") {
      navigate("/ats/layout/helpsection/postings");
    } else {
      navigate(`/ats/layout/customer/view`); // ${GetUserInfo[0].id}
      if (GetUserInfo) {
        setBreadCrumb(GetUserInfo[0].id, GetUserInfo[0].companyName, "Customer")
      }
    }
    handleCloseUserMenu();
  }

  const preserveThemeAndLogout = async () => {
    setLoggedOut(true);
    const themeSettings = localStorage.getItem("themeSettings");
    dispatch(updateAuth(null));
    dispatch(getCustDetailsStartReducer({}));
    Cookies.remove("userName");
    Cookies.remove("userPassword");

    dispatch(resetStore());
    await persistor.flush();  // Ensures all pending state is written to storage
    await persistor.purge();  // Purges the persisted state
    localStorage.clear();
    sessionStorage.clear();
    signOut();
    localStorage.setItem("themeSettings", themeSettings);
    navigate("/ats/login");
    // window.location.reload();
  }

  let storedCountryId = JSON.parse(decrypt( "selectCountryId"));

  useEffect(()=>{
    let index = selectedCountry?.findIndex(x => x.id === storedCountryId);
    if(index !== -1 && selectedCountry){
        console.log(selectedCountry[index]);
        setUserCountries(selectedCountry[index]);
        Encrypt(JSON.stringify(selectedCountry[index]), "selectCountry");
        Encrypt(JSON.stringify(storedCountryId), "selectCountryId");
      }
  },[storedCountryId])

    const onChangePlatformHandler = (e) => {
      setAllCountries(e.target.checked );
        Encrypt(JSON.stringify(!e.target.checked ? "all" : ""), "allCountryId");
        window.location.reload();
  }

  return (
    <AppBar position="fixed" className="headerBar" style={{
      backgroundColor: headerBackgroundColor,
      color: headerTextColor
    }}>
      <Toolbar>
        <Link to="/ats/layout/dashboard">
          <div style={{ width: "150px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
            {!isLogoLoading ? (
              <img
                src={logoUrl || DEFAULT_LOGO} // ✅ Default logo if empty
                alt="Company logo"
                className={logoUrl && logoUrl !== DEFAULT_LOGO ? "customLogo" : "hirewingImg"}
                style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} // ✅ Ensures image fits properly
                onClick={() => {
                  finalPages.map((x) => (x.isActive = false));
                }}
              />
            ) : (
              <div style={{ width: "150px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }} /> // ✅ Placeholder until logo loads
            )}
          </div>
        </Link>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            gap: "30px",
            margin: "0px 0 0 30px",
          }}
        >
          {finalPages.map((page) => (
            <Button
              component={Link}
              to={page.path}
              key={page.id}
              rel="tag"
              style={{
                margin: "2px 10px 2px 0",
                color: headerTextColor,
                display: "block",
                padding: "6px 8px",
                fontSize: "18px",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
              className={page.isActive === true ? "activebtn" : "nonactivebtn"}
              onClick={(e) => onActiveMenuHandler(page, true)}
            >
              {page.displayName}
            </Button>
          ))}
        </div>

        
        <div style={{ display: "flex", alignItems: "center", minHeight:"70px" }}>
          {dashboardPath === "/ats/layout/dashboard" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="allcountrydisplay">
                <img src={`${process.env.PUBLIC_URL}/assests/img/globe.svg`} alt="allcontries" style={{ height: "30px" }} />
              </div>
              <p>All Countries</p>
              <span id="headerToggle" >
                <Switch
                  {...label}
                  checked={allCountries}
                  name="allCountries"
                  // disabled={componentType !== "View" ? false : true}
                  onChange={(e) => onChangePlatformHandler(e)}
                  className="headerToggleBtn"
                />
              </span>
            </div>
          )}

              <List
                component="nav"
                aria-label="Device settings"
              >
                <ListItem
                  className="countrydisplay"
                  id="lock-button"
                  // style={{ marginTop: "-4px" }}
                  aria-haspopup="listbox"
                  aria-controls="lock-menu"
                  aria-expanded="true"
                  onClick={handleClickListItem}
                >
                  <ListItemText className="flagname"
                    style={{ display: "flex", gap: "15px", justifyContent: "left", width: "120px", cursor: "pointer", alignItems: "center" }}
                    primary={<img src={userCountries ? `${process.env.PUBLIC_URL}/assests/img/${userCountries.logo}` : ''} alt="" />}
                    secondary={userCountries ? userCountries.name : ''}
                  />
                </ListItem>
              </List>
              <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                onMouseLeave={handleClose}
                className="headerDropdown"
                MenuListProps={{
                  "aria-labelledby": "lock-button",
                  role: "listbox",
                }}
              >
                {selectedCountry && selectedCountry.map((option, index) => (
                  <MenuItem
                    style={{ display: "flex", gap: "15px", justifyContent: "left", height: "35px", padding: "0 12px", width: "135px", color: "#000" }}
                    // className="menucountrydropdown"
                    key={option.id}
                    selected={option.id === userCountries ? userCountries.id : 0}
                    onClick={(event) => handleMenuItemClick(event, index, option)}
                  >
                    <img src={`${process.env.PUBLIC_URL}/assests/img/${option.logo}`} alt=""
                      // className="countryflagsimg"
                      style={{ width: option.logo === "004-malaysia.svg" ? "30px" : "30px" }}

                    />
                    <p className="countryflagname">{option.name}</p>
                  </MenuItem>
                ))}
              </Menu>
          </div>
        {/* select flag section end here */}

        <div style={{ display: "flex", color: "var(--secondary-background-text-color)"}}>
          <h4 style={{ paddingLeft: "10px", fontWeight: "400", color: headerTextColor }}>
            {GetUserInfo !== null ? GetUserInfo[0].companyName : ""}
          </h4>
          <IconButton
            onClick={handleOpenUserMenu}
            style={{ padding: 0, marginRight: "20px", marginLeft: "10px" }}
          >
            <Avatar alt="Remy Sharp" style={{ backgroundColor: "var(--primary-bg-color)" }}>
              <PersonIcon style={{ color: "var(--primary-background-text-color)" }} />
            </Avatar>
          </IconButton>
          {/* userdetails start here */}
          <Menu
            style={{ marginTop: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
          >
            <div className="profileMenu">




              <div
                style={{ alignItems: "center", justifyContent: "flex-start" }}
              >
                <ProfileIcon
                  primaryColor={appTheme?.primaryColor}
                  primaryFontColor={appTheme?.primaryFontColor}
                />

                <h3 style={{ paddingLeft: "10px" }}>
                  {GetUserInfo !== null
                    ? GetUserInfo[0].companyName
                    : ""}
                </h3>
              </div>
              {user !== null && (
                <>
                  <h5>
                    {user.firstName} {user.lastName}
                  </h5>
                  <p>{user.email}</p>
                </>
              )}
              <div >
                <button className="help" onClick={() => { handleHelp("help") }}>
                  Help
                </button>
              </div>
              <div>
                {GetUserInfo !== null && (GetUserInfo[0].systemroles === "Admin" || GetUserInfo[0].systemroles === "Super Admin") ?
                  <p className="settingstext" onClick={() => { handleHelp("settings") }}>Settings</p>
                  : ("")}
              </div>
              <div id="signout" style={{ justifyContent: "end" }}>
                <Button
                  onClick={preserveThemeAndLogout}
                  className="signout"
                >
                  Sign Out
                </Button>
              </div>
            </div>
          </Menu>
          {/* userdetails end here */}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;