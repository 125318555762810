export const GroupOfStatus = ({primaryColor="#ffd800", primaryFontColor="rgba(0,0,0,0.8)"})=> {
    return (
        <svg  width="664.712" height="274.59" viewBox="0 0 664.712 274.59">
          <defs>
            <filter id="Rectangle_19230" x="0" y="0" width="664.712" height="274.59" filterUnits="userSpaceOnUse">
              <feOffset dy="3" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="3" result="blur"/>
              <feFlood flood-opacity="0.161"/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_38758" data-name="Group 38758" transform="translate(-10991.538 -19940.705)">
            <g id="Group_38757" data-name="Group 38757" transform="translate(10362 19106.895)">
              <g id="Group_38746" data-name="Group 38746" transform="translate(-3330.539 -18118.891)">
                <g transform="matrix(1, 0, 0, 1, 3960.08, 18952.7)" filter="url(#Rectangle_19230)">
                  <rect id="Rectangle_19230-2" data-name="Rectangle 19230" width="646.712" height="256.59" rx="20" transform="translate(9 6)" fill="#fff"/>
                </g>
                <rect id="Rectangle_19232" data-name="Rectangle 19232" width="92.005" height="90.552" transform="translate(4463.647 19083.072)" fill="#9fbb7d"/>
                <rect id="Rectangle_19233" data-name="Rectangle 19233" width="88.629" height="72.121" transform="translate(4251.782 19101.498)" fill="#dcc882"/>
                <rect id="Rectangle_19234" data-name="Rectangle 19234" width="88.629" height="108.503" transform="translate(4036.117 19064.957)" fill="#e76f51"/>
                <path id="Path_24446" data-name="Path 24446" d="M0,0V189.359" transform="translate(4018.391 18984.102)" fill="none" stroke="#707070" stroke-width="1"/>
                <line id="Line_321" data-name="Line 321" x1="5.909" transform="translate(4012.482 19131.789)" fill="none" stroke="#707070" stroke-width="1"/>
                <line id="Line_320" data-name="Line 320" x1="5.909" transform="translate(4012.482 19022.807)" fill="none" stroke="#707070" stroke-width="1"/>
                <line id="Line_316" data-name="Line 316" x2="561.318" transform="translate(4012.482 19173.455)" fill="none" stroke="#707070" stroke-width="1"/>
                <text id="_0" data-name="0" transform="translate(3999.399 19163.59)" font-size="6" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">0</tspan></text>
                <text id="_5" data-name="5" transform="translate(3999.399 19127.535)" font-size="6" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">5</tspan></text>
                <text id="_10" data-name="10" transform="translate(3995.179 19091.473)" font-size="6" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">10</tspan></text>
                <text id="_20" data-name="20" transform="translate(3995.179 19055.41)" font-size="6" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">20</tspan></text>
                <text id="_30" data-name="30" transform="translate(3995.179 19019.355)" font-size="6" font-family="Roboto-Regular, Roboto"><tspan x="0" y="0">30</tspan></text>
              </g>
              <g id="Group_38755" data-name="Group 38755" transform="translate(0 -2.774)">
                <path id="Union_28" data-name="Union 28" d="M468.653,32.7H0V19A19,19,0,0,1,19,0H596.461L560.8,32.7Z" transform="translate(638.77 842.585)" fill={primaryColor}/>
                <text id="Graph_Of_Status" data-name="Graph Of Status" transform="translate(651.712 863.936)" fill={primaryFontColor} font-size="13" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">⁠Candidates Grouped By Status (Select Status)</tspan></text>
                <text id="Option_1" data-name="Option 1" transform="translate(776.5 1078)" font-size="14" font-family="Roboto-Regular, Roboto"><tspan x="-52.486" y="0">Option 1</tspan></text>
                <text id="Option_2" data-name="Option 2" transform="translate(989 1078)" font-size="14" font-family="Roboto-Regular, Roboto"><tspan x="-52.486" y="0">Option 2</tspan></text>
                <text id="Option_3" data-name="Option 3" transform="translate(1208 1078)" font-size="14" font-family="Roboto-Regular, Roboto"><tspan x="-52.486" y="0">Option 3</tspan></text>
              </g>
            </g>
          </g>
        </svg>
    )
}