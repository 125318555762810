export const ContactApplicantIllu = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg"  width="625.515" height="152.297" viewBox="0 0 633.515 152.297">
      <defs>
        <clipPath id="clip-path">
          <circle id="Ellipse_1170" data-name="Ellipse 1170" cx="26.636" cy="26.636" r="26.636" fill="#3c4045"/>
        </clipPath>
      </defs>
      <g id="Group_41381" data-name="Group 41381" transform="translate(-1233.485 -936.703)">
        <g id="OBJECTS" transform="translate(1207 855.576)">
          <g id="Group_41378" data-name="Group 41378" transform="translate(26.485 81.127)">
            <g id="Group_41348" data-name="Group 41348">
              <path id="Path_63765" data-name="Path 63765" d="M210.615,232.894c4.948-6.949,14.974-24.459,16.958-54.36,2.173-32.749-1.472-77.876-40.829-88.058-14.616-3.781-29.672-1.456-44.559-2.159a294.14,294.14,0,0,1-37.506-4.192c-11.033-1.957-21.594-4.6-32.8-1.736C42.509,89.9,27.328,123.982,26.537,150.727c-.708,23.933,5.924,48.006,15.121,70.051.436,1.046,4.374,12.117,5.517,12.117Z" transform="translate(-26.485 -81.127)" fill="#e9ebea"/>
              <g id="Group_41347" data-name="Group 41347" transform="translate(9.562 27.493)">
                <g id="Group_41343" data-name="Group 41343" transform="translate(49.258)">
                  <g id="Group_41328" data-name="Group 41328" transform="translate(0 71.53)">
                    <path id="Path_63766" data-name="Path 63766" d="M203.581,300.728c-7.96,14.429-12.913,31.854-13.574,32.039-.894.251-22.555,7.687-33.077,10.887l3.416,6.323c13.546-.957,35.083-3.188,38.238-5.857,4.886-4.134,16.838-32.3,20.276-39.121C213.362,304.325,208.39,303.655,203.581,300.728Z" transform="translate(-156.93 -300.728)" fill="#fdc49f"/>
                  </g>
                  <g id="Group_41342" data-name="Group 41342" transform="translate(50.834)">
                    <path id="Path_63767" data-name="Path 63767" d="M386.5,294.875c5.393,16.374,11.862,34.452,13.851,33.54,2.447-1.121,2.208-20.8.583-35.195A80.785,80.785,0,0,1,386.5,294.875Z" transform="translate(-333.814 -225.076)" fill="#e8a87e"/>
                    <path id="Path_63768" data-name="Path 63768" d="M282.643,175.219s-5.735,27.547-6.477,35.89,5.689,14.09,14.958,13.719,3.522-25.925,0-33.077S282.643,175.219,282.643,175.219Z" transform="translate(-273.201 -160.284)" fill="#3d354a"/>
                    <path id="Path_63769" data-name="Path 63769" d="M273.174,323.631h51.265c-.124-11.942.1-26.281,1.521-34.9,2.781-16.824-.417-23.636-5.84-28.086s-13.487-6.118-13.487-6.118L295.789,264.4l-6.579-6.952s-15.807,5.979-18.587,7.925,1.251,30.311,2.364,36.567C273.509,304.881,273.418,314.381,273.174,323.631Z" transform="translate(-269.662 -203.833)" fill="#ffd800"/>
                    <path id="Path_63770" data-name="Path 63770" d="M382.233,273.365a5.991,5.991,0,0,0-1.272-3.027,26.158,26.158,0,0,0-10.722-7.819c-8.116-3.179-18.585-7.989-18.585-7.989l1.935,28.179s26.458,0,29.248-4.448Z" transform="translate(-314.682 -203.832)" fill="#ffd800"/>
                    <path id="Path_63771" data-name="Path 63771" d="M313.013,233.109v6l6.578,6.952,10.845-9.872V221.123S330.071,229.476,313.013,233.109Z" transform="translate(-293.465 -185.489)" fill="#fdc49f"/>
                    <path id="Path_63772" data-name="Path 63772" d="M313.013,236.622s8.525,2.217,17.424-9.1c0,0,0,13.993-17.424,15.1Z" transform="translate(-293.465 -189.002)" fill="#e8a87e"/>
                    <g id="Group_41332" data-name="Group 41332" transform="translate(8.418 9.793)">
                      <ellipse id="Ellipse_1164" data-name="Ellipse 1164" cx="19.765" cy="14.739" rx="19.765" ry="14.739" transform="translate(0 39.502) rotate(-87.851)" fill="#fdc49f"/>
                      <g id="Group_41330" data-name="Group 41330" transform="translate(28.734 17.263)">
                        <g id="Group_41329" data-name="Group 41329">
                          <path id="Path_63773" data-name="Path 63773" d="M353.255,202.9c.535-.211.773-.7,1.472-.784,4.964-.578,2.886,10.721-2.376,9.651C352.351,211.771,351.258,203.689,353.255,202.9Z" transform="translate(-352.053 -202.098)" fill="#fdc49f"/>
                        </g>
                      </g>
                      <g id="Group_41331" data-name="Group 41331" transform="translate(30.974 19.039)">
                        <path id="Path_63774" data-name="Path 63774" d="M357.021,206.068a1.378,1.378,0,0,1,.937.152,2.228,2.228,0,0,1,.726.663,2.5,2.5,0,0,1,.4.909,4.769,4.769,0,0,1,.109.964,5.081,5.081,0,0,1-.1.962,3.756,3.756,0,0,1-.34.914,2.5,2.5,0,0,1-.634.747,1.912,1.912,0,0,1-.89.335,2.22,2.22,0,0,0,.727-.517,2.755,2.755,0,0,0,.446-.735,4.646,4.646,0,0,0,.3-1.707,3.517,3.517,0,0,0-.339-1.668A1.8,1.8,0,0,0,357.021,206.068Z" transform="translate(-357.021 -206.039)" fill="#e8a87e"/>
                      </g>
                    </g>
                    <path id="Path_63775" data-name="Path 63775" d="M288.663,162.549s-2.552-10.12,2.314-12.9c0,0,4.565-8.2,16.882-7.508s18.573,13.209,17.878,22.942,7.23,21.551,5.7,31.284-11.123,12.652-16.685,11.262,1.947-29.895,1.947-29.895,5.244.579,6.724-3.464-.687-7.948-4.157-5.856c0,0-17.3-.882-17.583-14.2C301.683,154.213,294.483,161.337,288.663,162.549Z" transform="translate(-279.728 -142.098)" fill="#3d354a"/>
                    <g id="Group_41333" data-name="Group 41333" transform="translate(11.249 2.95)">
                      <path id="Path_63776" data-name="Path 63776" d="M310.728,149.8a17.629,17.629,0,0,0-14.023.619,2.824,2.824,0,0,0-2.1,2.819c4.718-2.4,10.843-2.985,15.4-1.383,12.313,4.336,13.49,18.511,13.5,18.654a1.089,1.089,0,0,0,1.085,1.01l.078,0a1.089,1.089,0,0,0,1.009-1.163C325.667,170.2,324.407,154.619,310.728,149.8Z" transform="translate(-294.608 -148.64)" fill="#bababa"/>
                    </g>
                    <path id="Path_63777" data-name="Path 63777" d="M374.508,255.472l5.4-.942s12.106,33.393,14.357,41.359.978,18.819-5.727,19.066-10.5-10.309-11.691-20.392S374.508,255.472,374.508,255.472Z" transform="translate(-327.231 -203.832)" fill="#fdc49f"/>
                    <g id="Group_41334" data-name="Group 41334" transform="translate(15.728 24.095)">
                      <path id="Path_63778" data-name="Path 63778" d="M307.953,195.534a26.767,26.767,0,0,1-.517,3.6,25.578,25.578,0,0,1-.985,3.516,13.469,13.469,0,0,1-.757,1.689l-.241.4c-.073.13-.154.238-.209.363a1.425,1.425,0,0,0-.179.715c.028.454.582.775,1.127.937a8.244,8.244,0,0,0,1.76.31,5.951,5.951,0,0,1-1.836,0,2.867,2.867,0,0,1-.916-.311,1.529,1.529,0,0,1-.4-.344,1.275,1.275,0,0,1-.235-.514,3.071,3.071,0,0,1,.586-1.853,14.327,14.327,0,0,0,.691-1.625c.417-1.113.76-2.262,1.1-3.412Z" transform="translate(-304.542 -195.534)" fill="#e8a87e"/>
                    </g>
                    <path id="Path_63779" data-name="Path 63779" d="M325.492,203.555a1.01,1.01,0,1,1-1.01-1.01A1.009,1.009,0,0,1,325.492,203.555Z" transform="translate(-299.209 -175.288)" fill="#3d354a"/>
                    <circle id="Ellipse_1165" data-name="Ellipse 1165" cx="1.01" cy="1.01" r="1.01" transform="translate(12.925 25.904)" fill="#3d354a"/>
                    <g id="Group_41335" data-name="Group 41335" transform="translate(22.4 23.621)">
                      <path id="Path_63780" data-name="Path 63780" d="M319.339,195.258a3.3,3.3,0,0,1,1.663-.723,4.336,4.336,0,0,1,1.843.107,4.445,4.445,0,0,1,2.283,1.486,4.288,4.288,0,0,1,.471.776c-.3-.1-.543-.246-.8-.358s-.5-.24-.756-.331a11.277,11.277,0,0,0-1.479-.522,10.284,10.284,0,0,0-1.535-.3C320.492,195.315,319.947,195.281,319.339,195.258Z" transform="translate(-319.339 -194.482)" fill="#3d354a"/>
                    </g>
                    <g id="Group_41336" data-name="Group 41336" transform="translate(11.313 21.985)">
                      <path id="Path_63781" data-name="Path 63781" d="M299.993,191.984a11.5,11.5,0,0,0-1.33-.047l-1.254.006c-.419,0-.845,0-1.284-.038a6.584,6.584,0,0,1-.67-.063,4.3,4.3,0,0,1-.7-.136,3.869,3.869,0,0,1,1.27-.651,4.811,4.811,0,0,1,1.392-.2,4.016,4.016,0,0,1,1.414.245A2.2,2.2,0,0,1,299.993,191.984Z" transform="translate(-294.751 -190.854)" fill="#3d354a"/>
                    </g>
                    <g id="Group_41337" data-name="Group 41337" transform="translate(14.944 39.158)">
                      <path id="Path_63782" data-name="Path 63782" d="M302.8,228.938a9.189,9.189,0,0,0,1.739,1.07,5.668,5.668,0,0,0,1.9.517,4.936,4.936,0,0,0,1.943-.2,7.278,7.278,0,0,0,.948-.343c.313-.135.6-.315.93-.457a8.153,8.153,0,0,1-.8.689,5.013,5.013,0,0,1-.939.512,4.307,4.307,0,0,1-2.134.338,4.752,4.752,0,0,1-2.046-.685A4.957,4.957,0,0,1,302.8,228.938Z" transform="translate(-302.803 -228.938)" fill="#3d354a"/>
                    </g>
                    <g id="Group_41340" data-name="Group 41340" transform="translate(21.056 23.678)">
                      <g id="Group_41338" data-name="Group 41338" transform="translate(0 9.271)">
                        <path id="Path_63783" data-name="Path 63783" d="M340.444,215.17a18.869,18.869,0,0,1-13.722,6.93" transform="translate(-322.048 -215.17)" fill="none" stroke="#9a959c" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2.415"/>
                        <path id="Path_63784" data-name="Path 63784" d="M321.958,228.286c.155.986-.97,1.982-2.513,2.224s-2.918-.362-3.073-1.348.97-1.982,2.513-2.224S321.8,227.3,321.958,228.286Z" transform="translate(-316.357 -221.602)" fill="#9a959c"/>
                      </g>
                      <g id="Group_41339" data-name="Group 41339" transform="translate(14.736)">
                        <ellipse id="Ellipse_1166" data-name="Ellipse 1166" cx="5.44" cy="6.712" rx="5.44" ry="6.712" fill="#bababa"/>
                        <ellipse id="Ellipse_1167" data-name="Ellipse 1167" cx="4.78" cy="5.898" rx="4.78" ry="5.898" transform="translate(0.66 0.814)" fill="none" stroke="#9a959c" stroke-miterlimit="10" stroke-width="0.797"/>
                        <path id="Path_63785" data-name="Path 63785" d="M360.595,206a2.377,2.377,0,1,1-2.326-2.87A2.641,2.641,0,0,1,360.595,206Z" transform="translate(-352.829 -199.288)" fill="#9a959c"/>
                      </g>
                    </g>
                    <g id="Group_41341" data-name="Group 41341" transform="translate(41.34 26.913)">
                      <path id="Path_63786" data-name="Path 63786" d="M366.875,215.782l-.057-6.873s-4.158-1.014-4.644-1.758,1.668-.833,1.668-.833-2.272-.731-2.417-1.531,3.112-.641,3.112-.641-1.97-.993-2.028-1.667.3-.836,1.181-.585,6.252,2.83,6.772,3.537,1.8,5.247,2.24,7.3.052,7.757-.021,9.925a35.45,35.45,0,0,0,.14,4.245l-5.461.475a7.164,7.164,0,0,0-1.217-3.824,9.021,9.021,0,0,1-1.716-5.418c-.031-1.864-2.671-6.187-3.028-7.3s1.035-1.3,1.686-.605a5.127,5.127,0,0,1,1.883,2.816A5,5,0,0,0,366.875,215.782Z" transform="translate(-361.341 -201.783)" fill="#fdc49f"/>
                      <path id="Path_63787" data-name="Path 63787" d="M366.885,211.842s3.336.512,4.113,1.165,1.784,4.692,1.878,5.4" transform="translate(-364.385 -207.306)" fill="none" stroke="#e8a87e" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.85"/>
                      <path id="Path_63788" data-name="Path 63788" d="M368.427,207.023s4.38,1.224,5.005,2.381a43.787,43.787,0,0,1,1.753,5.088" transform="translate(-365.232 -204.66)" fill="none" stroke="#e8a87e" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.85"/>
                    </g>
                  </g>
                  <path id="Path_63789" data-name="Path 63789" d="M278.958,261.005s-20.605,6.058-22.83,9.116-5.137,17.281-5.137,17.281l13.984,7.682Z" transform="translate(-208.577 -207.387)" fill="#ffd800"/>
                </g>
                <path id="Path_63790" data-name="Path 63790" d="M228,412.777H49.835a2.344,2.344,0,0,1-2.144-2.5h0a2.344,2.344,0,0,1,2.144-2.5H228a2.344,2.344,0,0,1,2.144,2.5h0A2.344,2.344,0,0,1,228,412.777Z" transform="translate(-47.691 -287.974)" fill="#9a959c"/>
                <g id="Group_41346" data-name="Group 41346" transform="translate(14.004 58.146)">
                  <rect id="Rectangle_19745" data-name="Rectangle 19745" width="52.835" height="2.283" transform="translate(29.751 59.37)" fill="#bababa"/>
                  <g id="Group_41345" data-name="Group 41345">
                    <g id="Group_41344" data-name="Group 41344">
                      <path id="Path_63791" data-name="Path 63791" d="M140.953,320.283H82.28a3.533,3.533,0,0,1-3.533-3.533V274.579a3.533,3.533,0,0,1,3.533-3.533h58.673a3.533,3.533,0,0,1,3.533,3.533v42.171A3.532,3.532,0,0,1,140.953,320.283Z" transform="translate(-78.747 -271.047)" fill="#bababa"/>
                      <path id="Path_63792" data-name="Path 63792" d="M213.741,271.047h-2.429a3.533,3.533,0,0,1,3.533,3.533v42.171a3.533,3.533,0,0,1-3.533,3.533h2.429a3.533,3.533,0,0,0,3.533-3.533V274.58A3.533,3.533,0,0,0,213.741,271.047Z" transform="translate(-151.536 -271.047)" fill="#9a959c"/>
                    </g>
                    <path id="Path_63793" data-name="Path 63793" d="M164.495,325.912l-2.245,24.5h4.311Z" transform="translate(-124.597 -301.172)" fill="#9a959c"/>
                    <path id="Path_63794" data-name="Path 63794" d="M137.267,321.337H123.64a1.879,1.879,0,0,0-1.835,1.475l-5.487,24.851a10.406,10.406,0,0,0,10.162,12.65h26.527v-.821a1.963,1.963,0,0,0-1.963-1.963h-3.772a10.406,10.406,0,0,1-10.357-11.42l2.223-22.708A1.88,1.88,0,0,0,137.267,321.337Z" transform="translate(-99.24 -298.66)" fill="#cfcfcf"/>
                  </g>
                </g>
                <line id="Line_338" data-name="Line 338" x2="37.196" transform="translate(17.578 60.57)" fill="none" stroke="#cfcfcf" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.066"/>
                <line id="Line_339" data-name="Line 339" x2="11.974" transform="translate(59.16 60.57)" fill="none" stroke="#cfcfcf" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.066"/>
              </g>
            </g>
            <g id="Group_41360" data-name="Group 41360" transform="translate(15.119 27.431)">
              <circle id="Ellipse_1168" data-name="Ellipse 1168" cx="26.636" cy="26.636" r="26.636" fill="#fff"/>
              <g id="Group_41359" data-name="Group 41359" clip-path="url(#clip-path)">
                <g id="Group_41349" data-name="Group 41349" transform="translate(34.007 36.747)">
                  <path id="Path_63795" data-name="Path 63795" d="M142.846,223.454c3.863,7,6.267,15.46,6.588,15.55.142.04,1.353.45,3.1,1.038v6.188c-3.569-.487-6.5-1.075-7.261-1.715-2.371-2.007-8.172-15.674-9.84-18.987C138.1,225.2,140.512,224.874,142.846,223.454Z" transform="translate(-135.431 -223.454)" fill="#fdc49f"/>
                </g>
                <g id="Group_41350" data-name="Group 41350" transform="translate(-0.903 36.747)">
                  <path id="Path_63796" data-name="Path 63796" d="M58.011,239.654c1.113-.375,1.843-.621,1.951-.651.321-.09,2.724-8.547,6.588-15.55,2.334,1.421,4.747,1.746,7.415,2.073-1.668,3.313-7.469,16.98-9.84,18.987-.674.57-3.076,1.1-6.114,1.552Z" transform="translate(-58.011 -223.453)" fill="#fdc49f"/>
                </g>
                <path id="Path_63797" data-name="Path 63797" d="M111.794,234.569H86.913c.06-5.8-.047-12.755-.738-16.937-1.35-8.165.2-11.471,2.834-13.631a17.485,17.485,0,0,1,6.546-2.969l5.264,4.791,3.193-3.374s7.672,2.9,9.021,3.846-.607,14.711-1.147,17.747A88.337,88.337,0,0,0,111.794,234.569Z" transform="translate(-74.106 -174.396)" fill="#3c4045"/>
                <path id="Path_63798" data-name="Path 63798" d="M74.311,210.173a2.9,2.9,0,0,1,.618-1.469c.79-.981,2.982-3.917,5.7-4.982a81.564,81.564,0,0,1,8.522-2.69l-.939,13.676s-12.841,0-14.2-2.159Z" transform="translate(-67.703 -174.396)" fill="#3c4045"/>
                <path id="Path_63799" data-name="Path 63799" d="M116.037,190.635v2.911l-3.193,3.374-5.264-4.791v-7.311S107.758,188.872,116.037,190.635Z" transform="translate(-86.131 -165.493)" fill="#fdc49f"/>
                <path id="Path_63800" data-name="Path 63800" d="M116.037,192.34s-4.138,1.076-8.456-4.417c0,0,0,6.792,8.456,7.328Z" transform="translate(-86.132 -167.198)" fill="#e8a87e"/>
                <g id="Group_41354" data-name="Group 41354" transform="translate(18.693 6.788)">
                  <ellipse id="Ellipse_1169" data-name="Ellipse 1169" cx="7.153" cy="9.592" rx="7.153" ry="9.592" transform="translate(1.593 0.537) rotate(-2.149)" fill="#fdc49f"/>
                  <g id="Group_41352" data-name="Group 41352" transform="translate(0 8.373)">
                    <g id="Group_41351" data-name="Group 41351">
                      <path id="Path_63801" data-name="Path 63801" d="M103.554,175.975c-.26-.1-.375-.341-.715-.381-2.409-.28-1.4,5.2,1.153,4.684C103.993,180.279,104.523,176.356,103.554,175.975Z" transform="translate(-101.469 -175.584)" fill="#fdc49f"/>
                    </g>
                  </g>
                  <g id="Group_41353" data-name="Group 41353" transform="translate(0.527 9.236)">
                    <path id="Path_63802" data-name="Path 63802" d="M103.692,177.511a.876.876,0,0,0-.649.495,1.733,1.733,0,0,0-.163.81,2.305,2.305,0,0,0,.142.829,1.069,1.069,0,0,0,.571.606.929.929,0,0,1-.432-.163,1.318,1.318,0,0,1-.306-.363,1.9,1.9,0,0,1-.216-.91,1.566,1.566,0,0,1,.248-.908A.825.825,0,0,1,103.692,177.511Z" transform="translate(-102.638 -177.497)" fill="#e8a87e"/>
                  </g>
                </g>
                <g id="Group_41355" data-name="Group 41355" transform="translate(30.104 13.725)">
                  <path id="Path_63803" data-name="Path 63803" d="M126.774,172.4l.488,1.687c.171.557.335,1.115.538,1.655a6.923,6.923,0,0,0,.335.789,1.519,1.519,0,0,1,.284.9.673.673,0,0,1-.311.414,1.429,1.429,0,0,1-.443.152,2.962,2.962,0,0,1-.891,0,3.671,3.671,0,0,0,.854-.152,1.324,1.324,0,0,0,.37-.169.45.45,0,0,0,.179-.285,1.5,1.5,0,0,0-.308-.72,6.586,6.586,0,0,1-.367-.819,12.03,12.03,0,0,1-.476-1.706A13.339,13.339,0,0,1,126.774,172.4Z" transform="translate(-126.774 -172.399)" fill="#e8a87e"/>
                </g>
                <path id="Path_63804" data-name="Path 63804" d="M119.085,176.291a.49.49,0,1,0,.49-.49A.49.49,0,0,0,119.085,176.291Z" transform="translate(-92.449 -160.542)" fill="#3d354a"/>
                <path id="Path_63805" data-name="Path 63805" d="M131.29,174.835a.49.49,0,1,0,.49-.49A.49.49,0,0,0,131.29,174.835Z" transform="translate(-99.15 -159.742)" fill="#3d354a"/>
                <g id="Group_41356" data-name="Group 41356" transform="translate(25.483 13.496)">
                  <path id="Path_63806" data-name="Path 63806" d="M119.564,172.266c-.295.01-.56.029-.818.061a4.818,4.818,0,0,0-.745.149,5.164,5.164,0,0,0-.719.252c-.243.1-.478.225-.757.336a1.859,1.859,0,0,1,.542-.688,2.159,2.159,0,0,1,.795-.409,2.128,2.128,0,0,1,.895-.051A1.582,1.582,0,0,1,119.564,172.266Z" transform="translate(-116.526 -171.89)" fill="#3d354a"/>
                </g>
                <g id="Group_41357" data-name="Group 41357" transform="translate(31.358 12.702)">
                  <path id="Path_63807" data-name="Path 63807" d="M129.555,170.677a1.052,1.052,0,0,1,.566-.427,1.983,1.983,0,0,1,.686-.12,2.094,2.094,0,0,1,1.292.413,3.086,3.086,0,0,1-.667.1c-.213.019-.419.013-.623.02s-.4,0-.609,0A6.009,6.009,0,0,0,129.555,170.677Z" transform="translate(-129.555 -170.129)" fill="#3d354a"/>
                </g>
                <g id="Group_41358" data-name="Group 41358" transform="translate(28.521 21.036)">
                  <path id="Path_63808" data-name="Path 63808" d="M126.884,188.611a2.424,2.424,0,0,1-.748.7,2.277,2.277,0,0,1-.992.334,2.07,2.07,0,0,1-1.035-.165,2.845,2.845,0,0,1-.456-.247,4.539,4.539,0,0,1-.387-.335,4.771,4.771,0,0,0,.911.39,2.435,2.435,0,0,0,.944.094A3.4,3.4,0,0,0,126.884,188.611Z" transform="translate(-123.265 -188.611)" fill="#3d354a"/>
                </g>
                <path id="Path_63809" data-name="Path 63809" d="M126.334,204.174s10,2.94,11.08,4.424,2.493,8.387,2.493,8.387l-6.787,3.728Z" transform="translate(-96.429 -176.121)" fill="#3c4045"/>
                <path id="Path_63810" data-name="Path 63810" d="M101.746,160.5s-2.425-5.52.467-9.254,4.335-1.271,4.335-1.271,2.044-3.5,7.491-1.51,4.889,7.469,1,7.787a45.133,45.133,0,0,1-8.9-.477l-2.954,4.977Z" transform="translate(-82.406 -145.195)" fill="#3c4045"/>
              </g>
            </g>
            <g id="Group_41377" data-name="Group 41377" transform="translate(7.567 14.267)">
              <g id="Group_41362" data-name="Group 41362" transform="translate(79.622 1.845)">
                <path id="Path_63811" data-name="Path 63811" d="M219.843,119.021v15.626a2.164,2.164,0,0,0,2.162,2.162h2.841c3.684,5.04,9.934,4.9,9.934,4.9a7.221,7.221,0,0,1-3.992-4.9H250a2.162,2.162,0,0,0,2.162-2.162V119.021A2.162,2.162,0,0,0,250,116.859h-28A2.164,2.164,0,0,0,219.843,119.021Z" transform="translate(-219.843 -116.859)" fill="#fff"/>
                <g id="Group_41361" data-name="Group 41361" transform="translate(2.92 3.742)">
                  <path id="Path_63812" data-name="Path 63812" d="M226.8,126.12h25.518a.481.481,0,0,0,.481-.481h0a.481.481,0,0,0-.481-.481H226.8a.481.481,0,0,0-.481.481h0A.481.481,0,0,0,226.8,126.12Z" transform="translate(-226.319 -125.158)" fill="#e8e9ff"/>
                  <path id="Path_63813" data-name="Path 63813" d="M226.8,131.553h25.518a.481.481,0,0,0,.481-.481h0a.481.481,0,0,0-.481-.481H226.8a.481.481,0,0,0-.481.481h0A.481.481,0,0,0,226.8,131.553Z" transform="translate(-226.319 -128.141)" fill="#e8e9ff"/>
                  <path id="Path_63814" data-name="Path 63814" d="M226.8,136.985h25.518a.481.481,0,0,0,.481-.481h0a.481.481,0,0,0-.481-.481H226.8a.481.481,0,0,0-.481.481h0A.481.481,0,0,0,226.8,136.985Z" transform="translate(-226.319 -131.124)" fill="#e8e9ff"/>
                  <path id="Path_63815" data-name="Path 63815" d="M226.8,142.418h25.518a.481.481,0,0,0,.481-.481h0a.481.481,0,0,0-.481-.481H226.8a.481.481,0,0,0-.481.481h0A.481.481,0,0,0,226.8,142.418Z" transform="translate(-226.319 -134.107)" fill="#e8e9ff"/>
                  <path id="Path_63816" data-name="Path 63816" d="M226.8,147.851h25.518a.481.481,0,0,0,.481-.481h0a.481.481,0,0,0-.481-.481H226.8a.481.481,0,0,0-.481.481h0A.481.481,0,0,0,226.8,147.851Z" transform="translate(-226.319 -137.09)" fill="#e8e9ff"/>
                </g>
              </g>
              <g id="Group_41364" data-name="Group 41364" transform="translate(49.357 1.461)">
                <path id="Path_63817" data-name="Path 63817" d="M172.038,117.3v9.337a1.293,1.293,0,0,1-1.292,1.292h-1.7a7.685,7.685,0,0,1-5.936,2.926,4.314,4.314,0,0,0,2.385-2.926H154.017a1.292,1.292,0,0,1-1.292-1.292V117.3a1.292,1.292,0,0,1,1.292-1.292h16.729A1.293,1.293,0,0,1,172.038,117.3Z" transform="translate(-152.725 -116.006)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.974"/>
                <g id="Group_41363" data-name="Group 41363" transform="translate(1.745 2.236)">
                  <path id="Path_63818" data-name="Path 63818" d="M172.13,121.539H156.882a.288.288,0,0,1-.288-.288h0a.288.288,0,0,1,.288-.288H172.13a.288.288,0,0,1,.288.288h0A.288.288,0,0,1,172.13,121.539Z" transform="translate(-156.594 -120.964)" fill="#fff"/>
                  <path id="Path_63819" data-name="Path 63819" d="M172.13,124.786H156.882a.288.288,0,0,1-.288-.288h0a.288.288,0,0,1,.288-.288H172.13a.288.288,0,0,1,.288.288h0A.288.288,0,0,1,172.13,124.786Z" transform="translate(-156.594 -122.747)" fill="#fff"/>
                  <path id="Path_63820" data-name="Path 63820" d="M172.13,128.032H156.882a.288.288,0,0,1-.288-.288h0a.288.288,0,0,1,.288-.288H172.13a.288.288,0,0,1,.288.288h0A.288.288,0,0,1,172.13,128.032Z" transform="translate(-156.594 -124.529)" fill="#fff"/>
                  <path id="Path_63821" data-name="Path 63821" d="M172.13,131.278H156.882a.288.288,0,0,1-.288-.288h0a.288.288,0,0,1,.288-.288H172.13a.288.288,0,0,1,.288.288h0A.288.288,0,0,1,172.13,131.278Z" transform="translate(-156.594 -126.311)" fill="#fff"/>
                  <path id="Path_63822" data-name="Path 63822" d="M172.13,134.525H156.882a.288.288,0,0,1-.288-.288h0a.288.288,0,0,1,.288-.288H172.13a.288.288,0,0,1,.288.288h0A.288.288,0,0,1,172.13,134.525Z" transform="translate(-156.594 -128.094)" fill="#fff"/>
                </g>
              </g>
              <g id="Group_41368" data-name="Group 41368" transform="translate(70.492 48.99)">
                <g id="Group_41365" data-name="Group 41365" transform="translate(0 0.958)">
                  <path id="Path_63823" data-name="Path 63823" d="M218.978,236H200.34a.744.744,0,0,1-.744-.744V224.278a.744.744,0,0,1,.744-.744h18.638a.744.744,0,0,1,.744.744v10.979A.744.744,0,0,1,218.978,236Z" transform="translate(-199.596 -223.534)" fill="#fff"/>
                  <path id="Path_63824" data-name="Path 63824" d="M199.6,225.183l10.063,5.49,10.064-5.49" transform="translate(-199.595 -224.439)" fill="none" stroke="#e8e9ff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.802"/>
                </g>
                <g id="Group_41367" data-name="Group 41367" transform="translate(16.704)">
                  <path id="Path_63825" data-name="Path 63825" d="M240.976,223.579a2.169,2.169,0,1,1-2.169-2.169A2.169,2.169,0,0,1,240.976,223.579Z" transform="translate(-236.638 -221.41)" fill="#ffd800"/>
                  <g id="Group_41366" data-name="Group 41366" transform="translate(1.162 1.162)">
                    <line id="Line_340" data-name="Line 340" y2="2.013" transform="translate(1.006)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.667"/>
                    <line id="Line_341" data-name="Line 341" x1="2.013" transform="translate(0 1.006)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.667"/>
                  </g>
                </g>
              </g>
              <g id="Group_41373" data-name="Group 41373" transform="translate(162.994 37.917)">
                <path id="Path_63827" data-name="Path 63827" d="M425.422,211.339a2.76,2.76,0,1,0-.761-5.4,8.559,8.559,0,0,0-.539-1.291,2.73,2.73,0,0,0,.626-.455,2.76,2.76,0,1,0-4.358-3.277,8.557,8.557,0,0,0-1.291-.539,2.76,2.76,0,1,0-5.28,0,8.563,8.563,0,0,0-1.291.539,2.731,2.731,0,0,0-.455-.626,2.76,2.76,0,1,0-3.9,3.9,2.732,2.732,0,0,0,.626.455,8.559,8.559,0,0,0-.539,1.291,2.76,2.76,0,1,0,0,5.28,8.561,8.561,0,0,0,.539,1.291,2.718,2.718,0,0,0-.626.455,2.76,2.76,0,0,0,3.9,3.9,2.731,2.731,0,0,0,.455-.626,8.566,8.566,0,0,0,1.291.539,2.76,2.76,0,1,0,5.28,0,8.566,8.566,0,0,0,1.291-.539,2.76,2.76,0,1,0,3.732-3.733,8.561,8.561,0,0,0,.539-1.291A2.747,2.747,0,0,0,425.422,211.339Zm-8.964,1.55a4.309,4.309,0,1,1,4.309-4.309A4.31,4.31,0,0,1,416.458,212.888Z" transform="translate(-404.734 -196.855)" fill="#fff"/>
                <circle id="Ellipse_1176" data-name="Ellipse 1176" cx="6.061" cy="6.061" r="6.061" transform="translate(5.663 5.663)" fill="none" stroke="#e8e9ff" stroke-miterlimit="10" stroke-width="1.496"/>
              </g>
              <g id="Group_41374" data-name="Group 41374" transform="translate(2.136 59.243)">
                <path id="Path_63828" data-name="Path 63828" d="M59.464,252.174a1.529,1.529,0,1,0,0-3.058,1.512,1.512,0,0,0-.421.066,4.732,4.732,0,0,0-.3-.716,1.529,1.529,0,1,0-2.067-2.068,4.712,4.712,0,0,0-.716-.3,1.529,1.529,0,1,0-2.926,0,4.731,4.731,0,0,0-.716.3,1.514,1.514,0,0,0-.252-.347,1.529,1.529,0,1,0-2.162,2.162,1.5,1.5,0,0,0,.347.252,4.713,4.713,0,0,0-.3.716,1.529,1.529,0,1,0,0,2.926,4.736,4.736,0,0,0,.3.716,1.512,1.512,0,0,0-.347.252,1.529,1.529,0,1,0,2.162,2.162,1.512,1.512,0,0,0,.252-.347,4.712,4.712,0,0,0,.716.3,1.529,1.529,0,1,0,2.926,0,4.731,4.731,0,0,0,.716-.3,1.526,1.526,0,1,0,2.414-1.815,1.5,1.5,0,0,0-.347-.252,4.716,4.716,0,0,0,.3-.716A1.5,1.5,0,0,0,59.464,252.174Zm-4.966.859a2.388,2.388,0,1,1,2.388-2.388A2.388,2.388,0,0,1,54.5,253.033Z" transform="translate(-48.003 -244.15)" fill="#fff"/>
                <circle id="Ellipse_1177" data-name="Ellipse 1177" cx="3.358" cy="3.358" r="3.358" transform="translate(3.138 3.138)" fill="none" stroke="#e8e9ff" stroke-miterlimit="10" stroke-width="0.829"/>
              </g>
              <g id="Group_41375" data-name="Group 41375" transform="translate(69.66 28.089)">
                <path id="Path_63829" data-name="Path 63829" d="M205.93,180.786a1.091,1.091,0,0,0,0-2.182,1.07,1.07,0,0,0-.3.047,3.385,3.385,0,0,0-.213-.511,1.089,1.089,0,1,0-1.3-1.723,1.064,1.064,0,0,0-.18.248,3.367,3.367,0,0,0-.51-.213,1.089,1.089,0,1,0-2.135-.3,1.07,1.07,0,0,0,.047.3,3.392,3.392,0,0,0-.511.213,1.074,1.074,0,0,0-.18-.248,1.091,1.091,0,1,0-1.544,1.544,1.075,1.075,0,0,0,.248.18,3.354,3.354,0,0,0-.213.511,1.089,1.089,0,1,0-.3,2.135,1.07,1.07,0,0,0,.3-.047,3.368,3.368,0,0,0,.213.51,1.089,1.089,0,1,0,1.3,1.723,1.074,1.074,0,0,0,.18-.248,3.351,3.351,0,0,0,.511.213,1.089,1.089,0,1,0,2.135.3,1.07,1.07,0,0,0-.047-.3,3.365,3.365,0,0,0,.51-.213,1.089,1.089,0,1,0,1.723-1.3,1.074,1.074,0,0,0-.248-.18,3.362,3.362,0,0,0,.213-.51A1.071,1.071,0,0,0,205.93,180.786Zm-3.545.613a1.7,1.7,0,1,1,1.7-1.7A1.7,1.7,0,0,1,202.385,181.4Z" transform="translate(-197.75 -175.059)" fill="#fff"/>
                <circle id="Ellipse_1178" data-name="Ellipse 1178" cx="2.397" cy="2.397" r="2.397" transform="translate(2.239 2.239)" fill="none" stroke="#e8e9ff" stroke-miterlimit="10" stroke-width="0.591"/>
              </g>
              <g id="Group_41376" data-name="Group 41376">
                <path id="Path_63830" data-name="Path 63830" d="M101.236,119.639h0a5.376,5.376,0,0,0,2.136-2.136h0a5.373,5.373,0,0,0,2.136,2.136h0a5.373,5.373,0,0,0-2.136,2.136h0A5.379,5.379,0,0,0,101.236,119.639Z" transform="translate(-75.096 -115.367)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.974"/>
                <path id="Path_63831" data-name="Path 63831" d="M255.736,196.783h0a5.376,5.376,0,0,0,2.136-2.136h0a5.373,5.373,0,0,0,2.136,2.136h0a5.373,5.373,0,0,0-2.136,2.136h0A5.373,5.373,0,0,0,255.736,196.783Z" transform="translate(-159.929 -157.725)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.974"/>
                <path id="Path_63832" data-name="Path 63832" d="M337.484,114.9h0a5.379,5.379,0,0,0,2.136-2.136h0a5.376,5.376,0,0,0,2.136,2.136h0a5.373,5.373,0,0,0-2.136,2.136h0A5.367,5.367,0,0,0,337.484,114.9Z" transform="translate(-204.815 -112.767)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.974"/>
                <path id="Path_63833" data-name="Path 63833" d="M393.1,186.518h0a5.373,5.373,0,0,0,2.136-2.136h0a5.376,5.376,0,0,0,2.136,2.136h0a5.376,5.376,0,0,0-2.136,2.136h0A5.373,5.373,0,0,0,393.1,186.518Z" transform="translate(-235.352 -152.089)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.974"/>
                <path id="Path_63834" data-name="Path 63834" d="M43.266,206.813h0a5.373,5.373,0,0,0,2.136-2.136h0a5.376,5.376,0,0,0,2.136,2.136h0a5.379,5.379,0,0,0-2.136,2.136h0A5.378,5.378,0,0,0,43.266,206.813Z" transform="translate(-43.266 -163.233)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.974"/>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_41379" data-name="Group 41379" transform="translate(1730.882 997.385)">
          <g id="Group_4218" data-name="Group 4218" transform="translate(0 15.076)">
            <rect id="Rectangle_2687" data-name="Rectangle 2687" width="129.304" height="58.04" rx="19.669" transform="translate(6.813 18.071)" fill="#e0e0e0"/>
            <rect id="Rectangle_2688" data-name="Rectangle 2688" width="129.304" height="58.04" rx="19.669" transform="translate(0 18.499)" fill="#f5f5f5"/>
            <path id="Rectangle_2689" data-name="Rectangle 2689" d="M13.738,0h89.214A13.738,13.738,0,0,1,116.69,13.738v7.915a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V13.738A13.738,13.738,0,0,1,13.738,0Z" transform="translate(6.687 23.972)" fill="#e0e0e0"/>
            <path id="Rectangle_2690" data-name="Rectangle 2690" d="M0,0H116.69a0,0,0,0,1,0,0V7.915a13.738,13.738,0,0,1-13.738,13.738H13.738A13.738,13.738,0,0,1,0,7.915V0A0,0,0,0,1,0,0Z" transform="translate(6.687 48.556)" fill="#e0e0e0"/>
            <g id="Group_4192" data-name="Group 4192" transform="translate(65.154 25.449)">
              <rect id="Rectangle_2691" data-name="Rectangle 2691" width="21.365" height="17.605" transform="translate(5.472 2.715)" fill="#e6e6e6"/>
              <rect id="Rectangle_2692" data-name="Rectangle 2692" width="5.472" height="17.605" transform="translate(0 2.715)" fill="#f5f5f5"/>
              <path id="Path_15999" data-name="Path 15999" d="M362.495,333.226a1.8,1.8,0,1,1-1.8-1.8A1.8,1.8,0,0,1,362.495,333.226Z" transform="translate(-357.959 -317.643)" fill="#e0e0e0"/>
              <rect id="Rectangle_2693" data-name="Rectangle 2693" width="16.049" height="20.32" transform="translate(16.39)" fill="#e6e6e6"/>
              <g id="Group_4191" data-name="Group 4191" transform="translate(11.757)">
                <rect id="Rectangle_2694" data-name="Rectangle 2694" width="4.64" height="20.32" fill="#f5f5f5"/>
                <circle id="Ellipse_48" data-name="Ellipse 48" cx="1.59" cy="1.59" r="1.59" transform="translate(0.73 1.362)" fill="#e0e0e0"/>
              </g>
              <circle id="Ellipse_49" data-name="Ellipse 49" cx="1.59" cy="1.59" r="1.59" transform="translate(12.487 1.362)" fill="#e0e0e0"/>
              <rect id="Rectangle_2695" data-name="Rectangle 2695" width="13.531" height="17.605" transform="translate(21.119 2.715)" fill="#ebebeb"/>
              <rect id="Rectangle_2696" data-name="Rectangle 2696" width="3.091" height="17.605" transform="translate(18.028 2.715)" fill="#f5f5f5"/>
              <rect id="Rectangle_2697" data-name="Rectangle 2697" width="17.748" height="14.626" transform="translate(32.194 5.694)" fill="#e6e6e6"/>
              <rect id="Rectangle_2698" data-name="Rectangle 2698" width="4.545" height="14.626" transform="translate(27.649 5.694)" fill="#f5f5f5"/>
              <rect id="Rectangle_2699" data-name="Rectangle 2699" width="13.333" height="16.881" transform="translate(41.262 3.439)" fill="#e6e6e6"/>
              <rect id="Rectangle_2700" data-name="Rectangle 2700" width="3.855" height="16.881" transform="translate(37.414 3.439)" fill="#f5f5f5"/>
              <rect id="Rectangle_2701" data-name="Rectangle 2701" width="12.951" height="14.626" transform="translate(45.193 5.694)" fill="#e6e6e6"/>
              <rect id="Rectangle_2702" data-name="Rectangle 2702" width="2.566" height="14.626" transform="translate(42.62 5.694)" fill="#f0f0f0"/>
            </g>
            <g id="Group_4193" data-name="Group 4193" transform="translate(20.146 49.223)">
              <rect id="Rectangle_2703" data-name="Rectangle 2703" width="16.538" height="17.605" transform="translate(4.236 2.715)" fill="#e6e6e6"/>
              <rect id="Rectangle_2704" data-name="Rectangle 2704" width="4.236" height="17.605" transform="translate(0 2.715)" fill="#f5f5f5"/>
              <rect id="Rectangle_2705" data-name="Rectangle 2705" width="12.423" height="20.32" transform="translate(12.687)" fill="#e6e6e6"/>
              <rect id="Rectangle_2706" data-name="Rectangle 2706" width="3.592" height="20.32" transform="translate(9.101)" fill="#f5f5f5"/>
              <rect id="Rectangle_2707" data-name="Rectangle 2707" width="16.538" height="17.605" transform="translate(60.656 2.715)" fill="#e6e6e6"/>
              <rect id="Rectangle_2708" data-name="Rectangle 2708" width="4.236" height="17.605" transform="translate(56.42 2.715)" fill="#f5f5f5"/>
              <rect id="Rectangle_2709" data-name="Rectangle 2709" width="12.423" height="20.32" transform="translate(69.108)" fill="#e6e6e6"/>
              <rect id="Rectangle_2710" data-name="Rectangle 2710" width="3.592" height="20.32" transform="translate(65.521)" fill="#f5f5f5"/>
              <rect id="Rectangle_2711" data-name="Rectangle 2711" width="10.474" height="17.605" transform="translate(16.348 2.715)" fill="#ebebeb"/>
              <rect id="Rectangle_2712" data-name="Rectangle 2712" width="2.393" height="17.605" transform="translate(13.955 2.715)" fill="#f5f5f5"/>
              <rect id="Rectangle_2713" data-name="Rectangle 2713" width="13.739" height="14.626" transform="translate(24.921 5.694)" fill="#e6e6e6"/>
              <rect id="Rectangle_2714" data-name="Rectangle 2714" width="3.518" height="14.626" transform="translate(21.403 5.694)" fill="#f5f5f5"/>
              <rect id="Rectangle_2715" data-name="Rectangle 2715" width="10.321" height="16.881" transform="translate(31.94 3.439)" fill="#e6e6e6"/>
              <rect id="Rectangle_2716" data-name="Rectangle 2716" width="2.984" height="16.881" transform="translate(28.961 3.439)" fill="#f5f5f5"/>
              <rect id="Rectangle_2717" data-name="Rectangle 2717" width="10.025" height="14.626" transform="translate(34.983 5.694)" fill="#e6e6e6"/>
              <rect id="Rectangle_2718" data-name="Rectangle 2718" width="1.986" height="14.626" transform="translate(32.992 5.694)" fill="#f0f0f0"/>
            </g>
            <g id="Group_4205" data-name="Group 4205" transform="translate(45.002 0)">
              <g id="Group_4203" data-name="Group 4203">
                <g id="Group_4195" data-name="Group 4195">
                  <g id="Group_4194" data-name="Group 4194">
                    <path id="Path_16000" data-name="Path 16000" d="M337.13,277.189a1.773,1.773,0,0,0-1.887-.943,1.872,1.872,0,0,0-1.4,1.631,1.574,1.574,0,0,0-1.791-.047,1.785,1.785,0,0,0-.72,1.733,1.318,1.318,0,0,0-1.914.389,1.48,1.48,0,0,0,.585,1.965.949.949,0,0,0,.047,1.889,1.025,1.025,0,0,0-.941.9,1.06,1.06,0,0,0,.709,1.113.863.863,0,0,0-.205,1.331.775.775,0,0,0,1.222-.388.253.253,0,0,0,.4.064.285.285,0,0,0-.077-.422.469.469,0,0,0,.809-.207,1.166,1.166,0,0,0,1.578,1.244.714.714,0,0,0,.467.96,1.623,1.623,0,0,0,1.14-.222,2.549,2.549,0,0,1,1.119-.377,3.545,3.545,0,0,1,1,.278,3.01,3.01,0,0,0,3.05-.8,1.3,1.3,0,0,0,2.173-.331.972.972,0,0,0,.855.773.957.957,0,0,0,.931-.668c.008.186.017.372.025.558a1.155,1.155,0,0,0,1.183-1.547,1.175,1.175,0,0,0,1.124-1.026,1.219,1.219,0,0,0-.838-1.3,2.289,2.289,0,0,0,.043-4.341,1.089,1.089,0,0,0-1.04.143.659.659,0,0,0,0,.987,2.1,2.1,0,0,0-.9-2.36,1.87,1.87,0,0,0-2.359.505,1.182,1.182,0,0,0,.012-1.305,1.036,1.036,0,0,0-1.177-.4,1.181,1.181,0,0,0-1.275-.967,1.229,1.229,0,0,0-.959,1.313.6.6,0,0,0-1.082-.2C336.715,277.025,337.13,277.189,337.13,277.189Z" transform="translate(-329.098 -275.994)" fill="#ebebeb"/>
                  </g>
                </g>
                <g id="Group_4202" data-name="Group 4202" transform="translate(1.684 1.302)">
                  <g id="Group_4196" data-name="Group 4196" transform="translate(5.151 4.921)">
                    <path id="Path_16001" data-name="Path 16001" d="M340.165,285.409a1.075,1.075,0,0,0-.809-.195.748.748,0,0,0-.59.551.578.578,0,0,0,.393.666c.337.081.674-.2,1.018-.148a3.048,3.048,0,0,1,.355.108,1.734,1.734,0,0,0,1.392-.5,1.472,1.472,0,0,0,.388-.369.5.5,0,0,0,.041-.511.665.665,0,0,0-.673-.219,2.684,2.684,0,0,0-1.372.573l.038-.235Z" transform="translate(-338.755 -284.786)" fill="#fafafa"/>
                  </g>
                  <g id="Group_4197" data-name="Group 4197" transform="translate(12.787 4.879)">
                    <path id="Path_16002" data-name="Path 16002" d="M350.49,284.967a1.3,1.3,0,0,0-.431-.226.44.44,0,0,0-.447.125.448.448,0,0,0,.125.592,1.309,1.309,0,0,0,.628.213c.227.035.515.043.627-.158a.391.391,0,0,0-.107-.454,1.126,1.126,0,0,0-.447-.221Z" transform="translate(-349.544 -284.727)" fill="#fafafa"/>
                  </g>
                  <g id="Group_4201" data-name="Group 4201">
                    <path id="Path_16003" data-name="Path 16003" d="M334.488,279.532a1.579,1.579,0,0,0-.917,1.1c-.032.146-.028.329.1.407a.419.419,0,0,0,.309.008l1.313-.312a1.865,1.865,0,0,0-.592.314.632.632,0,0,0-.224.6.608.608,0,0,0,.608.409,1.309,1.309,0,0,0,.723-.288.923.923,0,0,0,.457-.672c0-.282-.35-.542-.565-.362a4.818,4.818,0,0,0,1.409-.641,1.322,1.322,0,0,0,.408-.381.545.545,0,0,0,.041-.536c-.449-.815-1.016.381-1.292.488C335.742,279.863,335.152,279.255,334.488,279.532Z" transform="translate(-332.084 -278.142)" fill="#fafafa"/>
                    <path id="Path_16004" data-name="Path 16004" d="M331.84,284.353a.432.432,0,0,0,.115.455.279.279,0,0,0,.42-.086,1.477,1.477,0,0,0-.788.444c-.183.247-.138.678.154.774a.715.715,0,0,0,.544-.122,9.858,9.858,0,0,0,1.356-.894c-.129.3.183.642.51.67a1.575,1.575,0,0,0,.912-.319c.344-.208.737-.527.647-.919a.67.67,0,0,0-.85-.392,1.946,1.946,0,0,0-.822.63c.184-.268-.078-.667-.4-.71a.975.975,0,0,0-.822.379c.365-.2.447-.722-.042-.783C332.456,283.44,331.916,284.087,331.84,284.353Z" transform="translate(-331.477 -279.483)" fill="#fafafa"/>
                    <path id="Path_16005" data-name="Path 16005" d="M345.757,283.046a.528.528,0,1,0-.265,1.016.825.825,0,0,0-.556.29.432.432,0,0,0-.117.328.4.4,0,0,0,.393.3,1.152,1.152,0,0,0,.511-.143.942.942,0,0,0-.5.146.327.327,0,0,0-.075.463.433.433,0,0,0,.42.047,2.085,2.085,0,0,0,.861-.484.831.831,0,0,0,.72.3.494.494,0,0,0,.412-.588.523.523,0,0,0-.445-.324,1.349,1.349,0,0,0-.57.089,1.08,1.08,0,0,0,.458-.5c.069-.221-.08-.517-.311-.5-.26.021-.393.374-.6.5C345.839,283.746,346.312,283.253,345.757,283.046Z" transform="translate(-335.375 -279.348)" fill="#fafafa"/>
                    <g id="Group_4198" data-name="Group 4198" transform="translate(5.325 2.515)">
                      <path id="Path_16006" data-name="Path 16006" d="M339.379,281.49a1.059,1.059,0,0,0-.377.951,1.1,1.1,0,0,0,.562.971.9.9,0,0,0,1.154-.7,1.268,1.268,0,0,0-.707-1.269A.693.693,0,0,0,339.379,281.49Z" transform="translate(-339 -281.387)" fill="#fafafa"/>
                    </g>
                    <g id="Group_4199" data-name="Group 4199" transform="translate(8.095 1.799)">
                      <path id="Path_16007" data-name="Path 16007" d="M343.837,280.77l.122.105a.592.592,0,1,0,0,.737.887.887,0,0,0,1.035-.194.677.677,0,0,0-.142-.984.548.548,0,0,0-.793.469l-.143-.159Z" transform="translate(-342.914 -280.375)" fill="#fafafa"/>
                    </g>
                    <g id="Group_4200" data-name="Group 4200" transform="translate(12.086 2.768)">
                      <path id="Path_16008" data-name="Path 16008" d="M349.143,281.76a1.88,1.88,0,0,0-.564.8.648.648,0,0,0,.375.8c.316.08.617-.181.8-.454a.994.994,0,0,0,.194-.832c-.094-.28-.5-.446-.7-.23Z" transform="translate(-348.554 -281.745)" fill="#fafafa"/>
                    </g>
                    <path id="Path_16009" data-name="Path 16009" d="M341.517,278.7a.536.536,0,0,0-.746-.205.649.649,0,0,0-.231.773.825.825,0,0,0,.677.487c.36.04.785-.251.7-.6a.768.768,0,0,0,.689.26,1.815,1.815,0,0,0,.715-.269c.3-.173.623-.442.592-.789a.62.62,0,0,0-.569-.514,1.053,1.053,0,0,0-.759.283,6.444,6.444,0,0,1-.483.44.609.609,0,0,1-.615.087Z" transform="translate(-334.113 -277.833)" fill="#fafafa"/>
                  </g>
                </g>
              </g>
              <g id="Group_4204" data-name="Group 4204" transform="translate(2.625 8.382)">
                <path id="Path_16010" data-name="Path 16010" d="M344.19,298.023h-9.064l-.887-9.135h10.838Z" transform="translate(-333.226 -288.145)" fill="#f0f0f0"/>
                <path id="Path_16011" data-name="Path 16011" d="M345.361,289.053H333.029l-.222-1.215h12.775Z" transform="translate(-332.807 -287.838)" fill="#fafafa"/>
              </g>
            </g>
            <g id="Group_4217" data-name="Group 4217" transform="translate(19.022 33.71)">
              <g id="Group_4206" data-name="Group 4206">
                <path id="Path_16012" data-name="Path 16012" d="M330.7,335.663H320.3l3.26-10.5.477-1.536h2.353Z" transform="translate(-300.547 -323.625)" fill="#ebebeb"/>
                <path id="Path_16013" data-name="Path 16013" d="M318.593,323.625l-.477,1.536-3.26,10.5-9.618-.143-3.092-10.36-.477-1.536Z" transform="translate(-295.102 -323.625)" fill="#e0e0e0"/>
                <path id="Path_16014" data-name="Path 16014" d="M315.881,323.625l-.477,1.536-3.26,10.5-19.754-.294,4.208-11.745Z" transform="translate(-292.39 -323.625)" fill="#fafafa"/>
                <path id="Path_16015" data-name="Path 16015" d="M317.394,323.625l-.477,1.536h-19.35l.545-1.536Z" transform="translate(-293.903 -323.625)" fill="#ebebeb"/>
              </g>
              <g id="Group_4211" data-name="Group 4211" transform="translate(2.3 2.252)">
                <g id="Group_4207" data-name="Group 4207">
                  <path id="Path_16016" data-name="Path 16016" d="M299.978,331.08H295.64l1.513-4.273,4.149.047Zm-3.837-.354h3.578l1.1-3.524-3.419-.038Z" transform="translate(-295.64 -326.807)" fill="#ebebeb"/>
                </g>
                <g id="Group_4208" data-name="Group 4208" transform="translate(4.853)">
                  <path id="Path_16017" data-name="Path 16017" d="M306.834,331.08H302.5l1.513-4.273,4.148.047ZM303,330.726h3.577l1.1-3.524-3.42-.038Z" transform="translate(-302.497 -326.807)" fill="#ebebeb"/>
                </g>
                <g id="Group_4209" data-name="Group 4209" transform="translate(9.705)">
                  <path id="Path_16018" data-name="Path 16018" d="M313.691,331.08h-4.338l1.514-4.273,4.148.047Zm-3.837-.354h3.577l1.1-3.524-3.42-.038Z" transform="translate(-309.353 -326.807)" fill="#ebebeb"/>
                </g>
                <g id="Group_4210" data-name="Group 4210" transform="translate(14.558)">
                  <path id="Path_16019" data-name="Path 16019" d="M320.548,331.08H316.21l1.514-4.273,4.148.047Zm-3.837-.354h3.576l1.1-3.524-3.419-.038Z" transform="translate(-316.21 -326.807)" fill="#ebebeb"/>
                </g>
              </g>
              <g id="Group_4216" data-name="Group 4216" transform="translate(0.786 6.785)">
                <g id="Group_4212" data-name="Group 4212">
                  <path id="Path_16020" data-name="Path 16020" d="M297.839,337.485H293.5l1.514-4.273,4.148.047ZM294,337.131h3.577l1.1-3.524-3.42-.038Z" transform="translate(-293.501 -333.212)" fill="#ebebeb"/>
                </g>
                <g id="Group_4213" data-name="Group 4213" transform="translate(4.853)">
                  <path id="Path_16021" data-name="Path 16021" d="M304.7,337.485h-4.338l1.513-4.273,4.149.047Zm-3.837-.354h3.576l1.1-3.524-3.419-.038Z" transform="translate(-300.358 -333.212)" fill="#ebebeb"/>
                </g>
                <g id="Group_4214" data-name="Group 4214" transform="translate(9.705)">
                  <path id="Path_16022" data-name="Path 16022" d="M311.552,337.485h-4.338l1.514-4.273,4.149.047Zm-3.837-.354h3.577l1.1-3.524-3.42-.038Z" transform="translate(-307.214 -333.212)" fill="#ebebeb"/>
                </g>
                <g id="Group_4215" data-name="Group 4215" transform="translate(14.559)">
                  <path id="Path_16023" data-name="Path 16023" d="M318.409,337.485h-4.337l1.513-4.273,4.148.047Zm-3.837-.354h3.577l1.1-3.524-3.419-.038Z" transform="translate(-314.072 -333.212)" fill="#ebebeb"/>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_4244" data-name="Group 4244" transform="translate(104.532)">
            <g id="Group_4219" data-name="Group 4219" transform="translate(0 16.751)">
              <path id="Path_16024" data-name="Path 16024" d="M413.211,289.28a8.617,8.617,0,0,0,.939,4.6,2.435,2.435,0,0,0,2.064,1.089h3.229a2.434,2.434,0,0,0,2.064-1.089,8.615,8.615,0,0,0,.94-4.6c0-3.234-1.41-5.824-1.871-7.892a11.675,11.675,0,0,1,.386-3.026H414.7a11.675,11.675,0,0,1,.386,3.026C414.621,283.456,413.211,286.046,413.211,289.28Z" transform="translate(-413.211 -278.362)" fill="#f0f0f0"/>
            </g>
            <g id="Group_4243" data-name="Group 4243" transform="translate(0.176)">
              <g id="Group_4233" data-name="Group 4233" transform="translate(4.671)">
                <g id="Group_4221" data-name="Group 4221" transform="translate(0 1.135)">
                  <g id="Group_4220" data-name="Group 4220">
                    <path id="Path_16025" data-name="Path 16025" d="M421.922,256.3a3.34,3.34,0,0,0-1.672,1.084c-.616.82.447,2.75.447,2.75a2.6,2.6,0,0,0,2.253.719l1.249-.305s-1.321-3.783-2.268-4Z" transform="translate(-420.059 -256.297)" fill="#ebebeb"/>
                  </g>
                </g>
                <g id="Group_4227" data-name="Group 4227" transform="translate(1.456)">
                  <g id="Group_4222" data-name="Group 4222">
                    <path id="Path_16026" data-name="Path 16026" d="M427.451,256.733c-.166,1.644-1.554,3.5-3.023,3.348s-2.46-2.239-2.294-3.883,1.362-1.582,2.83-1.434S427.617,255.088,427.451,256.733Z" transform="translate(-422.115 -254.694)" fill="#ebebeb"/>
                  </g>
                  <g id="Group_4223" data-name="Group 4223" transform="translate(1.106 0.763)">
                    <path id="Path_16027" data-name="Path 16027" d="M423.766,255.771a4.189,4.189,0,0,0,3.309,1S423.035,258.49,423.766,255.771Z" transform="translate(-423.678 -255.771)" fill="#ebebeb"/>
                  </g>
                  <g id="Group_4224" data-name="Group 4224" transform="translate(2.228 0.553)">
                    <path id="Path_16028" data-name="Path 16028" d="M425.264,255.547s1.315.019,1.234.554c0,0,1.091.144,1.028-.336S425.264,255.547,425.264,255.547Z" transform="translate(-425.264 -255.475)" fill="#ebebeb"/>
                  </g>
                  <g id="Group_4225" data-name="Group 4225" transform="translate(1.106 0.763)">
                    <path id="Path_16029" data-name="Path 16029" d="M423.766,255.771a4.189,4.189,0,0,0,3.309,1S423.035,258.49,423.766,255.771Z" transform="translate(-423.678 -255.771)" fill="#e0e0e0"/>
                  </g>
                  <g id="Group_4226" data-name="Group 4226" transform="translate(2.228 0.553)">
                    <path id="Path_16030" data-name="Path 16030" d="M425.264,255.547s1.315.019,1.234.554c0,0,1.091.144,1.028-.336S425.264,255.547,425.264,255.547Z" transform="translate(-425.264 -255.475)" fill="#e0e0e0"/>
                  </g>
                </g>
                <g id="Group_4228" data-name="Group 4228" transform="translate(1.246 2.66)">
                  <path id="Path_16031" data-name="Path 16031" d="M421.82,261.232a2.406,2.406,0,0,0,2.072,1.453,3.217,3.217,0,0,0,2.412-.957,4.922,4.922,0,0,0,1.263-2.318c.057-.224.182-.786-.1-.929-.258-.132-.5.218-.66.372a9.07,9.07,0,0,1-1.483,1.192,8.112,8.112,0,0,1-1.683.8C423.477,260.9,421.877,261.379,421.82,261.232Z" transform="translate(-421.82 -258.452)" fill="#ebebeb"/>
                </g>
                <g id="Group_4229" data-name="Group 4229" transform="translate(5.082 1.936)">
                  <path id="Path_16032" data-name="Path 16032" d="M430.213,258.874a2.132,2.132,0,0,1-.636,1.974,3.717,3.717,0,0,1-1.243.677c-.31.115-.7.206-.938-.022-.258-.246-.149-.672-.017-1q.306-.764.653-1.512a5.4,5.4,0,0,1,.712-1.43c.3-.3.6-.032.908.285A2.107,2.107,0,0,1,430.213,258.874Z" transform="translate(-427.24 -257.429)" fill="#ebebeb"/>
                </g>
                <g id="Group_4230" data-name="Group 4230" transform="translate(0.654 2.029)">
                  <path id="Path_16033" data-name="Path 16033" d="M422.114,260.145A1.511,1.511,0,0,1,421,258.979c-.077-.413.11-1.389.632-1.418A7,7,0,0,0,422.114,260.145Z" transform="translate(-420.983 -257.561)" fill="#e0e0e0"/>
                </g>
                <g id="Group_4231" data-name="Group 4231" transform="translate(5.77 2.66)">
                  <path id="Path_16034" data-name="Path 16034" d="M428.212,260.694a1.713,1.713,0,0,0,1.335-.892c.143-.272.311-1.773-.246-1.234-.182.176-.278.83-.4,1.079A5.353,5.353,0,0,1,428.212,260.694Z" transform="translate(-428.212 -258.451)" fill="#e0e0e0"/>
                </g>
                <g id="Group_4232" data-name="Group 4232" transform="translate(1.967 5.382)">
                  <path id="Path_16035" data-name="Path 16035" d="M424.452,262.35a1.241,1.241,0,0,0,1.476.319c-.11.275-1.056.344-1.351.374-.768.076-1.088-.316-1.739-.745C422.838,262.3,423.924,262.679,424.452,262.35Z" transform="translate(-422.838 -262.298)" fill="#e0e0e0"/>
                </g>
              </g>
              <g id="Group_4235" data-name="Group 4235" transform="translate(3.576 6.817)">
                <g id="Group_4234" data-name="Group 4234">
                  <path id="Path_16036" data-name="Path 16036" d="M418.512,289.089l1.809-18.279,2.4-6.485.415.153-2.386,6.456-1.891,18.653Z" transform="translate(-418.512 -264.325)" fill="#e6e6e6"/>
                </g>
              </g>
              <g id="Group_4242" data-name="Group 4242" transform="translate(0 5.404)">
                <g id="Group_4236" data-name="Group 4236" transform="translate(3.183 2.21)">
                  <path id="Path_16037" data-name="Path 16037" d="M420.57,271.1l-.395.2a12.912,12.912,0,0,1-.693-1.912,15.756,15.756,0,0,0-1.525-3.706l.377-.232a16.081,16.081,0,0,1,1.571,3.808A12.657,12.657,0,0,0,420.57,271.1Z" transform="translate(-417.957 -265.451)" fill="#e6e6e6"/>
                </g>
                <g id="Group_4237" data-name="Group 4237" transform="translate(4.671 2.379)">
                  <path id="Path_16038" data-name="Path 16038" d="M422.159,268.775a2.592,2.592,0,0,0,1.646-.756,5.167,5.167,0,0,0,1.011-1.825c-.8.084-1.551-.44-2.356-.5a2.108,2.108,0,0,0-1.721.713c-.546.631-.865,1.4-.563,1.8A2.736,2.736,0,0,0,422.159,268.775Z" transform="translate(-420.059 -265.691)" fill="#f0f0f0"/>
                </g>
                <g id="Group_4239" data-name="Group 4239" transform="translate(0 2.944)">
                  <g id="Group_4238" data-name="Group 4238">
                    <path id="Path_16039" data-name="Path 16039" d="M413.76,267.03a3.075,3.075,0,0,1,2.585-.415,2.312,2.312,0,0,1,1.411,1.572,1.451,1.451,0,0,1-1.384.544,4.27,4.27,0,0,1-1.457-.55,4.283,4.283,0,0,0-1.457-.549Z" transform="translate(-413.459 -266.488)" fill="#f0f0f0"/>
                  </g>
                </g>
                <g id="Group_4241" data-name="Group 4241" transform="translate(0.891)">
                  <g id="Group_4240" data-name="Group 4240">
                    <path id="Path_16040" data-name="Path 16040" d="M418.117,266.161c-1.049.117-1.989.326-2.943-.867-.639-.8-.434-2.009-.387-2.965a21.156,21.156,0,0,1,3.432,1.45,1.677,1.677,0,0,1,.923,1.12.964.964,0,0,1-.762,1.087C418.362,265.96,418.117,266.161,418.117,266.161Z" transform="translate(-414.718 -262.329)" fill="#f0f0f0"/>
                  </g>
                </g>
              </g>
            </g>
            <path id="Path_16041" data-name="Path 16041" d="M414.982,286.3a8.716,8.716,0,0,0-.684,3.749c.026.241.1.515.275.558s.321-.159.379-.377a2.662,2.662,0,0,0,.042-.681c0-1.96,1.062-3.612,1.265-5.524.057-.544-.083-2.26-.4-1C415.573,284.147,415.371,285.2,414.982,286.3Z" transform="translate(-413.521 -262.841)" fill="#fafafa"/>
          </g>
        </g>
      </g>
    </svg>

      
      
  
    )
  }