
export const Settings = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="22" height="22" viewBox="0 0 22 22">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_4286" data-name="Rectangle 4286" width="22" height="22" transform="translate(0 0)" fill={primaryFontColor}/>
    </clipPath>
  </defs>
  <g id="Group_38704" data-name="Group 38704" transform="translate(0 -0.002)">
    <g id="Group_11665" data-name="Group 11665" transform="translate(0 0.002)" clip-path="url(#clip-path)">
      <path id="Path_16566" data-name="Path 16566" d="M15.4,17.086c0-.642,0-1.284,0-1.926A.224.224,0,0,1,15.466,15a5.921,5.921,0,0,0,1.485-3.031,5.827,5.827,0,0,0-.559-3.713,5.917,5.917,0,0,0-3.648-3.06,5.8,5.8,0,0,0-2.474-.21A5.954,5.954,0,0,0,6.808,6.623a6,6,0,0,0-1.079,7.259,5.975,5.975,0,0,0,.811,1.125.223.223,0,0,1,.054.136c0,1.294,0,2.587,0,3.881a.148.148,0,0,1-.078.14c-.4.265-.806.534-1.209.8a.666.666,0,0,1-.913-.089l-2.36-2.35a.644.644,0,0,1-.089-.892q.412-.619.827-1.235a.138.138,0,0,0,.013-.165,8.9,8.9,0,0,1-.633-1.523.127.127,0,0,0-.113-.1c-.494-.1-.987-.2-1.481-.3A.647.647,0,0,1,0,12.653Q0,10.962,0,9.27A.642.642,0,0,1,.558,8.6c.493-.1.987-.2,1.481-.3A.139.139,0,0,0,2.163,8.2a8.713,8.713,0,0,1,.626-1.5.145.145,0,0,0-.008-.174c-.276-.4-.546-.811-.818-1.218a.654.654,0,0,1,.089-.923l2.339-2.33a.663.663,0,0,1,.934-.091q.613.405,1.224.813a.124.124,0,0,0,.148.012,9.038,9.038,0,0,1,1.529-.632.143.143,0,0,0,.111-.126q.145-.738.3-1.475A.647.647,0,0,1,9.308,0Q11,0,12.694,0a.645.645,0,0,1,.672.549c.1.485.2.97.292,1.456a.175.175,0,0,0,.141.155,8.893,8.893,0,0,1,1.5.622.125.125,0,0,0,.149-.008c.411-.278.825-.552,1.238-.827a.658.658,0,0,1,.911.088l2.353,2.344a.654.654,0,0,1,.092.908q-.413.624-.827,1.247a.109.109,0,0,0-.008.13A8.953,8.953,0,0,1,19.846,8.2a.142.142,0,0,0,.127.11c.475.091.949.187,1.424.279a.674.674,0,0,1,.6.723q0,1.642,0,3.285a.667.667,0,0,1-.594.726q-.726.147-1.452.291a.127.127,0,0,0-.114.1,8.706,8.706,0,0,1-.629,1.514.153.153,0,0,0,.015.183c.275.4.546.811.819,1.217a.65.65,0,0,1-.086.9q-1.189,1.187-2.38,2.371a.645.645,0,0,1-.857.088c-.421-.276-.838-.557-1.258-.833a.13.13,0,0,1-.059-.131q0-.968,0-1.936" transform="translate(0 -0.002)" fill={primaryFontColor}/>
      <path id="Path_16567" data-name="Path 16567" d="M354.884,379.192h1.723c.036-.244.071-.485.106-.726.094-.651.185-1.3.281-1.954a.532.532,0,0,1,.839-.389,4.012,4.012,0,0,1,1.849,2.4,3.943,3.943,0,0,1-.491,3.316,3.9,3.9,0,0,1-1.629,1.441.152.152,0,0,0-.1.161q0,3.265,0,6.531a.642.642,0,0,1-.358.632.664.664,0,0,1-.282.067c-.723.005-1.446,0-2.169,0a.641.641,0,0,1-.64-.655c0-.039,0-.078,0-.117q0-3.221,0-6.443a.173.173,0,0,0-.11-.185,4.062,4.062,0,0,1-.205-7.176.518.518,0,0,1,.772.348c.074.4.122.8.18,1.2q.11.754.218,1.509a.352.352,0,0,0,.012.041" transform="translate(-344.742 -368.673)" fill={primaryFontColor}/>
    </g>
  </g>
</svg>




  )
}

