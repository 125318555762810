
export const Userinactive = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.627" height="22.993" viewBox="0 0 18.627 22.993">
      <g id="Group_41221" data-name="Group 41221" transform="translate(0.482 0.5)">
        <g id="customer">
          <circle id="Ellipse_1" data-name="Ellipse 1" cx="5.052" cy="5.052" r="5.052" transform="translate(3.744)" fill={primaryFontColor} stroke="#fff" stroke-width="1" />
          <path id="Path_2" data-name="Path 2" d="M130.582,239.911c-2.848-.19-5.323,0-7.458,1.41-3.573,2.287-2.885,5.366-2.885,9.133a2.094,2.094,0,0,0,2.281,1.823c13.749,0,14.3.354,15.111-1.088.267-.488.194-.333.194-5C137.824,242.482,133.807,239.911,130.582,239.911Z" transform="translate(-120.185 -230.316)" fill={primaryFontColor} stroke="#fff" stroke-width="1" />
        </g>
      </g>
    </svg>



  )
}

