
export const Jobboardportal_black = ({primaryFontColor = "#ffd800"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.899" height="24.901" viewBox="0 0 24.899 24.901"><g transform="translate(-2 -1.994)"><path d="M25.119,2.8a2.681,2.681,0,0,0-3.61-.2L13.469,8.947a.38.38,0,0,0-.1.1,3.859,3.859,0,0,0-1.12,2.539c-.124,0-.236-.025-.361-.025a4.661,4.661,0,0,0-3.373,1.431c-1.444,1.481-3.809,6.323-1.643,8.551a1.245,1.245,0,0,0,1.431.249,6.108,6.108,0,0,1,2.975-.548,4.808,4.808,0,0,0,5.29-5.6,3.783,3.783,0,0,0,2.3-1.1.747.747,0,0,0,.1-.1L25.306,6.42A2.684,2.684,0,0,0,25.119,2.8ZM13.481,18.108a2.562,2.562,0,0,1-2.2.647,9.516,9.516,0,0,0-2.8.311,8.068,8.068,0,0,1,1.83-4.344,2.184,2.184,0,0,1,1.581-.672,2.213,2.213,0,0,1,1.593.672,2.468,2.468,0,0,1,0,3.386Z" transform="translate(0.978)" fill={primaryFontColor}/><path d="M23.16,12.713a1.245,1.245,0,0,0-1.245,1.245v6.224a3.734,3.734,0,0,1-3.734,3.734H8.224a3.734,3.734,0,0,1-3.734-3.734V10.224A3.734,3.734,0,0,1,8.224,6.489h6.224a1.245,1.245,0,1,0,0-2.489H8.224A6.231,6.231,0,0,0,2,10.224v9.958A6.231,6.231,0,0,0,8.224,26.4h9.958A6.231,6.231,0,0,0,24.4,20.181V13.958A1.245,1.245,0,0,0,23.16,12.713Z" transform="translate(0 0.491)" fill={primaryFontColor}/></g></svg>


  )
}



