import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateSnackbar } from "../../_redux/SnackbarSlice";
import * as _ser from '../../_redux/_services/ClientsSlice';
import * as _applicantser from '../../_redux/_services/ApplicantsSlice';
import * as _postingser from "../../_redux/_services/JobPostingSlice";
import * as _props from '../../_redux/_services/JobPostingSlice';
import * as _userser from "../../_redux/_services/UserManagerSlice";
import { decrypt } from "../../_utilities/_encryptDecryptHelper";
import { checkRingCentralApp, formatPhoneNumber, isLoggedIntoRingCentral, redirectToLogin, sendSMS } from '../../_utilities/ringCentralUtils';
import { updateLoader } from "../../_redux/LoaderSlice";
import './SendSms.css';


const initialSnackbar = { type: "", message: "" };

const SendSms = ({ data, isAssociatedProfile, applicantDetails, jobCode, tempApplicant, applicantCodes, onReceivechildProps }) => {
  const [sms, setSms] = useState([]);
  const [smsTemplate, setSmsTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [profileDetails, setProfileDetails] = useState([]);  // { jobCode: "", candidateName: "", contactNumber: "" }
  const [jobCustomFeilds, setJobCustomFeilds] = useState("");
  const [customFields, setCustomFields] = useState("");
  const [customJobKeys, setCustomJobKeys] = useState("");
  const [getClients, setGetClients] = useState("");
  const [customApplicantKeys, setCustomApplicantKeys] = useState("");
  const [isGenerate, setIsGenerate] = useState(true);
  const [jobpostings, setJobpostings] = useState(null);
  const [selectJobCode, setSelectJobCode] = useState({ id: "", jobCode: "" });
  const [jobDetails, setjobDetails] = useState(null);
  const [templateCustomKeys, setTemplateCustomKeys] = useState("");
  const [templateCustomValues, setTemplateCustomValues] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const isLoggedIn = isLoggedIntoRingCentral();

  const dispatch = useDispatch();
  const initalState = { data: null, message: '', isloader: true }

  const listofTemplates = useSelector(state => state.GetSmsTemplates.data);
  const selectedTemplateResponse = useSelector(state => state.GetSmsTemplatebyId.data);
  const responseData = useSelector((state) => state.Listjobpostings.data);
  const customFieldsResponse = useSelector((state) => state.CustomFields.data);
  const getDetails = useSelector((state) => state.Jobpostingsbyid.data);
  const ClientsData = useSelector(state => state.GetClients.data);

  const ApplicantData = useSelector(state => state.ApplicantsById.data);
  //////////////////////////////// TO DO///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (customFieldsResponse && customFieldsResponse.length > 0) {
      const customfielddata = JSON.parse(JSON.stringify(customFieldsResponse));
      let posingsObj = [];
      let applicantCustomObj = [];
      customfielddata.forEach((list, i) => {
        if (parseInt(list.moduleId) === 1) {
          if (list.fieldType === "custom") {
            let newObj = { id: i, name: list.fieldName }
            // console.log(list.fieldName);
            posingsObj.push(newObj);
          }
        } else {
          if (list.fieldType === "custom") {
            let newObj = { id: i, name: list.fieldName }
            applicantCustomObj.push(newObj)
          }
        }
      });
      setCustomJobKeys(posingsObj);
      setCustomApplicantKeys(applicantCustomObj);
    }
  }, [customFieldsResponse, listofTemplates])

  // console.log(customJobKeys);
  // console.log(customApplicantKeys);

  //////////////////////////////// TO DO///////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (getDetails !== null && getDetails !== undefined && getDetails !== "") {
      let postingData = JSON.parse(JSON.stringify(getDetails));
      postingData.primarySkills = postingData.primarySkills.replace(/\n/g, ',');
      postingData.secondarySkills = postingData.secondarySkills.replace(/\n/g, ',');
      postingData.jobDesc = postingData.jobDesc.replace(/\r\n\r\n,/g, ',');
      postingData.jobDescForSM = postingData.jobDescForSM.replace(/\n,/g, ',');
      setjobDetails(postingData);
      dispatch(_props.jobpostingsbyidStartReducer({}));
      let customFieldsList = JSON.parse(getDetails.customFields);
      if (customFieldsList && customFieldsList.length > 0) {
        let customFieldsArray = [];
        customFieldsList.forEach((list, index) => {
          customFieldsArray.push({ id: index, name: list.key, value: list.value });
        });
        setJobCustomFeilds(customFieldsArray);
      }
      if (postingData.clientId) {
        dispatch(_ser.getClientsAsync(postingData.clientId));
      }
    }

  }, [getDetails]);

  useEffect(() => {
    if (isAssociatedProfile === true) {
      const url = window.location.pathname;
      const path = url.substring(url.lastIndexOf("/") + 1);

      GetJobData(path)
    }
  }, [])


  const GetJobData = (id) => {
    dispatch(_props.jobpostingsbyidAsync(id));

  }

  const handleChange = (value) => {
    setTemplateCustomKeys([])
    setSmsTemplate(value);
    dispatch(_userser.getSmsTemplatebyIdAsync(JSON.parse(decrypt('GetUserId')), value.id));
  };

  // useEffect(() => {
  //   if (data.length > 0) {
  //     let array = [];
  //     data.forEach(list => {
  //       array.push(list.applicantCode)
  //     })
  //     applicantCodes = array;
  //     console.log(applicantCodes);
  //     dispatch(_applicantser.applicantsByIdAsync(data[0].id));
  //   } else {
  //     if (data) {
  //       dispatch(_applicantser.applicantsByIdAsync(data.id));
  //     }
  //   }
  // }, [])

  const [applicants, setApplicants] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      data.forEach(list => {
          dispatch(_applicantser.applicantsByIdAsync(list.id));
      });
      } else {
      if (data) {
        dispatch(_applicantser.applicantsByIdAsync(data.id));
      }
    }
  }, [])

  useEffect(() => {
    if (ApplicantData) {
      setApplicants(prevApplicants => {
        const newApplicants = [...prevApplicants, ApplicantData[0]];
        console.log(newApplicants);
        return newApplicants;
      });
      dispatch(_applicantser.applicantsByIdStartReducer(initalState));
    }
  }, [ApplicantData])

  useEffect(()=>{
    if (applicants) {
      let allApplicantsCustom = [];
      let formattedApplicants = [];
      let applicantObj = {}, customFieldsList = [];
      
    applicants.forEach(list => {  
      let details = list;
      // let splitCode = jobCode.split('(');
      // let splitJobcode = splitCode[1].split(")");
      applicantObj = {
        jobCode: selectJobCode?.jobCode,   // splitJobcode[0]
        email: details.emailId ? details.emailId : "",
        applicantCode: details.applicantCode ? details.applicantCode : "",
        candidateName: details.fullName ? details.fullName : details.firstName + "" + details.lastName,
        contactNumber: details.contactNumber ? details.contactNumber : "",
        // candidateStatus: details.candidateStatus ? details.candidateStatus : "",
        source: details.resumesource ? details.resumesource : "",
        certification: details.certification ? JSON.parse(details.certification).join(", ") : '',
        educationDetails: details.educationDetails ? transformDataObjToArray(JSON.parse(details.educationDetails), "education") : '',
        skillDetails: details.skillDetails ? JSON.parse(details.skillDetails).map(x => x.name).join(", ") : '',
        workExp: details.workExp ? transformDataObjToArray(JSON.parse(details.workExp), "workExp") : '',
      }
      if (details) {
        customFieldsList = JSON.parse(details.customFields);
      }
      
      formattedApplicants.push(applicantObj);

      if (customFieldsList) {
        let customFieldsArray = [];
        customFieldsList.forEach((list, index) => {
          customFieldsArray.push({ id: index, name: list.key, value: list.value });
        });
        allApplicantsCustom.push( customFieldsArray);

      }
    })
    setProfileDetails(formattedApplicants);
    console.log(formattedApplicants);

    setCustomFields(allApplicantsCustom);
    console.log(allApplicantsCustom);
    }
  },[applicants, selectJobCode])

  // useEffect(() => {
  //   if (ApplicantData) {
  //     console.log(ApplicantData);
  //     let details = ApplicantData[0];
  //     let applicantObj = {}, customFieldsList = [];
  //     // let splitCode = jobCode.split('(');
  //     // let splitJobcode = splitCode[1].split(")");
  //     applicantObj = {
  //       jobCode: selectJobCode?.jobCode,   // splitJobcode[0]
  //       email: details.emailId ? details.emailId : "",
  //       applicantCode: details.applicantCode ? details.applicantCode : "",
  //       candidateName: details.fullName ? details.fullName : details.firstName + "" + details.lastName,
  //       contactNumber: details.contactNumber ? details.contactNumber : "",
  //       // candidateStatus: details.candidateStatus ? details.candidateStatus : "",
  //       source: details.resumesource ? details.resumesource : "",
  //       certification: details.certification ? JSON.parse(details.certification).join(", ") : '',
  //       educationDetails: details.educationDetails ? transformDataObjToArray(JSON.parse(details.educationDetails), "education") : '',
  //       skillDetails: details.skillDetails ? JSON.parse(details.skillDetails).map(x => x.name).join(", ") : '',
  //       workExp: details.workExp ? transformDataObjToArray(JSON.parse(details.workExp), "workExp") : '',
  //     }
  //     if (details) {
  //       customFieldsList = JSON.parse(details.customFields);
  //     }
  //     setProfileDetails(applicantObj)

  //     if (customFieldsList) {
  //       let customFieldsArray = [];
  //       customFieldsList.forEach((list, index) => {
  //         customFieldsArray.push({ id: index, name: list.key, value: list.value });
  //       });
  //       setCustomFields(customFieldsArray);
  //     }
  //   }
  //   dispatch(_applicantser.applicantsByIdStartReducer(initalState))

  // }, [ApplicantData, selectJobCode])


  useEffect(() => {
    if (ClientsData !== null && ClientsData.length > 0) {
      let ClientData = JSON.parse(JSON.stringify(ClientsData))
      setGetClients(ClientData[0]);
      dispatch(_ser.getClientsStartReducer(initalState));
    }
    console.log(getClients);
  }, [ClientsData])


  const transformDataObjToArray = (data, type) => {
    if (data.length > 0) {
      if (type === "education") {
        let educationArray = [];
        data.forEach(list => {
          let Degree = list.degree ? list.degree + "," : "";
          let Organization = list.organization ? list.organization + "," : "";
          let Duration = (list.startDate && list.endDate) ? list.startDate + " - " + list.endDate : list.startDate ? list.startDate : list.endDate ? list.endDate : '';
          let value = Degree + Organization + Duration;
          educationArray.push(`<p>${value}</p>`);
        })
        return educationArray.join(" ");
      }
      else if (type === "workExp") {
        let workExpArray = [];
        data.forEach(list => {
          let JobTitle = list.jobTitle ? list.jobTitle + "," : "";
          let Organization = list.organization ? list.organization + "," : "";
          let Duration = (list.startDate && list.endDate) ? list.startDate + " - " + list.endDate : list.startDate ? list.startDate : list.endDate ? list.endDate : '';
          let value = JobTitle + Organization + Duration;
          workExpArray.push(`<p>${value}</p>`);
        })
        return workExpArray.join(" ");
      }
      else {
        return ""
      }
    } else {
      return ""
    }
  }

  // useEffect(() => {
  //   if (isAssociatedProfile && jobCode) {
  //     let splitCode = jobCode.split('(');
  //     let splitJobcode = splitCode[1].split(")");
  //     setProfileDetails({ jobCode: splitJobcode[0], candidateName: profileDetails[0].candidateName, contactNumber: profileDetails[0].contactNumber });
  //   }
  //   let userId = JSON.parse(decrypt('GetUserId'))
  //   dispatch(_userser.customFieldsAsync(userId));

  // }, [isAssociatedProfile, jobCode])

  useEffect(() => {
    dispatch(_userser.getSmsTemplatesAsync(JSON.parse(decrypt('GetUserId'))));
    let obj = { current: 1, limit: 10000, offset: 1 };
    dispatch(_postingser.listjobpostingsAsync(obj, "list"));
  }, [])

  useEffect(() => {
    if (listofTemplates && listofTemplates.length > 0) {
      let templatesData = JSON.parse(JSON.stringify(listofTemplates));
      let templatesArray = [];
      templatesData.forEach(list => {
        templatesArray.push({ id: list.id, name: list.name, value: '' });
      })
      setSms(templatesArray);

    } else {
      setSms(null)
    }
  }, [listofTemplates])

  useEffect(() => {
    if (selectedTemplateResponse && selectedTemplateResponse.length > 0) {
      let selectedTemplateData = JSON.parse(JSON.stringify(selectedTemplateResponse));
      setSelectedTemplate(selectedTemplateData);
      dispatch(_userser.getSmsTemplatebyIdStartReducer({}));
      let adminCustomList = JSON.parse(selectedTemplateData[0].customValues);
      if (adminCustomList && adminCustomList.length > 0) {
        let array = [];
        adminCustomList.map((list, i) => {
          let selectedTempBody = selectedTemplateData[0].smsText;
          let isAdminList = selectedTempBody.includes(list);
          if (isAdminList) {
            array.push({ id: i + 1, name: list, value: "" })
          }
        })
        setTemplateCustomKeys(array)
        console.log(array);
      }
    }
  }, [selectedTemplateResponse])


  useEffect(() => {
    if (responseData && responseData?.rows?.length > 0) {
      setJobpostings([]);
      let myData = JSON.parse(JSON.stringify(responseData));
      setJobpostings(myData.rows);
      dispatch(_postingser.listjobpostingsStartReducer({}));
    }
    else if (responseData || responseData?.rows) {
      setJobpostings(null);
    }
  }, [responseData])

  const onActionTemplate = (type) => {
    if (type === "generate") {
      if (profileDetails[0].jobCode && profileDetails[0].jobCode !== "" && profileDetails[0].contactNumber !== "" && profileDetails[0].candidateName !== "") {
        if (templateCustomKeys.length === 0) {
          setIsGenerate(false);
          updateSmsbody();
        } else if (templateCustomKeys.length > 0) {
          if (templateCustomValues.length > 0) {
            let customValuesValidate = templateCustomValues.filter(list => list.value !== "")
            if (templateCustomKeys.length == customValuesValidate.length) {
              setIsGenerate(false);
              updateSmsbody();
            } else {
              dispatch(updateSnackbar({ type: "error", message: "All custom fields are required" }));
            }
          } else {
            dispatch(updateSnackbar({ type: "error", message: "All custom fields are required" }));
          }
        }
      } else if (profileDetails[0].contactNumber === "") {
        dispatch(updateSnackbar({ type: "error", message: "Please enter Contact" }));
      } else if (profileDetails[0].candidateName === "") {
        dispatch(updateSnackbar({ type: "error", message: "Please enter Candidate name" }));
      } else {
        dispatch(updateSnackbar({ type: "error", message: "Select JobCode" }));
      }
    } else if (type === "send") {
      
      if (profileDetails && profileDetails.length > 1) {
          profileDetails.forEach((profile, index) => {
            handleSmsInitiate(profile.contactNumber, selectedTemplate[index][0].smsText);
          })

      } else {
        let message = selectedTemplate[0][0].smsText;
        let toNumber = profileDetails[0].contactNumber;
        handleSmsInitiate(toNumber, message);
      }
    }
  }
  const onCustomfieldHandler = (value, key) => {
    let modifyObject = [...templateCustomKeys];
    modifyObject.find(x => x.name === key.name).value = value;
    setTemplateCustomValues(modifyObject);
    console.log(modifyObject);
  }

  const updateSmsbody = () => {
    let bodyArray = [];
    profileDetails.forEach((profileDetails, index) => {
    
    let modifyTemple = JSON.parse(JSON.stringify(selectedTemplate));
    // let subject = modifyTemple[0].subject;
    let templeBody = modifyTemple[0].smsText;
    var jobCode = /@{JobOpenings.jobCode}@/gi;
    var jobTitle = /@{JobOpenings.jobTitle}@/gi;
    var jobStatus = /@{JobOpenings.jobStatus}@/gi;
    var jobType = /@{JobOpenings.jobType}@/gi;
    var location = /@{JobOpenings.location}@/gi;
    var payBillRate = /@{JobOpenings.payBillRate}@/gi;
    var primarySkills = /@{JobOpenings.primarySkills}@/gi;
    var workModel = /@{JobOpenings.workModel}@/gi;
    var jobDescForSM = /@{JobOpenings.jobDescForSM}@/gi;
    var jobDesc = /@{JobOpenings.jobDesc}@/gi;
    var secondarySkills = /@{JobOpenings.secondarySkills}@/gi;
    var priority = /@{JobOpenings.priority}@/gi;
    var applicant = /@{Applicants.fullName}@/gi;
    var applicantCode = /@{Applicants.applicantCode}@/gi;
    var contactNumber = /@{Applicants.contactNumber}@/gi;
    var educationDetails = /@{Applicants.educationDetails}@/gi;
    var emailId = /@{Applicants.emailId}@/gi;
    var skillDetails = /@{Applicants.skillDetails}@/gi;
    var certification = /@{Applicants.certification}@/gi;
    var workExp = /@{Applicants.workExp}@/gi;
    var candidateStatus = /@{Applicants.candidateStatus}@/gi;
    var source = /@{Applicants.source}@/gi;
    var clientName = /@{Clients.clientName}@/gi;
    var region = /@{Clients.region}@/gi;
    var status = /@{Clients.status}@/gi;
    var managerEmail = /@{Clients.managerEmail}@/gi;

    let ApplicantKeys = [], JobKeys = [], finalCustomKeys = [],
      appendJobCustomFeilds = [], appendApplicantCustomFeilds = [], appendTemplateCustomValues = [], mappingFields = [];
    if (customJobKeys.length > 0) {
      customJobKeys.map((list, index) => {
        JobKeys.push(`@{JobOpenings.${list.name}}@`);
        appendJobCustomFeilds.push({ id: index, name: list.name, value: '' })
      });
      if (!jobCustomFeilds || jobCustomFeilds.length === 0) {
        mappingFields = jobCustomFeilds ? jobCustomFeilds : appendJobCustomFeilds;
      }
      if (selectedTemplate !== "" && selectedTemplate) {
        JobKeys.forEach((list) => {
          if (templeBody.includes(list)) {

            let filterObj = mappingFields.filter(x => `@{JobOpenings.${x.name}}@` === list);
            if (filterObj.length > 0) {
              templeBody.replace(list, filterObj[0].value);
            }
            else {
              templeBody.replace(new RegExp(list, "g"), "");
            }
          }
        })
      }
    }
    if (customApplicantKeys) {
      customApplicantKeys.map((list, index) => {
        ApplicantKeys.push(`@{Applicants.${list.name}}@`);
        appendApplicantCustomFeilds.push({ id: index, name: list.name, value: '' })
      })
      if (!customFields[index] || customFields[index].length === 0) {
        mappingFields = customFields[index] ? customFields[index] : appendApplicantCustomFeilds;
      }
      if (selectedTemplate !== "" && selectedTemplate) {
        ApplicantKeys.forEach((list) => {
          if (templeBody.includes(list)) {
            let filterObj = mappingFields.filter(x => `@{Applicants.${x.name}}@` === list);
            if (filterObj.length > 0) {
              templeBody.replace(list, filterObj[0].value ? filterObj[0].value : "");
            } else {
              templeBody.replace(new RegExp(list, "g"), "");
            }
          }
        })
      }
    }

    if (templateCustomKeys) {
      templateCustomKeys.map((list, index) => {
        finalCustomKeys.push(`@{Custom.${list.name}}@`);
        appendTemplateCustomValues.push({ id: index, name: list.name, value: '' })
      })
      if (!templateCustomValues || templateCustomValues.length === 0) {
        mappingFields = templateCustomKeys ? templateCustomKeys : appendTemplateCustomValues;
      }
      if (selectedTemplate !== "" && selectedTemplate) {
        finalCustomKeys.forEach((list) => {
          if (templeBody.includes(list)) {
            let filterObj = mappingFields.filter(x => `@{Custom.${x.name}}@` === list);
            if (filterObj.length > 0) {
              templeBody.replace((list, "g"), filterObj[0].value ? filterObj[0].value : "");
            } else {
              templeBody.replace(new RegExp(list, "g"), "");
            }
          }
        })
      }
    }

    templeBody = templeBody.replace(applicant, profileDetails.candidateName).replace(contactNumber, profileDetails.contactNumber).replace(jobCode, jobDetails.jobCode).replace(jobTitle, jobDetails.jobTitle)
      .replace(educationDetails, profileDetails.educationDetails)
      .replace(applicantCode, profileDetails.applicantCode)
      .replace(emailId, profileDetails.email)
      .replace(skillDetails, profileDetails.skillDetails)
      .replace(certification, profileDetails.certification)
      .replace(workExp, profileDetails.workExp)
      .replace(source, profileDetails.source)
      .replace(candidateStatus, profileDetails.candidateStatus)
      .replace(jobStatus, jobDetails.jobStatus).replace(jobType, jobDetails.jobType)
      .replace(location, jobDetails.location).replace(payBillRate, jobDetails.payBillRate)
      .replace(primarySkills, jobDetails.primarySkills).replace(workModel, jobDetails.workModel)
      .replace(jobDescForSM, jobDetails.jobDescForSM).replace(jobDesc, jobDetails.jobDesc)
      .replace(secondarySkills, jobDetails.secondarySkills).replace(priority, jobDetails.priority)
      .replace(clientName, getClients.clientName ? getClients.clientName : "").replace(region, getClients.region ? getClients.region : "")
      .replace(status, getClients.status ? getClients.status === "1" ? "Active" : "Inactive" : "").replace(managerEmail, getClients.managerEmail ? getClients.managerEmail : "");
    if (customFields[index].length > 0) { //customFields
      customFields[index].forEach(list => {
        templeBody = templeBody.replace(`@{Applicants.${list.name}}@`, list.value ? list.value : "");
      })
    }
    if (customApplicantKeys) {
      customApplicantKeys.forEach(list => {
        templeBody = templeBody.replace(`@{Applicants.${list.name}}@`, list.value ? list.value : "");
      })
    }

    if (jobCustomFeilds.length > 0) {   ///jobCustomFeilds
      jobCustomFeilds.forEach(list => {
        templeBody = templeBody.replace(`@{JobOpenings.${list.name}}@`, list.value ? list.value : "");
      })
    }
    if (customJobKeys.length > 0) {
      customJobKeys.forEach(list => {
        templeBody = templeBody.replace(`@{JobOpenings.${list.name}}@`, list.value ? list.value : "");
      })
    }

    if (templateCustomKeys.length > 0) {
      templateCustomKeys.forEach(list => {
        templeBody = templeBody.replace(new RegExp(`@{Custom.${list.name}}@`, "g"), list.value ? list.value : "");
      })
    }
    if (templateCustomValues.length > 0) {
      templateCustomValues.forEach(list => {
        templeBody = templeBody.replace(new RegExp(`@{Custom.${list.name}}@`, "g"), list.value ? list.value : "");
      })
    }

    // subject = subject.replace(jobTitle, jobDetails.jobTitle).replace(location, jobDetails.location);
    modifyTemple[0].smsText = templeBody;
    // modifyTemple[0].subject = subject;
    bodyArray.push(modifyTemple);
  })
  console.log(bodyArray);
  setSelectedTemplate(bodyArray);
  }

  const onChangeHandler = (value, type) => {
    let modifyObject = JSON.parse(JSON.stringify(profileDetails));
    modifyObject.forEach(list => {
      list[type] = value;
    })
    if (!Boolean(isAssociatedProfile)) {
      let myJobData = jobpostings.filter(x => x.jobCode === value);
      if (myJobData.length > 0) {
        GetJobData(myJobData[0].id);
      }
      console.log(value);
    }
    setProfileDetails(modifyObject);
  }

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(updateLoader(false));
      setErrorMessage("Please login to RingCentral to send SMS.");
      return;
    }
  }, [isLoggedIn])


  const handleSmsInitiate = async (toNumber, message) => {
    dispatch(updateLoader(true));

    try {
      setErrorMessage(""); // Clear any existing errors
      const formattedNumber = formatPhoneNumber(toNumber);

      // // Check if the RingCentral app is installed
      // const appExists = await checkRingCentralApp(formattedNumber);
      // console.log("App exists:", appExists);

      // if (appExists) {
      //   dispatch(updateLoader(false));

      //   // If the app exists, show the message after the user interacts with the app
      //   setErrorMessage("We found the RingCentral app. Initiating the SMS through the app.");
      //   return;
      // }

      // // If the app is not found, check if the user is logged in
      // if (!isLoggedIn) {
      //   dispatch(updateLoader(false));
      //   setErrorMessage("Please login to RingCentral to send SMS.");
      //   return;
      // }

      // // Proceed with RingOut if logged in but the app is not found
      // setErrorMessage("We don't see the RingCentral app installed. Sending SMS through RingOut...");

      // Fetch the stored main number and extension
      const companyMainNumber = localStorage.getItem("ringCentralMainNumber");
      const userExtension = localStorage.getItem("ringCentralUserExtension");
      const userDirectNumber = localStorage.getItem("ringCentralUserDirectNumber");

      if (!companyMainNumber || !userExtension) {
        dispatch(updateLoader(false));
        setErrorMessage("Unable to retrieve RingCentral user details. Please try logging in again.");
        return;
      }

      // Function to remove HTML tags
      function removeHTMLTags(str) {
        return str.replace(/<\/?[^>]+(>|$)/g, "")  // Remove HTML tags
                  .replace(/&nbsp;/g, " ");        // Replace &nbsp; with space;
      }

      // Rendering the string without HTML tags
      const result = removeHTMLTags(message);

      // const fromNumber = `${companyMainNumber}*${userExtension}`;
      const smsResponse = await sendSMS(formattedNumber, userDirectNumber, result); // Replace with your verified number
      console.log("RingOut response:", smsResponse);

      dispatch(updateLoader(false));
      if (smsResponse && smsResponse.messageStatus === "Queued") {

        setErrorMessage("We have successfully sent SMS via RingOut.");
        onReceivechildProps(false, "close");
        dispatch(updateSnackbar({ type: "success", message: "We have successfully sent SMS via RingOut." }));
        setProfileDetails([]);
        setApplicants([]);
        setCustomFields("");
      } else {
        throw new Error("Failed to send SMS through RingOut.");
      }
    } catch (error) {
      console.error("SMS initiation failed:", error);
      setErrorMessage("An error occurred while trying to send SMS. Please try again.");
    }

  };

  const handleLogin = () => {
    redirectToLogin();
  };


  return (
    <>
      {/* Error or Login Prompt */}
      {errorMessage && (
        <div>
          <p style={{ color: "red" }} className='text-left '>{errorMessage}</p>
          {!isLoggedIn && (
            <Button variant="contained"
            className="ringcentralBtn"
              onClick={handleLogin}>
              Login to RingCentral
            </Button>
          )}
        </div>
      )}
      {Boolean(isGenerate) && errorMessage !== "Please login to RingCentral to send SMS." ?
        <>{sms && sms.length > 0 ?
          <div id="mailtemplatebody">
            <div style={{ width: "100%" }}>
              <FormControl fullWidth autoComplete="off">
                <InputLabel className="boldSelectlabel">Template</InputLabel>
                <Select
                  value={smsTemplate}
                  name="Template"
                  autoComplete="off"
                  label="Template*&nbsp;&nbsp;&nbsp;"
                  onChange={(e) => handleChange(e.target.value, "Template")}
                  style={{ width: "100%", height: "3.5rem", borderRadius: "4px" }}
                >
                  {sms && sms.map((list) => (
                    <MenuItem className="selectOptions" key={list.id} value={list}>
                      {list.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {smsTemplate !== null && smsTemplate !== undefined ? (
              <>
                <div  style={{ minHeight: "calc(100vh - 400px)", marginTop: "30px" }}>

                {/* <ul className="emailkeys" style={{ maxHeight: "155px", overflow: "auto" }}> */}
                  <table>
                  <tr >               
                  <td width="160px">Job Code</td>
                  <td width="30px" align="center">:</td>
                    <td style={{ width: "400px"}}>
                      {Boolean(isAssociatedProfile) ?
                        <TextField id="outlined-basic" disabled size="small"
                          variant="outlined" name="jobCode" value={profileDetails[0].jobCode}
                        />
                        : <div className="boldlabel jobcode_search" style={{ display: "flex",width: "32%"}}>
                          <Autocomplete
                            options={jobpostings}
                            autoHighlight
                            id="controlled-demo"
                            autoComplete="off"
                            clearOnEscape
                            value={selectJobCode}
                            getOptionLabel={(option) => option?.jobCode ? option?.jobCode : ''}
                            blurOnSelect={true}
                            onChange={(e, value) => {
                              setSelectJobCode(value);
                              onChangeHandler(value?.jobCode, 'jobCode');
                            }}
                            // sx={{ width: 503 }}
                            renderInput={(params) => (
                              <TextField
                                {...params} size="small"
                                className="boldlabel"
                                autoComplete="off"
                                value={profileDetails[0].jobCode}
                                name="jobCode"
                              />
                            )}
                          />
                          <BsSearch style={{  position: "absolute", right: "-60px", top: "11px"}} />
                        </div>}
                        </td>
                    </tr>

                    <tr >
                    <td width="160px">Candidate Name</td>
                    <td width="30px" align="center">:</td>
                    <td width="400px" ><TextField id="outlined-basic" variant="outlined"
                        disabled={Boolean(isAssociatedProfile) ? false : true} size="small"
                        value={profileDetails[0].candidateName} name="candidateName"
                        onChange={(e) => { onChangeHandler(e.target.value, "candidateName") }}
                      /></td>
                    </tr>

                    <tr>
                      <td width="160px" style={{ wordBreak: "break-all" }}>Contact Number </td>
                      <td width="30px" align="center">:</td>
                      <td width="400px"><TextField id="outlined-basic" variant="outlined"
                        disabled={Boolean(isAssociatedProfile) ? false : true} size="small"
                        value={profileDetails[0].contactNumber} name="contactNumber" onChange={(e) => { onChangeHandler(e.target.value, "contactNumber") }}
                      /></td>
                    
                    </tr>
                    </table>
                   
                 
                  {templateCustomKeys && templateCustomKeys.length > 0 && (
                    <p className="customheading">Custom Fields</p>
                  )}
                  <ul className="emailkeys" style={{ maxHeight: "155px", overflow: "auto" }}>
                    {templateCustomKeys && templateCustomKeys.map((list) => (
                      <table>
                      <tr >
                        {/* <td></td> */}
                        <li><span style={{ wordBreak: "break-all", width: "120px" }}>{list.name}</span>
                          <td width="30px" align="center">:</td>
                          <td>
                            <TextField id="outlined-basic" size="small"
                              variant="outlined"
                              name={list.name}
                              value={list.value}
                              onChange={(e) => { onCustomfieldHandler(e.target.value, list) }}
                            />
                          </td>
                        </li>
                      </tr>
                      </table>
                      
                    ))}
                  </ul>
                </div>

                <Button variant="contained" component="label" size="medium"
                  className="generatetemplateSms"
                  onClick={(e) => { onActionTemplate("generate") }}
                >
                  Generate Template
                </Button>
              </>
            ) : ""}
          </div> : <h1 style={{ fontWeight: 300, lineHeight: 1.2, textAlign: "center", margin: "60px 0", fontSize: "3rem" }}>No email templates found</h1>}
        </> :
        <>
          {isGenerate === false && (
            <div style={{ textAlign: "left", maxHeight: "calc(100vh - 331px)", overflow: "auto" }} id="copyTemplate">
              <table style={{ marginBottom: "20px" }}>
                <tr height="30px">
                  <td>Job Code</td>
                  <td width="30px" align="center">:</td>
                  <td ><strong>{profileDetails[0].jobCode}</strong></td>
                </tr>
                <tr height="30px">
                  <td>Cadidate Name</td>
                  <td align="center">:</td>
                  <td><strong>{profileDetails[0].candidateName}</strong></td>
                </tr>
                <tr height="30px">
                  <td>Cadidate Number</td>
                  <td align="center">:</td>
                  <td><strong>{profileDetails[0].contactNumber}  </strong></td>
                </tr>
              </table>

              <div dangerouslySetInnerHTML={{ __html: selectedTemplate[0][0].smsText }}></div>
            </div>
          )}


          {!errorMessage && (
            <Button variant="contained" component="label"
              className="copytemplate"
              onClick={(e) => { onActionTemplate("send") }}
            >
              Send SMS
            </Button>
          )}
        </>
      }
    </>
  )
}

export default SendSms;