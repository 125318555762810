import React, { useState, useEffect } from "react";
import { Card, CardContent, Switch } from "@mui/material";
import DynamicTable from "../../../../components/DynamicTable/DynamicTable";
import "../ApplicantsNewDashboard.css";
import { configuration, columnsData } from "../widget";
import CardTitle from "../CardTitle/CardTitle";
import { Encrypt, decrypt } from "../../../../_utilities/_encryptDecryptHelper";
import { useNavigate } from "react-router-dom";
import useUnsavedChangesWarning from "../../../../hooks/useUnsavedChangesWarning";

const TableWidgetCard = ({
  card,
  showToggle = false,
  switchChange,
  editMode,
  handleSaveCardTitle,
  handleDeleteCard,
  handleEditCard,
  handleCardTitle,
  cardTitleChangeId,
}) => {
  const data = card.data;
  const [toggle, switchToggle] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const navigate = useNavigate();

  useUnsavedChangesWarning( unsavedChanges, "You have unsaved changes. Are you sure you want to leave?");

  let IsAllcountries = JSON.parse(decrypt("allCountryId"));

  useEffect(() => {
    if(IsAllcountries !== "" || IsAllcountries !== null){
      let userData = JSON.parse(decrypt("GetUserInfo"));
      let userCountries = JSON.parse(userData[0].selectedcountries);
      if(data && data.length > 0){
        data.forEach((item)=>{
         let countryIndex = userCountries.findIndex(x => x.id === item.countryId);
          if(countryIndex !== -1){
            item["countryLogo"] = userCountries[countryIndex].logo;
            item["countryName"] = userCountries[countryIndex].name;
          }
        })
      }
    };
  }, [data, IsAllcountries])

  let headerCountry = JSON.parse(decrypt("selectCountryId"));

  const onReceiveActionHandler = async (type, object) => {
       
      Encrypt(JSON.stringify(object.countryId), "selectCountryId");
      let country = {id: object.countryId, name : object.countryName , logo : object.countryLogo}
      Encrypt(JSON.stringify(country), "selectCountry");

      if(headerCountry !== object.countryId){
      if (window.confirm(`You are about to view job details from ${object.countryName}. The application will now display all information specific to ${object.countryName}. Do you want to proceed?`)) {
        // Navigate to view page
        navigate(`/ats/layout/jobpostings/view/${object.id}`);
      } else {
        // Cancel navigation or do nothing
        console.log('User canceled navigation');
      }
    } else {
      navigate(`/ats/layout/jobpostings/view/${object.id}`);
    }
  }

  return (
    <Card className="dashboard__card">
      <CardTitle
        card={card}
        editMode={editMode}
        handleSaveCardTitle={handleSaveCardTitle}
        handleDeleteCard={handleDeleteCard}
        handleEditCard={handleEditCard}
        handleCardTitle={handleCardTitle}
        cardTitleChangeId={cardTitleChangeId}
      />
      <CardContent style={{ position: "relative" }}>
        {showToggle && (
          <div style={{ textAlign: "right" }}>
            <div className="switch-container" id="dashboardBtn">
              <span className="text">Individual</span>
              <Switch
                checked={toggle}
                className="activeBtns"
                name="pieChartIndividualORTeam"
                onChange={() => {
                  const value = !toggle;
                  switchToggle(value);
                  switchChange(value);
                }}
              />
              <span className="text">Team</span>
            </div>
          </div>
        )}
        {data?.length > 0 ? (
          <DynamicTable
            config={configuration}
            data={data || []}
            colmns={columnsData}
            actionDropdown={[]}
            actions={[]}
            filterValue={""}
            noDataText={"No Postings found"}
            addModel={{}}
            searchKeyWord=""
            onReceiveActionProps={(type, obj) =>
              onReceiveActionHandler(type, obj)}
          />
        ) : (
          <div className="dashboard__card-no-data-container">
            No data available
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default React.memo(TableWidgetCard);
